import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { AuthHeader } from "Components/molecules";

export const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <Box component="main">
      <AuthHeader />
      <Box
        px={2}
        py={8}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        {children}
      </Box>
    </Box>
  );
};
