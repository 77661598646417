import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Button } from "Components";
import { Field, Formik, FormikConfig, Form } from "formik";
import { TextField } from "formik-mui";
import { CompanyJobClassification } from "gql/graphql";
import React, { useCallback, useEffect, useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  onDone: (newJobClassifications: string[]) => Promise<void>;
  initialJobClassifications?: CompanyJobClassification[] | null;
}

interface FormValues {
  value: "";
}

export const JobClassificationsDialog = ({
  open,
  onClose,
  onDone,
  initialJobClassifications,
}: Props) => {
  const [jobClassifications, setJobClassifications] = useState<string[]>([]);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const onRemoveJobClassification = useCallback((jobClassification: string) => {
    setJobClassifications((current) =>
      current.filter((c) => c !== jobClassification)
    );
  }, []);

  const getInputRef = useCallback((instance: HTMLInputElement | null) => {
    if (instance) {
      setInputRef((current) => current || instance);
    }
  }, []);

  const handleDone = useCallback(() => {
    onDone(jobClassifications).then(() => {
      setJobClassifications([]);
    });
  }, [jobClassifications, onDone]);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values, helpers) => {
      setJobClassifications((current) => {
        if (current.includes(values.value)) {
          return current;
        }
        return current.concat(values.value);
      });
      helpers.resetForm();
      setTimeout(() => {
        inputRef?.focus();
      }, 0);
    },
    [inputRef]
  );

  useEffect(() => {
    if (open && inputRef) {
      inputRef.focus();
    }
  }, [open, inputRef]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          background: "rgba(246, 246, 246, 1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Update Job Classifications
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(3),
          }}
        >
          <Box
            sx={{
              border: "1px solid rgba(130, 130, 130, 1)",
              borderRadius: (theme) => theme.spacing(0.5),
            }}
            p={1.5}
          >
            <Typography variant="h6">Job Classifications</Typography>
            <Box mt={2}>
              <Formik<FormValues>
                enableReinitialize
                onSubmit={onSubmit}
                initialValues={{
                  value: "",
                }}
              >
                {({ values, isSubmitting }) => {
                  return (
                    <Form>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={2}
                        flexWrap="nowrap"
                      >
                        <Field
                          component={TextField}
                          name="value"
                          size="small"
                          variant="outlined"
                          required
                          label="Job Classification"
                          fullWidth
                          inputRef={getInputRef}
                          autoComplete="off"
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={!values.value || isSubmitting}
                        >
                          ADD
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
              {initialJobClassifications?.length ||
              jobClassifications.length ? (
                <Box mt={2}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1.5}
                    direction="column"
                  >
                    {initialJobClassifications?.map(
                      (initialJobClassification) => (
                        <Grid item key={initialJobClassification.id}>
                          <Box
                            sx={{
                              border: "1px solid rgba(130, 130, 130, 1)",
                              borderRadius: (theme) => theme.spacing(0.5),
                              background: "rgba(248, 248, 248, 1)",
                            }}
                            p={1}
                          >
                            <Typography>
                              {initialJobClassification.name}
                            </Typography>
                          </Box>
                        </Grid>
                      )
                    )}
                    {jobClassifications?.map((jobClassification) => (
                      <Grid item key={jobClassification}>
                        <Box
                          sx={{
                            border: "1px solid rgba(130, 130, 130, 1)",
                            borderRadius: (theme) => theme.spacing(0.5),
                          }}
                          p={1}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={2}
                          flexWrap="nowrap"
                          flexDirection="row"
                        >
                          <Typography>{jobClassification}</Typography>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              onRemoveJobClassification(jobClassification);
                            }}
                          >
                            REMOVE
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDone} variant="contained">
          DONE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
