import React, { PropsWithChildren, useEffect } from "react";
import { Box, Container, Paper } from "@mui/material";
import { useResponsive } from "hooks";

declare global {
  interface Window {
    _mfq: any[];
  }
}

export const LepCalculatorLayout = ({ children }: PropsWithChildren) => {
  const { isMDDown } = useResponsive();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._mfq = window._mfq || [];
    function initFbQ() {
      const mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src =
        "//cdn.mouseflow.com/projects/7885981f-d6ad-447a-a081-770f22c822b0.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    }
    initFbQ();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <Container>
        <Box
          sx={{
            padding: ({ spacing: s }) => s(2, isMDDown ? 0 : 2),
          }}
        >
          <Paper
            sx={{
              padding: ({ spacing: s }) =>
                s(isMDDown ? 4 : 8, isMDDown ? 2 : 8),
            }}
          >
            {children}
          </Paper>
        </Box>
      </Container>
      <a
        title="Privacy-friendly Web Analytics"
        href="https://clicky.com/101371892"
      >
        <img
          alt="Clicky"
          src="//static.getclicky.com/media/links/badge.gif"
          style={{
            border: 0,
          }}
        />
      </a>
      <script async src="//static.getclicky.com/101371892.js" />
      <noscript>
        <p>
          <img
            alt="Clicky"
            width="1"
            height="1"
            src="//in.getclicky.com/101371892ns.gif"
          />
        </p>
      </noscript>
    </>
  );
};
