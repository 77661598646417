import React, { useCallback, useEffect, useMemo, useState } from "react";

import moment, { Moment } from "moment";
import { DatePicker, DatePickerProps } from "@mui/lab";
import { FieldProps } from "formik";
import { DATE_TYPING_FORMAT } from "app-constants";
import { Box, FormHelperText, TextField, TextFieldProps } from "@mui/material";

export interface FormikDatePickerProps
  extends DatePickerProps<Moment>,
    FieldProps {
  typingFormat?: string;
  size?: "small";
  required?: boolean;
  fullWidth?: boolean;
  maxWidth?: number;
  useFloatingError?: boolean;
  defaultOpenedDate?: string;
  disableDateSelector?: boolean;
}

export const FormikDatePicker: React.FC<FormikDatePickerProps> = ({
  typingFormat = DATE_TYPING_FORMAT,
  field,
  field: { name, value },
  form: { setFieldTouched, setFieldValue, getFieldMeta, validateForm },
  size = "small",
  label,
  required,
  fullWidth,
  maxWidth,
  useFloatingError,
  defaultOpenedDate,
  disableDateSelector,
  ...props
}) => {
  const [isValidatedOnce, setIsValidatedOnce] = useState(false);

  const touched = useMemo(
    () => getFieldMeta(name).touched,
    [getFieldMeta, name]
  );

  const error = useMemo(() => getFieldMeta(name).error, [getFieldMeta, name]);

  const momentValue = useMemo(() => {
    if (!value) {
      return null;
    }
    return moment(value as string);
  }, [value]);

  const onBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [setFieldTouched, name]);

  const onOpen = useCallback(() => {
    setFieldTouched(name, false);
    if (!value && defaultOpenedDate) {
      setFieldValue(name, defaultOpenedDate);
    }
  }, [setFieldTouched, name, value, setFieldValue, defaultOpenedDate]);

  const onChange = useCallback(
    (v: Moment | null) => {
      setFieldValue(name, v ? moment(v).toISOString() : "");
    },
    [name, setFieldValue]
  );

  useEffect(() => {
    if (value && !isValidatedOnce) {
      setInterval(() => {
        validateForm();
        setIsValidatedOnce(true);
      }, 0);
    }
  }, [validateForm, value, isValidatedOnce]);

  return (
    <DatePicker
      {...props}
      onClose={onBlur}
      inputFormat={typingFormat}
      onChange={onChange}
      onOpen={onOpen}
      open={disableDateSelector ? false : undefined}
      value={momentValue}
      renderInput={({ key, ...params }: TextFieldProps) => (
        <>
          <TextField
            {...params}
            value={momentValue}
            name={name}
            onBlur={onBlur}
            error={!!touched && !!error}
            helperText={
              touched && error && !useFloatingError ? error : undefined
            }
            size={size}
            label={label}
            required={required}
            fullWidth={fullWidth}
            InputProps={
              disableDateSelector
                ? {
                    endAdornment: null,
                  }
                : undefined
            }
            sx={{
              maxWidth,
            }}
            key={key}
          />
          {touched && error && useFloatingError ? (
            <Box
              sx={{
                position: "relative",
                height: 0,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
              >
                <FormHelperText
                  sx={{
                    paddingLeft: 0,
                    marginLeft: 0,
                    fontSize: "0.8rem",
                  }}
                  error
                >
                  {error}
                </FormHelperText>
              </Box>
            </Box>
          ) : null}
        </>
      )}
    />
  );
};
