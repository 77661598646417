import { getAxiosAuthorizationToken } from "api";
import { INTEGRATION_APP_URL, NODE_ENVIRONMENT } from "app-constants";
import { useAuth, useResponsive } from "hooks";
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
  CSSProperties,
} from "react";
import { Loader } from "Components";
import {
  addIntegrationPortalActionListeners,
  setIntegrationAppWindow,
} from "./helpers";

export interface IntegrationAppProps {
  path: string;
  appKey: string;
  appIntegrationSource?: string;
  onHandleCustomListenerHandler?: (payload?: Record<string, unknown>) => void;
  recordID?: string | null;
  extraData?: Record<string, unknown>;
  waitForExtraData?: boolean;
  waitForRecordID?: boolean;
  requiresToken?: boolean;
  style?: CSSProperties;
}

export const Integration = ({
  appKey,
  appIntegrationSource,
  path,
  recordID,
  onHandleCustomListenerHandler = () => {},
  extraData,
  waitForExtraData = false,
  waitForRecordID = false,
  requiresToken = true,
  style,
}: IntegrationAppProps) => {
  const iFramePostMessageRef = useRef<null | HTMLIFrameElement>(null);
  const [integrationAppReady, setIntegrationAppReady] = useState(false);
  const [uibReady, setUIBReady] = useState(false);
  const { isAuthenticated } = useAuth();

  const token = useMemo(
    () => (isAuthenticated ? getAxiosAuthorizationToken() : ""),
    [isAuthenticated]
  );

  const fullDetailsMissing = useMemo(
    () =>
      (waitForExtraData && !extraData) ||
      (waitForRecordID && !recordID) ||
      (requiresToken && !token),
    [
      waitForExtraData,
      extraData,
      waitForRecordID,
      recordID,
      requiresToken,
      token,
    ]
  );

  const configPath: string = useMemo(() => {
    const data = extraData ? `&extraData=${JSON.stringify(extraData)}` : "";
    return `/${path}?appKey=${appKey}${
      appIntegrationSource
        ? `&appIntegrationSource=${appIntegrationSource}`
        : ""
    }${NODE_ENVIRONMENT ? `&environment=${NODE_ENVIRONMENT}` : ""}${data}`;
  }, [appKey, appIntegrationSource, path, extraData]);

  const sendTokenAndID = useCallback(() => {
    if (
      iFramePostMessageRef.current?.contentWindow &&
      integrationAppReady &&
      !fullDetailsMissing
    ) {
      iFramePostMessageRef.current.contentWindow?.postMessage(
        JSON.stringify({
          message: "PAGE_LOAD_DATA",
          data: {
            token,
            recordID,
          },
          appKey,
        }),
        INTEGRATION_APP_URL
      );
    }
  }, [
    iFramePostMessageRef,
    integrationAppReady,
    token,
    recordID,
    fullDetailsMissing,
    appKey,
  ]);

  const handleAddIntegrationAppEventListener = useCallback(() => {
    return addIntegrationPortalActionListeners(
      [
        {
          message: "INTEGRATION_APP_READY",
          handler: () => {
            setIntegrationAppReady(true);
          },
        },
        {
          message: "UIB_READY",
          handler: () => {
            setUIBReady(true);
          },
        },
        {
          message: "ACTION_FROM_UI_BAKERY",
          handler: onHandleCustomListenerHandler,
        },
      ],
      appKey
    );
  }, [onHandleCustomListenerHandler, appKey]);

  useEffect(() => {
    const fn = handleAddIntegrationAppEventListener();

    return fn;
  }, [handleAddIntegrationAppEventListener]);

  useEffect(() => {
    sendTokenAndID();
  }, [sendTokenAndID]);

  useEffect(() => {
    if (
      integrationAppReady &&
      uibReady &&
      iFramePostMessageRef.current?.contentWindow
    ) {
      setIntegrationAppWindow(
        appKey,
        iFramePostMessageRef.current.contentWindow
      );
      return setIntegrationAppWindow(appKey, null);
    }
  }, [integrationAppReady, uibReady, appKey]);

  if (fullDetailsMissing) {
    return <Loader open />;
  }

  return (
    <iframe
      title="Start Policy Request"
      src={`${INTEGRATION_APP_URL}${configPath}`}
      ref={iFramePostMessageRef}
      style={{
        height: window.innerHeight,
        width: "100%",
        ...style,
      }}
    />
  );
};

export const IntegrationApp = ({
  mobileAppKey,
  desktopAppKey,
  onRenderedAppChange,
  ...integrationProps
}: Omit<IntegrationAppProps, "appKey"> & {
  mobileAppKey?: string;
  desktopAppKey: string;
  onRenderedAppChange?: (renderedApp: string) => void;
}) => {
  const { isMobile } = useResponsive();

  const showMobile = useMemo(
    () => isMobile && !!mobileAppKey,
    [isMobile, mobileAppKey]
  );

  const key = useMemo(() => (showMobile ? "mobile" : "desktop"), [showMobile]);

  useEffect(() => {
    if (onRenderedAppChange) {
      onRenderedAppChange(showMobile ? mobileAppKey! : desktopAppKey);
    }
  }, [onRenderedAppChange, mobileAppKey, showMobile, desktopAppKey]);

  if (showMobile) {
    return (
      <Integration {...integrationProps} appKey={mobileAppKey!} key={key} />
    );
  }

  return <Integration {...integrationProps} appKey={desktopAppKey} key={key} />;
};
