import { Box, Grid, Typography } from "@mui/material";
import React from "react";

interface Props {
  left: string | number;
  right: string | number;
  isHeading?: boolean;
  isBold?: boolean;
}

export const Group = ({ left, right, isHeading, isBold = false }: Props) => {
  return (
    <Box>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item flex={1}>
          <Typography
            variant="h5"
            sx={{
              fontStyle: "italic",
              color: "rgba(0, 133, 255, 1)",
              fontSize: isHeading ? 16 : 15,
              fontWeight: isHeading || isBold ? "bold" : undefined,
            }}
            align="right"
          >
            {left}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontStyle: "italic",
              color: "rgba(0, 133, 255, 1)",
              fontSize: 15,
              visibility: isHeading ? "hidden" : undefined,
            }}
          >
            --
          </Typography>
        </Grid>
        <Grid item flex={1}>
          <Typography
            variant="h5"
            sx={{
              fontStyle: "italic",
              color: "rgba(0, 133, 255, 1)",
              fontSize: isHeading ? 16 : 15,
              fontWeight: isHeading || isBold ? "bold" : undefined,
            }}
          >
            {right}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
