import { FormControl, Grid, MenuItem } from "@mui/material";
import { FormikDatePicker } from "Components/elements";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import React, { useMemo } from "react";
import { DATE_DISPLAY_FORMAT, DATE_TYPING_FORMAT } from "app-constants";
import {
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  GenderEnum,
  MedicarePrimaryEnum,
} from "gql/graphql";
import { useResponsive } from "hooks";
import {
  coverageTypeOptions,
  coverageTypeTitles,
  payrollCycleOptions,
  payrollCycleTitle,
  startOfToday,
} from "./helpers";
import { FormValues } from "./types";

export const EmployeeFields = () => {
  const { isDesktop, isTablet } = useResponsive();
  const { values } = useFormikContext<FormValues>();

  const xs = useMemo(() => {
    if (isDesktop) {
      return 4;
    }
    return 12;
  }, [isDesktop]);

  const xsForNames = useMemo(() => {
    if (isDesktop) {
      return 2;
    }
    if (isTablet) {
      return 6;
    }
    return 12;
  }, [isDesktop, isTablet]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={xsForNames}>
        <Field
          size="small"
          component={TextField}
          name="firstName"
          label="First Name"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={xsForNames}>
        <Field
          size="small"
          component={TextField}
          name="lastName"
          fullWidth
          label="Last Name"
          required
        />
      </Grid>

      <Grid item xs={xs}>
        <Field
          size="small"
          component={FormikDatePicker}
          disablePast={false}
          disableToolbar={false}
          disableFuture
          maxDate={startOfToday}
          label="Date of Birth"
          name="dob"
          required
          placeholder={DATE_TYPING_FORMAT.toUpperCase()}
          typingFormat={DATE_TYPING_FORMAT}
          displayFormat={DATE_DISPLAY_FORMAT}
          fullWidth
        />
      </Grid>

      <Grid item xs={xs}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Field
            component={TextField}
            select
            SelectProps={{
              displayEmpty: true,
            }}
            name="gender"
            label="Gender"
            required
            size="small"
          >
            <MenuItem value={GenderEnum.M}>Male</MenuItem>
            <MenuItem value={GenderEnum.F}>Female</MenuItem>
          </Field>
        </FormControl>
      </Grid>

      <Grid item xs={xs}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Field
            component={TextField}
            select
            SelectProps={{
              displayEmpty: true,
            }}
            name="medicarePrimary"
            label="Medicare Primary"
            size="small"
          >
            <MenuItem value={MedicarePrimaryEnum.Yes}>Yes</MenuItem>
            <MenuItem value={MedicarePrimaryEnum.No}>No</MenuItem>
            <MenuItem value={MedicarePrimaryEnum.U}>Unknown</MenuItem>
          </Field>
        </FormControl>
      </Grid>

      <Grid item xs={xs}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Field
            component={TextField}
            select
            SelectProps={{
              displayEmpty: true,
            }}
            name="employeeType"
            label="Employee Type"
            required
            size="small"
          >
            <MenuItem
              value={
                EmployeesTheCompanyWantsToOfferBenefitsToEnum.FullTimeEmployees
              }
            >
              Full-Time
            </MenuItem>
            <MenuItem
              value={
                EmployeesTheCompanyWantsToOfferBenefitsToEnum.PartTimeEmployees
              }
            >
              Part-Time
            </MenuItem>
            <MenuItem
              value={
                EmployeesTheCompanyWantsToOfferBenefitsToEnum.Contractors_1099
              }
            >
              1099 Contractor
            </MenuItem>
          </Field>
        </FormControl>
      </Grid>

      <Grid item xs={xs}>
        <Field
          size="small"
          component={TextField}
          name="zipCode"
          fullWidth
          label="Zip Code"
          required
        />
      </Grid>

      <Grid item xs={xs}>
        <Field
          size="small"
          component={FormikDatePicker}
          disablePast={false}
          disableToolbar={false}
          disableFuture
          maxDate={startOfToday}
          label="Date of Hire"
          name="dateOfHire"
          placeholder={DATE_TYPING_FORMAT.toUpperCase()}
          typingFormat={DATE_TYPING_FORMAT}
          displayFormat={DATE_DISPLAY_FORMAT}
          fullWidth
        />
      </Grid>

      <Grid item xs={xs}>
        <Field
          size="small"
          component={TextField}
          name="jobTitle"
          fullWidth
          label="Job Title"
        />
      </Grid>

      <Grid item xs={xs}>
        <Field
          size="small"
          component={TextField}
          name="salary"
          fullWidth
          integer="true"
          type="number"
          inputProps={{
            min: 0,
          }}
          label="Salary (USD)"
        />
      </Grid>

      <Grid item xs={xs}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Field
            component={TextField}
            select
            SelectProps={{
              displayEmpty: true,
            }}
            name="payrollCycle"
            label="Payroll Cycle"
            size="small"
          >
            {payrollCycleOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {payrollCycleTitle[option]}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
      </Grid>

      <Grid item xs={xs}>
        <Field
          size="small"
          fullWidth
          component={TextField}
          name="email"
          label="Email"
          type="email"
        />
      </Grid>

      <Grid item xs={xs}>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Field
            component={TextField}
            select
            SelectProps={{
              displayEmpty: true,
            }}
            name="coverage"
            label={values.coverage ? "Coverage Type" : "Select Coverage Type"}
            size="small"
            required
          >
            {coverageTypeOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {coverageTypeTitles[option]}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
      </Grid>
    </Grid>
  );
};
