import React, { useCallback, useEffect, useState } from "react";
import { quoteRequestActions, useAppDispatch } from "store";
import { ROUTES } from "app-constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Box, Grid, Typography } from "@mui/material";
import { Button, Loader } from "Components";
import {
  Opportunity,
  OpportunityActivePolicy,
  OpportunityProduct,
  OpportunityStageEnum,
  OpportunityTypeEnum,
} from "gql/graphql";
import { useResponsive } from "hooks";
import { UIBRequestPageRes } from "./types";
import { fetchOpportunitiesRequests } from "./request";
import { OpportunityStatus } from "./OpportunityStatus";
import { StageButton } from "./StageButton";

const formatDate = (date: any) => {
  try {
    return moment(date as string).format("DD MMM, YYYY hh:mm A");
  } catch (error) {
    return "";
  }
};

const getTotalEmployees = (products?: OpportunityProduct[] | null) => {
  try {
    return (
      products?.reduce((total, current) => {
        return (
          total +
          Number(current.opportunityProductInterestedEmployees?.length || 0)
        );
      }, 0) || 0
    );
  } catch (error) {
    return 0;
  }
};

const getSingleCarrier = (products?: OpportunityActivePolicy[] | null) => {
  try {
    if (!products) {
      return "";
    }
    return products[0].activePolicy.carrier || "";
  } catch (error) {
    return "";
  }
};

const getSinglePolicyCode = (products?: OpportunityActivePolicy[] | null) => {
  try {
    if (!products) {
      return "";
    }
    return products[0].activePolicy.number || "";
  } catch (error) {
    return "";
  }
};
const getTotalEmployeesAndDependents = (
  products?: OpportunityProduct[] | null
) => {
  try {
    return (
      products?.reduce((total, current) => {
        const employeesCount =
          current.opportunityProductInterestedEmployees?.length || 0;
        const dependentsCount =
          current.opportunityProductInterestedEmployees?.reduce<number>(
            (totalDependents, currentDependents) => {
              return (
                totalDependents +
                Number(
                  currentDependents.interestedEmployeeDependentIds?.length || 0
                )
              );
            },
            0
          ) || 0;
        return total + dependentsCount + employeesCount;
      }, 0) || 0
    );
  } catch (error) {
    return 0;
  }
};

const quoteNames: Record<OpportunityTypeEnum, string> = {
  [OpportunityTypeEnum.AorChange]: "Aor Change",
  [OpportunityTypeEnum.QuoteRequest]: "Quote Request",
  [OpportunityTypeEnum.InterestNotConfirmed]: "Interest Not Confirmed",
};

export const Requests = () => {
  const { isMDDown } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [loading, setLoading] = useState(false);
  const [opportunitiesFetched, setOpportunitiesFetched] = useState(false);

  const onSetForGetQuote = useCallback(() => {
    dispatch(quoteRequestActions.setForGetQuote());
  }, [dispatch]);

  const onSetForContinueQuote = useCallback(
    (quoteRequest: UIBRequestPageRes["quoteRequest"]) => {
      dispatch(quoteRequestActions.setForContinueQuote(quoteRequest));
    },
    [dispatch]
  );

  const onSetForHB2015 = useCallback(
    (quoteRequest: UIBRequestPageRes["quoteRequest"]) => {
      dispatch(quoteRequestActions.setForHB2015(quoteRequest));
    },
    [dispatch]
  );

  const onGoToNewRequest = useCallback(() => {
    onSetForGetQuote();
    navigate(ROUTES.dashboardHome.fullPath);
  }, [onSetForGetQuote, navigate]);

  const onGoToGetQuote = useCallback(() => {
    onSetForGetQuote();
    navigate(ROUTES.dashboardHome.fullPath, {
      state: { goToGetQuoteStep: true },
    });
  }, [onSetForGetQuote, navigate]);

  const onContinueQuote = useCallback(
    (quoteRequest: UIBRequestPageRes["quoteRequest"]) => {
      onSetForContinueQuote(quoteRequest);
      navigate(ROUTES.dashboardHome.fullPath, {
        state: { goToGetQuoteStep: true },
      });
    },
    [onSetForContinueQuote, navigate]
  );

  const onSignHB2015 = useCallback(
    (quoteRequest: UIBRequestPageRes["quoteRequest"]) => {
      onSetForHB2015(quoteRequest);
      navigate(ROUTES.dashboardHome.fullPath, {
        state: { goToGetQuoteStep: true },
      });
    },
    [onSetForHB2015, navigate]
  );

  const getOnClick = useCallback(
    (opportunity: Opportunity) => {
      if (opportunity.type === OpportunityTypeEnum.AorChange) {
        return () => {};
      }
      if (
        opportunity.type === OpportunityTypeEnum.QuoteRequest &&
        opportunity.requiresHB2015Signing &&
        opportunity.stage === OpportunityStageEnum.Submitted &&
        !!opportunity.opportunityActivePolicies?.some(
          (opportunityActivePolicy) =>
            !opportunityActivePolicy.hb2015LetterSigned
        )
      ) {
        return () => {
          onSignHB2015(opportunity);
        };
      }
      switch (opportunity.stage) {
        case OpportunityStageEnum.PendingInformationCollection:
          return () => {
            onContinueQuote(opportunity);
          };
        default:
          return () => {};
      }
    },
    [onContinueQuote, onSignHB2015]
  );

  const onNewQuote = useCallback(() => {
    onGoToNewRequest();
  }, [onGoToNewRequest]);

  const onGetQuote = useCallback(() => {
    onGoToGetQuote();
  }, [onGoToGetQuote]);

  const fetchOpportunities = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchOpportunitiesRequests();
      setOpportunities(res);
    } catch (error) {
      //
    } finally {
      setLoading(false);
      setOpportunitiesFetched(true);
    }
  }, []);

  useEffect(() => {
    fetchOpportunities();
  }, [fetchOpportunities]);

  return (
    <Box>
      <Loader open={loading} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: isMDDown ? 24 : 32,
              fontWeight: 500,
            }}
          >
            Requests
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={onNewQuote}>NEW QUOTE</Button>
        </Grid>
      </Grid>
      {!opportunities.length && opportunitiesFetched ? (
        <Box mt={3}>
          <Box
            mt={1}
            sx={{
              maxWidth: 534,
            }}
          >
            <Box
              sx={{
                border: "1px solid rgba(189, 189, 189, 1)",
                borderRadius: (theme) => theme.spacing(0.5),
                padding: (theme) => theme.spacing(7, 2),
              }}
            >
              <Typography textAlign="center">
                You currently have no Requests.
              </Typography>
            </Box>
            <Box mt={3}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                wrap="nowrap"
              >
                <Grid item>
                  <Button onClick={onGetQuote}>GET QUOTE</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
      {opportunities.length && opportunitiesFetched ? (
        <Box mt={3}>
          <Box mt={1}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={3}
              wrap="wrap"
            >
              {opportunities.map((opportunity) => (
                <Grid item xs={isMDDown ? 12 : 6} key={opportunity.id}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "fit-content",
                      minHeight: "100%",
                      border: "1px solid rgba(189, 189, 189, 1)",
                      padding: (theme) => theme.spacing(2),
                      borderRadius: (theme) => theme.spacing(0.5),
                      background:
                        opportunity.type === OpportunityTypeEnum.AorChange
                          ? "rgba(241, 248, 255, 1)                      "
                          : "rgba(248, 248, 248, 1)",
                    }}
                  >
                    <Typography fontWeight={500}>
                      {opportunity.name} - {quoteNames[opportunity.type!]}
                    </Typography>
                    {opportunity.type ===
                    OpportunityTypeEnum.AorChange ? null : (
                      <Box mt={1}>
                        <OpportunityStatus
                          requiresHB2015Signing={
                            !!opportunity.requiresHB2015Signing
                          }
                          opportunityActivePolicies={
                            opportunity.opportunityActivePolicies || []
                          }
                          status={opportunity.stage!}
                          type={opportunity.type!}
                        />
                      </Box>
                    )}
                    <Box mt={1.5}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        wrap="nowrap"
                      >
                        {opportunity.opportunityProducts?.map(
                          (opportunityProduct, index) => (
                            <Grid item key={opportunityProduct.id}>
                              <Typography
                                variant="caption"
                                sx={{
                                  borderRight:
                                    opportunity.opportunityProducts?.length &&
                                    opportunity.opportunityProducts?.length -
                                      1 ===
                                      index
                                      ? ""
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  padding: (theme) => theme.spacing(0, 1),
                                  paddingLeft: (theme) =>
                                    index === 0 ? theme.spacing(0) : undefined,
                                }}
                              >
                                {opportunityProduct.product.formattedName}
                              </Typography>
                            </Grid>
                          )
                        )}
                      </Grid>
                      {opportunity.type === OpportunityTypeEnum.AorChange ? (
                        <>
                          <Box mt={1.5}>
                            <Typography>
                              Carrier:{" "}
                              <Typography component="span" fontWeight={500}>
                                {getSingleCarrier(
                                  opportunity.opportunityActivePolicies
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <Typography>
                              Policy Code (Number):{" "}
                              <Typography component="span" fontWeight={500}>
                                {getSinglePolicyCode(
                                  opportunity.opportunityActivePolicies
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                      <Box
                        mt={
                          opportunity.type === OpportunityTypeEnum.AorChange
                            ? 1
                            : 1.5
                        }
                      >
                        <Typography>
                          Created On:{" "}
                          <Typography component="span" fontWeight={500}>
                            {formatDate(opportunity.createdAt)}
                          </Typography>
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography>
                          Last Modified On:{" "}
                          <Typography component="span" fontWeight={500}>
                            {formatDate(opportunity.updatedAt)}
                          </Typography>
                        </Typography>
                      </Box>
                      {opportunity.type !== OpportunityTypeEnum.AorChange ? (
                        <>
                          <Box mt={1}>
                            <Typography>
                              Interested Employees:{" "}
                              <Typography component="span" fontWeight={500}>
                                {getTotalEmployees(
                                  opportunity.opportunityProducts
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <Typography>
                              Interested Employees and Dependents:{" "}
                              <Typography component="span" fontWeight={500}>
                                {getTotalEmployeesAndDependents(
                                  opportunity.opportunityProducts
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                      <Box mt={2}>
                        <Grid
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                          wrap="nowrap"
                        >
                          <Grid item>
                            <StageButton
                              onClick={getOnClick(opportunity)}
                              stage={opportunity.stage!}
                              type={opportunity.type!}
                              requiresHB2015Signing={
                                !!opportunity.requiresHB2015Signing
                              }
                              opportunityActivePolicies={
                                opportunity.opportunityActivePolicies || []
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box mt={3}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                wrap="nowrap"
              >
                <Grid item>
                  <Button onClick={onGetQuote}>GET QUOTE</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
