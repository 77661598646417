import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  companySelectors,
  quoteRequestActions,
  quoteRequestSelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, FormikConfig } from "formik";
import { useResponsive } from "hooks";
import {
  Button,
  FormikAutocomplete,
  FormikDatePicker,
  FormikDropzone,
  Loader,
} from "Components";
import { TextField } from "formik-mui";
import { DATE_DISPLAY_FORMAT, DATE_TYPING_FORMAT } from "app-constants";
import { fetchS3SignedUrlForUpload, uploadFileToS3 } from "services";
import { Close } from "@mui/icons-material";
import * as yup from "yup";
import {
  OnGoToRequestsFromActivePolicy,
  OnGoToPolicyRequirementsFromActivePolicy,
  ActivePolicyWidgetRes,
  ActivePolicyWidgetResActivePolicy,
} from "../types";
import { mapStateActivePoliciesToActivePolicyWidgetRes } from "../helpers";
import { AddActivePolicyButton } from "./AddActivePolicyButton";

const validationSchema = yup.object().shape({
  activePolicies: yup
    .array()
    .required("You must add at least one active policy")
    .of(
      yup.object().shape({
        policyNumber: yup
          .string()
          .typeError("Policy number is required")
          .required("Policy number is required"),
        insuranceProvider: yup
          .string()
          .typeError("Insurance provider is required")
          .required("Insurance provider is required"),
        insuranceType: yup
          .string()
          .typeError("Insurance type is required")
          .required("Insurance type is required"),
        summary: yup.string().nullable(),
        otherDetails: yup.string().nullable(),
        termDate: yup
          .date()
          .typeError("Active policy term date is invalid")
          .nullable(),
      })
    ),
  otherDetails: yup.object().shape({
    startDoing: yup.string().nullable(),
    continueToDo: yup.string().nullable(),
  }),
});

interface EmployeeFormProps {
  onGoToPolicyRequirements: OnGoToPolicyRequirementsFromActivePolicy;
  onGoToRequests: OnGoToRequestsFromActivePolicy;
  onGoToProducts: () => void;
}

export const ActivePolicyForm = ({
  onGoToPolicyRequirements,
  onGoToRequests,
  onGoToProducts,
}: EmployeeFormProps) => {
  const { isDesktop, isSMUp } = useResponsive();
  const company = useAppSelector(companySelectors.getCompany);
  const activePolicies = useAppSelector(
    quoteRequestSelectors.getActivePolicies
  );
  const products = useAppSelector(quoteRequestSelectors.getProducts);
  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );
  const dispatch = useAppDispatch();
  const [loadingCount, setLoadingCount] = useState(0);
  const [activePolicyToDelete, setActivePolicyToDelete] =
    useState<ActivePolicyWidgetResActivePolicy | null>(null);
  const carriers = useAppSelector(quoteRequestSelectors.getCarriers);

  const initialValues = useMemo(() => {
    return mapStateActivePoliciesToActivePolicyWidgetRes(activePolicies);
  }, [activePolicies]);

  const insuranceProviders = useMemo(
    () => carriers.map((c) => ({ value: c.id, title: `${c.name}` })),
    [carriers]
  );

  const loading = useMemo(() => loadingCount > 0, [loadingCount]);

  const insuranceTypes = useMemo(
    () =>
      productsOffers
        .filter((productOffer) =>
          products?.some((product) => productOffer.id === product.id)
        )
        .map((productOffer) => ({
          value: productOffer.name,
          title: `${productOffer.formattedName}`,
        })),
    [productsOffers, products]
  );

  const setLoading = useCallback((value: boolean) => {
    setLoadingCount((current) => current + (value === true ? 1 : -1));
  }, []);

  const mapValues = useCallback((values: ActivePolicyWidgetRes) => {
    return {
      ...values,
      activePolicies: values.activePolicies.map((activePolicy) => ({
        ...activePolicy,
        document: activePolicy.document.map((d) => ({
          s3Key: (d as any).key,
          fileName: (d as any).file.name,
        })),
      })),
    };
  }, []);

  const onSubmit: FormikConfig<ActivePolicyWidgetRes>["onSubmit"] = useCallback(
    (values) => {
      onGoToPolicyRequirements(mapValues(values));
    },
    [onGoToPolicyRequirements, mapValues]
  );

  const handleSaveAsDraft = useCallback(
    (values: ActivePolicyWidgetRes) => {
      onGoToRequests(mapValues(values));
    },
    [onGoToRequests, mapValues]
  );

  const handleOpenDeleteDialog = useCallback(
    (activePolicy: ActivePolicyWidgetResActivePolicy) => {
      setActivePolicyToDelete(activePolicy);
    },
    []
  );

  const handleCloseDeleteDialog = useCallback(() => {
    setActivePolicyToDelete(null);
  }, []);

  const fetchCarriers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchCarriers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading]);

  const fetchProductsOffers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchProductsOffers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading]);

  const handleUploadFile = useCallback(
    async (
      file: File,
      abortRequest: (instance: XMLHttpRequest) => void,
      trackProgress: (progressEvent: ProgressEvent<EventTarget>) => any
    ) => {
      const s3Data = await fetchS3SignedUrlForUpload(
        file.name,
        file.type,
        "Document"
      );
      await uploadFileToS3(file, s3Data.url, abortRequest, trackProgress);
      return s3Data.key;
    },
    []
  );

  useEffect(() => {
    if (!productsOffers.length) {
      fetchProductsOffers();
    }
  }, [productsOffers.length, fetchProductsOffers]);

  useEffect(() => {
    if (!carriers?.length) {
      fetchCarriers();
    }
  }, [carriers?.length, fetchCarriers]);

  useEffect(() => {
    if (!products?.length) {
      onGoToProducts();
    }
  }, [products?.length, onGoToProducts]);

  if (!company) {
    return null;
  }

  return (
    <Box>
      <Loader open={loading} />
      <Typography
        variant="h5"
        sx={{
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        Active Policies
      </Typography>

      <Formik<ActivePolicyWidgetRes>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isValid, values, isSubmitting, setFieldValue }) => {
          return (
            <Form>
              {values.activePolicies.length ? (
                <>
                  {values.activePolicies.map((activePolicy, index) => {
                    return (
                      <Box key={activePolicy.id} mt={3}>
                        <Box
                          sx={{
                            border: "1px solid rgba(189, 189, 189, 1)",
                            borderRadius: (theme) => theme.spacing(0.5),
                            gap: (theme) => theme.spacing(3),
                            padding: (theme) => theme.spacing(2),
                            background: "white",
                          }}
                        >
                          <Grid
                            container
                            justifyContent="flex-start"
                            flexDirection="row"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            spacing={isDesktop ? 3 : 1.5}
                          >
                            <Grid item xs={isDesktop ? 4 : 12}>
                              <Field
                                required
                                size="small"
                                name={`activePolicies.${index}.policyNumber`}
                                component={TextField}
                                label="Policy Number"
                                fullWidth
                              />
                            </Grid>
                            {isDesktop ? (
                              <Grid item xs={isDesktop ? 8 : 12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    color="error"
                                    onClick={() => {
                                      handleOpenDeleteDialog(activePolicy);
                                    }}
                                    variant="contained"
                                  >
                                    DELETE
                                  </Button>
                                </Box>
                              </Grid>
                            ) : null}
                            <Grid item xs={isDesktop ? 4 : 12}>
                              <Field
                                required
                                size="small"
                                name={`activePolicies.${index}.insuranceProvider`}
                                component={FormikAutocomplete}
                                label="Insurance Provider"
                                fullWidth
                                options={insuranceProviders}
                              />
                            </Grid>
                            <Grid item xs={isDesktop ? 4 : 12}>
                              <Field
                                required
                                size="small"
                                name={`activePolicies.${index}.insuranceType`}
                                component={FormikAutocomplete}
                                label="Insurance Type"
                                fullWidth
                                options={insuranceTypes}
                              />
                            </Grid>
                            <Grid item xs={isDesktop ? 4 : 12}>
                              <Field
                                component={FormikDatePicker}
                                label="Active Policy Term Date"
                                name={`activePolicies.${index}.activePolicyTermDate`}
                                placeholder={DATE_TYPING_FORMAT.toUpperCase()}
                                typingFormat={DATE_TYPING_FORMAT}
                                displayFormat={DATE_DISPLAY_FORMAT}
                                fullWidth
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={isDesktop ? 6 : 12}>
                              <Field
                                component={TextField}
                                label="Summary of Monthly Cost (Premiums)"
                                name={`activePolicies.${index}.summary`}
                                fullWidth
                                size="small"
                                multiline
                                rows={3}
                              />
                            </Grid>
                            <Grid item xs={isDesktop ? 6 : 12}>
                              <Field
                                component={TextField}
                                label="Other Details"
                                name={`activePolicies.${index}.otherDetails`}
                                fullWidth
                                size="small"
                                multiline
                                rows={3}
                              />
                            </Grid>

                            <Grid item xs={isDesktop ? 6 : 12}>
                              <Field
                                component={FormikDropzone}
                                initialFiles={
                                  initialValues.activePolicies[
                                    index
                                  ]?.document.map((d) => ({
                                    key: d.s3Key,
                                    id: d.s3Key,
                                    file: {
                                      type: "file",
                                      name: d.fileName,
                                    },
                                  })) || []
                                }
                                name={`activePolicies.${index}.document`}
                                options={{
                                  accept: {
                                    "image/*": [".png", ".jpeg", ".jpg"],
                                    "application/pdf": [".pdf"],
                                  },
                                  multiple: false,
                                }}
                                onUpload={handleUploadFile}
                                progressPosition="top"
                                showStatus
                                size="small"
                                inputId={`activePolicies.${index}.document`}
                                fullWidth
                              />
                            </Grid>
                            {!isDesktop ? (
                              <Grid item xs={isDesktop ? 8 : 12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    color="error"
                                    onClick={() => {
                                      handleOpenDeleteDialog(activePolicy);
                                    }}
                                    variant="contained"
                                  >
                                    DELETE
                                  </Button>
                                </Box>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>
                      </Box>
                    );
                  })}
                  <Box mt={3}>
                    <Typography variant="h6">Other Details</Typography>
                    <Box mt={1}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        flexDirection="row"
                        alignItems="flex-start"
                        flexWrap="wrap"
                        spacing={isDesktop ? 3 : 1}
                      >
                        <Grid item xs={isDesktop ? 6 : 12}>
                          <Typography variant="body2">
                            Is there anything that your current benefits&apos;
                            broker does not do now that you would really like
                            Buffer to start doing?
                          </Typography>
                          <Box mt={1.5}>
                            <Field
                              component={TextField}
                              name="otherDetails.startDoing"
                              fullWidth
                              size="small"
                              multiline
                              rows={3}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={isDesktop ? 6 : 12}>
                          <Typography variant="body2">
                            Is there anything that your current benefits&apos;
                            broker does now that you would really like Buffer to
                            continue doing?
                          </Typography>
                          <Box mt={1.5}>
                            <Field
                              component={TextField}
                              name="otherDetails.continueToDo"
                              fullWidth
                              size="small"
                              multiline
                              rows={3}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    border: "1px solid rgba(189, 189, 189, 1)",
                    borderRadius: (theme) => theme.spacing(0.5),
                    display: "flex",
                    gap: (theme) => theme.spacing(3),
                    background: "white",
                    padding: (theme) => theme.spacing(3, 1.5),
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  mt={3}
                >
                  <Typography variant="h6">No Active Policy Added</Typography>
                  <AddActivePolicyButton />
                </Box>
              )}
              <Box mt={3}>
                <Grid container spacing={1} justifyContent="flex-end">
                  {values.activePolicies.length ? (
                    <>
                      <Grid
                        item
                        flex={isSMUp ? 1 : undefined}
                        xs={isSMUp ? undefined : 12}
                        sx={{
                          display: "flex",
                          justifyContent: isSMUp ? "flex-start" : "flex-end",
                          marginBottom: (theme) =>
                            !isSMUp ? theme.spacing(3) : undefined,
                        }}
                      >
                        <AddActivePolicyButton />
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={
                            !isValid ||
                            isSubmitting ||
                            !values.activePolicies.length
                          }
                          onClick={() => handleSaveAsDraft(values)}
                          color="success"
                          variant="contained"
                        >
                          SAVE AS DRAFT
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        !values.activePolicies.length
                      }
                      type="submit"
                    >
                      NEXT
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Dialog open={!!activePolicyToDelete}>
                <DialogTitle
                  sx={{
                    background: "rgba(246, 246, 246, 1)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={500}>
                    Delete Active Policy
                  </Typography>
                  <IconButton onClick={handleCloseDeleteDialog}>
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Box
                    sx={{
                      paddingTop: (theme) => theme.spacing(3),
                    }}
                  >
                    {activePolicyToDelete?.policyNumber ? (
                      <Typography fontWeight={500} textAlign="center">
                        Are you sure you want to{" "}
                        <Typography
                          color="error.main"
                          component="span"
                          fontWeight={700}
                        >
                          delete
                        </Typography>{" "}
                        <Typography component="span" fontWeight={700}>
                          Active Policy:
                        </Typography>{" "}
                        <Typography
                          color="error.main"
                          component="span"
                          fontWeight={700}
                        >
                          {activePolicyToDelete?.policyNumber}
                        </Typography>
                        ?
                      </Typography>
                    ) : (
                      <Typography fontWeight={500} textAlign="center">
                        Are you sure you want to{" "}
                        <Typography
                          color="error.main"
                          component="span"
                          fontWeight={700}
                        >
                          delete
                        </Typography>{" "}
                        this Active Policy?
                      </Typography>
                    )}
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDeleteDialog}>CANCEL</Button>
                  <Button
                    onClick={() => {
                      setFieldValue(
                        "activePolicies",
                        values.activePolicies.filter(
                          (activePolicy) =>
                            activePolicy.id !== activePolicyToDelete?.id
                        )
                      );
                      handleCloseDeleteDialog();
                    }}
                    color="error"
                    variant="contained"
                  >
                    DELETE
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
