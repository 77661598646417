import React, { useCallback } from "react";
import { Box, css, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Typography from "Components/elements/Typography/Typography";
import { useResponsive } from "hooks";
import { ROUTES } from "app-constants";

interface Props {
  state: {
    state: boolean;
    collapse: boolean;
  };
  onUpdate?: (data: Props["state"]) => void;
}

const navs = [
  {
    label: "Dashboard",
    route: ROUTES.dashboardHome.fullPath,
    Icon: DashboardIcon,
  },
  {
    label: "Company Details",
    route: ROUTES.companyDetails.fullPath,
    Icon: ApartmentIcon,
  },
  { label: "Request", route: ROUTES.requests.fullPath, Icon: ApartmentIcon },
];

const Overlay = styled(Box)<{
  state?: "true" | "false";
  ismobile: "true" | "false";
}>`
  display: ${({ state, ismobile }) =>
    state === "true" && ismobile === "true" ? "block" : "none"};
  position: fixed;
  background-color: #0000001f;
  height: 100vh;
  width: 100%;
  z-index: 800;
`;

const SidebarWrapper = styled(Box)<{
  collapsed?: "true" | "false";
  state?: "true" | "false";
}>`
  width: 300px;
  max-width: ${({ collapsed }) => (collapsed !== "true" ? "300px" : "66px")};
  background-color: white;
  height: 100%;
  z-index: 900;
  padding: 16px;
  transition: 0.5s all;
  transform: translate(${({ state }) => (state === "true" ? "0px" : "-300px")});
  border-right: 1px solid var(--grey5);
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: fixed;
    left: 0;
  }
`;

const NavButton = styled(Box)<{
  isactive?: "false" | "true";
  collapsed?: "false" | "true";
}>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: ${({ collapsed }) =>
    collapsed === "true" ? "11px 0 11px 4px" : "15.5px 0 15.5px 4px"};
  gap: 20px;
  max-height: ${({ collapsed }) => (collapsed === "true" ? 32 : 52)};
  background-color: ${({ isactive }) =>
    isactive === "true" ? "#f1f8ff" : "none"};
  height: 100%;
  text-decoration: none;

  ${({ isactive }) =>
    isactive === "true" &&
    css`
      ::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 16px;
        background-color: #0959d1;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    `}
`;

export const Sidebar = React.memo(
  ({ state: sidebarState, onUpdate }: Props) => {
    const { isMobile } = useResponsive();

    const closeSidebar = () => {
      if (onUpdate) onUpdate({ collapse: false, state: false });
    };

    const onCollapse = () => {
      if (onUpdate)
        onUpdate({ ...sidebarState, collapse: !sidebarState.collapse });
    };

    const onNavBarClick = useCallback(() => {
      if (onUpdate && isMobile) {
        onUpdate({ collapse: false, state: false });
      }
    }, [isMobile, onUpdate]);

    return (
      <>
        <Overlay
          onClick={closeSidebar}
          state={sidebarState.state ? "true" : "false"}
          ismobile={isMobile ? "true" : "false"}
        />
        <SidebarWrapper
          state={sidebarState.state ? "true" : "false"}
          collapsed={sidebarState.collapse ? "true" : "false"}
        >
          {sidebarState.state && (
            <Box
              sx={{
                position: "absolute",
                background: "#FCFCFC",
                right: "-12px",
                top: "8px",
                width: "24px",
                height: "24px",
                borderRadius: "4px",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 4px 10px #0959d11f",
                display: { xs: "none", sm: "flex", md: "none" },
              }}
              onClick={onCollapse}
            >
              <ChevronLeftIcon
                sx={{
                  color: "#828282",
                  transform: `rotate(${!sidebarState.collapse ? 0 : 180}deg)`,
                }}
              />
            </Box>
          )}
          <Box overflow="hidden">
            {navs.map(({ label, route, Icon }) => (
              <NavLink
                to={route}
                key={label}
                style={{
                  maxHeight: sidebarState.collapse ? 32 : undefined,
                }}
                onClick={onNavBarClick}
              >
                {({ isActive }) => (
                  <NavButton
                    isactive={isActive ? "true" : "false"}
                    collapsed={sidebarState.collapse ? "true" : "false"}
                  >
                    <Icon
                      sx={{
                        width: 18,
                        height: 18,
                        marginLeft: sidebarState.collapse ? 1 : 3,
                        fontSize: 10,
                        color: isActive
                          ? "var(--primary-dark)"
                          : "var(--grey2)",
                      }}
                    />
                    {!sidebarState.collapse && (
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        content={label}
                        style={{
                          whiteSpace: "nowrap",
                          color: isActive
                            ? "var(--primary-dark)"
                            : "var(--grey2)",
                        }}
                      />
                    )}
                  </NavButton>
                )}
              </NavLink>
            ))}
          </Box>
        </SidebarWrapper>
      </>
    );
  }
);
