import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { EmployeeFormWidgetResValue, StateEmployee } from "store";
import { employeeTableColumns } from "./helpers";
import { EmployeeTableCell } from "./EmployeeTableCell";
import { MappedEmployee } from "./types";
import { DependentTableRow } from "./DependentTableRow";

interface Props {
  employee: MappedEmployee;
  name: string;
  employeeValues: EmployeeFormWidgetResValue;
  employeeData?: StateEmployee;
}

export const EmployeeTableRow = ({
  employee,
  name,
  employeeValues,
  employeeData,
}: Props) => {
  return (
    <>
      <TableRow>
        {employeeTableColumns.map((label) => (
          <TableCell key={label.key}>
            <EmployeeTableCell
              employee={employee}
              label={label}
              name={name}
              employeeValues={employeeValues}
              employeeData={employeeData}
            />
          </TableCell>
        ))}
      </TableRow>
      {employee.dependents.map((dependent, index) => (
        <DependentTableRow
          key={dependent.id}
          name={`${name}.dependents.${index}`}
          dependent={dependent}
        />
      ))}
    </>
  );
};
