import { Grid } from "@mui/material";
import { useResponsive } from "hooks";
import React, { PropsWithChildren } from "react";

export const GroupInput = ({ children }: PropsWithChildren) => {
  const { isDesktop } = useResponsive();
  return (
    <Grid
      container
      spacing={isDesktop ? 2 : 3}
      flexDirection={isDesktop ? "row" : "column"}
      justifyContent="flex-start"
      alignItems="stretch"
    >
      {children}
    </Grid>
  );
};

export const GroupInputItem = ({ children }: PropsWithChildren) => {
  return (
    <Grid item flex="1">
      {children}
    </Grid>
  );
};
