import { Box, Button, Menu, MenuItem, Theme } from "@mui/material";
import React, {
  ChangeEventHandler,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Sort } from "@mui/icons-material";
import { EmployeeFormWidgetResValue, StateEmployee } from "store";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import { employeeTableColumnsForTablet } from "./helpers";
import { MappedEmployee } from "./types";
import { EmployeesTableForTabletRow } from "./EmployeesTableForTabletRow";

interface Props {
  employees: MappedEmployee[];
  onSort: (key: string) => void;
  selectAllValue: boolean;
  handleSelectAll: ChangeEventHandler<HTMLInputElement>;
  name: string;
  employeesValues: EmployeeFormWidgetResValue[];
  employeesData: StateEmployee[];
}

export const EmployeesTableForTablet = ({
  employees,
  onSort,
  selectAllValue,
  handleSelectAll,
  name,
  employeesValues,
  employeesData,
}: Props) => {
  const [sortingAnchorEl, setSortingAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const openSort = useMemo(() => Boolean(sortingAnchorEl), [sortingAnchorEl]);

  const handleOpenSort = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setSortingAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSort = useCallback(() => {
    setSortingAnchorEl(null);
  }, []);

  const getLabelSorting = useCallback(
    (key: string) => {
      return () => {
        onSort(key);
        handleCloseSort();
      };
    },
    [onSort, handleCloseSort]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            id="sort-by-button"
            aria-controls={openSort ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSort ? "true" : undefined}
            onClick={handleOpenSort}
            variant="text"
            sx={{
              textTransform: "none",
              color: (theme) => theme.palette.text.primary,
              paddingLeft: 0,
            }}
          >
            <Sort fontSize="small" /> Sort by
          </Button>
          <Menu
            id="sort-by-menu"
            anchorEl={sortingAnchorEl}
            open={openSort}
            onClose={handleCloseSort}
            MenuListProps={{
              "aria-labelledby": "sort-by-button",
            }}
          >
            {employeeTableColumnsForTablet
              .filter((i) => i.sortFunction)
              .map((label) => (
                <MenuItem onClick={getLabelSorting(label.key)} key={label.key}>
                  {label.label}
                </MenuItem>
              ))}
          </Menu>
        </Box>
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          sx={{
            color: (theme: Theme) => theme.palette.primary,
          }}
          color="primary"
          onChange={handleSelectAll}
          value={selectAllValue}
          checked={selectAllValue}
          Label={{
            label: "Select All",
            sx: {
              color: (theme: Theme) => theme.palette.primary,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          border: "1px solid rgba(189, 189, 189, 1)",
          borderRadius: (theme) => theme.spacing(0.5),
          gap: (theme) => theme.spacing(3),
          background: "white",
        }}
        mt={2}
      >
        {employees.map((employee, index) => (
          <EmployeesTableForTabletRow
            key={employee.id}
            isLast={employees.length - 1 === index}
            employee={employee}
            name={`${name}.${employee.formKey}`}
            employeeValues={employeesValues[Number(employee.formKey)]}
            employeeData={employeesData.find((e) => e.id === employee.id)}
          />
        ))}
      </Box>
    </Box>
  );
};
