import { Box, Button, Menu, MenuItem, Pagination } from "@mui/material";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Sort } from "@mui/icons-material";
import { employeeTableColumns } from "./helpers";
import { MappedEmployee } from "./types";
import { EmployeesTableForTabletRow } from "./EmployeesTableForTabletRow";

interface Props {
  employees: MappedEmployee[];
  onDeleteEmployee: (employee: MappedEmployee) => void;
  onEditEmployee: (employee: MappedEmployee) => void;
  onSort: (key: string) => void;
  employeesCount: number;
  onEmployeesPageChange: (page: number) => void;
  rowsPerPage: number;
  page: number;
}

export const EmployeesTableForTablet = ({
  employees,
  onDeleteEmployee,
  onEditEmployee,
  onSort,
  employeesCount,
  rowsPerPage,
  page,
  onEmployeesPageChange,
}: Props) => {
  const [sortingAnchorEl, setSortingAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const openSort = useMemo(() => Boolean(sortingAnchorEl), [sortingAnchorEl]);

  const count = useMemo(
    () => Math.ceil(employeesCount / rowsPerPage),
    [employeesCount, rowsPerPage]
  );

  const handleOpenSort = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setSortingAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSort = useCallback(() => {
    setSortingAnchorEl(null);
  }, []);

  const getLabelSorting = useCallback(
    (key: string) => {
      return () => {
        onSort(key);
        handleCloseSort();
      };
    },
    [onSort, handleCloseSort]
  );

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown> | null, newPage: number) => {
      onEmployeesPageChange(newPage + 1);
    },
    [onEmployeesPageChange]
  );

  return (
    <Box>
      <Box>
        <Button
          id="census-sort-by-button"
          aria-controls={openSort ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openSort ? "true" : undefined}
          onClick={handleOpenSort}
          variant="text"
          sx={{
            textTransform: "none",
            color: (theme) => theme.palette.text.primary,
            paddingLeft: 0,
          }}
        >
          <Sort fontSize="small" /> Sort by
        </Button>
        <Menu
          id="census-sort-by-menu"
          anchorEl={sortingAnchorEl}
          open={openSort}
          onClose={handleCloseSort}
          MenuListProps={{
            "aria-labelledby": "census-sort-by-button",
          }}
        >
          {employeeTableColumns
            .filter((i) => i.sortFunction)
            .map((headerLabel) => (
              <MenuItem
                onClick={getLabelSorting(headerLabel.key)}
                key={headerLabel.key}
              >
                {headerLabel.label}
              </MenuItem>
            ))}
        </Menu>
      </Box>
      <Box
        sx={{
          border: "1px solid rgba(189, 189, 189, 1)",
          borderRadius: (theme) => theme.spacing(0.5),
          gap: (theme) => theme.spacing(3),
        }}
      >
        {employees.map((employee, index) => (
          <EmployeesTableForTabletRow
            key={employee.id}
            isLast={employees.length - 1 === index}
            isFirst={index === 0}
            onDeleteEmployee={onDeleteEmployee}
            onEditEmployee={onEditEmployee}
            employee={employee}
          />
        ))}
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination count={count} onChange={handlePageChange} page={page} />
      </Box>
    </Box>
  );
};
