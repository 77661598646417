import {
  CoverageTypeEnum,
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  GenderEnum,
  MedicarePrimaryEnum,
  PayrollCycleEnum,
  RelationshipEnum,
} from "gql/graphql";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { FormValuesDependent } from "./types";

const stringSortFunction = (a: any, b: any) =>
  String(a).localeCompare(String(b));

const numberSortFunction = (a: any, b: any) => {
  return Number(a) - Number(b);
};

export const employeeTableColumns = [
  {
    label: "",
    key: "actions",
  },
  {
    label: "First Name",
    key: "firstName",
    sortFunction: stringSortFunction,
  },
  {
    label: "Last Name",
    key: "lastName",
    sortFunction: stringSortFunction,
  },
  {
    label: "Relationship",
    key: "relationship",
  },
  {
    label: "Coverage Type",
    key: "coverage",
  },
  {
    label: "Age",
    key: "age",
    sortFunction: numberSortFunction,
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Salary (USD)",
    key: "salary",
    sortFunction: numberSortFunction,
  },
  {
    label: "Job Title",
    key: "jobTitle",
  },
];

export const employeeTableColumnsForTablet = [
  {
    label: "Salary (USD)",
    key: "salary",
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Age",
    key: "age",
    sortFunction: numberSortFunction,
  },
  {
    label: "Job Title",
    key: "jobTitle",
  },
  {
    label: "Date of Hire",
    key: "dateOfHire",
  },
  {
    label: "EmployeeType",
    key: "employeeType",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Zip Code",
    key: "zipCode",
  },
  {
    label: "Medicare Primary",
    key: "medicarePrimary",
  },
  {
    label: "Coverage Type",
    key: "coverage",
  },
];

export const dependentTableColumnsForTablet = [
  {
    label: "Relationship",
    key: "relationship",
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Age",
    key: "age",
    sortFunction: numberSortFunction,
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Zip Code",
    key: "zipCode",
  },
  {
    label: "Medicare Primary",
    key: "medicarePrimary",
  },
];

export const startOfToday = moment().startOf("day");

export const payrollCycleOptions = Object.values(PayrollCycleEnum);

export const payrollCycleTitle = {
  [PayrollCycleEnum.Annually]: "Annually",
  [PayrollCycleEnum.BiWeekly]: "Bi Weekly",
  [PayrollCycleEnum.Monthly]: "Monthly",
  [PayrollCycleEnum.SemiMonthly]: "Semi Monthly",
  [PayrollCycleEnum.Weekly]: "Weekly",
};

export const coverageTypeOptions = Object.values(CoverageTypeEnum);

export const coverageTypeTitles = {
  [CoverageTypeEnum.Cobra]: "COBRA",
  [CoverageTypeEnum.CobraOnly]: "COBRA Only",
  [CoverageTypeEnum.CobraPlusChildren]: "COBRA + Child(ren)",
  [CoverageTypeEnum.CobraPlusFamily]: "COBRA + Family",
  [CoverageTypeEnum.CobraPlusSpouse]: "COBRA + Spouse",
  [CoverageTypeEnum.EmployeeOnly]: "Employee Only",
  [CoverageTypeEnum.EmployeePlusChildren]: "Employee + Child(ren)",
  [CoverageTypeEnum.EmployeePlusFamily]: "Employee + Family",
  [CoverageTypeEnum.EmployeePlusSpouse]: "Employee + Spouse",
};

export const createDependent = (
  relationship: RelationshipEnum
): FormValuesDependent => {
  return {
    id: uuidv4(),
    isCustomId: true,
    firstName: "",
    lastName: "",
    dob: "",
    gender: "" as GenderEnum,
    medicarePrimary: "" as MedicarePrimaryEnum,
    zipCode: "",
    relationship,
  };
};

export const getDateValue = (value: string) => {
  try {
    const [date] = new Date(value).toLocaleDateString().split(" ");
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  } catch (error) {
    return null;
  }
};

export const getGender = (gender?: GenderEnum | null) => {
  if (!gender) {
    return "";
  }
  if (gender === GenderEnum.F) {
    return "Female";
  }
  return "Male";
};

export const getAge = (dateOfBirth?: any) => {
  try {
    if (!dateOfBirth) {
      return "";
    }
    const years = moment().diff(moment(dateOfBirth as string), "years");
    return String(years);
  } catch (error) {
    return "";
  }
};

export const getSalary = (salary?: number | null | string) => {
  if (salary === undefined || salary === null || salary === "") {
    return "";
  }
  return String(salary);
};

export const getMedicarePrimary = (
  medicarePrimary?: MedicarePrimaryEnum | null
) => {
  if (!medicarePrimary) {
    return "";
  }

  if (medicarePrimary === MedicarePrimaryEnum.U) {
    return "Unknown";
  }

  if (medicarePrimary === MedicarePrimaryEnum.Yes) {
    return "Yes";
  }

  if (medicarePrimary === MedicarePrimaryEnum.No) {
    return "No";
  }

  return medicarePrimary;
};

export const getEmployeeType = (
  employeeType?: EmployeesTheCompanyWantsToOfferBenefitsToEnum | null
) => {
  if (!employeeType) {
    return "";
  }

  if (
    employeeType ===
    EmployeesTheCompanyWantsToOfferBenefitsToEnum.FullTimeEmployees
  ) {
    return "Full-Time";
  }

  if (
    employeeType ===
    EmployeesTheCompanyWantsToOfferBenefitsToEnum.PartTimeEmployees
  ) {
    return "Part-Time";
  }

  if (
    employeeType ===
    EmployeesTheCompanyWantsToOfferBenefitsToEnum.Contractors_1099
  ) {
    return "1099 Contractor";
  }

  return employeeType;
};

export const getRelationShip = (relationship?: RelationshipEnum | null) => {
  if (!relationship) {
    return "";
  }

  if (relationship === RelationshipEnum.Child) {
    return "Child";
  }

  if (relationship === RelationshipEnum.Spouse) {
    return "Child";
  }
  return relationship;
};

export const getRelationShipEnum = (
  relationship?: RelationshipEnum | string | null
): RelationshipEnum => {
  if (!relationship) {
    return "" as RelationshipEnum;
  }

  if (
    relationship.trim().toLowerCase() ===
    RelationshipEnum.Child.trim().toLowerCase()
  ) {
    return RelationshipEnum.Child;
  }

  if (
    relationship.trim().toLowerCase() ===
    RelationshipEnum.Spouse.trim().toLowerCase()
  ) {
    return RelationshipEnum.Spouse;
  }
  return relationship as RelationshipEnum;
};
