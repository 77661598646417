import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { pkAuthActions } from "@deep-consulting-solutions/auth-web-muiv5";
import { setAuthorizationHeader } from "api";
import {
  getContactDetails,
  getPortalUserCompany,
  signUp,
  updateCompanyRequest,
  updateJobClassificationsRequest,
  fetchEmployeesRequest,
  deleteEmployeeRequest,
  updateEmployeeRequest,
  addEmployeeRequest,
} from "services";
import { AppDispatch } from "store/store";
import {
  AddEmployeeToCensusInput,
  Company,
  CompanyJobClassification,
  Employee,
  UpdateEmployeeToCensusInput,
} from "gql/graphql";
import { RootState, CompanyState } from "../types";

const ENTITY = "company";

const initialState: CompanyState = {
  company: null,
  contact: null,
  employees: [],
  employeesPage: 1,
  employeesRowsPerPage: 10,
  employeesCount: 0,
};

const fetchCompany = createAsyncThunk<{ company: CompanyState["company"] }>(
  `${ENTITY}/fetchCompany`,
  async () => {
    const company = await getPortalUserCompany();

    return { company };
  }
);

const updateJobClassifications = createAsyncThunk<
  {
    jobClassifications: CompanyJobClassification[];
  },
  {
    newJobClassifications: string[];
  }
>(
  `${ENTITY}/updateJobClassifications`,
  async ({ newJobClassifications }, { getState }) => {
    const { company } = getState() as RootState;
    const jobClassifications = await updateJobClassificationsRequest(
      newJobClassifications.concat(
        company.company?.jobClassifications?.map(
          (jobClassification) => jobClassification.name
        ) || []
      ),
      company.company?.id || ""
    );

    return { jobClassifications };
  }
);

const updateCompany = createAsyncThunk<
  {
    company: Company;
  },
  {
    values: Partial<Company>;
  }
>(`${ENTITY}/updateCompany`, async ({ values }, { getState }) => {
  const { company } = getState() as RootState;
  const res = await updateCompanyRequest(company.company?.id || "", values);

  return { company: res };
});

const fetchContact = createAsyncThunk<any>(
  `${ENTITY}/fetchContact`,
  async () => {
    const contact = await getContactDetails();

    return { contact };
  }
);
const companySignUp = createAsyncThunk<
  boolean,
  {
    email: string;
    password: string;
  },
  { dispatch: AppDispatch }
>(`${ENTITY}/companySignUp`, async ({ email, password }, { dispatch }) => {
  const res = await signUp(password);
  if (!res) {
    return false;
  }

  const { token } = res;
  setAuthorizationHeader(`${token}`);
  dispatch(pkAuthActions.login({ email, password }));

  return true;
});

const fetchEmployees = createAsyncThunk<
  {
    employees: Employee[];
    count: number;
  },
  { companyId: string },
  { state: RootState }
>(`${ENTITY}/fetchEmployees`, async ({ companyId }, { getState }) => {
  const { company } = getState();
  const res = await fetchEmployeesRequest(
    companyId,
    company.employeesPage,
    company.employeesRowsPerPage
  );

  return { employees: res.data, count: res.total || 0 };
});

const deleteEmployee = createAsyncThunk<
  {
    employees: Employee[];
    count: number;
  },
  { employeeID: string },
  { state: RootState }
>(`${ENTITY}/deleteEmployee`, async ({ employeeID }, { getState }) => {
  const { company } = getState();
  if (!company.company?.id) {
    return { employees: company.employees, count: company.employeesCount };
  }
  await deleteEmployeeRequest(company.company.id, employeeID);
  const res = await fetchEmployeesRequest(
    company.company.id,
    company.employeesPage,
    company.employeesRowsPerPage
  );

  return { employees: res.data, count: res.total || 0 };
});

const addEmployee = createAsyncThunk<
  {
    employees: Employee[];
    count: number;
  },
  { values: AddEmployeeToCensusInput },
  { state: RootState }
>(`${ENTITY}/addEmployee`, async ({ values }, { getState }) => {
  const { company } = getState();
  if (!company.company?.id) {
    return { employees: company.employees, count: company.employeesCount };
  }
  await addEmployeeRequest(company.company.id, values);
  const res = await fetchEmployeesRequest(
    company.company.id,
    company.employeesPage,
    company.employeesRowsPerPage
  );

  return { employees: res.data, count: res.total || 0 };
});

const updateEmployee = createAsyncThunk<
  {
    employees: Employee[];
    count: number;
  },
  { values: UpdateEmployeeToCensusInput },
  { state: RootState }
>(`${ENTITY}/updateEmployee`, async ({ values }, { getState }) => {
  const { company } = getState();
  if (!company.company?.id) {
    return { employees: company.employees, count: company.employeesCount };
  }
  await updateEmployeeRequest(company.company.id, values);
  const res = await fetchEmployeesRequest(
    company.company.id,
    company.employeesPage,
    company.employeesRowsPerPage
  );

  return { employees: res.data, count: res.total || 0 };
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setEmployeesPage: (state, action: PayloadAction<number>) => {
      state.employeesPage = action.payload;
    },
    setEmployeesRowsPerPage: (state, action: PayloadAction<number>) => {
      state.employeesRowsPerPage = action.payload;
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.company = action.payload.company;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.company = action.payload.company;
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.contact = action.payload.contact;
      })
      .addCase(updateJobClassifications.fulfilled, (state, action) => {
        if (state.company) {
          state.company.jobClassifications = action.payload.jobClassifications;
        }
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employees = action.payload.employees;
        state.employeesCount = action.payload.count;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.employees = action.payload.employees;
        state.employeesCount = action.payload.count;
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.employees = action.payload.employees;
        state.employeesCount = action.payload.count;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.employees = action.payload.employees;
        state.employeesCount = action.payload.count;
      }),
});

const getCompany = ({ company: { company } }: RootState) => company;
const getContact = ({ company: { contact } }: RootState) => contact;
const getEmployees = ({ company: { employees } }: RootState) => employees;
const getEmployeesCount = ({ company: { employeesCount } }: RootState) =>
  employeesCount;
const getEmployeesRowsPerPage = ({
  company: { employeesRowsPerPage },
}: RootState) => employeesRowsPerPage;
const getEmployeesPage = ({ company: { employeesPage } }: RootState) =>
  employeesPage;

export const companySelectors = {
  getCompany,
  getContact,
  getEmployees,
  getEmployeesPage,
  getEmployeesCount,
  getEmployeesRowsPerPage,
};

export const companyActions = {
  ...slice.actions,
  fetchCompany,
  updateCompany,
  fetchContact,
  companySignUp,
  updateJobClassifications,
  fetchEmployees,
  deleteEmployee,
  updateEmployee,
  addEmployee,
};

export const companyReducer = slice.reducer;
