import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  IconButton,
  LinearProgress,
  Tooltip,
  Theme,
} from "@mui/material";
import {
  PictureAsPdf as PDFIcon,
  PhotoLibrary as ImageIcon,
  Close as CloseIcon,
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  FileCopyOutlined,
} from "@mui/icons-material";
import { FileError } from "react-dropzone";
import theme from "utils/theme";
import { UploadableFile } from "./FormikDropzone.types";

export interface FileInfoProps {
  uploadableFile: UploadableFile;
  onDelete: (file: File) => Promise<void>;
  onUploaded: (uploadableFile: UploadableFile, key: string, res?: any) => void;
  onError: (file: File, error: FileError) => void;
  onUpload: (
    file: File,
    abortRequest: (instance: XMLHttpRequest) => void,
    trackProgress: (progressEvent: ProgressEvent<EventTarget>) => any
  ) => Promise<string>;
  showStatus?: boolean;
  size: "small" | "large";
  isInitial?: boolean;
  getDownloadUrl?: (s3Key: string) => Promise<string>;
}

const createStyles = ({ spacing: s, palette: p }: Theme) => ({
  fileIconSmall: {
    marginRight: s(1),
  },
  fileIcon: {
    marginRight: s(2),
  },
  fileInfoSmall: {
    fontSize: "0.8125rem",
    letterSpacing: "0.15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: s(1),
  },
  fileInfo: {
    fontSize: "1.125rem",
    letterSpacing: "0.15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: s(2),
  },
  fileName: {
    color: p.text.primary,
  },
  errorMessage: {
    color: p.common.white,
  },
  check: {
    color: p.success.light,
  },
  progressbar: {
    height: 8,
    borderRadius: 5,
    backgroundColor: p.grey[200],
  },
  colorPrimary: {},
  filler: {
    borderRadius: 5,
    backgroundColor: p.primary.dark,
  },
});

const classes = createStyles(theme);

const FileInfo: FC<FileInfoProps> = ({
  uploadableFile,
  onDelete,
  onUploaded,
  onError,
  onUpload,
  getDownloadUrl,
  showStatus,
  size,
  isInitial = false,
}) => {
  const { file, errors } = uploadableFile;
  const [downloadUrl, setDownloadUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(isInitial);
  const [uploadClient, setUploadClient] = useState<XMLHttpRequest | null>(null);

  const handleCancelUpload = () => {
    if (uploadClient) uploadClient.abort();
    onDelete(file);
  };

  const getUploadClient = (client: XMLHttpRequest) => {
    setUploadClient(client);
  };

  const trackProgress = (event: ProgressEvent<EventTarget>) => {
    if (event.lengthComputable) {
      const percentage = Math.round((event.loaded / event.total) * 100);
      setProgress(percentage);
    }
  };

  const hasErrors = useMemo(() => {
    return !!errors && errors.length > 0;
  }, [errors]);

  const upload = useCallback(async () => {
    try {
      const key = await onUpload(file, getUploadClient, trackProgress);
      setIsUploaded(true);
      onUploaded(uploadableFile, key, key);
    } catch (err) {
      onError(file, err as FileError);
    }
  }, [file, onError, onUpload, onUploaded, uploadableFile]);

  const fetchDownloadUrl = useCallback(async () => {
    try {
      if (uploadableFile.key && isInitial && getDownloadUrl) {
        const url = await getDownloadUrl(uploadableFile.key);
        setDownloadUrl(url);
      }
    } catch (err) {
      //
    }
  }, [uploadableFile.key, isInitial, getDownloadUrl]);

  useEffect(() => {
    fetchDownloadUrl();
  }, [fetchDownloadUrl]);

  useEffect(() => {
    if (!hasErrors && !isUploaded && !isInitial) {
      upload();
    }
  }, [hasErrors, isUploaded, isInitial, upload]);

  useEffect(() => {
    if (isInitial) {
      setProgress(100);
      setIsUploaded(true);
    }
  }, [isInitial]);

  const isSmall = useMemo(() => size === "small", [size]);
  return (
    <Box my={isSmall ? 1 : 2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" overflow="hidden">
          {(() => {
            if (downloadUrl) {
              return (
                <img
                  src={downloadUrl}
                  alt="Company Logo"
                  style={{
                    width: theme.spacing(5),
                    height: theme.spacing(5),
                    objectFit: "contain",
                  }}
                />
              );
            }
            if (file.type === "application/pdf") {
              return (
                <PDFIcon
                  color="primary"
                  sx={isSmall ? classes.fileIconSmall : classes.fileIcon}
                />
              );
            }
            if (file.type.includes("image")) {
              return (
                <ImageIcon
                  color="primary"
                  sx={isSmall ? classes.fileIconSmall : classes.fileIcon}
                />
              );
            }
            return (
              <FileCopyOutlined
                color="primary"
                sx={isSmall ? classes.fileIconSmall : classes.fileIcon}
              />
            );
          })()}

          {!downloadUrl ? (
            <Typography
              color="textSecondary"
              sx={isSmall ? classes.fileInfoSmall : classes.fileInfo}
            >
              File: <span style={classes.fileName}>{file.name}</span>{" "}
              {!isInitial && (
                <>
                  {isUploaded
                    ? "uploaded"
                    : (hasErrors && "cannot be uploaded") || "is uploading..."}
                </>
              )}
            </Typography>
          ) : null}
        </Box>
        <Box display="flex" alignItems="center">
          {showStatus && !isInitial && (
            <>
              {errors && errors.length > 0 && (
                <Tooltip
                  arrow
                  title={errors.map((error) => (
                    <Typography
                      key={error.code}
                      variant="body2"
                      sx={classes.errorMessage}
                    >
                      {error.message}
                    </Typography>
                  ))}
                >
                  <ErrorIcon color="error" />
                </Tooltip>
              )}
              {isUploaded && <CheckIcon sx={classes.check} />}
            </>
          )}
          <IconButton
            aria-label={
              isUploaded || hasErrors ? "delete file" : "cancel upload"
            }
            size="small"
            onClick={handleCancelUpload}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {!isUploaded && !hasErrors && (
        <Box mt={1}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ root: classes.progressbar, bar: classes.filler }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FileInfo;
