export const DATE_FORMAT = {};

export const INTEGRATION_APP_URL =
  process.env.REACT_APP_INTEGRATION_APP_URL || "";
export const TOKEN_EXPIRED_BE_ERROR = "Token has expired!";
export const NODE_ENVIRONMENT = process.env.REACT_APP_NODE_ENVIRONMENT || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "";
export const DATE_TYPING_FORMAT = "DD/MM/YYYY";
export const DATE_DISPLAY_FORMAT = "DD MMM YYYY";
export const US_DATE_TYPING_FORMAT = "MM/DD/YYYY";
export const US_DATE_DISPLAY_FORMAT = "DD MMM YYYY";
