import { combineReducers } from "@reduxjs/toolkit";
import { pkAuthReducerObj } from "@deep-consulting-solutions/auth-web-muiv5";
import authReducer from "./auth/authDocSlice";
import { companyReducer } from "./company";
import { quoteRequestReducer } from "./quote-request";

export const rootReducer = combineReducers({
  ...pkAuthReducerObj,
  auth: authReducer,
  company: companyReducer,
  quoteRequest: quoteRequestReducer,
});
