import { ReducerSliceEnum } from "@deep-consulting-solutions/auth-web-muiv5";
import { createAction, createSlice } from "@reduxjs/toolkit";
import { ROUTES } from "app-constants";
import { AuthState, RootState } from "store";

const initialState: AuthState = {
  authenticated: false,
};

const ENTITY = "app-auth";

const tokenExpired = createAction(`${ENTITY}/tokenExpired`);

const resetAuth = createAction(`${ENTITY}/resetAuth`);

export const authDocSlice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    onAuthenticated: (state) => {
      state.authenticated = true;
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(tokenExpired, () => {
        const origin = window.location.origin;
        const endsWithSlash = origin[origin.length - 1] === "/";
        // hard redirect to login page.
        window.location.href = `${origin}${endsWithSlash ? "" : "/"}${
          ROUTES.login.path
        }`;
        return { ...initialState };
      })
      .addCase(resetAuth, () => {
        return { ...initialState };
      }),
});

export const authActions = { ...authDocSlice.actions, tokenExpired, resetAuth };

export const authSelectors = {
  isAuthenticated: (state: RootState) => state.auth.authenticated,
  token: (state: RootState) => state[ReducerSliceEnum.auth].token,
};

export default authDocSlice.reducer;
