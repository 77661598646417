import React, {
  PropsWithChildren,
  useCallback,
  useState,
  useEffect,
} from "react";
import { Box, Grid } from "@mui/material";
import { DashboardHeader, Sidebar } from "Components/molecules";
import { useResponsive } from "hooks";
import { useAppDispatch, companyActions, companySelectors } from "store";
import { Loader } from "Components/elements";
import { useSelector } from "react-redux";

export const DashboardLayout = ({ children }: PropsWithChildren) => {
  const { isMobile, isTablet } = useResponsive();
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [sidebar, setSidebar] = useState({ state: false, collapse: false });
  const dispatch = useAppDispatch();

  const company = useSelector(companySelectors.getCompany);

  const fetchPortalCompany = useCallback(async () => {
    try {
      if (company?.id) throw new Error("Company already exists");

      await dispatch(companyActions.fetchCompany());
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [company?.id, dispatch]);

  const onSidebarUpdate = useCallback(
    (data: { state: boolean; collapse: boolean }) => {
      setSidebar(data);
    },
    []
  );

  const toggleSidebar = useCallback((arg: boolean) => {
    setSidebar({ state: arg, collapse: false });
  }, []);

  const childrenWrapperRef = useCallback(
    (divElement: HTMLDivElement | null) => {
      if (divElement) {
        setWrapperHeight(divElement.offsetHeight);
      }
    },
    []
  );

  const childrenWrapperRef2 = useCallback(
    (divElement: HTMLDivElement | null) => {
      if (divElement) {
        setHeaderHeight(divElement.offsetHeight);
      }
    },
    []
  );

  const updateBodyOverflow = useCallback((isCallback = false) => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = isCallback ? "auto" : "hidden";
    }
  }, []);

  useEffect(() => {
    onSidebarUpdate({ state: !isMobile, collapse: isTablet });
  }, [isMobile, onSidebarUpdate, isTablet]);

  useEffect(() => {
    fetchPortalCompany();
  }, [fetchPortalCompany]);

  useEffect(() => {
    updateBodyOverflow();
    return () => {
      updateBodyOverflow(true);
    };
  }, [updateBodyOverflow]);

  return (
    <>
      <Loader open={loading} />
      <Grid
        wrap="nowrap"
        justifyContent="space-between"
        direction="column"
        alignItems="stretch"
        sx={{
          height: "100vh",
          maxHeight: "100vh",
        }}
        container
        ref={childrenWrapperRef}
      >
        <Grid item ref={childrenWrapperRef2}>
          <DashboardHeader
            state={sidebar.state}
            onClickOpen={() => toggleSidebar(true)}
            onClickClose={() => toggleSidebar(false)}
          />
        </Grid>
        <Grid item flex={1}>
          <Grid
            container
            wrap="nowrap"
            justifyContent="space-between"
            sx={{
              height: "100%",
            }}
            alignItems="flex-start"
          >
            <Grid
              item
              sx={{
                height: "100%",
              }}
            >
              <Sidebar state={sidebar} onUpdate={onSidebarUpdate} />
            </Grid>
            <Grid
              item
              flex={1}
              sx={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: wrapperHeight - headerHeight,
                  padding: ({ spacing: s }) => s(2),
                  overflow: "hidden auto",
                }}
              >
                {children}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
