import { Button } from "Components";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { ActivePolicyTypeEnum } from "gql/graphql";
import { ActivePolicyWidgetRes } from "../types";

export const AddActivePolicyButton = () => {
  const { setFieldValue, values } = useFormikContext<ActivePolicyWidgetRes>();
  const handleAddActivePolicy = useCallback(() => {
    setFieldValue(
      "activePolicies",
      values.activePolicies.concat({
        insuranceProvider: "",
        insuranceType: "" as ActivePolicyTypeEnum,
        otherDetails: "",
        policyNumber: "",
        summary: "",
        activePolicyTermDate: "",
        document: [],
        id: uuidv4(),
        isCustomId: true,
      })
    );
  }, [setFieldValue, values.activePolicies]);
  return (
    <Button variant="outlined" onClick={handleAddActivePolicy}>
      ADD ACTIVE POLICY
    </Button>
  );
};
