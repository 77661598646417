import {
  PATH_OAUTH_RESPONSE,
  PATH_RECOVER_PASSWORD,
  PATH_UPDATE_EMAIL_VERIFICATION,
} from "@deep-consulting-solutions/auth-web-muiv5";

export const ROUTES = {
  login: {
    path: "login",
    fullPath: "/auth/login",
  },
  auth: {
    path: "auth",
    fullPath: "/auth",
  },
  activation: {
    path: "activation",
    fullPath: "/activation",
  },
  signUp: {
    path: "signUp",
    fullPath: "/signUp",
  },
  forgetPassword: {
    path: "/forgot-password",
    fullPath: "/auth/forgot-password",
  },
  resetPassword: {
    path: "resetPassword",
    fullPath: "/resetPassword",
  },
  dashboard: {
    path: "/dashboard",
    fullPath: "/dashboard",
  },
  dashboardHome: {
    path: "/home",
    fullPath: "/dashboard/home",
  },
  requests: {
    path: "/requests",
    fullPath: "/dashboard/requests",
  },
  index: {
    path: "",
    fullPath: "/",
  },
  oauthResponse: {
    path: PATH_OAUTH_RESPONSE,
  },
  recoverPassword: {
    path: PATH_RECOVER_PASSWORD,
  },
  updateEmailVerification: {
    path: PATH_UPDATE_EMAIL_VERIFICATION,
  },
  terminationLetterRequest: {
    fullPath: "/dashboard/requests/termination-letter/:opportunityID",
    path: "/termination-letter/:opportunityID",
  },
  companyDetails: {
    path: "/company-details",
    fullPath: "/dashboard/company-details",
  },
  updateCensus: {
    path: "/update-census",
    fullPath: "/dashboard/update-census",
  },
  lepCalculator: {
    fullPath: "/lep-calculator",
    path: "/lep-calculator",
  },
  lepCalculatorPartB: {
    fullPath: "/lep-calculator/part-b",
    path: "/part-b",
  },
  lepCalculatorPartD: {
    fullPath: "/lep-calculator/part-d",
    path: "/part-d",
  },
};
