import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import { BrowserRouter } from "react-router-dom";
import theme from "utils/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { NotificationProvider } from "services";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <BrowserRouter>
              <QueryClientProvider client={reactQueryClient}>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <App />
                </LocalizationProvider>
                <ReactQueryDevtools />
              </QueryClientProvider>
            </BrowserRouter>
          </NotificationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
