import { axiosRequest } from "api";

export const postPartD = async (values: any) => {
  const res = await axiosRequest.post("/calculator/part-d", values);
  return res.data;
};

export const postPartB = async (values: any) => {
  const res = await axiosRequest.post("/calculator/part-b", values);
  return res.data;
};
