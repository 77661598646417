import {
  CoverageTypeEnum,
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  GenderEnum,
  MedicarePrimaryEnum,
  RelationshipEnum,
} from "gql/graphql";
import moment from "moment";
import {
  EmployeeDependentFormWidgetResValue,
  EmployeeFormWidgetResValue,
  StateEmployee,
} from "store";

const stringSortFunction = (a: any, b: any) =>
  String(a).localeCompare(String(b));

const numberSortFunction = (a: any, b: any) => {
  return Number(a) - Number(b);
};

export const employeeTableColumns = [
  {
    label: "",
    key: "actions",
  },
  {
    label: "First Name",
    key: "firstName",
    sortFunction: stringSortFunction,
  },
  {
    label: "Last Name",
    key: "lastName",
    sortFunction: stringSortFunction,
  },
  {
    label: "Relationship",
    key: "relationship",
  },
  {
    label: "Age",
    key: "age",
    sortFunction: numberSortFunction,
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Salary (USD)",
    key: "salary",
    sortFunction: numberSortFunction,
  },
];

export const employeeTableColumnsForTablet = [
  {
    label: "Salary (USD)",
    key: "salary",
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Age",
    key: "age",
    sortFunction: numberSortFunction,
  },
];

export const dependentTableColumnsForTablet = [
  {
    label: "Relationship",
    key: "relationship",
  },
  {
    label: "Gender",
    key: "gender",
  },
  {
    label: "Age",
    key: "age",
    sortFunction: numberSortFunction,
  },
];

export const coverageTypeOptions = Object.values(CoverageTypeEnum);

export const coverageTypeTitles = {
  [CoverageTypeEnum.Cobra]: "COBRA",
  [CoverageTypeEnum.CobraOnly]: "COBRA Only",
  [CoverageTypeEnum.CobraPlusChildren]: "COBRA + Child(ren)",
  [CoverageTypeEnum.CobraPlusFamily]: "COBRA + Family",
  [CoverageTypeEnum.CobraPlusSpouse]: "COBRA + Spouse",
  [CoverageTypeEnum.EmployeeOnly]: "Employee Only",
  [CoverageTypeEnum.EmployeePlusChildren]: "Employee + Child(ren)",
  [CoverageTypeEnum.EmployeePlusFamily]: "Employee + Family",
  [CoverageTypeEnum.EmployeePlusSpouse]: "Employee + Spouse",
};

export const getGender = (gender?: GenderEnum | null) => {
  if (!gender) {
    return "";
  }
  if (gender === GenderEnum.F) {
    return "Female";
  }
  return "Male";
};

export const getAge = (dateOfBirth?: any) => {
  try {
    if (!dateOfBirth) {
      return "";
    }
    const years = moment().diff(moment(dateOfBirth as string), "years");
    return String(years);
  } catch (error) {
    return "";
  }
};

export const getSalary = (salary?: number | null | string) => {
  if (salary === undefined || salary === null || salary === "") {
    return "";
  }
  return String(salary);
};

export const getMedicarePrimary = (
  medicarePrimary?: MedicarePrimaryEnum | null
) => {
  if (!medicarePrimary) {
    return "";
  }

  if (medicarePrimary === MedicarePrimaryEnum.U) {
    return "Unknown";
  }

  if (medicarePrimary === MedicarePrimaryEnum.Yes) {
    return "Yes";
  }

  if (medicarePrimary === MedicarePrimaryEnum.No) {
    return "No";
  }

  return medicarePrimary;
};

export const getEmployeeType = (
  employeeType?: EmployeesTheCompanyWantsToOfferBenefitsToEnum | null
) => {
  if (!employeeType) {
    return "";
  }

  if (
    employeeType ===
    EmployeesTheCompanyWantsToOfferBenefitsToEnum.FullTimeEmployees
  ) {
    return "Full-Time";
  }

  if (
    employeeType ===
    EmployeesTheCompanyWantsToOfferBenefitsToEnum.PartTimeEmployees
  ) {
    return "Part-Time";
  }

  if (
    employeeType ===
    EmployeesTheCompanyWantsToOfferBenefitsToEnum.Contractors_1099
  ) {
    return "1099 Contractor";
  }

  return employeeType;
};

export const getRelationShip = (relationship?: RelationshipEnum | null) => {
  if (!relationship) {
    return "";
  }

  if (relationship === RelationshipEnum.Child) {
    return "Child";
  }

  if (relationship === RelationshipEnum.Spouse) {
    return "Child";
  }
  return relationship;
};

export const getRelationShipEnum = (
  relationship?: RelationshipEnum | string | null
): RelationshipEnum => {
  if (!relationship) {
    return "" as RelationshipEnum;
  }

  if (
    relationship.trim().toLowerCase() ===
    RelationshipEnum.Child.trim().toLowerCase()
  ) {
    return RelationshipEnum.Child;
  }

  if (
    relationship.trim().toLowerCase() ===
    RelationshipEnum.Spouse.trim().toLowerCase()
  ) {
    return RelationshipEnum.Spouse;
  }
  return relationship as RelationshipEnum;
};

const getIsDependentSelected = (
  isEmployeeSelected: boolean,
  coverage: CoverageTypeEnum,
  relationship?: RelationshipEnum | null
): boolean => {
  const shouldBeEmployeeOnly = [
    CoverageTypeEnum.EmployeeOnly,
    CoverageTypeEnum.CobraOnly,
    CoverageTypeEnum.Cobra,
  ].includes(coverage);

  if (!isEmployeeSelected || shouldBeEmployeeOnly || !coverage) {
    return false;
  }

  const isSpouse = relationship === RelationshipEnum.Spouse;

  const shouldBeSpouse = [
    CoverageTypeEnum.EmployeePlusSpouse,
    CoverageTypeEnum.CobraPlusSpouse,
  ].includes(coverage);

  if (shouldBeSpouse && isSpouse) {
    return true;
  }

  const isChild = relationship === RelationshipEnum.Child;

  const shouldBeChild = [
    CoverageTypeEnum.EmployeePlusChildren,
    CoverageTypeEnum.CobraPlusChildren,
  ].includes(coverage);
  if (shouldBeChild && isChild) {
    return true;
  }

  const shouldBeFamily = [
    CoverageTypeEnum.EmployeePlusFamily,
    CoverageTypeEnum.CobraPlusFamily,
  ].includes(coverage);
  const isFamily = isSpouse || isChild;

  if (shouldBeFamily && isFamily) {
    return true;
  }

  return false;
};

export const syncCoverageAndSelection = (
  employee: StateEmployee,
  coverage: CoverageTypeEnum,
  isSelected: boolean
): EmployeeFormWidgetResValue => {
  return {
    id: employee.id!,
    coverage,
    isSelected,
    dependents:
      employee.employeeDependents?.map<EmployeeDependentFormWidgetResValue>(
        (dependent) => {
          return {
            id: dependent?.id || "",
            isSelected: getIsDependentSelected(
              isSelected,
              coverage,
              getRelationShipEnum(dependent?.relationship)
            ),
          };
        }
      ) || [],
  };
};
