import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import {
  EmployeeFormWidgetRes,
  EmployeeFormWidgetResValue,
  StateEmployee,
} from "store";
import React, { ChangeEventHandler, useCallback } from "react";
import { Field, useFormikContext } from "formik";
import { CheckboxWithLabel, Select } from "formik-mui";
import { CoverageTypeEnum } from "gql/graphql";
import {
  coverageTypeOptions,
  coverageTypeTitles,
  employeeTableColumnsForTablet,
  syncCoverageAndSelection,
} from "./helpers";
import { MappedEmployee } from "./types";
import { DependentTableForTabletRow } from "./DependentTableForTabletRow";

interface Props {
  isLast: boolean;
  employee: MappedEmployee;
  name: string;
  employeeValues: EmployeeFormWidgetResValue;
  employeeData?: StateEmployee;
}

export const EmployeesTableForTabletRow = ({
  isLast,
  name,
  employeeValues,
  employeeData,
  employee,
}: Props) => {
  const { setFieldValue } = useFormikContext<EmployeeFormWidgetRes>();

  const handleSelectionChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const isSelected = e.target.checked;
        if (employeeData) {
          setFieldValue(
            `${name}`,
            syncCoverageAndSelection(
              employeeData,
              employeeValues?.coverage,
              isSelected
            )
          );
        }
      },
      [setFieldValue, employeeData, employeeValues?.coverage, name]
    );

  const handleCoverageChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const coverage = e.target.value;
        if (employeeData) {
          setFieldValue(
            `${name}`,
            syncCoverageAndSelection(
              employeeData,
              coverage as CoverageTypeEnum,
              employeeValues?.isSelected
            )
          );
        }
      },
      [setFieldValue, employeeData, employeeValues?.isSelected, name]
    );

  return (
    <Box
      sx={{
        borderBottom: isLast ? "" : "1px solid rgba(189, 189, 189, 1)",
        gap: (theme) => theme.spacing(3),
      }}
    >
      <Grid
        container
        flexWrap="nowrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        p={2}
      >
        <Grid item>
          <Box>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              sx={{
                color: (theme: Theme) => theme.palette.primary,
              }}
              color="primary"
              name={`${name}.isSelected`}
              onChange={handleSelectionChange}
              Label={{
                label: (
                  <Typography color="primary">
                    {employee.firstName || ""} {employee.lastName || ""}
                  </Typography>
                ),
                sx: {
                  color: (theme: Theme) => theme.palette.primary,
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              width: 220,
            }}
          >
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <Field
                name={`${name}.coverage`}
                component={Select}
                label={
                  employeeValues?.coverage
                    ? "Coverage Type"
                    : "Select Coverage Type"
                }
                options={coverageTypeOptions}
                fullWidth
                color="primary"
                // size="small"
                required={employeeValues?.isSelected}
                onChange={handleCoverageChange}
              >
                {coverageTypeOptions.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {coverageTypeTitles[option]}
                    </MenuItem>
                  );
                })}
              </Field>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Box mt={2} p={2}>
        <Grid
          container
          flexWrap="wrap"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
          pl={4}
        >
          {employeeTableColumnsForTablet.map((label) => (
            <Grid key={label.key} item xs={4}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {label.label}:
              </Typography>
              <Typography>{(employee as any)[label.key]}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {employee.dependents.map((dependent, index) => (
        <DependentTableForTabletRow
          key={dependent.id}
          dependent={dependent}
          name={`${name}.dependents.${index}`}
        />
      ))}
    </Box>
  );
};
