import React from "react";
import { Box } from "@mui/material";
import bufferLogo from "media/images/buffer-logo.png";

export const AuthHeader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      component="header"
      px={{ xs: 2, lg: 3 }}
      sx={{
        position: "sticky",
        backgroundColor: "#FCFCFC",
        width: "100%",
        top: 0,
        height: { xs: 48, sm: 48, md: 60, lg: 64 },
        borderBottom: "1px solid #E0E0E0",
        zIndex: 1000,
      }}
    >
      <Box>
        <img
          src={bufferLogo}
          alt="buffer insurance"
          style={{ maxWidth: 100, objectFit: "contain" }}
        />
      </Box>
    </Box>
  );
};
