import {
  pkAuthSelectors,
  useIsChecked,
} from "@deep-consulting-solutions/auth-web-muiv5";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { authActions, authSelectors, useAppDispatch } from "store";

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const isPkAuthenticated = useSelector(pkAuthSelectors.isAuthorised);
  const isAppAuthenticated = useSelector(authSelectors.isAuthenticated);
  const isCheckedToken = useIsChecked();
  const userToken = useSelector(authSelectors.token);
  const authContact = useSelector(pkAuthSelectors.authContact);

  const fullName = useMemo(() => {
    if (authContact?.firstName && authContact?.lastName) {
      return `${authContact?.firstName} ${authContact?.lastName}`;
    }
    if (authContact?.firstName) {
      return authContact?.firstName;
    }

    if (authContact?.lastName) {
      return authContact?.lastName;
    }

    return "";
  }, [authContact]);

  const isChecked = useMemo(() => {
    return !userToken || isCheckedToken;
  }, [userToken, isCheckedToken]);

  const isAuthenticated = useMemo(() => {
    return isAppAuthenticated && isPkAuthenticated && !!userToken;
  }, [isAppAuthenticated, isPkAuthenticated, userToken]);

  const onAuthenticated = useCallback(() => {
    dispatch(authActions.onAuthenticated());
  }, [dispatch]);

  return { isAuthenticated, isChecked, fullName, userToken, onAuthenticated };
};
