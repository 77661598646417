import { Box } from "@mui/material";
import { useResponsive } from "hooks";
import React, {
  useCallback,
  useMemo,
  useState,
  ChangeEventHandler,
} from "react";
import { EmployeeFormWidgetResValue, StateEmployee } from "store";
import {
  getAge,
  getGender,
  getRelationShip,
  getSalary,
  employeeTableColumns,
} from "./helpers";
import { EmployeesTableForDesktop } from "./EmployeesTableForDesktop";
import { MappedEmployee } from "./types";
import { EmployeesTableForTablet } from "./EmployeesTableForTablet";

interface Props {
  employees: StateEmployee[];
  name: string;
  employeesValues: EmployeeFormWidgetResValue[];
  selectAllValue: boolean;
  handleSelectAll: ChangeEventHandler<HTMLInputElement>;
  isSameTeam: boolean;
}

export const EmployeesTable = ({
  employees,
  employeesValues,
  name,
  selectAllValue,
  handleSelectAll,
  isSameTeam,
}: Props) => {
  const { isDesktop } = useResponsive();

  const [sortBy, setSortBy] = useState("firstName");

  const [sortAsc, setSortAsc] = useState(true);

  const mappedEmployees: MappedEmployee[] = useMemo(() => {
    return (
      employees?.map<MappedEmployee>((employee, index) => {
        return {
          id: employee?.id || "",
          formKey: String(index),
          lastName: employee?.contact?.lastName || "",
          firstName: employee?.contact?.firstName || "",
          relationship: "Employee",
          age: getAge(employee?.contact?.dateOfBirth),
          gender: getGender(employee.contact?.gender),
          salary: getSalary(employee?.salary),
          dependents:
            employee.employeeDependents?.map((employeeDependent) => ({
              id: employeeDependent?.id || "",
              lastName: employeeDependent?.contact?.lastName || "",
              firstName: employeeDependent?.contact?.firstName || "",
              relationship: getRelationShip(employeeDependent?.relationship),
              age: getAge(employeeDependent?.contact?.dateOfBirth),
              gender: getGender(employeeDependent?.contact?.gender),
              salary: "--",
            })) || [],
        };
      }) || []
    );
  }, [employees]);

  const sortedEmployees = useMemo(() => {
    const sortFunction = employeeTableColumns.find(
      (l) => l.key === sortBy
    )?.sortFunction;

    if (!sortFunction) {
      return mappedEmployees;
    }

    return mappedEmployees.sort((a: any, b: any) =>
      sortAsc
        ? sortFunction(a[sortBy], b[sortBy])
        : sortFunction(b[sortBy], a[sortBy])
    );
  }, [sortBy, mappedEmployees, sortAsc]);

  const onSort = useCallback((key: string) => {
    setSortBy((current) => {
      if (current !== key) {
        setSortAsc(true);
        return key;
      }
      setSortAsc((c) => !c);
      return current;
    });
  }, []);

  return (
    <Box mt={3}>
      {isDesktop ? (
        <Box
          sx={{
            background: "white",
            border: "1px solid rgba(189, 189, 189, 1)",
            borderRadius: (theme) => theme.spacing(0.5),
          }}
          mt={!isSameTeam ? 2 : 0}
        >
          <EmployeesTableForDesktop
            employees={sortedEmployees}
            onSort={onSort}
            name={name}
            employeesValues={employeesValues}
            selectAllValue={selectAllValue}
            handleSelectAll={handleSelectAll}
            employeesData={employees}
          />
        </Box>
      ) : (
        <Box mt={1.5}>
          <EmployeesTableForTablet
            employees={sortedEmployees}
            onSort={onSort}
            name={name}
            employeesValues={employeesValues}
            selectAllValue={selectAllValue}
            handleSelectAll={handleSelectAll}
            employeesData={employees}
          />
        </Box>
      )}
    </Box>
  );
};
