import { ArrowDownward } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

export const EmployeeTableHeaderCell = ({
  label,
  onSort,
}: {
  label: string;
  onSort?: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
      }}
    >
      {onSort ? (
        <IconButton onClick={onSort}>
          <ArrowDownward fontSize="small" />
        </IconButton>
      ) : null}
      <Typography variant="subtitle2">{label}</Typography>
    </Box>
  );
};
