import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useAppSelector,
  quoteRequestSelectors,
  EmployeeFormWidgetRes,
  EmployeeFormWidgetResValue,
} from "store";
import { Box, Collapse, IconButton, Grid, Typography } from "@mui/material";
import { ArrowDownward, KeyboardArrowDownOutlined } from "@mui/icons-material";
import { PolicyRequirementsInput } from "gql/graphql";
import { useFormikContext } from "formik";
import { getValueFromObject } from "Components/elements/AddressFormFields/helpers";
import { EmployeesTable } from "./EmployeeTable";

interface PolicyRequirementsFormProps {
  product: PolicyRequirementsInput;
  name: string;
  defaultExpanded: boolean;
}

const SAME_TEAM = "SAME_TEAM";

export const HeaderCellWithSort = ({
  label,
  onSort,
}: {
  label: string;
  onSort: () => void;
}) => {
  return (
    <Grid item flex={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
        }}
      >
        <IconButton onClick={onSort}>
          <ArrowDownward fontSize="small" />
        </IconButton>
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
    </Grid>
  );
};

export const EmployeeForm = ({
  product,
  name,
  defaultExpanded,
}: PolicyRequirementsFormProps) => {
  const { values, setFieldValue } = useFormikContext<EmployeeFormWidgetRes>();
  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );
  const employees = useAppSelector(quoteRequestSelectors.getEmployees);

  const [selectAll, setSelectAll] = useState(false);
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const employeesValues = useMemo(() => {
    return getValueFromObject(
      name.split("."),
      values
    ) as EmployeeFormWidgetResValue[];
  }, [name, values]);

  const employeesValuesSelected = useMemo(() => {
    return employeesValues.every((a) => a.isSelected);
  }, [employeesValues]);

  const handleExpand = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  const handleSelectAll: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.target.checked) {
        employeesValues.forEach((i, index) => {
          setFieldValue(`${name}.${index}.isSelected`, true);
        });
      } else if (employeesValuesSelected) {
        employeesValues.forEach((i, index) => {
          setFieldValue(`${name}.${index}.isSelected`, false);
        });
      }
      setSelectAll(e?.target.checked);
    },
    [setFieldValue, employeesValues, name, employeesValuesSelected]
  );

  useEffect(() => {
    if (values.sameTeam && product.id === SAME_TEAM) {
      setIsExpanded(true);
    }
  }, [values.sameTeam, product.id]);

  return (
    <Box
      sx={{
        border:
          product.id !== SAME_TEAM ? "1px solid rgba(189, 189, 189, 1)" : "",
        borderRadius: (theme) => theme.spacing(0.5),
        gap: (theme) => theme.spacing(3),
        padding: (theme) => theme.spacing(product.id === SAME_TEAM ? 0 : 2),
        background: product.id !== SAME_TEAM ? "rgba(244, 244, 244, 1)" : "",
      }}
      key={product.id}
    >
      {product.id !== SAME_TEAM ? (
        <Box
          display="flex"
          gap={2}
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            cursor: "pointer",
          }}
          onClick={handleExpand}
          role="button"
        >
          <Box
            sx={{
              transformOrigin: "center",
              transition: "transform 0.3s",
              transform: `rotate(${isExpanded ? "0deg" : "-90deg"})`,
            }}
          >
            <KeyboardArrowDownOutlined
              color={isExpanded ? "primary" : undefined}
            />
          </Box>

          <Typography variant="subtitle2">
            {productsOffers.find(
              (productsOffer) => productsOffer.id === product.id
            )?.formattedName || ""}
          </Typography>
        </Box>
      ) : null}
      <Collapse in={isExpanded}>
        <EmployeesTable
          employees={employees}
          employeesValues={employeesValues}
          name={name}
          selectAllValue={employeesValuesSelected ? selectAll : false}
          handleSelectAll={handleSelectAll}
          isSameTeam={product.id === SAME_TEAM}
        />
      </Collapse>
    </Box>
  );
};
