import React, { useCallback, useRef, useEffect, useState } from "react";
import { Button, Grid, Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Field, Form, FormikConfig, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { parse } from "query-string";

import { notifications } from "services";

import {
  AuthFormSkeleton,
  BigButton,
  pkAuthSelectors,
} from "@deep-consulting-solutions/auth-web-muiv5";
import { ROUTES, VALIDATIONS } from "app-constants";
import {
  AppDispatch,
  companyActions,
  companySelectors,
  useAppSelector,
} from "store";
import { FormikLowerCasedField, Loader } from "Components";
import { setAuthorizationHeader } from "api";

const validationSchema = Yup.object({
  email: VALIDATIONS.email,
  password: VALIDATIONS.password,
  repassword: VALIDATIONS.repassword,
});

interface Values {
  email: string;
  password: string;
  repassword: string;
}

export const ActivationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isAuthorised = useSelector(pkAuthSelectors.isAuthorised);
  const [loading, setLoading] = useState(false);
  const { token } = parse(location.search);

  const formRef = useRef<FormikProps<Values>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const contact = useAppSelector(companySelectors.getContact);

  const onLoginClick = useCallback(() => {
    navigate(ROUTES.requests.fullPath);
  }, [navigate]);

  const validateToken = useCallback(async () => {
    if (typeof token === "string" && token) {
      setLoading(true);
      setAuthorizationHeader(token);
      await dispatch(companyActions.fetchContact());
      setLoading(false);
    } else {
      navigate(ROUTES.login.path);
    }
    return () => setLoading(false);
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.validateForm();
    }
  }, []);

  useEffect(() => {
    if (!isAuthorised && !token) {
      notifications.notifyError("Activation link is invalid!");
      navigate(ROUTES.login.path);
    }
  }, [isAuthorised, token, navigate]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const onSubmit: FormikConfig<Values>["onSubmit"] = useCallback(
    async ({ repassword, ...values }, helpers) => {
      helpers.setSubmitting(true);
      const res = await dispatch(
        companyActions.companySignUp({
          password: values.password,
          email: values.email,
        })
      );

      helpers.setSubmitting(false);

      if (!companyActions.companySignUp.fulfilled.match(res)) return;
      navigate(ROUTES.dashboard.fullPath);
    },
    [dispatch, navigate]
  );

  return (
    <Box
      sx={{
        maxWidth: 400,
        borderRadius: 12,
        width: "100%",
        overflow: "hidden",
      }}
      position="relative"
    >
      {loading && <Loader open={loading} />}

      <AuthFormSkeleton
        location={location}
        isActivation
        disabled={!contact}
        form={
          <Formik
            innerRef={formRef}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              email: contact?.email || "",
              password: "",
              repassword: "",
            }}
          >
            {({ isValid, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={FormikLowerCasedField}
                      name="email"
                      label="Email"
                      autoComplete="username"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="password"
                      label="Password"
                      placeholder="Password"
                      autoComplete="new-password"
                      name="password"
                      disabled={!contact}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="password"
                      label="Re-type Password"
                      placeholder="Re-type Password"
                      autoComplete="new-password"
                      name="repassword"
                      disabled={!contact}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BigButton
                      disabled={!isValid || isSubmitting || !contact}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                    >
                      activate
                    </BigButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
        title="Activate Account"
        footer={
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Typography variant="body1" align="right">
              Already have an account?
            </Typography>
            <Button variant="text" color="primary" onClick={onLoginClick}>
              Login
            </Button>
          </Box>
        }
      />
    </Box>
  );
};
