import React, { useCallback } from "react";
import { LoginForm } from "@deep-consulting-solutions/auth-web-muiv5";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "app-constants";
import { Box } from "@mui/material";
import { useAuth } from "hooks";

export const Login = () => {
  const navigate = useNavigate();
  const { onAuthenticated } = useAuth();

  const location = useLocation();

  const onForgotPasswordClick = useCallback(() => {
    navigate(ROUTES.forgetPassword.fullPath);
  }, [navigate]);

  return (
    <Box
      sx={{
        maxWidth: 400,
        borderRadius: 12,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <LoginForm
        onAuthorised={onAuthenticated}
        onForgotPasswordClick={onForgotPasswordClick}
        location={location}
      />
    </Box>
  );
};
