import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector, quoteRequestSelectors } from "store";
import { Box, Collapse, Divider, Grid, Typography } from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { PolicyRequirementsInput } from "gql/graphql";
import { Field, useFormikContext } from "formik";
import { SavePolicyRequirementsAction } from "store/reducers/quote-request/types";
import { useResponsive } from "hooks";
import { FormikDatePicker } from "Components";
import { DATE_TYPING_FORMAT } from "app-constants";
import { TextField } from "formik-mui";

interface PolicyRequirementsFormProps {
  product: PolicyRequirementsInput;
  name: string;
  defaultExpanded: boolean;
}

const ALL_PRODUCTS_ARE_SAME = "ALL_PRODUCTS_ARE_SAME";

export const PolicyRequirement = ({
  product,
  name,
  defaultExpanded,
}: PolicyRequirementsFormProps) => {
  const { isDesktop } = useResponsive();
  const { values } = useFormikContext<SavePolicyRequirementsAction>();
  const products = useAppSelector(quoteRequestSelectors.getProducts);
  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const allProductsLabel = useMemo(() => {
    const productNames =
      products
        ?.map((p) => {
          const productsOffer = productsOffers.find((pO) => pO.id === p.id);
          return productsOffer?.formattedName;
        })
        ?.filter((p) => !!p) || [];
    return (
      <Typography component="span">
        Medical Requirements for:{" "}
        {productNames.map((productsOffer, index) => (
          <span key={productsOffer}>
            <b>{productsOffer}</b>
            {index === productNames.length - 1 ? "" : ", "}
          </span>
        ))}
      </Typography>
    );
  }, [products, productsOffers]);

  const handleExpand = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  useEffect(() => {
    if (
      values.policyRequirementsAreTheSame &&
      product.id === ALL_PRODUCTS_ARE_SAME
    ) {
      setIsExpanded(true);
    }
  }, [values.policyRequirementsAreTheSame, product.id]);

  return (
    <Box
      sx={{
        border: "1px solid rgba(189, 189, 189, 1)",
        borderRadius: (theme) => theme.spacing(0.5),
        gap: (theme) => theme.spacing(3),
        padding: (theme) => theme.spacing(2),
        background: "white",
      }}
      key={product.id}
    >
      <Box
        display="flex"
        gap={2}
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          cursor: "pointer",
        }}
        onClick={handleExpand}
        role="button"
      >
        {product.id === ALL_PRODUCTS_ARE_SAME ? (
          allProductsLabel
        ) : (
          <Typography variant="subtitle2">
            {productsOffers.find(
              (productsOffer) => productsOffer.id === product.id
            )?.formattedName || ""}
          </Typography>
        )}
        <Box
          sx={{
            transformOrigin: "center",
            transition: "transform 0.3s",
            transform: `rotate(${isExpanded ? "0deg" : "90deg"})`,
          }}
        >
          <KeyboardArrowDownOutlined
            color={isExpanded ? "primary" : undefined}
          />
        </Box>
      </Box>
      <Collapse in={isExpanded}>
        <Box pt={3} pb={3}>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Field
              component={FormikDatePicker}
              label="Planned Effective Date"
              name={`${name}.plannedEffectiveDate`}
              placeholder={DATE_TYPING_FORMAT.toUpperCase()}
              fullWidth
              required
              size="small"
            />
          </Grid>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Field
              component={TextField}
              label="Number of Team Members"
              name={`${name}.numberOfTeamMembersThatMightWantToEnroll`}
              fullWidth
              size="small"
              integer="true"
              inputProps={{
                min: 0,
              }}
              type="number"
              helperText="Number of team members that might want to enroll"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography mb={1.5} mt={1} variant="subtitle2">
              Approximate Employer Contribution
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={isDesktop ? 4 : 12}>
                <Field
                  component={TextField}
                  label="Employer Contribution"
                  name={`${name}.approximateEmployerContribution`}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography mt={1} mb={1.5} variant="subtitle2">
              Coverage Requirements
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={isDesktop ? 4 : 12}>
                <Field
                  component={TextField}
                  label="Maximum Monthly Premium Per Employee"
                  name={`${name}.maximumMonthlyContributionByEmployee`}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={isDesktop ? 4 : 12}>
                <Field
                  component={TextField}
                  label="Other Specific Coverage Related Requests"
                  name={`${name}.otherSpecificCoverageRelatedRequests`}
                  fullWidth
                  size="small"
                  rows={2}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};
