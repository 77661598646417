import {
  fetchEmployeesRequest,
  getCarriersRequest,
  getProductsOffersRequest,
  submitOpportunity,
} from "services";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Employee,
  GetCarriersAndGAsRecord,
  Opportunity,
  OpportunityWithOpportunityActivePoliciesResponse,
  Product,
} from "gql/graphql";

import { PolicyOptionEnum, QuoteRequestStepEnum } from "../enums";
import { RootState, QuoteRequestState } from "../types";
import { SavePolicyRequirementsAction } from "./types";
import {
  mapQuoteRequestToSubmitOpportunityInput,
  mapQuoteRequestToState,
} from "./helpers";

const ENTITY = "quote-request";

const initialState: QuoteRequestState = {
  step: QuoteRequestStepEnum.GET_QUOTE,
  products: [],
  opportunityID: undefined,
  company: {},
  numberOfTeamMembersThatMightWantToEnroll: undefined,
  policy: undefined,
  policyRequirements: [],
  policyRequirementsAreTheSame: undefined,
  otherDetails: {
    clientEmployeesCoveragePriorities: "",
    whatWouldClientLikeBufferToStartDoing: "",
    currentBrokerTasksThatClientWantsContinued: "",
  },
  employeeForm: {
    sameTeam: false,
    products: [],
  },
  activePolicies: {
    activePolicies: [],
    otherDetails: {
      startDoing: "",
      continueToDo: "",
    },
  },
  hasMissingRequiredActivePolicyFields: false,
  hasMissingRequiredPolicyRequirementFields: false,
  productsOffers: [],
  carriers: [],
  employees: [],
};

const submitQuote = createAsyncThunk<
  OpportunityWithOpportunityActivePoliciesResponse | null,
  { isDraft: boolean },
  { state: RootState }
>(
  `${ENTITY}/submitQuote`,
  async ({ isDraft }, { getState, rejectWithValue }) => {
    const {
      quoteRequest,
      company: { company },
    } = getState();

    if (!company?.id) {
      throw new Error("Company Id is required");
    }
    const payload = mapQuoteRequestToSubmitOpportunityInput(
      quoteRequest,
      company.id,
      isDraft
    );

    const res = await submitOpportunity(payload);

    if (!res?.opportunity) {
      if (!quoteRequest.opportunityID) {
        return rejectWithValue("");
      }
      return rejectWithValue(quoteRequest.opportunityID);
    }

    return res;
  }
);

const fetchProductsOffers = createAsyncThunk<{ products: Product[] }>(
  `${ENTITY}/fetchProductsOffers`,
  async () => {
    const res = await getProductsOffersRequest();

    return { products: res };
  }
);

const fetchCarriers = createAsyncThunk<{ carriers: GetCarriersAndGAsRecord[] }>(
  `${ENTITY}/fetchCarriers`,
  async () => {
    const res = await getCarriersRequest();

    return { carriers: res };
  }
);

const fetchEmployees = createAsyncThunk<
  {
    employees: Employee[];
  },
  { companyId: string }
>(`${ENTITY}/fetchEmployees`, async ({ companyId }) => {
  const res = await fetchEmployeesRequest(companyId, 1, 1000);

  return { employees: res.data };
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    saveStep: (state, action: PayloadAction<QuoteRequestStepEnum>) => {
      state.step = action.payload;
    },

    saveGetQuoteData: (
      state,
      action: PayloadAction<
        Pick<
          QuoteRequestState,
          "policy" | "numberOfTeamMembersThatMightWantToEnroll"
        >
      >
    ) => {
      state.numberOfTeamMembersThatMightWantToEnroll =
        action.payload.numberOfTeamMembersThatMightWantToEnroll;

      state.policy = action.payload.policy;
    },
    reset: () => {
      return initialState;
    },
    saveCompanyInformation: (
      state,
      action: PayloadAction<QuoteRequestState["company"]>
    ) => {
      state.company = action.payload;
    },
    saveOpportunityID: (state, action: PayloadAction<string>) => {
      state.opportunityID = action.payload;
    },
    saveProducts: (
      state,
      action: PayloadAction<QuoteRequestState["products"]>
    ) => {
      if (!Array.isArray(action.payload)) return;
      state.products = action.payload;
    },
    saveActivePolicies: (
      state,
      action: PayloadAction<QuoteRequestState["activePolicies"]>
    ) => {
      if (action.payload) {
        state.activePolicies = action.payload;
        if (state.otherDetails) {
          state.otherDetails.currentBrokerTasksThatClientWantsContinued =
            action.payload.otherDetails.continueToDo;
          state.otherDetails.whatWouldClientLikeBufferToStartDoing =
            action.payload.otherDetails.startDoing;
        } else {
          state.otherDetails = {
            currentBrokerTasksThatClientWantsContinued:
              action.payload.otherDetails.continueToDo,
            whatWouldClientLikeBufferToStartDoing:
              action.payload.otherDetails.startDoing,
            clientEmployeesCoveragePriorities: "",
          };
        }
      }
    },
    saveCompanyImageBase64: (state, action: PayloadAction<string>) => {
      state.companyImageBase64 = action.payload;
    },
    saveEmployeeFormRes: (
      state,
      action: PayloadAction<QuoteRequestState["employeeForm"]>
    ) => {
      state.employeeForm = action.payload;
    },
    savePolicyRequirements: (
      state,
      action: PayloadAction<SavePolicyRequirementsAction>
    ) => {
      state.policyRequirements = action.payload.products;
      state.policyRequirementsAreTheSame =
        action.payload.policyRequirementsAreTheSame;
      if (state.otherDetails) {
        state.otherDetails.clientEmployeesCoveragePriorities =
          action.payload.otherDetails;
      } else {
        state.otherDetails = {
          clientEmployeesCoveragePriorities: action.payload.otherDetails,
          whatWouldClientLikeBufferToStartDoing: "",
          currentBrokerTasksThatClientWantsContinued: "",
        };
      }
    },
    setForGetQuote: (state) => {
      if (state.opportunityID) {
        return initialState;
      }
      state.step = QuoteRequestStepEnum.GET_QUOTE;
    },
    setForContinueQuote: (state, action: PayloadAction<Opportunity>) => {
      return mapQuoteRequestToState(
        initialState,
        action.payload,
        QuoteRequestStepEnum.GET_QUOTE
      );
    },
    setForHB2015: (state, action: PayloadAction<Opportunity>) => {
      return mapQuoteRequestToState(
        initialState,
        action.payload,
        QuoteRequestStepEnum.SIGN_HB2015_LETTER
      );
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(submitQuote.fulfilled, (state, action) => {
        state.opportunityID = action.payload?.opportunity?.id || "";
        state.accessLink = action.payload?.accessLink || "";
        state.opportunity =
          (action.payload?.opportunity as Opportunity) || undefined;
      })
      .addCase(submitQuote.rejected, (state, action) => {
        state.opportunityID = action.payload as string;
      })
      .addCase(fetchProductsOffers.fulfilled, (state, action) => {
        state.productsOffers = action.payload.products;
      })
      .addCase(fetchCarriers.fulfilled, (state, action) => {
        state.carriers = action.payload.carriers;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employees = action.payload.employees;
      }),
});

const getStep = ({ quoteRequest: { step } }: RootState) => step;

const getCompany = ({ quoteRequest: { company } }: RootState) => company;

const getOpportunityID = ({ quoteRequest: { opportunityID } }: RootState) =>
  opportunityID;

const getPolicy = ({ quoteRequest: { policy } }: RootState) => policy;

const getOpportunity = ({ quoteRequest: { opportunity } }: RootState) =>
  opportunity;

const getQuoteCompanyInformation = ({ quoteRequest: { company } }: RootState) =>
  company;

const getIsExistingPolicy = ({ quoteRequest: { policy } }: RootState) =>
  policy === PolicyOptionEnum.Change;

const getNumberOfTeamMembersThatMightWantToEnroll = ({
  quoteRequest: { numberOfTeamMembersThatMightWantToEnroll },
}: RootState) => numberOfTeamMembersThatMightWantToEnroll;

const getProducts = ({ quoteRequest: { products } }: RootState) => products;

const getPolicyRequirements = ({
  quoteRequest: { policyRequirements },
}: RootState) => policyRequirements;

const getOtherDetails = ({ quoteRequest: { otherDetails } }: RootState) =>
  otherDetails;

const getActivePolicies = ({ quoteRequest: { activePolicies } }: RootState) =>
  activePolicies;

const getEmployeeFormRes = ({ quoteRequest: { employeeForm } }: RootState) =>
  employeeForm;

const getPolicyRequirementsAreTheSame = ({
  quoteRequest: { policyRequirementsAreTheSame },
}: RootState) => policyRequirementsAreTheSame;

const getHasMissingRequiredActivePolicyFields = ({
  quoteRequest: { hasMissingRequiredActivePolicyFields },
}: RootState) => hasMissingRequiredActivePolicyFields;

const getHasMissingRequiredPolicyRequirementFields = ({
  quoteRequest: { hasMissingRequiredPolicyRequirementFields },
}: RootState) => hasMissingRequiredPolicyRequirementFields;

const getCompanyImageBase64 = ({
  quoteRequest: { companyImageBase64 },
}: RootState) => companyImageBase64;

const getProductsOffers = ({ quoteRequest: { productsOffers } }: RootState) =>
  productsOffers;

const getCarriers = ({ quoteRequest: { carriers } }: RootState) => carriers;

const getEmployees = ({ quoteRequest: { employees } }: RootState) => employees;

export const quoteRequestSelectors = {
  getStep,
  getPolicy,
  getProducts,
  getOtherDetails,
  getOpportunityID,
  getIsExistingPolicy,
  getPolicyRequirements,
  getQuoteCompanyInformation,
  getPolicyRequirementsAreTheSame,
  getNumberOfTeamMembersThatMightWantToEnroll,
  getActivePolicies,
  getEmployeeFormRes,
  getOpportunity,
  getHasMissingRequiredActivePolicyFields,
  getHasMissingRequiredPolicyRequirementFields,
  getCompanyImageBase64,
  getCompany,
  getProductsOffers,
  getCarriers,
  getEmployees,
};

export const quoteRequestActions = {
  ...slice.actions,
  submitQuote,
  fetchProductsOffers,
  fetchCarriers,
  fetchEmployees,
};

export const quoteRequestReducer = slice.reducer;
