import * as React from "react";
import { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import { RadioGroupProps, useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box, RadioGroup } from "@mui/material";
import theme from "utils/theme";

export const RadioCardGroup = ({
  children,
  ...rest
}: PropsWithChildren<RadioGroupProps>) => {
  return (
    <RadioGroup
      sx={{
        flexWrap: "nowrap",
        flexDirection: "row",
        gap: 2,
      }}
      {...rest}
    >
      {children}
    </RadioGroup>
  );
};

interface StyledFormControlLabelProps
  extends Omit<FormControlLabelProps, "label"> {
  checked: boolean;
  label?: string;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel label={undefined} {...props} />
))(({ theme: t, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: t.palette.primary.main,
    padding: 0,
  },
  "MuiButtonBase-root-MuiRadio-root": {
    padding: 0,
  },
}));

interface Props extends Omit<FormControlLabelProps, "control" | "label"> {
  label?: string;
  children: ({
    checked,
    color,
  }: {
    checked: boolean;
    color?: string;
  }) => React.ReactNode;
}

export const RadioCard = ({ value, label, children, ...props }: Props) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === value;
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        borderRadius: "12px",
        padding: "20px",
        alignItems: "flex-start",
        flex: 1,
        border: `${checked ? 2 : 1}px solid ${
          checked ? theme.palette.primary.main : "#666666"
        }`,
      }}
    >
      <StyledFormControlLabel
        sx={{ mr: 0 }}
        checked={checked}
        value={value}
        {...props}
        control={<Radio />}
      />
      <Box
        sx={{
          color: label && checked ? theme.palette.primary.main : undefined,
        }}
      >
        {children({
          checked: radioGroup ? radioGroup.value === value : false,
          color: checked ? theme.palette.primary.main : undefined,
        }) || label}
      </Box>
    </Box>
  );
};
