import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";

const fontSize = 18;

export const LepCalculatorPartBResult5 = () => {
  const year = useMemo(() => {
    return moment().year();
  }, []);
  return (
    <Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <ArrowForward fontSize="small" /> This is the amount you would have to
          pay{" "}
          <b>
            every month as a penalty on top of your monthly Medicare B premium
          </b>{" "}
          in {year}. This amount changes slightly every year after your
          enrollment.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          The penalty <b>never expires</b> and you have to pay it as long as you
          are enrolled.
          <br />
          <b>
            The longer you spend without coverage, the higher the penalty goes!
          </b>
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize,
        }}
      >
        <u>
          <b>
            <i>Get help to:</i>
          </b>
        </u>
      </Typography>
      <Typography
        sx={{
          fontSize,
          marginBottom: ({ spacing }) => spacing(2),
        }}
        component="ul"
      >
        <li>
          <i>See if the estimated penalty really applies to you</i>
        </li>
        <li>
          <i>Find out if you can get more out of your Medicare</i>
        </li>
        <li>
          <i>Get the most suitable plans picked for you</i>
        </li>
      </Typography>
      <Typography
        sx={{
          fontSize,
        }}
      >
        <b>Book a free consultation with our expert today!</b>
      </Typography>
    </Box>
  );
};
