import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";
import { useResponsive } from "hooks";
import { LepCalculatorPartBResult2 } from "./LepCalculatorPartBResult2";
import { LepCalculatorPartBResult1 } from "./LepCalculatorPartBResult1";
import { LepCalculatorPartBResult3 } from "./LepCalculatorPartBResult3";
import { LepCalculatorPartBResult4 } from "./LepCalculatorPartBResult4";
import { LepCalculatorPartBResult5 } from "./LepCalculatorPartBResult5";

interface Props {
  resultVariant: number;
  calculatedPenalty: string | null;
  enrollmentStartDateString: string;
  dateOfBirth: string;
  uuid: string;
  email: string;
  enrollmentEndDateString: string;
}

export const LepCalculatorPartBResult = ({
  resultVariant,
  calculatedPenalty,
  enrollmentStartDateString,
  dateOfBirth,
  uuid,
  email,
  enrollmentEndDateString,
}: Props) => {
  const { isMDDown } = useResponsive();
  const penaltyScore = useMemo(() => {
    if (Number(calculatedPenalty) === 0) {
      return 0;
    }
    return calculatedPenalty;
  }, [calculatedPenalty]);

  const imageUrl = useMemo(() => {
    if (resultVariant >= 4) {
      return "https://bufferinsurance.com/wp-content/uploads/2023/02/Free-Consultation-v5.jpg";
    }
    return "https://bufferinsurance.com/wp-content/uploads/2023/02/1.png";
  }, [resultVariant]);

  const ebookUrl = useMemo(() => {
    const params = `?email=${email}&form1_id=${uuid}&dob=${moment(
      dateOfBirth
    ).format("MM/DD/YYYY")}`;
    if (resultVariant >= 4) {
      return `https://bufferinsurance.com/book-your-medicare-maximizer-assessmen${params}t`;
    }
    return `https://bufferinsurance.com/get-medicare-ebook${params}`;
  }, [dateOfBirth, uuid, email, resultVariant]);

  const buttonText = useMemo(() => {
    if (resultVariant >= 4) {
      return "BOOK MEDICARE MAXIMIZER ASSESSMENT";
    }
    return "GET MY FREE EBOOK!";
  }, [resultVariant]);

  return (
    <Box>
      <Box pb={5.25} display="flex" justifyContent="center">
        <Typography
          variant="h3"
          sx={{
            fontSize: 24,
            color: "rgba(0, 133, 255, 1)",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
          align="center"
          lineHeight={1.4}
        >
          Your estimated late enrollment
          <br /> penalty for Medicare Part B<br /> is ${penaltyScore} per month
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexWrap="nowrap"
        component="div"
        pb={4}
        alignItems={isMDDown ? "center" : "flex-start"}
        gap={4}
        flexDirection={isMDDown ? "column" : "row"}
      >
        <Box flex={1} maxWidth={700}>
          {resultVariant === 1 ? <LepCalculatorPartBResult1 /> : null}
          {resultVariant === 2 ? (
            <LepCalculatorPartBResult2
              enrollmentStartDateString={enrollmentStartDateString}
            />
          ) : null}
          {resultVariant === 3 ? <LepCalculatorPartBResult3 /> : null}
          {resultVariant === 4 ? (
            <LepCalculatorPartBResult4
              enrollmentEndDateString={enrollmentEndDateString}
            />
          ) : null}
          {resultVariant === 5 ? <LepCalculatorPartBResult5 /> : null}
        </Box>
        <Box position="relative">
          <Box display="flex" justifyContent="center">
            <Box
              display="block"
              component="a"
              href={ebookUrl}
              target="_blank"
              rel="noreferrer"
              width="fit-content"
            >
              <Box
                width={isMDDown ? "100%" : "auto"}
                maxWidth={isMDDown ? 300 : undefined}
                height={isMDDown ? "auto" : 340}
                component="img"
                src={imageUrl}
                alt="buffer lep ebook"
                display="block"
                sx={{
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          component="a"
          href={ebookUrl}
          target="_blank"
          rel="noreferrer"
          sx={{
            paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
            paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};
