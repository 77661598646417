import { Box } from "@mui/material";
import React from "react";
import { useFormikContext } from "formik";
import { LepCalculatorPartBFormValues } from "interfaces";
import { LepCalculatorRadios } from "../Fields/LepCalculatorRadios";

export const LepCalculatorPartBFormB = () => {
  const { errors, touched } = useFormikContext<LepCalculatorPartBFormValues>();
  return (
    <Box>
      <Box pb={4}>
        <LepCalculatorRadios
          name="enrolledInMedicaid"
          options={[
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ]}
          label={{
            title: "Are you enrolled in Medicaid?",
          }}
          errorMessage={
            errors.enrolledInMedicaid && touched.enrolledInMedicaid
              ? errors.enrolledInMedicaid
              : undefined
          }
        />
      </Box>
      <Box pb={4}>
        <LepCalculatorRadios
          name="receiveSupplementalSecurityIncome"
          options={[
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ]}
          label={{
            title:
              "Do you receive Supplemental Security Income (SSI) benefits from Social Security?",
          }}
          errorMessage={
            errors.receiveSupplementalSecurityIncome &&
            touched.receiveSupplementalSecurityIncome
              ? errors.receiveSupplementalSecurityIncome
              : undefined
          }
        />
      </Box>
      <Box>
        <LepCalculatorRadios
          name="haveEndStageRenalDisease"
          options={[
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ]}
          label={{
            title: "Do you have End Stage Renal Disease?",
          }}
          errorMessage={
            errors.haveEndStageRenalDisease && touched.haveEndStageRenalDisease
              ? errors.haveEndStageRenalDisease
              : undefined
          }
        />
      </Box>
    </Box>
  );
};
