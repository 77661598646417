import React, { useCallback, useEffect, useMemo } from "react";
import { FormikProps } from "formik";
import { TextField } from "formik-mui";
import { Grid } from "@mui/material";
import { useResponsive } from "hooks";
import { codeToCountry, getCountryCode, getValueFromObject } from "./helpers";
import { FormikCountrySelectInput } from "./CountrySelectField";
import { FormikStreetField } from "./FormikStreetField";
import { FieldWithNotifyLabel } from "./FieldWithNotifyLabel";

export interface AddressFormValues {
  country: string;
  street: string;
  city: string;
  islandOrStateOrRegion: string | null;
  poBoxOrZipOrPostalCode: string;
}

interface AddressFormFieldsProps<FormValues extends Record<string, any>> {
  formikProps: FormikProps<FormValues>;
  streetName?: string;
  cityName?: string;
  islandName?: string;
  countryName?: string;
  poBoxName?: string;
  use2Stars?: boolean;
  disabledFields?: Partial<{
    country: boolean;
    street: boolean;
    island: boolean;
    city: boolean;
    poBox: boolean;
  }>;
}

export const AddressFormFields = <FormValues extends Record<string, any>>({
  formikProps,
  streetName = "street",
  cityName = "city",
  islandName = "islandOrStateOrRegion",
  countryName = "country",
  poBoxName = "poBoxOrZipOrPostalCode",
  use2Stars,
  notify,
  disabledFields,
}: { notify?: boolean } & AddressFormFieldsProps<FormValues>) => {
  const { values, setFieldValue, validateForm } = formikProps;
  const { isDesktop } = useResponsive();

  const country = useMemo(
    () =>
      getCountryCode(
        getValueFromObject(countryName.split("."), values) as string
      ),
    [countryName, values]
  );

  const onCountryChange = useCallback(
    (selectedCountry: string) => {
      setFieldValue(
        countryName,
        codeToCountry(selectedCountry) || selectedCountry
      );
    },
    [setFieldValue, countryName]
  );

  useEffect(() => {
    if (country) {
      validateForm();
    }
  }, [validateForm, country]);

  return (
    <Grid
      container
      spacing={2}
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item>
        <Grid
          container
          spacing={isDesktop ? 2 : 3}
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item flex="1">
            <FieldWithNotifyLabel
              component={FormikCountrySelectInput}
              name={countryName}
              label={use2Stars ? "Country *" : "Country"}
              onCountryChange={onCountryChange}
              useCountryCode={false}
              notify={notify}
              disabled={!!disabledFields?.country}
              fullWidth
              required
              size="small"
            />
          </Grid>
          <Grid item flex="1">
            <FormikStreetField<FormValues>
              use2Stars={use2Stars}
              name={streetName}
              country={country || undefined}
              formikProps={formikProps}
              cityName={cityName}
              islandName={islandName}
              countryName={countryName}
              poBoxName={poBoxName}
              notify={notify}
              disabled={!!disabledFields?.street}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={isDesktop ? 2 : 3}
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item flex="1">
            <FieldWithNotifyLabel
              component={TextField}
              name={cityName}
              label="City"
              notify={notify}
              disabled={!!disabledFields?.city}
              fullWidth
              required
              size="small"
            />
          </Grid>

          <Grid item flex="1">
            <FieldWithNotifyLabel
              component={TextField}
              name={islandName}
              label="State"
              notify={notify}
              disabled={!!disabledFields?.island}
              fullWidth
              required
              size="small"
            />
          </Grid>

          <Grid item flex="1">
            <FieldWithNotifyLabel
              component={TextField}
              name={poBoxName}
              label="Zip Code"
              notify={notify}
              disabled={!!disabledFields?.poBox}
              fullWidth
              required
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
