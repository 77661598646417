import { Box } from "@mui/material";
import { useResponsive } from "hooks";
import React, { useCallback, useMemo, useState } from "react";
import { StateEmployee } from "store";
import { EmployeesTheCompanyWantsToOfferBenefitsToEnum } from "gql/graphql";
import { EmployeesTableForTablet } from "./EmployeesTableForTablet";
import { EmployeesTableForDesktop } from "./EmployeesTableForDesktop";
import { MappedEmployee } from "./types";
import {
  employeeTableColumns,
  getAge,
  getGender,
  getMedicarePrimary,
  getRelationShip,
  getSalary,
} from "./helpers";

interface Props {
  employees: StateEmployee[];
  onDeleteEmployee: (employee: StateEmployee) => void;
  onEditEmployee: (employee: StateEmployee) => void;
  employeesCount: number;
  onEmployeesPageChange: (page: number) => void;
  onEmployeesRowsPerPageChange: (page: number) => void;
  rowsPerPage: number;
  page: number;
}

export const EmployeesTable = ({
  employees,
  onDeleteEmployee,
  onEditEmployee,
  employeesCount,
  rowsPerPage,
  page,
  onEmployeesPageChange,
  onEmployeesRowsPerPageChange,
}: Props) => {
  const { isDesktop } = useResponsive();

  const [sortBy, setSortBy] = useState("firstName");

  const [sortAsc, setSortAsc] = useState(true);

  const mappedEmployees: MappedEmployee[] = useMemo(() => {
    return (
      employees?.map<MappedEmployee>((employee) => {
        return {
          id: employee?.id || "",
          lastName: employee?.contact?.lastName || "",
          firstName: employee?.contact?.firstName || "",
          relationship: "Employee",
          coverage: employee.coverage || "",
          age: getAge(employee?.contact?.dateOfBirth),
          gender: getGender(employee.contact?.gender),
          salary: getSalary(employee?.salary),
          jobTitle: employee.contact?.jobTitle || "",
          employeeType:
            employee.employeeType ||
            ("" as EmployeesTheCompanyWantsToOfferBenefitsToEnum),
          email: employee.contact?.email || "",
          medicarePrimary: getMedicarePrimary(
            employee.contact?.medicarePrimary
          ),
          zipCode: employee.contact?.zipCode || "",
          dateOfHire: employee.dateOfHire || "",
          dependents:
            employee.employeeDependents?.map((employeeDependent) => ({
              id: employeeDependent?.id || "",
              lastName: employeeDependent?.contact?.lastName || "",
              firstName: employeeDependent?.contact?.firstName || "",
              relationship: getRelationShip(employeeDependent?.relationship),
              coverage: "",
              age: getAge(employeeDependent?.contact?.dateOfBirth),
              gender: getGender(employeeDependent?.contact?.gender),
              salary: "--",
              jobTitle: "--",
              email: employeeDependent?.contact?.email || "-",
              medicarePrimary: getMedicarePrimary(
                employeeDependent?.contact?.medicarePrimary
              ),
              zipCode:
                ((employeeDependent?.contact as any)?.zipCode as string) || "",
            })) || [],
        };
      }) || []
    );
  }, [employees]);

  const sortedEmployees = useMemo(() => {
    const sortFunction = employeeTableColumns.find(
      (l) => l.key === sortBy
    )?.sortFunction;

    if (!sortFunction) {
      return mappedEmployees;
    }

    return mappedEmployees.sort((a: any, b: any) =>
      sortAsc
        ? sortFunction(a[sortBy], b[sortBy])
        : sortFunction(b[sortBy], a[sortBy])
    );
  }, [sortBy, mappedEmployees, sortAsc]);

  const onSort = useCallback((key: string) => {
    setSortBy((current) => {
      if (current !== key) {
        setSortAsc(true);
        return key;
      }
      setSortAsc((c) => !c);
      return current;
    });
  }, []);

  const handleDeleteEmployee = useCallback(
    (mappedEmployee: MappedEmployee) => {
      const employee = employees.find((e) => e.id === mappedEmployee.id)!;
      onDeleteEmployee(employee);
    },
    [employees, onDeleteEmployee]
  );

  const handleEditEmployee = useCallback(
    (mappedEmployee: MappedEmployee) => {
      const employee = employees.find((e) => e.id === mappedEmployee.id)!;
      onEditEmployee(employee);
    },
    [employees, onEditEmployee]
  );

  return (
    <Box mt={3}>
      {isDesktop ? (
        <EmployeesTableForDesktop
          employees={sortedEmployees}
          onDeleteEmployee={handleDeleteEmployee}
          onEditEmployee={handleEditEmployee}
          onSort={onSort}
          employeesCount={employeesCount}
          onEmployeesPageChange={onEmployeesPageChange}
          rowsPerPage={rowsPerPage}
          page={page}
          onEmployeesRowsPerPageChange={onEmployeesRowsPerPageChange}
        />
      ) : (
        <EmployeesTableForTablet
          employees={sortedEmployees}
          onDeleteEmployee={handleDeleteEmployee}
          onEditEmployee={handleEditEmployee}
          onSort={onSort}
          employeesCount={employeesCount}
          onEmployeesPageChange={onEmployeesPageChange}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      )}
    </Box>
  );
};
