import { Field, Form, Formik, FormikConfig } from "formik";
import React, { useCallback } from "react";
import { PolicyOptionEnum } from "store";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Theme,
  Typography,
} from "@mui/material";
import { Button } from "Components";
import { useResponsive } from "hooks";
import { RadioGroup, TextField } from "formik-mui";
import { GetQuoteProps, GoToSubmitRequestParams } from "./types";

const policyOptions = [
  {
    label: "New Policy",
    value: PolicyOptionEnum.New,
    description: <>I want a new group insurance coverage.</>,
  },
  {
    label: "Policy Change",
    value: PolicyOptionEnum.Change,
    description: (
      <>
        I already have group insurance coverage, and I am looking to{" "}
        <strong>change my current policy.</strong>
      </>
    ),
  },
];

const validationSchema = yup.object().shape({
  policy: yup
    .string()
    .required("Policy is required")
    .oneOf(
      policyOptions.map((p) => p.value),
      "Policy is required"
    )
    .typeError("Policy is required"),
  numberOfTeamMembersThatMightWantToEnroll: yup.number().when("policy", {
    is: PolicyOptionEnum.Change,
    then: yup
      .number()
      .min(
        0,
        "Number of team members that might want to enroll must be greater than 0"
      )
      .integer(
        "Sorry, your input is not a valid integer. Please enter a whole number with no decimal places."
      )
      .required("Number of team members that might want to enroll is required")
      .typeError(
        "Sorry, your input is not a valid integer. Please enter a whole number with no decimal places."
      ),
    otherwise: yup.number().nullable(),
  }),
});

interface Props {
  initialValues: Partial<GoToSubmitRequestParams>;
  onGoToCompanyInformation: GetQuoteProps["onGoToCompanyInformation"];
}

export const GetQuoteFormStep = ({
  initialValues,
  onGoToCompanyInformation,
}: Props) => {
  const { isDesktop, isMobile } = useResponsive();
  const onSubmit: FormikConfig<GoToSubmitRequestParams>["onSubmit"] =
    useCallback(
      ({ policy, numberOfTeamMembersThatMightWantToEnroll }) => {
        onGoToCompanyInformation({
          policy,
          numberOfTeamMembersThatMightWantToEnroll:
            policy === PolicyOptionEnum.Change
              ? numberOfTeamMembersThatMightWantToEnroll
              : undefined,
        });
      },
      [onGoToCompanyInformation]
    );
  return (
    <Formik<GoToSubmitRequestParams>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        policy: (initialValues.policy || "") as PolicyOptionEnum,
        numberOfTeamMembersThatMightWantToEnroll:
          initialValues.numberOfTeamMembersThatMightWantToEnroll ||
          ("" as unknown as number),
      }}
      validationSchema={validationSchema}
    >
      {({ values, isValid }) => {
        return (
          <Form>
            <Grid
              container
              direction={isDesktop ? "row" : "column"}
              spacing={isDesktop ? 3 : 2}
              alignItems="stretch"
            >
              {policyOptions.map((option) => (
                <Grid item key={option.value} flex={1}>
                  <Box
                    sx={{
                      border:
                        values.policy === option.value
                          ? `2px solid rgba(6, 105, 222, 1)`
                          : `1px solid rgba(0, 0, 0, 0.6)`,
                      padding: (theme) => theme.spacing(isMobile ? 1.5 : 2),
                      borderRadius: (theme) => theme.spacing(1),
                      height: "100%",
                      background:
                        values.policy === option.value
                          ? `rgba(241, 248, 255, 1)`
                          : "white",
                    }}
                  >
                    <Field component={RadioGroup} name="policy" required>
                      <FormControlLabel
                        value={option.value}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexFLow: "row nowrap",
                        }}
                        label={
                          <Typography
                            color="primary"
                            sx={{
                              fontWeight: "700",
                              fontSize: isMobile ? 18 : undefined,
                            }}
                            variant="h6"
                          >
                            {option.label}
                          </Typography>
                        }
                        control={<Radio size="medium" color="primary" />}
                      />
                    </Field>
                    <Box
                      sx={{
                        paddingLeft: (theme) =>
                          theme.spacing(isDesktop ? 4.25 : 4),
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: isMobile ? 14 : undefined,
                        }}
                      >
                        {option.description}
                      </Typography>
                      {option.value === PolicyOptionEnum.Change &&
                      values.policy === PolicyOptionEnum.Change ? (
                        <Box mt={2}>
                          <Field
                            component={TextField}
                            name="numberOfTeamMembersThatMightWantToEnroll"
                            size="small"
                            integer="true"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            variant="outlined"
                            required={values.policy === PolicyOptionEnum.Change}
                            label="Number of Team Members That Might Want to Enroll"
                            fullWidth
                            sx={{
                              label: {
                                whiteSpace: "normal",
                              },
                              input: (theme: Theme) => ({
                                height: theme.spacing(6),
                              }),
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box mt={3}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button type="submit" disabled={!isValid} variant="contained">
                    CONTINUE
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
