import { Grid } from "@mui/material";
import { BackButton, Loader, MinimalViewLayout } from "Components";
import { SignHB2015Letter } from "Components/SignHB2015Letter";
import { Opportunity } from "gql/graphql";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getOpportunityRequest } from "services";
import {
  companySelectors,
  quoteRequestActions,
  quoteRequestSelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import {
  getLocationRequest,
  getOpportunityActivePolicies,
  getOpportunityActivePolicySignedDocument,
  getSigningInfoRequest,
} from "./request";

interface HB2015LetterProps {
  onGoBack: () => void;
  onNext: () => void;
}

export const HB2015Letter = ({ onGoBack, onNext }: HB2015LetterProps) => {
  const company = useAppSelector(companySelectors.getCompany);
  const opportunityID = useAppSelector(quoteRequestSelectors.getOpportunityID);
  const carriers = useAppSelector(quoteRequestSelectors.getCarriers);
  const dispatch = useAppDispatch();
  const [loadingCount, setLoadingCount] = useState(0);
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);
  const [locationInfo, setLocationInfo] = useState<{
    ip: any;
    country: any;
    latitude: string;
    longitude: string;
  } | null>(null);

  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );

  const loading = useMemo(() => loadingCount > 0, [loadingCount]);

  const setLoading = useCallback((value: boolean) => {
    setLoadingCount((current) => current + (value === true ? 1 : -1));
  }, []);

  const fetchOpportunity = useCallback(async () => {
    try {
      if (!company?.id || !opportunityID) {
        return;
      }
      setLoading(true);
      const res = await getOpportunityRequest(opportunityID, company?.id);
      setOpportunity(res);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [setLoading, opportunityID, company?.id]);

  const fetchSigningInfo = useCallback(
    async (opportunityActivePolicyId: string) => {
      try {
        if (!company?.id || !locationInfo) {
          return null;
        }
        setLoading(true);
        const res = await getSigningInfoRequest(
          company?.id,
          opportunityActivePolicyId,
          locationInfo
        );
        return res;
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
      return null;
    },
    [setLoading, company?.id, locationInfo]
  );

  const fetchSigningStatus = useCallback(
    async (opportunityActivePolicyId: string) => {
      try {
        if (!company?.id || !opportunityID) {
          return false;
        }
        const res = await getOpportunityActivePolicies(
          opportunityID,
          company?.id
        );
        return !!res.opportunityActivePolicies?.find(
          (p) => p.id === opportunityActivePolicyId
        )?.hb2015LetterSigned;
      } catch (error) {
        //
      } finally {
        //
      }
      return false;
    },
    [opportunityID, company?.id]
  );

  const fetchLocationInfo = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getLocationRequest();
      setLocationInfo(res);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
    return null;
  }, [setLoading]);

  const fetchSigningDocumentInfo = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const res = await getOpportunityActivePolicySignedDocument(id);
        return res;
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
      return "";
    },
    [setLoading]
  );

  const fetchProductsOffers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchProductsOffers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading]);

  const fetchCarriers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchCarriers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading]);

  useEffect(() => {
    fetchLocationInfo();
  }, [fetchLocationInfo]);

  useEffect(() => {
    fetchOpportunity();
  }, [fetchOpportunity]);

  useEffect(() => {
    if (!productsOffers.length) {
      fetchProductsOffers();
    }
  }, [productsOffers.length, fetchProductsOffers]);

  useEffect(() => {
    if (!carriers?.length) {
      fetchCarriers();
    }
  }, [carriers?.length, fetchCarriers]);

  return (
    <MinimalViewLayout>
      <Loader open={loading} />
      <Grid
        container
        alignItems="center"
        columnSpacing={1}
        justifyContent="space-between"
      >
        <Grid item>
          <BackButton onClick={onGoBack} />
        </Grid>
      </Grid>
      <SignHB2015Letter
        opportunity={opportunity}
        onNext={onNext}
        fetchSigningInfo={fetchSigningInfo}
        fetchSigningDocumentInfo={fetchSigningDocumentInfo}
        fetchSigningStatus={fetchSigningStatus}
        productsOffers={productsOffers}
        carriers={carriers}
      />
    </MinimalViewLayout>
  );
};
