import { Box, Typography } from "@mui/material";
import React from "react";

export const LepCalculatorSpamText = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth={550}>
        <Typography variant="caption" align="center" component="p">
          We will not spam, rent, or sell your information. You can opt out of
          our emails at any time. <br />{" "}
          <u>
            <a
              href="https://bufferinsurance.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </u>
        </Typography>
      </Box>
    </Box>
  );
};
