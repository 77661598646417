import { Box, FormControl, FormHelperText, Grid } from "@mui/material";
import React from "react";
import { FormikNumberField } from "Components/FormikNumberField";
import { Field, useFormikContext } from "formik";
import { LepCalculatorPartDFormValues } from "interfaces";
import { LepCalculatorRadios } from "../Fields/LepCalculatorRadios";
import { LepCalculatorLabel } from "../Fields";
import { NumberSlider } from "./NumberSlider";

export const LepCalculatorPartDFormA = () => {
  const { errors, touched } = useFormikContext<LepCalculatorPartDFormValues>();

  return (
    <Box>
      <Box pb={4}>
        <FormControl>
          <Box pb={1}>
            <LepCalculatorLabel title="How many months after your 65th birthday did you (or will you) spend without creditable coverage?" />
          </Box>
          <Grid container spacing={1} wrap="wrap" alignItems="flex-start">
            <Grid item width={70}>
              <Field
                component={FormikNumberField}
                name="coverageNumberOfMonths"
                type="integer"
                size="small"
                fullWidth
                hideErrorMessage
                min={1}
                max={12}
                numberFormatProps={{
                  fixedDecimalScale: true,
                  decimalScale: 0,
                  max: 12,
                  min: 1,
                }}
              />
            </Grid>
            <Grid item flex={1} mt={1.5}>
              <NumberSlider />
            </Grid>
          </Grid>
          {errors.coverageNumberOfMonths && touched.coverageNumberOfMonths ? (
            <Box
              sx={{
                position: "relative",
                height: 0,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
              >
                <FormHelperText error>
                  {errors.coverageNumberOfMonths}
                </FormHelperText>
              </Box>
            </Box>
          ) : null}
        </FormControl>
      </Box>
      <Box pb={4}>
        <LepCalculatorRadios
          name="enrolledInMedicaid"
          options={[
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ]}
          label={{
            title: "Are you enrolled in Medicaid?",
          }}
          errorMessage={
            errors.enrolledInMedicaid && touched.enrolledInMedicaid
              ? errors.enrolledInMedicaid
              : undefined
          }
        />
      </Box>
      <Box>
        <LepCalculatorRadios
          name="receiveSupplementalSecurityIncome"
          options={[
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ]}
          label={{
            title:
              "Do you receive Supplemental Security Income (SSI) benefits from Social Security?",
          }}
          errorMessage={
            errors.receiveSupplementalSecurityIncome &&
            touched.receiveSupplementalSecurityIncome
              ? errors.receiveSupplementalSecurityIncome
              : undefined
          }
        />
      </Box>
    </Box>
  );
};
