import {
  Alert,
  AlertTitle,
  Collapse,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "Components";
import {
  GetCarriersAndGAsRecord,
  GetSigningUrlForHb2015LetterResponse,
  Opportunity,
  OpportunityActivePolicy,
  Product,
} from "gql/graphql";
import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import { useResponsive } from "hooks";
import { SigningTab } from "./SigningTab";

interface SignHB2015LetterProps {
  onNext: () => void;
  opportunity: null | Opportunity;
  fetchSigningInfo: (
    opportunityActivePolicyId: string,
    silent: boolean
  ) => Promise<GetSigningUrlForHb2015LetterResponse | null>;
  fetchSigningDocumentInfo: (id: string) => Promise<string>;
  fetchSigningStatus: (id: string) => Promise<boolean>;
  productsOffers: Product[];
  carriers: GetCarriersAndGAsRecord[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`active-policy-tabpanel-${index}-info`}
      aria-labelledby={`active-policy-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const SignHB2015Letter = ({
  fetchSigningInfo,
  onNext,
  opportunity,
  fetchSigningDocumentInfo,
  fetchSigningStatus,
  productsOffers,
  carriers,
}: SignHB2015LetterProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [signedDocuments, setSignedDocuments] = useState<string[]>([]);
  const { isDesktop } = useResponsive();
  const onToggleShowDetails = useCallback(() => {
    setShowDetails((current) => !current);
  }, []);

  const opportunityActivePolicies = useMemo(
    () => opportunity?.opportunityActivePolicies || [],
    [opportunity?.opportunityActivePolicies]
  );

  const getPolicyTitle = useCallback(
    (opportunityActivePolicy: OpportunityActivePolicy) => {
      const productFormattedName =
        productsOffers.find(
          (p) =>
            p.name ===
            (opportunityActivePolicy.activePolicy.insuranceType as string)
        )?.formattedName || "";

      const carrierFormattedName =
        carriers.find(
          (p) => p.id === opportunityActivePolicy.activePolicy.carrier
        )?.name || "";
      return `${opportunityActivePolicy.activePolicy.number} -
      ${productFormattedName}${
        carrierFormattedName ? ` (${carrierFormattedName})` : ""
      }`;
    },
    [productsOffers, carriers]
  );

  const quoteName = useMemo(() => opportunity?.name || "", [opportunity?.name]);

  const allSigned = useMemo(
    () =>
      opportunityActivePolicies.every((opportunityActivePolicy) =>
        signedDocuments.includes(opportunityActivePolicy.id!)
      ),
    [opportunityActivePolicies, signedDocuments]
  );

  const hasOpportunityActivePolicies = useMemo(
    () => !!opportunityActivePolicies.length,
    [opportunityActivePolicies?.length]
  );

  const currentActivePolicy = useMemo(() => {
    if (!opportunity?.opportunityActivePolicies) {
      return "";
    }
    return opportunity?.opportunityActivePolicies[tabValue]?.id || "";
  }, [opportunity?.opportunityActivePolicies, tabValue]);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      const getNewActivePolicy = () => {
        if (!opportunity?.opportunityActivePolicies) {
          return "";
        }
        return opportunity?.opportunityActivePolicies[newValue]?.id || "";
      };
      setTabValue((current) =>
        !signedDocuments.includes(getNewActivePolicy()) ? current : newValue
      );
    },
    [opportunity?.opportunityActivePolicies, signedDocuments]
  );

  const onPreviousTab = useCallback(() => {
    setTabValue((current) => current - 1);
  }, []);

  const onNextTab = useCallback(() => {
    if (tabValue === opportunityActivePolicies.length - 1) {
      onNext();
    } else {
      setTabValue((current) => current + 1);
    }
  }, [opportunityActivePolicies.length, tabValue, onNext]);

  const updateSignedDocument = useCallback((id: string) => {
    setSignedDocuments((current) => current.concat(id));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box maxWidth={700}>
          <Typography
            variant="h5"
            fontWeight={500}
            fontSize={!isDesktop ? 20 : undefined}
            textAlign="center"
          >
            Sign HB2015 letter{quoteName ? ` for ${quoteName}` : ""}
          </Typography>
          <Box mt={2}>
            <Alert severity="info">
              <AlertTitle>
                Signing the HB2015 letter grants access to your claims history
                on your existing policy for the carriers we will ask for policy
                offers. This is necessary for companies with more than 50 people
                to assess risk.
              </AlertTitle>
              <Collapse in={showDetails}>
                For clients that have a group size (company size) &gt; 50, the
                Insurance providers assess the risks for the groups
                individually, and provide a quote based on this assessment. In
                order to make this assessment, the insurance provider needs the
                claims history for the group.
              </Collapse>
              <Grid
                container
                alignItems="center"
                columnSpacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="text"
                    onClick={onToggleShowDetails}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    {showDetails ? "Hide" : "View"} Details
                  </Button>
                </Grid>
              </Grid>
            </Alert>
            {hasOpportunityActivePolicies &&
            opportunityActivePolicies.length > 1 ? (
              <Box mt={3} sx={{ background: "rgba(244, 244, 244, 1)" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="active policy tabs"
                >
                  {opportunityActivePolicies?.map(
                    (opportunityActivePolicy, index) => (
                      <Tab
                        key={opportunityActivePolicy.id}
                        label={
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            wrap="nowrap"
                            spacing={2}
                          >
                            <Grid item>
                              <CheckCircleOutline
                                color={
                                  signedDocuments.includes(
                                    opportunityActivePolicy.id!
                                  )
                                    ? "success"
                                    : "disabled"
                                }
                              />
                            </Grid>
                            <Grid item>
                              <Typography>
                                Policy {getPolicyTitle(opportunityActivePolicy)}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        id={`active-policy-tab-${index}`}
                        aria-controls={`active-policy-tabpanel-${index}`}
                      />
                    )
                  )}
                </Tabs>
              </Box>
            ) : null}
            {hasOpportunityActivePolicies ? (
              <Box mt={2} maxWidth={700}>
                {opportunityActivePolicies.map(
                  (opportunityActivePolicy, index) => (
                    <CustomTabPanel
                      key={opportunityActivePolicy.id}
                      value={tabValue}
                      index={index}
                    >
                      <Alert severity="info">
                        Please sign an HB2015 letter for your active policy:{" "}
                        <strong>
                          {getPolicyTitle(opportunityActivePolicy)}
                        </strong>
                        .
                      </Alert>
                    </CustomTabPanel>
                  )
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        {opportunity?.opportunityActivePolicies?.map(
          (opportunityActivePolicy, index) => (
            <SigningTab
              key={opportunityActivePolicy.id}
              value={tabValue}
              index={index}
              opportunityActivePolicy={opportunityActivePolicy}
              fetchSigningInfo={fetchSigningInfo}
              updateSignedDocument={updateSignedDocument}
              fetchSigningDocumentInfo={fetchSigningDocumentInfo}
              fetchSigningStatus={fetchSigningStatus}
            />
          )
        )}
      </Box>
      {hasOpportunityActivePolicies ? (
        <Box mt={3}>
          <Grid
            container
            alignItems="center"
            columnSpacing={1}
            justifyContent={tabValue !== 0 ? "space-between" : "flex-end"}
          >
            {tabValue !== 0 ? (
              <Grid item>
                <Button variant="contained" onClick={onPreviousTab}>
                  PREVIOUS
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                variant="contained"
                onClick={onNextTab}
                disabled={
                  (tabValue === opportunityActivePolicies.length - 1 &&
                    !allSigned) ||
                  !signedDocuments.includes(currentActivePolicy)
                }
              >
                NEXT
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};
