import React from "react";
import { Box, Grid, Theme } from "@mui/material";
import { CheckboxWithLabel } from "formik-mui";
import { Field } from "formik";
import { MappedEmployeeDependent } from "./types";

interface Props {
  dependent: MappedEmployeeDependent;
  label: {
    label: string;
    key: string;
  };
  name: string;
}

export const DependentTableCell = ({ dependent, label, name }: Props) => {
  if (label.key === "actions") {
    return (
      <Grid
        container
        flexWrap="nowrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item>
          <Box sx={{ width: (theme) => theme.spacing(2) }}>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              sx={{
                color: (theme: Theme) => theme.palette.primary,
              }}
              color="primary"
              name={`${name}.isSelected`}
              disabled
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  return <>{(dependent as any)[label.key]}</>;
};
