import { Typography } from "@mui/material";
import React from "react";

interface Props {
  title: string;
}

export const LepCalculatorTitle = ({ title }: Props) => {
  return (
    <Typography
      variant="h2"
      sx={{
        fontSize: 30,
        color: "rgba(0, 133, 255, 1)",
        fontWeight: "bold",
      }}
      align="center"
    >
      {title}
    </Typography>
  );
};
