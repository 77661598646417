import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  DialogContent,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "Components/elements";
import {
  StateEmployee,
  companyActions,
  companySelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import { notifications } from "services";
import { MedicarePrimaryEnum } from "gql/graphql";
import { EmployeeCensusHeader } from "./EmployeeCensusHeader";
import { EmployeesTablePage } from "./EmployeesTablePage";
import { DeleteEmployeeDialog } from "./DeleteEmployeeDialog";
import { EditEmployeeDialog } from "./EditEmployeeDialog";
import { AddEmployeeDialog } from "./AddEmployeeDialog";
import { FormValues } from "./types";
import { getDateValue } from "./helpers";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const EmployeeCensus = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySelectors.getCompany);
  const employees = useAppSelector(companySelectors.getEmployees);
  const employeesPage = useAppSelector(companySelectors.getEmployeesPage);
  const employeesCount = useAppSelector(companySelectors.getEmployeesCount);
  const rowsPerPage = useAppSelector(companySelectors.getEmployeesRowsPerPage);

  const [page, setPage] = useState("employees-table");
  const [loadingCount, setLoadingCount] = useState(0);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [employeeToRemove, setEmployeeToRemove] =
    useState<StateEmployee | null>(null);
  const [employeeToEdit, setEmployeeToEdit] = useState<StateEmployee | null>(
    null
  );

  const loading = useMemo(() => loadingCount > 0, [loadingCount]);

  const setLoading = useCallback((value: boolean) => {
    setLoadingCount((current) => current + (value === true ? 1 : -1));
  }, []);

  const onLoadFromCensusSheet = useCallback(() => {
    setPage("census-sheet");
  }, []);

  const onAddEmployee = useCallback(() => {
    setOpenAddEmployee(true);
  }, []);

  const fetchEmployees = useCallback(async () => {
    try {
      if (!company?.id) {
        return;
      }
      setLoading(true);
      await dispatch(companyActions.fetchEmployees({ companyId: company.id }));
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading, company?.id]);

  const onEditEmployee = useCallback((employee: StateEmployee) => {
    setEmployeeToEdit(employee);
  }, []);

  const onDeleteEmployee = useCallback((employee: StateEmployee) => {
    setEmployeeToRemove(employee);
  }, []);

  const onCloseRemoveEmployeeDialog = useCallback(() => {
    setEmployeeToRemove(null);
  }, []);

  const onCloseEditEmployeeDialog = useCallback(() => {
    setEmployeeToEdit(null);
  }, []);

  const onCloseAddEmployeeDialog = useCallback(() => {
    setOpenAddEmployee(false);
  }, []);

  const onRemoveEmployee = useCallback(async () => {
    if (!employeeToRemove?.id) {
      return;
    }
    try {
      setLoading(true);
      const res = await dispatch(
        companyActions.deleteEmployee({ employeeID: employeeToRemove.id })
      );
      if (companyActions.deleteEmployee.fulfilled.match(res)) {
        notifications.notifySuccess("Employee removed successfully");
        onCloseRemoveEmployeeDialog();
      } else {
        notifications.notifyError("Failed to delete employee.");
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [setLoading, employeeToRemove?.id, onCloseRemoveEmployeeDialog, dispatch]);

  const onSaveEmployee = useCallback(
    async (values: FormValues) => {
      if (!employeeToEdit?.id) {
        return;
      }
      try {
        setLoading(true);
        const res = await dispatch(
          companyActions.updateEmployee({
            values: {
              id: employeeToEdit.id,
              email: values.email || null,
              employeeType: values.employeeType,
              // payrollCycle: values.payrollCycle || null,
              firstName: values.firstName,
              gender: values.gender,
              jobType: values.jobTitle || null,
              lastName: values.lastName,
              medicarePrimary: values.medicarePrimary || MedicarePrimaryEnum.No,
              salary: values.salary ? Number(values.salary) : null,
              zipCode: values.zipCode,
              coverageType: values.coverage,
              dateOfBirth: getDateValue(values.dob),
              dateOfHire: values.dateOfHire
                ? getDateValue(values.dateOfHire)
                : null,
              dependents: values.dependents.map((dependent) => ({
                dateOfBirth: getDateValue(dependent.dob),
                firstName: dependent.firstName,
                gender: dependent.gender,
                lastName: dependent.lastName,
                medicarePrimary:
                  dependent.medicarePrimary || MedicarePrimaryEnum.No,
                relationship: dependent.relationship,
                zipCode: dependent.zipCode,
                ...(dependent.isCustomId ? {} : { id: dependent.id }),
              })),
            },
          })
        );

        if (companyActions.updateEmployee.fulfilled.match(res)) {
          notifications.notifySuccess("Employee updated successfully");
          onCloseEditEmployeeDialog();
        } else {
          notifications.notifyError("Failed to update employee.");
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [setLoading, employeeToEdit?.id, dispatch, onCloseEditEmployeeDialog]
  );

  const onSaveAddEmployee = useCallback(
    async (values: FormValues) => {
      try {
        setLoading(true);
        const res = await dispatch(
          companyActions.addEmployee({
            values: {
              email: values.email || null,
              employeeType: values.employeeType,
              // payrollCycle: values.payrollCycle || null,
              firstName: values.firstName,
              gender: values.gender,
              jobType: values.jobTitle || null,
              lastName: values.lastName,
              medicarePrimary: values.medicarePrimary || MedicarePrimaryEnum.No,
              salary: values.salary ? Number(values.salary) : null,
              zipCode: values.zipCode,
              coverageType: values.coverage,
              dateOfBirth: getDateValue(values.dob),
              dateOfHire: values.dateOfHire
                ? getDateValue(values.dateOfHire)
                : null,
              dependents: values.dependents.map((dependent) => ({
                dateOfBirth: getDateValue(dependent.dob),
                firstName: dependent.firstName,
                gender: dependent.gender,
                lastName: dependent.lastName,
                medicarePrimary:
                  dependent.medicarePrimary || MedicarePrimaryEnum.No,
                relationship: dependent.relationship,
                zipCode: dependent.zipCode,
              })),
            },
          })
        );
        if (companyActions.addEmployee.fulfilled.match(res)) {
          notifications.notifySuccess("Employee added successfully");
          onCloseAddEmployeeDialog();
        } else {
          notifications.notifyError("Failed to add employee.");
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, onCloseAddEmployeeDialog]
  );

  const onEmployeesPageChange = useCallback(
    (newEmployeesPage: number) => {
      dispatch(companyActions.setEmployeesPage(newEmployeesPage));
      fetchEmployees();
    },
    [dispatch, fetchEmployees]
  );

  const onEmployeesRowsPerPageChange = useCallback(
    (newEmployeesRowsPerPage: number) => {
      dispatch(companyActions.setEmployeesRowsPerPage(newEmployeesRowsPerPage));
      fetchEmployees();
    },
    [dispatch, fetchEmployees]
  );

  useEffect(() => {
    if (open) {
      fetchEmployees();
    }
  }, [fetchEmployees, open]);

  return (
    <>
      <Dialog fullWidth open={open} maxWidth="lg">
        <DialogTitle
          sx={{
            background: "rgba(246, 246, 246, 1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            Update Employee Census
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              paddingTop: (theme) => theme.spacing(3),
            }}
          >
            <EmployeeCensusHeader
              onLoadFromCensusSheet={onLoadFromCensusSheet}
              onAddEmployee={onAddEmployee}
            />
            {page === "employees-table" ? (
              <EmployeesTablePage
                employees={employees}
                onDeleteEmployee={onDeleteEmployee}
                onEditEmployee={onEditEmployee}
                employeesCount={employeesCount}
                onEmployeesPageChange={onEmployeesPageChange}
                rowsPerPage={rowsPerPage}
                page={employeesPage}
                onEmployeesRowsPerPageChange={onEmployeesRowsPerPageChange}
              />
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
      <DeleteEmployeeDialog
        open={!!employeeToRemove}
        onClose={onCloseRemoveEmployeeDialog}
        onRemove={onRemoveEmployee}
        employee={employeeToRemove}
      />
      <EditEmployeeDialog
        open={!!employeeToEdit}
        onClose={onCloseEditEmployeeDialog}
        onSave={onSaveEmployee}
        employee={employeeToEdit}
        onCreateNew={onAddEmployee}
      />
      <AddEmployeeDialog
        open={openAddEmployee}
        onClose={onCloseAddEmployeeDialog}
        onSave={onSaveAddEmployee}
      />
      <Loader open={loading} />
    </>
  );
};
