import { Box } from "@mui/material";
import { useFormikContext } from "formik";
import { LepCalculatorPartDFormValues } from "interfaces";
import React from "react";

export const NumberSlider = () => {
  const { values, setFieldValue } =
    useFormikContext<LepCalculatorPartDFormValues>();

  return (
    <Box
      sx={{
        alignItems: "center",
        width: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        component="input"
        type="range"
        min="0"
        max="120"
        value={values.coverageNumberOfMonths || 0}
        style={{
          backgroundSize: `${parseFloat(
            String(((values.coverageNumberOfMonths || 0) / 120) * 100)
          )}%`,
        }}
        sx={{
          WebkitAppearance: "none",
          width: "100%",
          height: "16px",
          background: "rgba(218, 218, 218, 1)",
          borderRadius: "8px",
          backgroundImage:
            "linear-gradient(rgba(145, 189, 229, 1), rgba(145, 189, 229, 1))",
          backgroundRepeat: "no-repeat",
          "::-webkit-slider-thumb": {
            WebkitAppearance: "none",
            height: "32px",
            width: "32px",
            borderRadius: "50%",
            background: "rgba(0, 133, 255, 1)",
            boxShadow: "0 0 2px 0 rgba(0, 133, 255, 1)",
            transition: "background 0.3s ease-in-out",
          },
          "::-webkit-slider-runnable-track": {
            WebkitAppearance: "none",
            boxShadow: "none",
            border: "none",
            background: "transparent",
          },
        }}
        className="slider"
        onChange={(event) => {
          setFieldValue("coverageNumberOfMonths", event.target.value);
        }}
      />
    </Box>
  );
};
