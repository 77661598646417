import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { GetQuoteProps } from "./types";
import { GetQuoteActivityStep } from "./GetQuoteActivityStep";
import { GetQuoteFormStep } from "./GetQuoteFormStep";

export const GetQuote = ({
  onGoToCompanyInformation,
  onHandleShowNav,
  step,
  onGetQuote,
  initialValues,
}: GetQuoteProps) => {
  useEffect(() => {
    onHandleShowNav(step === "get-quote");
  }, [step, onHandleShowNav]);

  return (
    <Box>
      {step === "activity" ? (
        <GetQuoteActivityStep onGetQuote={onGetQuote} />
      ) : null}
      {step === "get-quote" ? (
        <GetQuoteFormStep
          initialValues={initialValues}
          onGoToCompanyInformation={onGoToCompanyInformation}
        />
      ) : null}
    </Box>
  );
};
