import {
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  PayrollCycleEnum,
} from "gql/graphql";

export const statesOfIncorporation = [
  {
    value: "Alabama",
    title: "Alabama",
  },
  {
    value: "Alaska",
    title: "Alaska",
  },
  {
    value: "American Samoa",
    title: "American Samoa",
  },
  {
    value: "Arizona",
    title: "Arizona",
  },
  {
    value: "Arkansas",
    title: "Arkansas",
  },
  {
    value: "California",
    title: "California",
  },
  {
    value: "Colorado",
    title: "Colorado",
  },
  {
    value: "Connecticut",
    title: "Connecticut",
  },
  {
    value: "Delaware",
    title: "Delaware",
  },
  {
    value: "District of Columbia",
    title: "District of Columbia",
  },
  {
    value: "Florida",
    title: "Florida",
  },
  {
    value: "Georgia",
    title: "Georgia",
  },
  {
    value: "Guam",
    title: "Guam",
  },
  {
    value: "Hawaii",
    title: "Hawaii",
  },
  {
    value: "Idaho",
    title: "Idaho",
  },
  {
    value: "Illinois",
    title: "Illinois",
  },
  {
    value: "Indiana",
    title: "Indiana",
  },
  {
    value: "Iowa",
    title: "Iowa",
  },
  {
    value: "Kansas",
    title: "Kansas",
  },
  {
    value: "Kentucky",
    title: "Kentucky",
  },
  {
    value: "Louisiana",
    title: "Louisiana",
  },
  {
    value: "Maine",
    title: "Maine",
  },
  {
    value: "Maryland",
    title: "Maryland",
  },
  {
    value: "Massachusetts",
    title: "Massachusetts",
  },
  {
    value: "Michigan",
    title: "Michigan",
  },
  {
    value: "Minnesota",
    title: "Minnesota",
  },
  {
    value: "Mississippi",
    title: "Mississippi",
  },
  {
    value: "Missouri",
    title: "Missouri",
  },
  {
    value: "Montana",
    title: "Montana",
  },
  {
    value: "Nebraska",
    title: "Nebraska",
  },
  {
    value: "Nevada",
    title: "Nevada",
  },
  {
    value: "New Hampshire",
    title: "New Hampshire",
  },
  {
    value: "New Jersey",
    title: "New Jersey",
  },
  {
    value: "New Mexico",
    title: "New Mexico",
  },
  {
    value: "New York",
    title: "New York",
  },
  {
    value: "North Carolina",
    title: "North Carolina",
  },
  {
    value: "North Dakota",
    title: "North Dakota",
  },
  {
    value: "Northern Mariana Islands",
    title: "Northern Mariana Islands",
  },
  {
    value: "Ohio",
    title: "Ohio",
  },
  {
    value: "Oklahoma",
    title: "Oklahoma",
  },
  {
    value: "Oregon",
    title: "Oregon",
  },
  {
    value: "Pennsylvania",
    title: "Pennsylvania",
  },
  {
    value: "Puerto Rico",
    title: "Puerto Rico",
  },
  {
    value: "Rhode Island",
    title: "Rhode Island",
  },
  {
    value: "South Carolina",
    title: "South Carolina",
  },
  {
    value: "South Dakota",
    title: "South Dakota",
  },
  {
    value: "Tennessee",
    title: "Tennessee",
  },
  {
    value: "Texas",
    title: "Texas",
  },
  {
    value: "U.S. Virgin Islands",
    title: "U.S. Virgin Islands",
  },
  {
    value: "Utah",
    title: "Utah",
  },
  {
    value: "Vermont",
    title: "Vermont",
  },
  {
    value: "Virginia",
    title: "Virginia",
  },
  {
    value: "Washington",
    title: "Washington",
  },
  {
    value: "West Virginia",
    title: "West Virginia",
  },
  {
    value: "Wisconsin",
    title: "Wisconsin",
  },
  {
    value: "Wyoming",
    title: "Wyoming",
  },
];

export const sicCodes = [
  { value: "111", title: "111 - Wheat", description: "Wheat" },
  { value: "112", title: "112 - Rice", description: "Rice" },
  { value: "115", title: "115 - Corn", description: "Corn" },
  { value: "116", title: "116 - Soybeans", description: "Soybeans" },
  {
    value: "119",
    title: "119 - Cash grains, n.e.c.",
    description: "Cash grains, n.e.c.",
  },
  { value: "131", title: "131 - Cotton", description: "Cotton" },
  { value: "132", title: "132 - Tobacco", description: "Tobacco" },
  {
    value: "133",
    title: "133 - Sugarcane and sugar beet",
    description: "Sugarcane and sugar beet",
  },
  {
    value: "134",
    title: "134 - Irish potatoes",
    description: "Irish potatoes",
  },
  {
    value: "139",
    title: "139 - Field crops, except cash grains, n.e.c.",
    description: "Field crops, except cash grains, n.e.c.",
  },
  {
    value: "161",
    title: "161 - Vegetables and melons",
    description: "Vegetables and melons",
  },
  { value: "171", title: "171 - Berry crops", description: "Berry crops" },
  { value: "172", title: "172 - Grapes", description: "Grapes" },
  { value: "173", title: "173 - Tree nuts", description: "Tree nuts" },
  { value: "174", title: "174 - Citrus fruits", description: "Citrus fruits" },
  {
    value: "175",
    title: "175 - Deciduous tree fruits",
    description: "Deciduous tree fruits",
  },
  {
    value: "179",
    title: "179 - Fruits and tree nuts, n.e.c.",
    description: "Fruits and tree nuts, n.e.c.",
  },
  {
    value: "181",
    title: "181 - Ornamental nursery products",
    description: "Ornamental nursery products",
  },
  {
    value: "182",
    title: "182 - Food crops grown under cover",
    description: "Food crops grown under cover",
  },
  {
    value: "191",
    title: "191 - General farms, primarily crop",
    description: "General farms, primarily crop",
  },
  {
    value: "211",
    title: "211 - Beef cattle feedlots",
    description: "Beef cattle feedlots",
  },
  {
    value: "212",
    title: "212 - Beef cattle, except feedlots",
    description: "Beef cattle, except feedlots",
  },
  { value: "213", title: "213 - Hogs", description: "Hogs" },
  {
    value: "214",
    title: "214 - Sheep and goats",
    description: "Sheep and goats",
  },
  {
    value: "219",
    title: "219 - General livestock, n.e.c.",
    description: "General livestock, n.e.c.",
  },
  { value: "241", title: "241 - Dairy farms", description: "Dairy farms" },
  {
    value: "251",
    title: "251 - Broiler, fryer, and roaster chickens",
    description: "Broiler, fryer, and roaster chickens",
  },
  { value: "252", title: "252 - Chicken eggs", description: "Chicken eggs" },
  {
    value: "253",
    title: "253 - Turkeys and turkey eggs",
    description: "Turkeys and turkey eggs",
  },
  {
    value: "254",
    title: "254 - Poultry hatcheries",
    description: "Poultry hatcheries",
  },
  {
    value: "259",
    title: "259 - Poultry and eggs, n.e.c.",
    description: "Poultry and eggs, n.e.c.",
  },
  {
    value: "271",
    title: "271 - Fur-bearing animals and rabbits",
    description: "Fur-bearing animals and rabbits",
  },
  {
    value: "272",
    title: "272 - Horses and other equines",
    description: "Horses and other equines",
  },
  {
    value: "273",
    title: "273 - Animal aquaculture",
    description: "Animal aquaculture",
  },
  {
    value: "279",
    title: "279 - Animal specialties, n.e.c.",
    description: "Animal specialties, n.e.c.",
  },
  {
    value: "291",
    title: "291 - General farms, primarily animal",
    description: "General farms, primarily animal",
  },
  {
    value: "711",
    title: "711 - Soil preparation services",
    description: "Soil preparation services",
  },
  {
    value: "721",
    title: "721 - Crop planting and protection",
    description: "Crop planting and protection",
  },
  {
    value: "722",
    title: "722 - Crop harvesting",
    description: "Crop harvesting",
  },
  {
    value: "723",
    title: "723 - Crop preparation services for market",
    description: "Crop preparation services for market",
  },
  {
    value: "724",
    title: "724 - Cotton ginning",
    description: "Cotton ginning",
  },
  {
    value: "741",
    title: "741 - Veterinary services, farm livestock",
    description: "Veterinary services, farm livestock",
  },
  {
    value: "742",
    title: "742 - Veterinary services, specialties",
    description: "Veterinary services, specialties",
  },
  {
    value: "751",
    title: "751 - Livestock services, exc. specialties",
    description: "Livestock services, exc. specialties",
  },
  {
    value: "752",
    title: "752 - Animal specialty services",
    description: "Animal specialty services",
  },
  {
    value: "761",
    title: "761 - Farm labor contractors",
    description: "Farm labor contractors",
  },
  {
    value: "762",
    title: "762 - Farm management services",
    description: "Farm management services",
  },
  {
    value: "781",
    title: "781 - Landscape counseling and planning",
    description: "Landscape counseling and planning",
  },
  {
    value: "782",
    title: "782 - Lawn and garden services",
    description: "Lawn and garden services",
  },
  {
    value: "783",
    title: "783 - Ornamental shrub and tree services",
    description: "Ornamental shrub and tree services",
  },
  { value: "811", title: "811 - Timber tracts", description: "Timber tracts" },
  {
    value: "831",
    title: "831 - Forest Products",
    description: "Forest Products",
  },
  {
    value: "851",
    title: "851 - Forestry services",
    description: "Forestry services",
  },
  { value: "912", title: "912 - Finfish", description: "Finfish" },
  { value: "913", title: "913 - Shellfish", description: "Shellfish" },
  {
    value: "919",
    title: "919 - Miscellaneous marine products",
    description: "Miscellaneous marine products",
  },
  {
    value: "921",
    title: "921 - Fish hatcheries and preserves",
    description: "Fish hatcheries and preserves",
  },
  {
    value: "971",
    title: "971 - Hunting, trapping, game propagation",
    description: "Hunting, trapping, game propagation",
  },
  { value: "1011", title: "1011 - Iron ores", description: "Iron ores" },
  { value: "1021", title: "1021 - Copper ores", description: "Copper ores" },
  {
    value: "1031",
    title: "1031 - Lead and zinc ores",
    description: "Lead and zinc ores",
  },
  { value: "1041", title: "1041 - Gold ores", description: "Gold ores" },
  { value: "1044", title: "1044 - Silver ores", description: "Silver ores" },
  {
    value: "1061",
    title: "1061 - Ferroalloy ores, except vanadium",
    description: "Ferroalloy ores, except vanadium",
  },
  {
    value: "1081",
    title: "1081 - Metal mining services",
    description: "Metal mining services",
  },
  {
    value: "1094",
    title: "1094 - Uranium-radium-vanadium ores",
    description: "Uranium-radium-vanadium ores",
  },
  {
    value: "1099",
    title: "1099 - Metal ores, n.e.c.",
    description: "Metal ores, n.e.c.",
  },
  {
    value: "1221",
    title: "1221 - Bituminous coal and lignite surface",
    description: "Bituminous coal and lignite surface",
  },
  {
    value: "1222",
    title: "1222 - Bituminous coal underground",
    description: "Bituminous coal underground",
  },
  {
    value: "1231",
    title: "1231 - Anthracite mining",
    description: "Anthracite mining",
  },
  {
    value: "1241",
    title: "1241 - Coal mining services",
    description: "Coal mining services",
  },
  {
    value: "1311",
    title: "1311 - Crude petroleum and natural gas",
    description: "Crude petroleum and natural gas",
  },
  {
    value: "1321",
    title: "1321 - Natural gas liquids",
    description: "Natural gas liquids",
  },
  {
    value: "1381",
    title: "1381 - Drilling oil and gas wells",
    description: "Drilling oil and gas wells",
  },
  {
    value: "1382",
    title: "1382 - Oil and gas exploration services",
    description: "Oil and gas exploration services",
  },
  {
    value: "1389",
    title: "1389 - Oil and gas field services, n.e.c.",
    description: "Oil and gas field services, n.e.c.",
  },
  {
    value: "1411",
    title: "1411 - Dimension stone",
    description: "Dimension stone",
  },
  {
    value: "1422",
    title: "1422 - Crushed and broken limestone",
    description: "Crushed and broken limestone",
  },
  {
    value: "1423",
    title: "1423 - Crushed and broken granite",
    description: "Crushed and broken granite",
  },
  {
    value: "1429",
    title: "1429 - Crushed and broken stone, n.e.c.",
    description: "Crushed and broken stone, n.e.c.",
  },
  {
    value: "1442",
    title: "1442 - Construction sand and gravel",
    description: "Construction sand and gravel",
  },
  {
    value: "1446",
    title: "1446 - Industrial sand",
    description: "Industrial sand",
  },
  {
    value: "1455",
    title: "1455 - Kaolin and ball clay",
    description: "Kaolin and ball clay",
  },
  {
    value: "1459",
    title: "1459 - Clay and related minerals, n.e.c.",
    description: "Clay and related minerals, n.e.c.",
  },
  {
    value: "1474",
    title: "1474 - Potash, soda, and borate minerals",
    description: "Potash, soda, and borate minerals",
  },
  {
    value: "1475",
    title: "1475 - Phosphate rock",
    description: "Phosphate rock",
  },
  {
    value: "1479",
    title: "1479 - Chemical and fertilizer mining, n.e.c.",
    description: "Chemical and fertilizer mining, n.e.c.",
  },
  {
    value: "1481",
    title: "1481 - Nonmetallic minerals services",
    description: "Nonmetallic minerals services",
  },
  {
    value: "1499",
    title: "1499 - Nonmetallic minerals, n.e.c.",
    description: "Nonmetallic minerals, n.e.c.",
  },
  {
    value: "1521",
    title: "1521 - Single-family housing construction",
    description: "Single-family housing construction",
  },
  {
    value: "1522",
    title: "1522 - Residential construction",
    description: "Residential construction",
  },
  {
    value: "1531",
    title: "1531 - Operative builders",
    description: "Operative builders",
  },
  {
    value: "1541",
    title: "1541 - Industrial buildings and warehouses",
    description: "Industrial buildings and warehouses",
  },
  {
    value: "1542",
    title: "1542 - Nonresidential construction, n.e.c.",
    description: "Nonresidential construction, n.e.c.",
  },
  {
    value: "1611",
    title: "1611 - Highway and street construction",
    description: "Highway and street construction",
  },
  {
    value: "1622",
    title: "1622 - Bridge, tunnel, & elevated highway",
    description: "Bridge, tunnel, & elevated highway",
  },
  {
    value: "1623",
    title: "1623 - Water, sewer, and utility lines",
    description: "Water, sewer, and utility lines",
  },
  {
    value: "1629",
    title: "1629 - Heavy construction, n.e.c.",
    description: "Heavy construction, n.e.c.",
  },
  {
    value: "1711",
    title: "1711 - Plumbing, heating, air conditioning",
    description: "Plumbing, heating, air conditioning",
  },
  {
    value: "1721",
    title: "1721 - Painting, paper hanging, decorating",
    description: "Painting, paper hanging, decorating",
  },
  {
    value: "1731",
    title: "1731 - Electrical work",
    description: "Electrical work",
  },
  {
    value: "1741",
    title: "1741 - Masonry and other stonework",
    description: "Masonry and other stonework",
  },
  {
    value: "1742",
    title: "1742 - Plastering, dry wall and insulation",
    description: "Plastering, dry wall and insulation",
  },
  {
    value: "1743",
    title: "1743 - Terrazzo, tile, marble, mosaic work",
    description: "Terrazzo, tile, marble, mosaic work",
  },
  { value: "1751", title: "1751 - Carpentering", description: "Carpentering" },
  {
    value: "1752",
    title: "1752 - Floor laying and floor work, n.e.c.",
    description: "Floor laying and floor work, n.e.c.",
  },
  {
    value: "1761",
    title: "1761 - Roofing and sheet metal work",
    description: "Roofing and sheet metal work",
  },
  {
    value: "1771",
    title: "1771 - Concrete work",
    description: "Concrete work",
  },
  {
    value: "1781",
    title: "1781 - Water well drilling",
    description: "Water well drilling",
  },
  {
    value: "1791",
    title: "1791 - Structural steel erection",
    description: "Structural steel erection",
  },
  {
    value: "1793",
    title: "1793 - Glass and glazing work",
    description: "Glass and glazing work",
  },
  {
    value: "1794",
    title: "1794 - Excavating and foundation work",
    description: "Excavating and foundation work",
  },
  {
    value: "1795",
    title: "1795 - Wrecking and demolition work",
    description: "Wrecking and demolition work",
  },
  {
    value: "1796",
    title: "1796 - Installing building equipment, n.e.c.",
    description: "Installing building equipment, n.e.c.",
  },
  {
    value: "1799",
    title: "1799 - Special trade contractors, n.e.c.",
    description: "Special trade contractors, n.e.c.",
  },
  {
    value: "2011",
    title: "2011 - Meat packing plants",
    description: "Meat packing plants",
  },
  {
    value: "2013",
    title: "2013 - Sausages and other prepared meats",
    description: "Sausages and other prepared meats",
  },
  {
    value: "2015",
    title: "2015 - Poultry slaughtering and processing",
    description: "Poultry slaughtering and processing",
  },
  {
    value: "2021",
    title: "2021 - Creamery butter",
    description: "Creamery butter",
  },
  {
    value: "2022",
    title: "2022 - Cheese, natural and processed",
    description: "Cheese, natural and processed",
  },
  {
    value: "2023",
    title: "2023 - Condensed and evaporated milk",
    description: "Condensed and evaporated milk",
  },
  {
    value: "2024",
    title: "2024 - Ice cream and frozen desserts",
    description: "Ice cream and frozen desserts",
  },
  { value: "2026", title: "2026 - Fluid milk", description: "Fluid milk" },
  {
    value: "2032",
    title: "2032 - Canned specialties",
    description: "Canned specialties",
  },
  {
    value: "2033",
    title: "2033 - Canned fruits and vegetables",
    description: "Canned fruits and vegetables",
  },
  {
    value: "2034",
    title: "2034 - Dehydrated fruits, vegetables, soups",
    description: "Dehydrated fruits, vegetables, soups",
  },
  {
    value: "2035",
    title: "2035 - Pickles, sauces, and salad dressings",
    description: "Pickles, sauces, and salad dressings",
  },
  {
    value: "2037",
    title: "2037 - Frozen fruits and vegetables",
    description: "Frozen fruits and vegetables",
  },
  {
    value: "2038",
    title: "2038 - Frozen specialties",
    description: "Frozen specialties",
  },
  {
    value: "2041",
    title: "2041 - Flour and other grain mill products",
    description: "Flour and other grain mill products",
  },
  {
    value: "2043",
    title: "2043 - Cereal breakfast foods",
    description: "Cereal breakfast foods",
  },
  { value: "2044", title: "2044 - Rice milling", description: "Rice milling" },
  {
    value: "2045",
    title: "2045 - Blended and prepared flour mixes and doughs",
    description: "Blended and prepared flour mixes and doughs",
  },
  {
    value: "2046",
    title: "2046 - Wet corn milling",
    description: "Wet corn milling",
  },
  {
    value: "2047",
    title: "2047 - Dog and cat food",
    description: "Dog and cat food",
  },
  {
    value: "2048",
    title: "2048 - Prepared feeds",
    description: "Prepared feeds",
  },
  {
    value: "2051",
    title: "2051 - Bread, cake, and related products",
    description: "Bread, cake, and related products",
  },
  {
    value: "2052",
    title: "2052 - Cookies and crackers",
    description: "Cookies and crackers",
  },
  {
    value: "2053",
    title: "2053 - Frozen bakery products, except bread",
    description: "Frozen bakery products, except bread",
  },
  {
    value: "2061",
    title: "2061 - Raw cane sugar",
    description: "Raw cane sugar",
  },
  {
    value: "2062",
    title: "2062 - Cane sugar refining",
    description: "Cane sugar refining",
  },
  { value: "2063", title: "2063 - Beet sugar", description: "Beet sugar" },
  {
    value: "2064",
    title: "2064 - Candy & other confectionery products",
    description: "Candy & other confectionery products",
  },
  {
    value: "2066",
    title: "2066 - Chocolate and cocoa products",
    description: "Chocolate and cocoa products",
  },
  { value: "2067", title: "2067 - Chewing gum", description: "Chewing gum" },
  {
    value: "2068",
    title: "2068 - Salted and roasted nuts and seeds",
    description: "Salted and roasted nuts and seeds",
  },
  {
    value: "2074",
    title: "2074 - Cottonseed oil mills",
    description: "Cottonseed oil mills",
  },
  {
    value: "2075",
    title: "2075 - Soybean oil mills",
    description: "Soybean oil mills",
  },
  {
    value: "2076",
    title: "2076 - Vegetable oil mills, n.e.c.",
    description: "Vegetable oil mills, n.e.c.",
  },
  {
    value: "2077",
    title: "2077 - Animal and marine fats and oils",
    description: "Animal and marine fats and oils",
  },
  {
    value: "2079",
    title: "2079 - Edible fats and oils, n.e.c.",
    description: "Edible fats and oils, n.e.c.",
  },
  {
    value: "2082",
    title: "2082 - Malt beverages",
    description: "Malt beverages",
  },
  { value: "2083", title: "2083 - Malt", description: "Malt" },
  {
    value: "2084",
    title: "2084 - Wines, brandy, and brandy spirits",
    description: "Wines, brandy, and brandy spirits",
  },
  {
    value: "2085",
    title: "2085 - Distilled and blended liquors",
    description: "Distilled and blended liquors",
  },
  {
    value: "2086",
    title: "2086 - Bottled and canned soft drinks",
    description: "Bottled and canned soft drinks",
  },
  {
    value: "2087",
    title: "2087 - Flavoring extracts and sirups, n.e.c.",
    description: "Flavoring extracts and sirups, n.e.c.",
  },
  {
    value: "2091",
    title: "2091 - Canned and cured fish and seafoods",
    description: "Canned and cured fish and seafoods",
  },
  {
    value: "2092",
    title: "2092 - Fresh or frozen packaged fish",
    description: "Fresh or frozen packaged fish",
  },
  {
    value: "2095",
    title: "2095 - Roasted coffee",
    description: "Roasted coffee",
  },
  {
    value: "2096",
    title: "2096 - Potato chips and similar snacks",
    description: "Potato chips and similar snacks",
  },
  {
    value: "2097",
    title: "2097 - Manufactured ice",
    description: "Manufactured ice",
  },
  {
    value: "2098",
    title: "2098 - Macaroni and spaghetti",
    description: "Macaroni and spaghetti",
  },
  {
    value: "2099",
    title: "2099 - Food preparations, n.e.c.",
    description: "Food preparations, n.e.c.",
  },
  { value: "2111", title: "2111 - Cigarettes", description: "Cigarettes" },
  { value: "2121", title: "2121 - Cigars", description: "Cigars" },
  {
    value: "2131",
    title: "2131 - Chewing and smoking tobacco",
    description: "Chewing and smoking tobacco",
  },
  {
    value: "2141",
    title: "2141 - Tobacco stemming and redrying",
    description: "Tobacco stemming and redrying",
  },
  {
    value: "2211",
    title: "2211 - Weaving mills, cotton",
    description: "Weaving mills, cotton",
  },
  {
    value: "2221",
    title: "2221 - Weaving mills, synthetics",
    description: "Weaving mills, synthetics",
  },
  {
    value: "2231",
    title: "2231 - Weaving and finishing mills, wool",
    description: "Weaving and finishing mills, wool",
  },
  {
    value: "2241",
    title: "2241 - Narrow fabric mills",
    description: "Narrow fabric mills",
  },
  {
    value: "2251",
    title: "2251 - Women`s hosiery, except socks",
    description: "Women`s hosiery, except socks",
  },
  {
    value: "2252",
    title: "2252 - Hosiery, n.e.c.",
    description: "Hosiery, n.e.c.",
  },
  {
    value: "2253",
    title: "2253 - Knit outerwear mills",
    description: "Knit outerwear mills",
  },
  {
    value: "2254",
    title: "2254 - Knit underwear mills",
    description: "Knit underwear mills",
  },
  {
    value: "2257",
    title: "2257 - Circular knit fabric mills",
    description: "Circular knit fabric mills",
  },
  {
    value: "2258",
    title: "2258 - Lace & warp knit fabric mills",
    description: "Lace & warp knit fabric mills",
  },
  {
    value: "2259",
    title: "2259 - Knitting mills, n.e.c.",
    description: "Knitting mills, n.e.c.",
  },
  {
    value: "2261",
    title: "2261 - Finishing plants, cotton",
    description: "Finishing plants, cotton",
  },
  {
    value: "2262",
    title: "2262 - Finishing plants, manmade",
    description: "Finishing plants, manmade",
  },
  {
    value: "2269",
    title: "2269 - Finishing plants, n.e.c.",
    description: "Finishing plants, n.e.c.",
  },
  {
    value: "2273",
    title: "2273 - Woven carpets and rugs",
    description: "Woven carpets and rugs",
  },
  {
    value: "2281",
    title: "2281 - Yarn mills, except wool",
    description: "Yarn mills, except wool",
  },
  {
    value: "2282",
    title: "2282 - Throwing and winding mills",
    description: "Throwing and winding mills",
  },
  { value: "2284", title: "2284 - Thread mills", description: "Thread mills" },
  {
    value: "2295",
    title: "2295 - Coated fabrics, not rubberized",
    description: "Coated fabrics, not rubberized",
  },
  {
    value: "2296",
    title: "2296 - Tire cord and fabric",
    description: "Tire cord and fabric",
  },
  {
    value: "2297",
    title: "2297 - Nonwoven fabrics",
    description: "Nonwoven fabrics",
  },
  {
    value: "2298",
    title: "2298 - Cordage and twine",
    description: "Cordage and twine",
  },
  {
    value: "2299",
    title: "2299 - Textile goods, n.e.c.",
    description: "Textile goods, n.e.c.",
  },
  {
    value: "2311",
    title: "2311 - Men`s and boys` suits and coats",
    description: "Men`s and boys` suits and coats",
  },
  {
    value: "2321",
    title: "2321 - Men`s and boys` shirts",
    description: "Men`s and boys` shirts",
  },
  {
    value: "2322",
    title: "2322 - Men`s & boys` underwear + nightwear",
    description: "Men`s & boys` underwear + nightwear",
  },
  {
    value: "2323",
    title: "2323 - Men`s and boys` n.e.c.kwear",
    description: "Men`s and boys` n.e.c.kwear",
  },
  {
    value: "2325",
    title: "2325 - Men`s and boys` trousers and slacks",
    description: "Men`s and boys` trousers and slacks",
  },
  {
    value: "2326",
    title: "2326 - Men`s and boys` work clothing",
    description: "Men`s and boys` work clothing",
  },
  {
    value: "2329",
    title: "2329 - Men`s and boys` clothing, n.e.c.",
    description: "Men`s and boys` clothing, n.e.c.",
  },
  {
    value: "2331",
    title: "2331 - Women`s & misses` blouses & shirts",
    description: "Women`s & misses` blouses & shirts",
  },
  {
    value: "2335",
    title: "2335 - Women`s and misses` dresses",
    description: "Women`s and misses` dresses",
  },
  {
    value: "2337",
    title: "2337 - Women`s and misses` suits and coats",
    description: "Women`s and misses` suits and coats",
  },
  {
    value: "2339",
    title: "2339 - Women`s and misses` outerwear, n.e.c.",
    description: "Women`s and misses` outerwear, n.e.c.",
  },
  {
    value: "2341",
    title: "2341 - Women`s and children`s underwear",
    description: "Women`s and children`s underwear",
  },
  {
    value: "2342",
    title: "2342 - Bras, girdles, and allied garments",
    description: "Bras, girdles, and allied garments",
  },
  {
    value: "2353",
    title: "2353 - Hats and caps, and millinery",
    description: "Hats and caps, and millinery",
  },
  {
    value: "2361",
    title: "2361 - Children`s dresses and blouses",
    description: "Children`s dresses and blouses",
  },
  {
    value: "2369",
    title: "2369 - Children`s outerwear, n.e.c.",
    description: "Children`s outerwear, n.e.c.",
  },
  { value: "2371", title: "2371 - Fur goods", description: "Fur goods" },
  {
    value: "2381",
    title: "2381 - Fabric dress and work gloves",
    description: "Fabric dress and work gloves",
  },
  {
    value: "2384",
    title: "2384 - Robes and dressing gowns",
    description: "Robes and dressing gowns",
  },
  {
    value: "2385",
    title: "2385 - Waterproof outergarments",
    description: "Waterproof outergarments",
  },
  {
    value: "2386",
    title: "2386 - Leather and sheep lined clothing",
    description: "Leather and sheep lined clothing",
  },
  {
    value: "2387",
    title: "2387 - Apparel belts",
    description: "Apparel belts",
  },
  {
    value: "2389",
    title: "2389 - Apparel and accessories, n.e.c.",
    description: "Apparel and accessories, n.e.c.",
  },
  {
    value: "2391",
    title: "2391 - Curtains and draperies",
    description: "Curtains and draperies",
  },
  {
    value: "2392",
    title: "2392 - House furnishings, n.e.c.",
    description: "House furnishings, n.e.c.",
  },
  { value: "2393", title: "2393 - Textile bags", description: "Textile bags" },
  {
    value: "2394",
    title: "2394 - Canvas and related products",
    description: "Canvas and related products",
  },
  {
    value: "2395",
    title: "2395 - Pleating and stitching",
    description: "Pleating and stitching",
  },
  {
    value: "2396",
    title: "2396 - Automotive and apparel trimmings",
    description: "Automotive and apparel trimmings",
  },
  {
    value: "2397",
    title: "2397 - Schiffli machine embroideries",
    description: "Schiffli machine embroideries",
  },
  {
    value: "2399",
    title: "2399 - Fabricated textile products, n.e.c.",
    description: "Fabricated textile products, n.e.c.",
  },
  {
    value: "2411",
    title: "2411 - Logging camps & logging contractors",
    description: "Logging camps & logging contractors",
  },
  {
    value: "2421",
    title: "2421 - Sawmills and planing mills, general",
    description: "Sawmills and planing mills, general",
  },
  {
    value: "2426",
    title: "2426 - Hardwood dimension & flooring mills",
    description: "Hardwood dimension & flooring mills",
  },
  {
    value: "2429",
    title: "2429 - Special product sawmills, n.e.c.",
    description: "Special product sawmills, n.e.c.",
  },
  { value: "2431", title: "2431 - Millwork", description: "Millwork" },
  {
    value: "2434",
    title: "2434 - Wood kitchen cabinets",
    description: "Wood kitchen cabinets",
  },
  {
    value: "2435",
    title: "2435 - Hardwood veneer and plywood",
    description: "Hardwood veneer and plywood",
  },
  {
    value: "2436",
    title: "2436 - Softwood veneer and plywood",
    description: "Softwood veneer and plywood",
  },
  {
    value: "2439",
    title: "2439 - Structural wood members, n.e.c.",
    description: "Structural wood members, n.e.c.",
  },
  {
    value: "2441",
    title: "2441 - Nailed wood boxes and shook",
    description: "Nailed wood boxes and shook",
  },
  {
    value: "2448",
    title: "2448 - Wood pallets and skids",
    description: "Wood pallets and skids",
  },
  {
    value: "2449",
    title: "2449 - Wood containers, n.e.c.",
    description: "Wood containers, n.e.c.",
  },
  { value: "2451", title: "2451 - Mobile homes", description: "Mobile homes" },
  {
    value: "2452",
    title: "2452 - Prefabricated wood buildings",
    description: "Prefabricated wood buildings",
  },
  {
    value: "2491",
    title: "2491 - Wood preserving",
    description: "Wood preserving",
  },
  {
    value: "2493",
    title: "2493 - Reconstituted wood products",
    description: "Reconstituted wood products",
  },
  {
    value: "2499",
    title: "2499 - Wood products, n.e.c.",
    description: "Wood products, n.e.c.",
  },
  {
    value: "2511",
    title: "2511 - Wood household furniture",
    description: "Wood household furniture",
  },
  {
    value: "2512",
    title: "2512 - Upholstered household furniture",
    description: "Upholstered household furniture",
  },
  {
    value: "2514",
    title: "2514 - Metal household furniture",
    description: "Metal household furniture",
  },
  {
    value: "2515",
    title: "2515 - Mattresses and bedsprings",
    description: "Mattresses and bedsprings",
  },
  {
    value: "2517",
    title: "2517 - Wood TV and radio cabinets",
    description: "Wood TV and radio cabinets",
  },
  {
    value: "2519",
    title: "2519 - Household furniture, n.e.c.",
    description: "Household furniture, n.e.c.",
  },
  {
    value: "2521",
    title: "2521 - Wood office furniture",
    description: "Wood office furniture",
  },
  {
    value: "2522",
    title: "2522 - Metal office furniture",
    description: "Metal office furniture",
  },
  {
    value: "2531",
    title: "2531 - Public building & related furniture",
    description: "Public building & related furniture",
  },
  {
    value: "2541",
    title: "2541 - Wood partitions and fixtures",
    description: "Wood partitions and fixtures",
  },
  {
    value: "2542",
    title: "2542 - Metal partitions and fixtures",
    description: "Metal partitions and fixtures",
  },
  {
    value: "2591",
    title: "2591 - Drapery hardware & blinds & shades",
    description: "Drapery hardware & blinds & shades",
  },
  {
    value: "2599",
    title: "2599 - Furniture and fixtures, n.e.c.",
    description: "Furniture and fixtures, n.e.c.",
  },
  { value: "2611", title: "2611 - Pulp mills", description: "Pulp mills" },
  {
    value: "2621",
    title: "2621 - Paper mills, except building paper",
    description: "Paper mills, except building paper",
  },
  {
    value: "2631",
    title: "2631 - Paperboard mills",
    description: "Paperboard mills",
  },
  {
    value: "2652",
    title: "2652 - Set-up paperboard boxes",
    description: "Set-up paperboard boxes",
  },
  {
    value: "2653",
    title: "2653 - Corrugated and solid fiber boxes",
    description: "Corrugated and solid fiber boxes",
  },
  {
    value: "2655",
    title: "2655 - Fiber cans, drums & similar products",
    description: "Fiber cans, drums & similar products",
  },
  {
    value: "2656",
    title: "2656 - Sanitary food containers",
    description: "Sanitary food containers",
  },
  {
    value: "2657",
    title: "2657 - Folding paperboard boxes",
    description: "Folding paperboard boxes",
  },
  {
    value: "2671",
    title: "2671 - Paper coated & laminated, packaging",
    description: "Paper coated & laminated, packaging",
  },
  {
    value: "2672",
    title: "2672 - Paper coated and laminated, n.e.c.",
    description: "Paper coated and laminated, n.e.c.",
  },
  {
    value: "2673",
    title: "2673 - Bags: plastics, laminated, & coated",
    description: "Bags: plastics, laminated, & coated",
  },
  {
    value: "2674",
    title: "2674 - Bags: uncoated paper & multiwall",
    description: "Bags: uncoated paper & multiwall",
  },
  {
    value: "2675",
    title: "2675 - Die-cut paper and board",
    description: "Die-cut paper and board",
  },
  {
    value: "2676",
    title: "2676 - Sanitary paper products",
    description: "Sanitary paper products",
  },
  { value: "2677", title: "2677 - Envelopes", description: "Envelopes" },
  {
    value: "2678",
    title: "2678 - Stationery products",
    description: "Stationery products",
  },
  {
    value: "2679",
    title: "2679 - Converted paper products, n.e.c.",
    description: "Converted paper products, n.e.c.",
  },
  { value: "2711", title: "2711 - Newspapers", description: "Newspapers" },
  {
    value: "2721",
    title: "2721 - Periodicals, paper",
    description: "Periodicals, paper",
  },
  {
    value: "2731",
    title: "2731 - Book publishing",
    description: "Book publishing",
  },
  {
    value: "2732",
    title: "2732 - Book printing",
    description: "Book printing",
  },
  {
    value: "2741",
    title: "2741 - Miscellaneous publishing",
    description: "Miscellaneous publishing",
  },
  {
    value: "2752",
    title: "2752 - Commercial printing, lithographic",
    description: "Commercial printing, lithographic",
  },
  {
    value: "2754",
    title: "2754 - Commercial printing, gravure",
    description: "Commercial printing, gravure",
  },
  {
    value: "2759",
    title: "2759 - Commercial printing, n.e.c.",
    description: "Commercial printing, n.e.c.",
  },
  {
    value: "2761",
    title: "2761 - Manifold business forms",
    description: "Manifold business forms",
  },
  {
    value: "2771",
    title: "2771 - Greeting card publishing",
    description: "Greeting card publishing",
  },
  {
    value: "2782",
    title: "2782 - Blankbooks and looseleaf binders",
    description: "Blankbooks and looseleaf binders",
  },
  {
    value: "2789",
    title: "2789 - Bookbinding and related work",
    description: "Bookbinding and related work",
  },
  { value: "2791", title: "2791 - Typesetting", description: "Typesetting" },
  {
    value: "2796",
    title: "2796 - Platemaking services",
    description: "Platemaking services",
  },
  {
    value: "2812",
    title: "2812 - Alkalies and chlorine",
    description: "Alkalies and chlorine",
  },
  {
    value: "2813",
    title: "2813 - Industrial gases",
    description: "Industrial gases",
  },
  {
    value: "2816",
    title: "2816 - Inorganic pigments",
    description: "Inorganic pigments",
  },
  {
    value: "2819",
    title: "2819 - Industrial inorganic chemicals, n.e.c.",
    description: "Industrial inorganic chemicals, n.e.c.",
  },
  {
    value: "2821",
    title: "2821 - Plastics materials and resins",
    description: "Plastics materials and resins",
  },
  {
    value: "2822",
    title: "2822 - Synthetic rubber",
    description: "Synthetic rubber",
  },
  {
    value: "2823",
    title: "2823 - Cellulosic man-made fibers",
    description: "Cellulosic man-made fibers",
  },
  {
    value: "2824",
    title: "2824 - Organic fibers, noncellulosic",
    description: "Organic fibers, noncellulosic",
  },
  {
    value: "2833",
    title: "2833 - Medicinals and botanicals",
    description: "Medicinals and botanicals",
  },
  {
    value: "2834",
    title: "2834 - Pharmaceutical preparations",
    description: "Pharmaceutical preparations",
  },
  {
    value: "2835",
    title: "2835 - Diagnostic substances",
    description: "Diagnostic substances",
  },
  {
    value: "2836",
    title: "2836 - Biological products exc. diagnostic",
    description: "Biological products exc. diagnostic",
  },
  {
    value: "2841",
    title: "2841 - Soap and other detergents",
    description: "Soap and other detergents",
  },
  {
    value: "2842",
    title: "2842 - Polishes and sanitation goods",
    description: "Polishes and sanitation goods",
  },
  {
    value: "2843",
    title: "2843 - Surface active agents",
    description: "Surface active agents",
  },
  {
    value: "2844",
    title: "2844 - Toilet preparations",
    description: "Toilet preparations",
  },
  {
    value: "2851",
    title: "2851 - Paints and allied products",
    description: "Paints and allied products",
  },
  {
    value: "2861",
    title: "2861 - Gum and wood chemicals",
    description: "Gum and wood chemicals",
  },
  {
    value: "2865",
    title: "2865 - Cyclic crudes and intermediates",
    description: "Cyclic crudes and intermediates",
  },
  {
    value: "2869",
    title: "2869 - Industrial organic chemicals, n.e.c.",
    description: "Industrial organic chemicals, n.e.c.",
  },
  {
    value: "2873",
    title: "2873 - Nitrogenous fertilizers",
    description: "Nitrogenous fertilizers",
  },
  {
    value: "2874",
    title: "2874 - Phosphatic fertilizers",
    description: "Phosphatic fertilizers",
  },
  {
    value: "2875",
    title: "2875 - Fertilizers, mixing only",
    description: "Fertilizers, mixing only",
  },
  {
    value: "2879",
    title: "2879 - Agricultural chemicals, n.e.c.",
    description: "Agricultural chemicals, n.e.c.",
  },
  {
    value: "2891",
    title: "2891 - Adhesives and sealants",
    description: "Adhesives and sealants",
  },
  { value: "2892", title: "2892 - Explosives", description: "Explosives" },
  { value: "2893", title: "2893 - Printing ink", description: "Printing ink" },
  { value: "2895", title: "2895 - Carbon black", description: "Carbon black" },
  {
    value: "2899",
    title: "2899 - Chemical preparations, n.e.c.",
    description: "Chemical preparations, n.e.c.",
  },
  {
    value: "2911",
    title: "2911 - Petroleum refining",
    description: "Petroleum refining",
  },
  {
    value: "2951",
    title: "2951 - Asphalt paving mixtures and blocks",
    description: "Asphalt paving mixtures and blocks",
  },
  {
    value: "2952",
    title: "2952 - Asphalt felts and coatings",
    description: "Asphalt felts and coatings",
  },
  {
    value: "2992",
    title: "2992 - Lubricating oils and greases",
    description: "Lubricating oils and greases",
  },
  {
    value: "2999",
    title: "2999 - Petroleum and coal products, n.e.c.",
    description: "Petroleum and coal products, n.e.c.",
  },
  {
    value: "3011",
    title: "3011 - Tires and inner tubes",
    description: "Tires and inner tubes",
  },
  {
    value: "3021",
    title: "3021 - Rubber and plastics footwear",
    description: "Rubber and plastics footwear",
  },
  {
    value: "3052",
    title: "3052 - Rubber & plastics hose & belting",
    description: "Rubber & plastics hose & belting",
  },
  {
    value: "3053",
    title: "3053 - Gaskets, packing and sealing devices",
    description: "Gaskets, packing and sealing devices",
  },
  {
    value: "3061",
    title: "3061 - Mechanical rubber goods",
    description: "Mechanical rubber goods",
  },
  {
    value: "3069",
    title: "3069 - Fabricated rubber products, n.e.c.",
    description: "Fabricated rubber products, n.e.c.",
  },
  {
    value: "3081",
    title: "3081 - Unsupported plastics film & sheet",
    description: "Unsupported plastics film & sheet",
  },
  {
    value: "3082",
    title: "3082 - Unsupported plastics profile shapes",
    description: "Unsupported plastics profile shapes",
  },
  {
    value: "3083",
    title: "3083 - Laminated plastics plate & sheet",
    description: "Laminated plastics plate & sheet",
  },
  {
    value: "3084",
    title: "3084 - Plastics pipe",
    description: "Plastics pipe",
  },
  {
    value: "3085",
    title: "3085 - Plastics bottles",
    description: "Plastics bottles",
  },
  {
    value: "3086",
    title: "3086 - Plastics foam products",
    description: "Plastics foam products",
  },
  {
    value: "3087",
    title: "3087 - Custom compound purchased resins",
    description: "Custom compound purchased resins",
  },
  {
    value: "3088",
    title: "3088 - Plastics plumbing fixtures",
    description: "Plastics plumbing fixtures",
  },
  {
    value: "3089",
    title: "3089 - Plastics products, n.e.c.",
    description: "Plastics products, n.e.c.",
  },
  {
    value: "3111",
    title: "3111 - Leather tanning and finishing",
    description: "Leather tanning and finishing",
  },
  {
    value: "3131",
    title: "3131 - Footwear cut stock",
    description: "Footwear cut stock",
  },
  {
    value: "3142",
    title: "3142 - House slippers",
    description: "House slippers",
  },
  {
    value: "3143",
    title: "3143 - Men`s footwear, except athletic",
    description: "Men`s footwear, except athletic",
  },
  {
    value: "3144",
    title: "3144 - Women`s footwear, except athletic",
    description: "Women`s footwear, except athletic",
  },
  {
    value: "3149",
    title: "3149 - Footwear, except rubber, n.e.c.",
    description: "Footwear, except rubber, n.e.c.",
  },
  {
    value: "3151",
    title: "3151 - Leather gloves and mittens",
    description: "Leather gloves and mittens",
  },
  { value: "3161", title: "3161 - Luggage", description: "Luggage" },
  {
    value: "3171",
    title: "3171 - Women`s handbags and purses",
    description: "Women`s handbags and purses",
  },
  {
    value: "3172",
    title: "3172 - Personal leather goods, n.e.c.",
    description: "Personal leather goods, n.e.c.",
  },
  {
    value: "3199",
    title: "3199 - Leather goods, n.e.c.",
    description: "Leather goods, n.e.c.",
  },
  { value: "3211", title: "3211 - Flat glass", description: "Flat glass" },
  {
    value: "3221",
    title: "3221 - Glass containers",
    description: "Glass containers",
  },
  {
    value: "3229",
    title: "3229 - Pressed and blown glass, n.e.c.",
    description: "Pressed and blown glass, n.e.c.",
  },
  {
    value: "3231",
    title: "3231 - Products of purchased glass",
    description: "Products of purchased glass",
  },
  {
    value: "3241",
    title: "3241 - Cement, hydraulic",
    description: "Cement, hydraulic",
  },
  {
    value: "3251",
    title: "3251 - Brick and structural clay tile",
    description: "Brick and structural clay tile",
  },
  {
    value: "3253",
    title: "3253 - Ceramic wall and floor tile",
    description: "Ceramic wall and floor tile",
  },
  {
    value: "3255",
    title: "3255 - Clay refractories",
    description: "Clay refractories",
  },
  {
    value: "3259",
    title: "3259 - Structural clay products, n.e.c.",
    description: "Structural clay products, n.e.c.",
  },
  {
    value: "3261",
    title: "3261 - Vitreous plumbing fixtures",
    description: "Vitreous plumbing fixtures",
  },
  {
    value: "3262",
    title: "3262 - Vitreous china food utensils",
    description: "Vitreous china food utensils",
  },
  {
    value: "3263",
    title: "3263 - Fine earthenware food utensils",
    description: "Fine earthenware food utensils",
  },
  {
    value: "3264",
    title: "3264 - Porcelain electrical supplies",
    description: "Porcelain electrical supplies",
  },
  {
    value: "3269",
    title: "3269 - Pottery products, n.e.c.",
    description: "Pottery products, n.e.c.",
  },
  {
    value: "3271",
    title: "3271 - Concrete block and brick",
    description: "Concrete block and brick",
  },
  {
    value: "3272",
    title: "3272 - Concrete products, n.e.c.",
    description: "Concrete products, n.e.c.",
  },
  {
    value: "3273",
    title: "3273 - Ready-mixed concrete",
    description: "Ready-mixed concrete",
  },
  { value: "3274", title: "3274 - Lime", description: "Lime" },
  {
    value: "3275",
    title: "3275 - Gypsum products",
    description: "Gypsum products",
  },
  {
    value: "3281",
    title: "3281 - Cut stone and stone products",
    description: "Cut stone and stone products",
  },
  {
    value: "3291",
    title: "3291 - Abrasive products",
    description: "Abrasive products",
  },
  {
    value: "3292",
    title: "3292 - Asbestos products",
    description: "Asbestos products",
  },
  {
    value: "3295",
    title: "3295 - Minerals, ground or treated",
    description: "Minerals, ground or treated",
  },
  { value: "3296", title: "3296 - Mineral wool", description: "Mineral wool" },
  {
    value: "3297",
    title: "3297 - Nonclay refractories",
    description: "Nonclay refractories",
  },
  {
    value: "3299",
    title: "3299 - Nonmetallic mineral products, n.e.c.",
    description: "Nonmetallic mineral products, n.e.c.",
  },
  {
    value: "3312",
    title: "3312 - Blast furnaces and steel mills",
    description: "Blast furnaces and steel mills",
  },
  {
    value: "3313",
    title: "3313 - Electrometallurgical products",
    description: "Electrometallurgical products",
  },
  {
    value: "3315",
    title: "3315 - Steel wire and related products",
    description: "Steel wire and related products",
  },
  {
    value: "3316",
    title: "3316 - Cold finishing of steel shapes",
    description: "Cold finishing of steel shapes",
  },
  {
    value: "3317",
    title: "3317 - Steel pipe and tubes",
    description: "Steel pipe and tubes",
  },
  {
    value: "3321",
    title: "3321 - Gray and ductile iron foundries",
    description: "Gray and ductile iron foundries",
  },
  {
    value: "3322",
    title: "3322 - Malleable iron foundries",
    description: "Malleable iron foundries",
  },
  {
    value: "3324",
    title: "3324 - Steel investment foundries",
    description: "Steel investment foundries",
  },
  {
    value: "3325",
    title: "3325 - Steel foundries, n.e.c.",
    description: "Steel foundries, n.e.c.",
  },
  {
    value: "3331",
    title: "3331 - Primary copper",
    description: "Primary copper",
  },
  {
    value: "3334",
    title: "3334 - Primary aluminum",
    description: "Primary aluminum",
  },
  {
    value: "3339",
    title: "3339 - Primary nonferrous metals, n.e.c.",
    description: "Primary nonferrous metals, n.e.c.",
  },
  {
    value: "3341",
    title: "3341 - Secondary nonferrous metals",
    description: "Secondary nonferrous metals",
  },
  {
    value: "3351",
    title: "3351 - Copper rolling and drawing",
    description: "Copper rolling and drawing",
  },
  {
    value: "3353",
    title: "3353 - Aluminum sheet, plate, and foil",
    description: "Aluminum sheet, plate, and foil",
  },
  {
    value: "3354",
    title: "3354 - Aluminum extruded products",
    description: "Aluminum extruded products",
  },
  {
    value: "3355",
    title: "3355 - Aluminum rolling and drawing, n.e.c.",
    description: "Aluminum rolling and drawing, n.e.c.",
  },
  {
    value: "3356",
    title: "3356 - Nonferrous rolling and drawing, n.e.c.",
    description: "Nonferrous rolling and drawing, n.e.c.",
  },
  {
    value: "3357",
    title: "3357 - Nonferrous wire drawing & insulating",
    description: "Nonferrous wire drawing & insulating",
  },
  {
    value: "3363",
    title: "3363 - Aluminum die-castings",
    description: "Aluminum die-castings",
  },
  {
    value: "3364",
    title: "3364 - Nonferrous die-casting exc. aluminum",
    description: "Nonferrous die-casting exc. aluminum",
  },
  {
    value: "3365",
    title: "3365 - Aluminum foundries",
    description: "Aluminum foundries",
  },
  {
    value: "3366",
    title: "3366 - Copper foundries",
    description: "Copper foundries",
  },
  {
    value: "3369",
    title: "3369 - Nonferrous foundries, n.e.c.",
    description: "Nonferrous foundries, n.e.c.",
  },
  {
    value: "3398",
    title: "3398 - Metal heat treating",
    description: "Metal heat treating",
  },
  {
    value: "3399",
    title: "3399 - Primary metal products, n.e.c.",
    description: "Primary metal products, n.e.c.",
  },
  { value: "3411", title: "3411 - Metal cans", description: "Metal cans" },
  {
    value: "3412",
    title: "3412 - Metal barrels, drums, and pails",
    description: "Metal barrels, drums, and pails",
  },
  { value: "3421", title: "3421 - Cutlery", description: "Cutlery" },
  {
    value: "3423",
    title: "3423 - Hand and edge tools, n.e.c.",
    description: "Hand and edge tools, n.e.c.",
  },
  {
    value: "3425",
    title: "3425 - Hand saws and saw blades",
    description: "Hand saws and saw blades",
  },
  {
    value: "3429",
    title: "3429 - Hardware, n.e.c.",
    description: "Hardware, n.e.c.",
  },
  {
    value: "3431",
    title: "3431 - Metal sanitary ware",
    description: "Metal sanitary ware",
  },
  {
    value: "3432",
    title: "3432 - Plumbing fittings and brass goods",
    description: "Plumbing fittings and brass goods",
  },
  {
    value: "3433",
    title: "3433 - Heating equipment, except electric",
    description: "Heating equipment, except electric",
  },
  {
    value: "3441",
    title: "3441 - Fabricated structural metal",
    description: "Fabricated structural metal",
  },
  {
    value: "3442",
    title: "3442 - Metal doors, sash, and trim",
    description: "Metal doors, sash, and trim",
  },
  {
    value: "3443",
    title: "3443 - Fabricated plate work (boiler shops)",
    description: "Fabricated plate work (boiler shops)",
  },
  {
    value: "3444",
    title: "3444 - Sheet metal work",
    description: "Sheet metal work",
  },
  {
    value: "3446",
    title: "3446 - Architectural metal work",
    description: "Architectural metal work",
  },
  {
    value: "3448",
    title: "3448 - Prefabricated metal buildings",
    description: "Prefabricated metal buildings",
  },
  {
    value: "3449",
    title: "3449 - Miscellaneous metal work",
    description: "Miscellaneous metal work",
  },
  {
    value: "3451",
    title: "3451 - Screw machine products",
    description: "Screw machine products",
  },
  {
    value: "3452",
    title: "3452 - Bolts, nuts, rivets, and washers",
    description: "Bolts, nuts, rivets, and washers",
  },
  {
    value: "3462",
    title: "3462 - Iron and steel forgings",
    description: "Iron and steel forgings",
  },
  {
    value: "3463",
    title: "3463 - Nonferrous forgings",
    description: "Nonferrous forgings",
  },
  {
    value: "3465",
    title: "3465 - Automotive stampings",
    description: "Automotive stampings",
  },
  {
    value: "3466",
    title: "3466 - Crowns and closures",
    description: "Crowns and closures",
  },
  {
    value: "3469",
    title: "3469 - Metal stampings, n.e.c.",
    description: "Metal stampings, n.e.c.",
  },
  {
    value: "3471",
    title: "3471 - Plating and polishing",
    description: "Plating and polishing",
  },
  {
    value: "3479",
    title: "3479 - Metal coating and allied services",
    description: "Metal coating and allied services",
  },
  {
    value: "3482",
    title: "3482 - Small arms ammunition",
    description: "Small arms ammunition",
  },
  {
    value: "3483",
    title: "3483 - Ammunition, exc. for small arms, n.e.c.",
    description: "Ammunition, exc. for small arms, n.e.c.",
  },
  { value: "3484", title: "3484 - Small arms", description: "Small arms" },
  {
    value: "3489",
    title: "3489 - Ordnance and accessories, n.e.c.",
    description: "Ordnance and accessories, n.e.c.",
  },
  {
    value: "3491",
    title: "3491 - Industrial valves",
    description: "Industrial valves",
  },
  {
    value: "3492",
    title: "3492 - Fluid power valves & hose fittings",
    description: "Fluid power valves & hose fittings",
  },
  {
    value: "3493",
    title: "3493 - Steel springs, except wire",
    description: "Steel springs, except wire",
  },
  {
    value: "3494",
    title: "3494 - Valves and pipe fittings",
    description: "Valves and pipe fittings",
  },
  { value: "3495", title: "3495 - Wire springs", description: "Wire springs" },
  {
    value: "3496",
    title: "3496 - Misc. fabricated wire products",
    description: "Misc. fabricated wire products",
  },
  {
    value: "3497",
    title: "3497 - Metal foil and leaf",
    description: "Metal foil and leaf",
  },
  {
    value: "3498",
    title: "3498 - Fabricated pipe and fittings",
    description: "Fabricated pipe and fittings",
  },
  {
    value: "3499",
    title: "3499 - Fabricated metal products, n.e.c.",
    description: "Fabricated metal products, n.e.c.",
  },
  {
    value: "3511",
    title: "3511 - Turbines and turbine generator sets",
    description: "Turbines and turbine generator sets",
  },
  {
    value: "3519",
    title: "3519 - Internal combustion engines, n.e.c.",
    description: "Internal combustion engines, n.e.c.",
  },
  {
    value: "3523",
    title: "3523 - Farm machinery and equipment",
    description: "Farm machinery and equipment",
  },
  {
    value: "3524",
    title: "3524 - Lawn and garden equipment",
    description: "Lawn and garden equipment",
  },
  {
    value: "3531",
    title: "3531 - Construction machinery",
    description: "Construction machinery",
  },
  {
    value: "3532",
    title: "3532 - Mining machinery",
    description: "Mining machinery",
  },
  {
    value: "3533",
    title: "3533 - Oil and gas field machinery",
    description: "Oil and gas field machinery",
  },
  {
    value: "3534",
    title: "3534 - Elevators and moving stairways",
    description: "Elevators and moving stairways",
  },
  {
    value: "3535",
    title: "3535 - Conveyors and conveying equipment",
    description: "Conveyors and conveying equipment",
  },
  {
    value: "3536",
    title: "3536 - Hoists, cranes, and monorails",
    description: "Hoists, cranes, and monorails",
  },
  {
    value: "3537",
    title: "3537 - Industrial trucks and tractors",
    description: "Industrial trucks and tractors",
  },
  {
    value: "3541",
    title: "3541 - Machine tools, metal cutting types",
    description: "Machine tools, metal cutting types",
  },
  {
    value: "3542",
    title: "3542 - Machine tools, metal forming types",
    description: "Machine tools, metal forming types",
  },
  {
    value: "3543",
    title: "3543 - Industrial patterns",
    description: "Industrial patterns",
  },
  {
    value: "3544",
    title: "3544 - Special dies, tools, jigs & fixtures",
    description: "Special dies, tools, jigs & fixtures",
  },
  {
    value: "3545",
    title: "3545 - Machine tool accessories",
    description: "Machine tool accessories",
  },
  {
    value: "3546",
    title: "3546 - Power driven hand tools",
    description: "Power driven hand tools",
  },
  {
    value: "3547",
    title: "3547 - Rolling mill machinery",
    description: "Rolling mill machinery",
  },
  {
    value: "3548",
    title: "3548 - Welding apparatus",
    description: "Welding apparatus",
  },
  {
    value: "3549",
    title: "3549 - Metalworking machinery, n.e.c.",
    description: "Metalworking machinery, n.e.c.",
  },
  {
    value: "3552",
    title: "3552 - Textile machinery",
    description: "Textile machinery",
  },
  {
    value: "3553",
    title: "3553 - Woodworking machinery",
    description: "Woodworking machinery",
  },
  {
    value: "3554",
    title: "3554 - Paper industries machinery",
    description: "Paper industries machinery",
  },
  {
    value: "3555",
    title: "3555 - Printing trades machinery",
    description: "Printing trades machinery",
  },
  {
    value: "3556",
    title: "3556 - Food products machinery",
    description: "Food products machinery",
  },
  {
    value: "3559",
    title: "3559 - Special industry machinery, n.e.c.",
    description: "Special industry machinery, n.e.c.",
  },
  {
    value: "3561",
    title: "3561 - Pumps and pumping equipment",
    description: "Pumps and pumping equipment",
  },
  {
    value: "3562",
    title: "3562 - Ball and roller bearings",
    description: "Ball and roller bearings",
  },
  {
    value: "3563",
    title: "3563 - Air and gas compressors",
    description: "Air and gas compressors",
  },
  {
    value: "3564",
    title: "3564 - Blowers and fans",
    description: "Blowers and fans",
  },
  {
    value: "3565",
    title: "3565 - Industrial patterns",
    description: "Industrial patterns",
  },
  {
    value: "3566",
    title: "3566 - Speed changers, drives, and gears",
    description: "Speed changers, drives, and gears",
  },
  {
    value: "3567",
    title: "3567 - Industrial furnaces and ovens",
    description: "Industrial furnaces and ovens",
  },
  {
    value: "3568",
    title: "3568 - Power transmission equipment, n.e.c.",
    description: "Power transmission equipment, n.e.c.",
  },
  {
    value: "3569",
    title: "3569 - General industrial machinery, n.e.c.",
    description: "General industrial machinery, n.e.c.",
  },
  {
    value: "3571",
    title: "3571 - Electronic computers",
    description: "Electronic computers",
  },
  {
    value: "3572",
    title: "3572 - Computer storage devices",
    description: "Computer storage devices",
  },
  {
    value: "3575",
    title: "3575 - Computer terminals",
    description: "Computer terminals",
  },
  {
    value: "3577",
    title: "3577 - Computer peripheral equipment, n.e.c.",
    description: "Computer peripheral equipment, n.e.c.",
  },
  {
    value: "3578",
    title: "3578 - Calculating and accounting equipment",
    description: "Calculating and accounting equipment",
  },
  {
    value: "3579",
    title: "3579 - Office machines, n.e.c.",
    description: "Office machines, n.e.c.",
  },
  {
    value: "3581",
    title: "3581 - Automatic merchandising machines",
    description: "Automatic merchandising machines",
  },
  {
    value: "3582",
    title: "3582 - Commercial laundry equipment",
    description: "Commercial laundry equipment",
  },
  {
    value: "3585",
    title: "3585 - Refrigeration and heating equipment",
    description: "Refrigeration and heating equipment",
  },
  {
    value: "3586",
    title: "3586 - Measuring and dispensing pumps",
    description: "Measuring and dispensing pumps",
  },
  {
    value: "3589",
    title: "3589 - Service industry machinery, n.e.c.",
    description: "Service industry machinery, n.e.c.",
  },
  {
    value: "3592",
    title: "3592 - Carburetors, pistons, rings, valves",
    description: "Carburetors, pistons, rings, valves",
  },
  {
    value: "3593",
    title: "3593 - Fluid power cylinders & actuators",
    description: "Fluid power cylinders & actuators",
  },
  {
    value: "3594",
    title: "3594 - Fluid power pumps and motors",
    description: "Fluid power pumps and motors",
  },
  {
    value: "3596",
    title: "3596 - Scales and balances, exc. laboratory",
    description: "Scales and balances, exc. laboratory",
  },
  {
    value: "3599",
    title: "3599 - Industrial machinery, n.e.c.",
    description: "Industrial machinery, n.e.c.",
  },
  { value: "3612", title: "3612 - Transformers", description: "Transformers" },
  {
    value: "3613",
    title: "3613 - Switchgear and switchboard apparatus",
    description: "Switchgear and switchboard apparatus",
  },
  {
    value: "3621",
    title: "3621 - Motors and generators",
    description: "Motors and generators",
  },
  {
    value: "3624",
    title: "3624 - Carbon and graphite products",
    description: "Carbon and graphite products",
  },
  {
    value: "3625",
    title: "3625 - Relays and industrial controls",
    description: "Relays and industrial controls",
  },
  {
    value: "3629",
    title: "3629 - Electrical industrial apparatus, n.e.c.",
    description: "Electrical industrial apparatus, n.e.c.",
  },
  {
    value: "3631",
    title: "3631 - Household cooking equipment",
    description: "Household cooking equipment",
  },
  {
    value: "3632",
    title: "3632 - Household refrigerators and freezers",
    description: "Household refrigerators and freezers",
  },
  {
    value: "3633",
    title: "3633 - Household laundry equipment",
    description: "Household laundry equipment",
  },
  {
    value: "3634",
    title: "3634 - Electric housewares and fans",
    description: "Electric housewares and fans",
  },
  {
    value: "3635",
    title: "3635 - Household vacuum cleaners",
    description: "Household vacuum cleaners",
  },
  {
    value: "3639",
    title: "3639 - Household appliances, n.e.c.",
    description: "Household appliances, n.e.c.",
  },
  {
    value: "3641",
    title: "3641 - Electric lamps",
    description: "Electric lamps",
  },
  {
    value: "3643",
    title: "3643 - Current-carrying wiring devices",
    description: "Current-carrying wiring devices",
  },
  {
    value: "3644",
    title: "3644 - Noncurrent-carrying wiring devices",
    description: "Noncurrent-carrying wiring devices",
  },
  {
    value: "3645",
    title: "3645 - Residential lighting fixtures",
    description: "Residential lighting fixtures",
  },
  {
    value: "3646",
    title: "3646 - Commercial lighting fixtures",
    description: "Commercial lighting fixtures",
  },
  {
    value: "3647",
    title: "3647 - Vehicular lighting equipment",
    description: "Vehicular lighting equipment",
  },
  {
    value: "3648",
    title: "3648 - Lighting equipment, n.e.c.",
    description: "Lighting equipment, n.e.c.",
  },
  {
    value: "3651",
    title: "3651 - Household audio and video equipment",
    description: "Household audio and video equipment",
  },
  {
    value: "3652",
    title: "3652 - Phonograph records",
    description: "Phonograph records",
  },
  {
    value: "3661",
    title: "3661 - Telephone and telegraph apparatus",
    description: "Telephone and telegraph apparatus",
  },
  {
    value: "3663",
    title: "3663 - Radio & TV communications equipment",
    description: "Radio & TV communications equipment",
  },
  {
    value: "3669",
    title: "3669 - Communications equipment, n.e.c.",
    description: "Communications equipment, n.e.c.",
  },
  {
    value: "3671",
    title: "3671 - Electron tubes",
    description: "Electron tubes",
  },
  {
    value: "3672",
    title: "3672 - Cathode ray television picture tubes",
    description: "Cathode ray television picture tubes",
  },
  {
    value: "3674",
    title: "3674 - Semiconductors and related devices",
    description: "Semiconductors and related devices",
  },
  {
    value: "3675",
    title: "3675 - Electronic capacitors",
    description: "Electronic capacitors",
  },
  {
    value: "3676",
    title: "3676 - Electronic resistors",
    description: "Electronic resistors",
  },
  {
    value: "3677",
    title: "3677 - Electronic coils and transformers",
    description: "Electronic coils and transformers",
  },
  {
    value: "3678",
    title: "3678 - Electronic connectors",
    description: "Electronic connectors",
  },
  {
    value: "3679",
    title: "3679 - Electronic components, n.e.c.",
    description: "Electronic components, n.e.c.",
  },
  {
    value: "3691",
    title: "3691 - Storage batteries",
    description: "Storage batteries",
  },
  {
    value: "3692",
    title: "3692 - Primary batteries, dry and wet",
    description: "Primary batteries, dry and wet",
  },
  {
    value: "3694",
    title: "3694 - Engine electrical equipment",
    description: "Engine electrical equipment",
  },
  {
    value: "3695",
    title: "3695 - Magnetic and optical recording media",
    description: "Magnetic and optical recording media",
  },
  {
    value: "3699",
    title: "3699 - Electrical equipment & supplies, n.e.c.",
    description: "Electrical equipment & supplies, n.e.c.",
  },
  {
    value: "3711",
    title: "3711 - Motor vehicles and car bodies",
    description: "Motor vehicles and car bodies",
  },
  {
    value: "3713",
    title: "3713 - Truck and bus bodies",
    description: "Truck and bus bodies",
  },
  {
    value: "3714",
    title: "3714 - Motor vehicle parts and accessories",
    description: "Motor vehicle parts and accessories",
  },
  {
    value: "3715",
    title: "3715 - Truck trailers",
    description: "Truck trailers",
  },
  { value: "3716", title: "3716 - Motor Homes", description: "Motor Homes" },
  { value: "3721", title: "3721 - Aircraft", description: "Aircraft" },
  {
    value: "3724",
    title: "3724 - Aircraft engines and engine parts",
    description: "Aircraft engines and engine parts",
  },
  {
    value: "3728",
    title: "3728 - Aircraft equipment, n.e.c.",
    description: "Aircraft equipment, n.e.c.",
  },
  {
    value: "3731",
    title: "3731 - Ship building and repairing",
    description: "Ship building and repairing",
  },
  {
    value: "3732",
    title: "3732 - Boat building and repairing",
    description: "Boat building and repairing",
  },
  {
    value: "3743",
    title: "3743 - Railroad equipment",
    description: "Railroad equipment",
  },
  {
    value: "3751",
    title: "3751 - Motorcycles, bicycles, and parts",
    description: "Motorcycles, bicycles, and parts",
  },
  {
    value: "3761",
    title: "3761 - Guided missiles and space vehicles",
    description: "Guided missiles and space vehicles",
  },
  {
    value: "3764",
    title: "3764 - Space propulsion units and parts",
    description: "Space propulsion units and parts",
  },
  {
    value: "3769",
    title: "3769 - Space vehicle equipment, n.e.c.",
    description: "Space vehicle equipment, n.e.c.",
  },
  {
    value: "3792",
    title: "3792 - Travel trailers and campers",
    description: "Travel trailers and campers",
  },
  {
    value: "3795",
    title: "3795 - Tanks and tank components",
    description: "Tanks and tank components",
  },
  {
    value: "3799",
    title: "3799 - Transportation equipment, n.e.c.",
    description: "Transportation equipment, n.e.c.",
  },
  {
    value: "3812",
    title: "3812 - Search and Navigation Equipment",
    description: "Search and Navigation Equipment",
  },
  {
    value: "3821",
    title: "3821 - Laboratory apparatus and furniture",
    description: "Laboratory apparatus and furniture",
  },
  {
    value: "3822",
    title: "3822 - Environmental controls",
    description: "Environmental controls",
  },
  {
    value: "3823",
    title: "3823 - Process control instruments",
    description: "Process control instruments",
  },
  {
    value: "3824",
    title: "3824 - Fluid meters and counting devices",
    description: "Fluid meters and counting devices",
  },
  {
    value: "3825",
    title: "3825 - Instruments to measure electricity",
    description: "Instruments to measure electricity",
  },
  {
    value: "3826",
    title: "3826 - Analytical instruments",
    description: "Analytical instruments",
  },
  {
    value: "3827",
    title: "3827 - Optical instruments and lenses",
    description: "Optical instruments and lenses",
  },
  {
    value: "3829",
    title: "3829 - Measuring & controlling devices, n.e.c.",
    description: "Measuring & controlling devices, n.e.c.",
  },
  {
    value: "3841",
    title: "3841 - Surgical and medical instruments",
    description: "Surgical and medical instruments",
  },
  {
    value: "3842",
    title: "3842 - Surgical appliances and supplies",
    description: "Surgical appliances and supplies",
  },
  {
    value: "3843",
    title: "3843 - Dental equipment and supplies",
    description: "Dental equipment and supplies",
  },
  {
    value: "3844",
    title: "3844 - X-ray apparatus and tubes",
    description: "X-ray apparatus and tubes",
  },
  {
    value: "3845",
    title: "3845 - Electromedical equipment",
    description: "Electromedical equipment",
  },
  {
    value: "3851",
    title: "3851 - Ophthalmic goods",
    description: "Ophthalmic goods",
  },
  {
    value: "3861",
    title: "3861 - Photographic equipment and supplies",
    description: "Photographic equipment and supplies",
  },
  {
    value: "3873",
    title: "3873 - Watches, clocks, and watchcases",
    description: "Watches, clocks, and watchcases",
  },
  {
    value: "3911",
    title: "3911 - Jewelry, precious metal",
    description: "Jewelry, precious metal",
  },
  {
    value: "3914",
    title: "3914 - Silverware and plated ware",
    description: "Silverware and plated ware",
  },
  {
    value: "3915",
    title: "3915 - Jewelers` materials & lapidary work",
    description: "Jewelers` materials & lapidary work",
  },
  {
    value: "3931",
    title: "3931 - Musical instruments",
    description: "Musical instruments",
  },
  { value: "3942", title: "3942 - Dolls", description: "Dolls" },
  {
    value: "3944",
    title: "3944 - Games, toys, and children`s vehicles",
    description: "Games, toys, and children`s vehicles",
  },
  {
    value: "3949",
    title: "3949 - Sporting and athletic goods, n.e.c.",
    description: "Sporting and athletic goods, n.e.c.",
  },
  {
    value: "3951",
    title: "3951 - Pens and mechanical pencils",
    description: "Pens and mechanical pencils",
  },
  {
    value: "3952",
    title: "3952 - Lead pencils and art goods",
    description: "Lead pencils and art goods",
  },
  {
    value: "3953",
    title: "3953 - Marking devices",
    description: "Marking devices",
  },
  {
    value: "3955",
    title: "3955 - Carbon paper and inked ribbons",
    description: "Carbon paper and inked ribbons",
  },
  {
    value: "3961",
    title: "3961 - Costume jewelry",
    description: "Costume jewelry",
  },
  {
    value: "3965",
    title: "3965 - Fasteners, buttons, needles, & pins",
    description: "Fasteners, buttons, needles, & pins",
  },
  {
    value: "3991",
    title: "3991 - Brooms and brushes",
    description: "Brooms and brushes",
  },
  {
    value: "3993",
    title: "3993 - Signs and advertising displays",
    description: "Signs and advertising displays",
  },
  {
    value: "3995",
    title: "3995 - Burial caskets",
    description: "Burial caskets",
  },
  {
    value: "3996",
    title: "3996 - Hard surface floor coverings",
    description: "Hard surface floor coverings",
  },
  {
    value: "3999",
    title: "3999 - Manufacturing industries, n.e.c.",
    description: "Manufacturing industries, n.e.c.",
  },
  {
    value: "4011",
    title: "4011 - Railroads, line-haul operating",
    description: "Railroads, line-haul operating",
  },
  {
    value: "4013",
    title: "4013 - Switching and terminal services",
    description: "Switching and terminal services",
  },
  {
    value: "4111",
    title: "4111 - Local and suburban transit",
    description: "Local and suburban transit",
  },
  {
    value: "4119",
    title: "4119 - Local passenger transportation, n.e.c.",
    description: "Local passenger transportation, n.e.c.",
  },
  { value: "4121", title: "4121 - Taxicabs", description: "Taxicabs" },
  {
    value: "4131",
    title: "4131 - Intercity highway transportation",
    description: "Intercity highway transportation",
  },
  {
    value: "4141",
    title: "4141 - Local bus charter service",
    description: "Local bus charter service",
  },
  {
    value: "4142",
    title: "4142 - Bus charter service, except local",
    description: "Bus charter service, except local",
  },
  { value: "4151", title: "4151 - School buses", description: "School buses" },
  {
    value: "4173",
    title: "4173 - Bus service facilities",
    description: "Bus service facilities",
  },
  {
    value: "4212",
    title: "4212 - Local trucking, without storage",
    description: "Local trucking, without storage",
  },
  {
    value: "4213",
    title: "4213 - Trucking, except local",
    description: "Trucking, except local",
  },
  {
    value: "4214",
    title: "4214 - Local trucking and storage",
    description: "Local trucking and storage",
  },
  {
    value: "4215",
    title: "4215 - Courier services, except by air",
    description: "Courier services, except by air",
  },
  {
    value: "4221",
    title: "4221 - Farm product warehousing and storage",
    description: "Farm product warehousing and storage",
  },
  {
    value: "4222",
    title: "4222 - Refrigerated warehousing",
    description: "Refrigerated warehousing",
  },
  {
    value: "4224",
    title: "4224 - Household goods warehousing",
    description: "Household goods warehousing",
  },
  {
    value: "4225",
    title: "4225 - General warehousing and storage",
    description: "General warehousing and storage",
  },
  {
    value: "4226",
    title: "4226 - Special warehousing and storage, n.e.c.",
    description: "Special warehousing and storage, n.e.c.",
  },
  {
    value: "4231",
    title: "4231 - Trucking terminal facilities",
    description: "Trucking terminal facilities",
  },
  {
    value: "4311",
    title: "4311 - U.S. postal service",
    description: "U.S. postal service",
  },
  {
    value: "4412",
    title: "4412 - Deep sea foreign transportation",
    description: "Deep sea foreign transportation",
  },
  {
    value: "4424",
    title: "4424 - Deep sea domestic transportation",
    description: "Deep sea domestic transportation",
  },
  {
    value: "4432",
    title: "4432 - Great lakes transportation",
    description: "Great lakes transportation",
  },
  {
    value: "4449",
    title: "4449 - Water Transportation of Freight, n.e.c.",
    description: "Water Transportation of Freight, n.e.c.",
  },
  {
    value: "4481",
    title: "4481 - Deep sea passenger trans., except ferry",
    description: "Deep sea passenger trans., except ferry",
  },
  { value: "4482", title: "4482 - Ferries", description: "Ferries" },
  {
    value: "4489",
    title: "4489 - Water passenger transportation, n.e.c.",
    description: "Water passenger transportation, n.e.c.",
  },
  {
    value: "4491",
    title: "4491 - Marine cargo handling",
    description: "Marine cargo handling",
  },
  {
    value: "4492",
    title: "4492 - Towing and tugboat service",
    description: "Towing and tugboat service",
  },
  { value: "4493", title: "4493 - Marinas", description: "Marinas" },
  {
    value: "4499",
    title: "4499 - Water transportation services, n.e.c.",
    description: "Water transportation services, n.e.c.",
  },
  {
    value: "4512",
    title: "4512 - Air transportation, scheduled",
    description: "Air transportation, scheduled",
  },
  {
    value: "4513",
    title: "4513 - Air Courier Services",
    description: "Air Courier Services",
  },
  {
    value: "4522",
    title: "4522 - Air transportation, nonscheduled",
    description: "Air transportation, nonscheduled",
  },
  {
    value: "4581",
    title: "4581 - Airports Flying Fields & Airport Terminal Services",
    description: "Airports Flying Fields & Airport Terminal Services",
  },
  {
    value: "4612",
    title: "4612 - Crude petroleum pipe lines",
    description: "Crude petroleum pipe lines",
  },
  {
    value: "4613",
    title: "4613 - Refined petroleum pipe lines",
    description: "Refined petroleum pipe lines",
  },
  {
    value: "4619",
    title: "4619 - Pipe lines, n.e.c.",
    description: "Pipe lines, n.e.c.",
  },
  {
    value: "4724",
    title: "4724 - Travel agencies",
    description: "Travel agencies",
  },
  {
    value: "4725",
    title: "4725 - Tour operators",
    description: "Tour operators",
  },
  {
    value: "4729",
    title: "4729 - Passenger transport arrangement, n.e.c.",
    description: "Passenger transport arrangement, n.e.c.",
  },
  {
    value: "4731",
    title: "4731 - Freight transportation arrangement",
    description: "Freight transportation arrangement",
  },
  {
    value: "4741",
    title: "4741 - Railroad car rental",
    description: "Railroad car rental",
  },
  {
    value: "4783",
    title: "4783 - Packing and crating",
    description: "Packing and crating",
  },
  {
    value: "4785",
    title:
      "4785 - Fixed facilities, Inspection and weighing services for vehicles",
    description:
      "Fixed facilities, Inspection and weighing services for vehicles",
  },
  {
    value: "4789",
    title: "4789 - Transportation services, n.e.c.",
    description: "Transportation services, n.e.c.",
  },
  {
    value: "4812",
    title: "4812 - Radiotelephone communications",
    description: "Radiotelephone communications",
  },
  {
    value: "4813",
    title: "4813 - Telephone communications, exc. radio",
    description: "Telephone communications, exc. radio",
  },
  {
    value: "4822",
    title: "4822 - Telegraph and other communication",
    description: "Telegraph and other communication",
  },
  {
    value: "4832",
    title: "4832 - Radio broadcasting",
    description: "Radio broadcasting",
  },
  {
    value: "4833",
    title: "4833 - Television broadcasting",
    description: "Television broadcasting",
  },
  {
    value: "4841",
    title: "4841 - Cable and other pay TV services",
    description: "Cable and other pay TV services",
  },
  {
    value: "4899",
    title: "4899 - Communication services, n.e.c.",
    description: "Communication services, n.e.c.",
  },
  {
    value: "4911",
    title: "4911 - Electric services",
    description: "Electric services",
  },
  {
    value: "4922",
    title: "4922 - Natural gas transmission",
    description: "Natural gas transmission",
  },
  {
    value: "4923",
    title: "4923 - Gas transmission and distribution",
    description: "Gas transmission and distribution",
  },
  {
    value: "4924",
    title: "4924 - Natural gas distribution",
    description: "Natural gas distribution",
  },
  {
    value: "4925",
    title: "4925 - Gas production and/or distribution",
    description: "Gas production and/or distribution",
  },
  {
    value: "4931",
    title: "4931 - Electric and other services combined",
    description: "Electric and other services combined",
  },
  {
    value: "4932",
    title: "4932 - Gas and other services combined",
    description: "Gas and other services combined",
  },
  {
    value: "4939",
    title: "4939 - Combination utilities, n.e.c.",
    description: "Combination utilities, n.e.c.",
  },
  { value: "4941", title: "4941 - Water supply", description: "Water supply" },
  {
    value: "4952",
    title: "4952 - Sewerage systems",
    description: "Sewerage systems",
  },
  {
    value: "4953",
    title: "4953 - Refuse systems",
    description: "Refuse systems",
  },
  {
    value: "4959",
    title: "4959 - Sanitary services, n.e.c.",
    description: "Sanitary services, n.e.c.",
  },
  { value: "4961", title: "4961 - Steam supply", description: "Steam supply" },
  {
    value: "4971",
    title: "4971 - Irrigation systems",
    description: "Irrigation systems",
  },
  {
    value: "5012",
    title: "5012 - Automobiles and other motor vehicles",
    description: "Automobiles and other motor vehicles",
  },
  {
    value: "5013",
    title: "5013 - Automotive parts and supplies",
    description: "Automotive parts and supplies",
  },
  {
    value: "5014",
    title: "5014 - Tires and tubes",
    description: "Tires and tubes",
  },
  {
    value: "5015",
    title: "5015 - Motor vehicle parts, used",
    description: "Motor vehicle parts, used",
  },
  { value: "5021", title: "5021 - Furniture", description: "Furniture" },
  {
    value: "5023",
    title: "5023 - Home furnishings",
    description: "Home furnishings",
  },
  {
    value: "5031",
    title: "5031 - Lumber, plywood and millwork",
    description: "Lumber, plywood and millwork",
  },
  {
    value: "5032",
    title: "5032 - Brick, stone, & related materials",
    description: "Brick, stone, & related materials",
  },
  {
    value: "5033",
    title: "5033 - Roofing, siding, & insulation",
    description: "Roofing, siding, & insulation",
  },
  {
    value: "5039",
    title: "5039 - Construction materials, n.e.c.",
    description: "Construction materials, n.e.c.",
  },
  {
    value: "5043",
    title: "5043 - Photographic equipment and supplies",
    description: "Photographic equipment and supplies",
  },
  {
    value: "5044",
    title: "5044 - Office equipment",
    description: "Office equipment",
  },
  {
    value: "5045",
    title: "5045 - Computers, peripherals & software",
    description: "Computers, peripherals & software",
  },
  {
    value: "5046",
    title: "5046 - Commercial equipment, n.e.c.",
    description: "Commercial equipment, n.e.c.",
  },
  {
    value: "5047",
    title: "5047 - Medical and hospital equipment",
    description: "Medical and hospital equipment",
  },
  {
    value: "5048",
    title: "5048 - Ophthalmic goods",
    description: "Ophthalmic goods",
  },
  {
    value: "5049",
    title: "5049 - Professional equipment, n.e.c.",
    description: "Professional equipment, n.e.c.",
  },
  {
    value: "5051",
    title: "5051 - Metals service centers and offices",
    description: "Metals service centers and offices",
  },
  {
    value: "5052",
    title: "5052 - Coal and other minerals and ores",
    description: "Coal and other minerals and ores",
  },
  {
    value: "5063",
    title: "5063 - Electrical apparatus and equipment",
    description: "Electrical apparatus and equipment",
  },
  {
    value: "5064",
    title: "5064 - Electrical appliances, TV & radios",
    description: "Electrical appliances, TV & radios",
  },
  {
    value: "5065",
    title: "5065 - Electronic parts and equipment",
    description: "Electronic parts and equipment",
  },
  { value: "5072", title: "5072 - Hardware", description: "Hardware" },
  {
    value: "5074",
    title: "5074 - Plumbing & hydronic heating supplies",
    description: "Plumbing & hydronic heating supplies",
  },
  {
    value: "5075",
    title: "5075 - Warm air heating & air conditioning",
    description: "Warm air heating & air conditioning",
  },
  {
    value: "5078",
    title: "5078 - Refrigeration equipment and supplies",
    description: "Refrigeration equipment and supplies",
  },
  {
    value: "5082",
    title: "5082 - Construction and mining machinery",
    description: "Construction and mining machinery",
  },
  {
    value: "5083",
    title: "5083 - Farm and garden machinery",
    description: "Farm and garden machinery",
  },
  {
    value: "5084",
    title: "5084 - Industrial machinery and equipment",
    description: "Industrial machinery and equipment",
  },
  {
    value: "5085",
    title: "5085 - Industrial supplies",
    description: "Industrial supplies",
  },
  {
    value: "5087",
    title: "5087 - Service establishment equipment",
    description: "Service establishment equipment",
  },
  {
    value: "5088",
    title: "5088 - Transportation equipment & supplies",
    description: "Transportation equipment & supplies",
  },
  {
    value: "5091",
    title: "5091 - Sporting & recreational goods",
    description: "Sporting & recreational goods",
  },
  {
    value: "5092",
    title: "5092 - Toys and hobby goods and supplies",
    description: "Toys and hobby goods and supplies",
  },
  {
    value: "5093",
    title: "5093 - Scrap and waste materials",
    description: "Scrap and waste materials",
  },
  {
    value: "5094",
    title: "5094 - Jewelry & precious stones",
    description: "Jewelry & precious stones",
  },
  {
    value: "5099",
    title: "5099 - Durable goods, n.e.c.",
    description: "Durable goods, n.e.c.",
  },
  {
    value: "5111",
    title: "5111 - Printing and writing paper",
    description: "Printing and writing paper",
  },
  {
    value: "5112",
    title: "5112 - Stationery and office supplies",
    description: "Stationery and office supplies",
  },
  {
    value: "5113",
    title: "5113 - Industrial & personal service paper",
    description: "Industrial & personal service paper",
  },
  {
    value: "5122",
    title: "5122 - Drugs, proprietaries, and sundries",
    description: "Drugs, proprietaries, and sundries",
  },
  {
    value: "5131",
    title: "5131 - Piece goods & notions",
    description: "Piece goods & notions",
  },
  {
    value: "5136",
    title: "5136 - Men`s and boys` clothing",
    description: "Men`s and boys` clothing",
  },
  {
    value: "5137",
    title: "5137 - Women`s and children`s clothing",
    description: "Women`s and children`s clothing",
  },
  { value: "5139", title: "5139 - Footwear", description: "Footwear" },
  {
    value: "5141",
    title: "5141 - Groceries, general line",
    description: "Groceries, general line",
  },
  { value: "5142", title: "5142 - Frozen foods", description: "Frozen foods" },
  {
    value: "5143",
    title: "5143 - Dairy products",
    description: "Dairy products",
  },
  {
    value: "5144",
    title: "5144 - Poultry and poultry products",
    description: "Poultry and poultry products",
  },
  {
    value: "5145",
    title: "5145 - Confectionery",
    description: "Confectionery",
  },
  {
    value: "5146",
    title: "5146 - Fish and seafoods",
    description: "Fish and seafoods",
  },
  {
    value: "5147",
    title: "5147 - Meats and meat products",
    description: "Meats and meat products",
  },
  {
    value: "5148",
    title: "5148 - Fresh fruits and vegatables",
    description: "Fresh fruits and vegatables",
  },
  {
    value: "5149",
    title: "5149 - Groceries and related products, n.e.c.",
    description: "Groceries and related products, n.e.c.",
  },
  { value: "5153", title: "5153 - Grain", description: "Grain" },
  { value: "5154", title: "5154 - Livestock", description: "Livestock" },
  {
    value: "5159",
    title: "5159 - Farm-product raw materials, n.e.c.",
    description: "Farm-product raw materials, n.e.c.",
  },
  {
    value: "5162",
    title: "5162 - Plastics materials & basic shapes",
    description: "Plastics materials & basic shapes",
  },
  {
    value: "5169",
    title: "5169 - Chemicals & allied products, n.e.c.",
    description: "Chemicals & allied products, n.e.c.",
  },
  {
    value: "5171",
    title: "5171 - Petroleum bulk stations & terminals",
    description: "Petroleum bulk stations & terminals",
  },
  {
    value: "5172",
    title: "5172 - Petroleum products, n.e.c.",
    description: "Petroleum products, n.e.c.",
  },
  { value: "5181", title: "5181 - Beer and ale", description: "Beer and ale" },
  {
    value: "5182",
    title: "5182 - Wine and distilled beverages",
    description: "Wine and distilled beverages",
  },
  {
    value: "5191",
    title: "5191 - Farm supplies",
    description: "Farm supplies",
  },
  {
    value: "5192",
    title: "5192 - Books, periodicals, & newspapers",
    description: "Books, periodicals, & newspapers",
  },
  {
    value: "5193",
    title: "5193 - Flowers & florists` supplies",
    description: "Flowers & florists` supplies",
  },
  {
    value: "5194",
    title: "5194 - Tobacco and tobacco products",
    description: "Tobacco and tobacco products",
  },
  {
    value: "5198",
    title: "5198 - Paints, varnishes, and supplies",
    description: "Paints, varnishes, and supplies",
  },
  {
    value: "5199",
    title: "5199 - Nondurable goods, n.e.c.",
    description: "Nondurable goods, n.e.c.",
  },
  {
    value: "5211",
    title: "5211 - Lumber and other building materials",
    description: "Lumber and other building materials",
  },
  {
    value: "5231",
    title: "5231 - Paint, glass, and wallpaper stores",
    description: "Paint, glass, and wallpaper stores",
  },
  {
    value: "5251",
    title: "5251 - Hardware stores",
    description: "Hardware stores",
  },
  {
    value: "5261",
    title: "5261 - Retail nurseries and garden stores",
    description: "Retail nurseries and garden stores",
  },
  {
    value: "5271",
    title: "5271 - Mobile home dealers",
    description: "Mobile home dealers",
  },
  {
    value: "5311",
    title: "5311 - Department stores",
    description: "Department stores",
  },
  {
    value: "5331",
    title: "5331 - Variety stores",
    description: "Variety stores",
  },
  {
    value: "5399",
    title: "5399 - Misc. general merchandise stores",
    description: "Misc. general merchandise stores",
  },
  {
    value: "5411",
    title: "5411 - Grocery stores",
    description: "Grocery stores",
  },
  {
    value: "5421",
    title: "5421 - Meat and fish markets",
    description: "Meat and fish markets",
  },
  {
    value: "5431",
    title: "5431 - Fruit stores and vegetable markets",
    description: "Fruit stores and vegetable markets",
  },
  {
    value: "5441",
    title: "5441 - Candy, nut, and confectionery stores",
    description: "Candy, nut, and confectionery stores",
  },
  {
    value: "5451",
    title: "5451 - Dairy products stores",
    description: "Dairy products stores",
  },
  {
    value: "5461",
    title: "5461 - Retail bakeries-baking and selling",
    description: "Retail bakeries-baking and selling",
  },
  {
    value: "5499",
    title: "5499 - Miscellaneous food stores",
    description: "Miscellaneous food stores",
  },
  {
    value: "5511",
    title: "5511 - New and used car dealers",
    description: "New and used car dealers",
  },
  {
    value: "5521",
    title: "5521 - Used car dealers",
    description: "Used car dealers",
  },
  {
    value: "5531",
    title: "5531 - Auto and home supply stores",
    description: "Auto and home supply stores",
  },
  {
    value: "5541",
    title: "5541 - Gasoline service stations",
    description: "Gasoline service stations",
  },
  { value: "5551", title: "5551 - Boat dealers", description: "Boat dealers" },
  {
    value: "5561",
    title: "5561 - Recreation & utility trailer dealers",
    description: "Recreation & utility trailer dealers",
  },
  {
    value: "5571",
    title: "5571 - Motorcycle dealers",
    description: "Motorcycle dealers",
  },
  {
    value: "5599",
    title: "5599 - Automotive dealers, n.e.c.",
    description: "Automotive dealers, n.e.c.",
  },
  {
    value: "5611",
    title: "5611 - Men`s & boys` clothing & furnishings",
    description: "Men`s & boys` clothing & furnishings",
  },
  {
    value: "5621",
    title: "5621 - Women`s ready-to-wear stores",
    description: "Women`s ready-to-wear stores",
  },
  {
    value: "5632",
    title: "5632 - Women`s accessory & specialty stores",
    description: "Women`s accessory & specialty stores",
  },
  {
    value: "5641",
    title: "5641 - Childrens and infants wear stores",
    description: "Childrens and infants wear stores",
  },
  {
    value: "5651",
    title: "5651 - Family clothing stores",
    description: "Family clothing stores",
  },
  { value: "5661", title: "5661 - Shoe stores", description: "Shoe stores" },
  {
    value: "5699",
    title: "5699 - Miscellaneous apparel & accessories",
    description: "Miscellaneous apparel & accessories",
  },
  {
    value: "5712",
    title: "5712 - Furniture stores",
    description: "Furniture stores",
  },
  {
    value: "5713",
    title: "5713 - Floor covering stores",
    description: "Floor covering stores",
  },
  {
    value: "5714",
    title: "5714 - Drapery and upholstery stores",
    description: "Drapery and upholstery stores",
  },
  {
    value: "5719",
    title: "5719 - Misc. home furnishings stores",
    description: "Misc. home furnishings stores",
  },
  {
    value: "5722",
    title: "5722 - Household appliance stores",
    description: "Household appliance stores",
  },
  {
    value: "5731",
    title: "5731 - Radio, TV, & electronic stores",
    description: "Radio, TV, & electronic stores",
  },
  {
    value: "5734",
    title: "5734 - Computer and software stores",
    description: "Computer and software stores",
  },
  {
    value: "5735",
    title: "5735 - Record & prerecorded tape stores",
    description: "Record & prerecorded tape stores",
  },
  {
    value: "5736",
    title: "5736 - Musical instrument stores",
    description: "Musical instrument stores",
  },
  {
    value: "5812",
    title: "5812 - Eating places",
    description: "Eating places",
  },
  {
    value: "5813",
    title: "5813 - Drinking places",
    description: "Drinking places",
  },
  {
    value: "5912",
    title: "5912 - Drug stores and proprietary stores",
    description: "Drug stores and proprietary stores",
  },
  {
    value: "5921",
    title: "5921 - Liquor stores",
    description: "Liquor stores",
  },
  {
    value: "5932",
    title: "5932 - Used merchandise stores",
    description: "Used merchandise stores",
  },
  {
    value: "5941",
    title: "5941 - Sporting goods and bicycle shops",
    description: "Sporting goods and bicycle shops",
  },
  { value: "5942", title: "5942 - Book stores", description: "Book stores" },
  {
    value: "5943",
    title: "5943 - Stationery stores",
    description: "Stationery stores",
  },
  {
    value: "5944",
    title: "5944 - Jewelry stores",
    description: "Jewelry stores",
  },
  {
    value: "5945",
    title: "5945 - Hobby, toy, and game shops",
    description: "Hobby, toy, and game shops",
  },
  {
    value: "5946",
    title: "5946 - Camera & photographic supply stores",
    description: "Camera & photographic supply stores",
  },
  {
    value: "5947",
    title: "5947 - Gift, novelty, and souvenir shops",
    description: "Gift, novelty, and souvenir shops",
  },
  {
    value: "5948",
    title: "5948 - Luggage and leather goods stores",
    description: "Luggage and leather goods stores",
  },
  {
    value: "5949",
    title: "5949 - Sewing, needlework, and piece goods",
    description: "Sewing, needlework, and piece goods",
  },
  {
    value: "5961",
    title: "5961 - Catalog and mail-order houses",
    description: "Catalog and mail-order houses",
  },
  {
    value: "5962",
    title: "5962 - Merchandising machine operators",
    description: "Merchandising machine operators",
  },
  {
    value: "5963",
    title: "5963 - Direct selling establishments",
    description: "Direct selling establishments",
  },
  {
    value: "5983",
    title: "5983 - Fuel oil dealers",
    description: "Fuel oil dealers",
  },
  {
    value: "5984",
    title: "5984 - Liquefied petroleum gas dealers",
    description: "Liquefied petroleum gas dealers",
  },
  {
    value: "5989",
    title: "5989 - Fuel dealers, n.e.c.",
    description: "Fuel dealers, n.e.c.",
  },
  { value: "5992", title: "5992 - Florists", description: "Florists" },
  {
    value: "5993",
    title: "5993 - Cigar stores and stands",
    description: "Cigar stores and stands",
  },
  {
    value: "5994",
    title: "5994 - News dealers and newsstands",
    description: "News dealers and newsstands",
  },
  {
    value: "5995",
    title: "5995 - Optical goods stores",
    description: "Optical goods stores",
  },
  {
    value: "5999",
    title: "5999 - Miscellaneous retail stores, n.e.c.",
    description: "Miscellaneous retail stores, n.e.c.",
  },
  {
    value: "6011",
    title: "6011 - Federal reserve banks",
    description: "Federal reserve banks",
  },
  {
    value: "6019",
    title: "6019 - Central reserve depository, n.e.c.",
    description: "Central reserve depository, n.e.c.",
  },
  {
    value: "6021",
    title: "6021 - National banks, commercial",
    description: "National banks, commercial",
  },
  {
    value: "6022",
    title: "6022 - State banks, federal reserve",
    description: "State banks, federal reserve",
  },
  {
    value: "6029",
    title: "6029 - Commercial banks, n.e.c.",
    description: "Commercial banks, n.e.c.",
  },
  {
    value: "6035",
    title: "6035 - Savings and loan associations, federally chartered",
    description: "Savings and loan associations, federally chartered",
  },
  {
    value: "6036",
    title: "6036 - Banks, savings: not federally chartered",
    description: "Banks, savings: not federally chartered",
  },
  {
    value: "6061",
    title: "6061 - Credit unions Federally Chartered",
    description: "Credit unions Federally Chartered",
  },
  {
    value: "6062",
    title: "6062 - Credit unions Not Federally Chartered",
    description: "Credit unions Not Federally Chartered",
  },
  {
    value: "6081",
    title: "6081 - Foreign bank & branches & agencies",
    description: "Foreign bank & branches & agencies",
  },
  {
    value: "6082",
    title: "6082 - Foreign Trade & International Banking Institutions",
    description: "Foreign Trade & International Banking Institutions",
  },
  {
    value: "6091",
    title: "6091 - Non-Deposit Trust Facilities",
    description: "Non-Deposit Trust Facilities",
  },
  {
    value: "6099",
    title: "6099 - Functions closely related to banking",
    description: "Functions closely related to banking",
  },
  {
    value: "6111",
    title: "6111 - Federal & fed.-sponsored credit",
    description: "Federal & fed.-sponsored credit",
  },
  {
    value: "6141",
    title: "6141 - Personal credit institutions",
    description: "Personal credit institutions",
  },
  {
    value: "6153",
    title: "6153 - Short-term business credit",
    description: "Short-term business credit",
  },
  {
    value: "6159",
    title: "6159 - Misc. business credit institutions",
    description: "Misc. business credit institutions",
  },
  {
    value: "6162",
    title: "6162 - Mortgage bankers and correspondents",
    description: "Mortgage bankers and correspondents",
  },
  { value: "6163", title: "6163 - Loan brokers", description: "Loan brokers" },
  {
    value: "6211",
    title: "6211 - Security brokers and dealers",
    description: "Security brokers and dealers",
  },
  {
    value: "6221",
    title: "6221 - Commodity contracts brokers, dealers",
    description: "Commodity contracts brokers, dealers",
  },
  {
    value: "6231",
    title: "6231 - Security and commodity exchanges",
    description: "Security and commodity exchanges",
  },
  {
    value: "6282",
    title: "6282 - Investment advisory service",
    description: "Investment advisory service",
  },
  {
    value: "6289",
    title: "6289 - Security and commodity services, n.e.c.",
    description: "Security and commodity services, n.e.c.",
  },
  {
    value: "6311",
    title: "6311 - Life insurance",
    description: "Life insurance",
  },
  {
    value: "6321",
    title: "6321 - Accident and health insurance",
    description: "Accident and health insurance",
  },
  {
    value: "6324",
    title: "6324 - Hospital and medical service plans",
    description: "Hospital and medical service plans",
  },
  {
    value: "6331",
    title: "6331 - Fire, marine, and casualty insurance",
    description: "Fire, marine, and casualty insurance",
  },
  {
    value: "6351",
    title: "6351 - Surety insurance",
    description: "Surety insurance",
  },
  {
    value: "6361",
    title: "6361 - Title insurance",
    description: "Title insurance",
  },
  {
    value: "6371",
    title: "6371 - Pension, health, and welfare funds",
    description: "Pension, health, and welfare funds",
  },
  {
    value: "6399",
    title: "6399 - Insurance carriers, n.e.c.",
    description: "Insurance carriers, n.e.c.",
  },
  {
    value: "6411",
    title: "6411 - Insurance agents, brokers, & service",
    description: "Insurance agents, brokers, & service",
  },
  {
    value: "6512",
    title: "6512 - Nonresidential building operators",
    description: "Nonresidential building operators",
  },
  {
    value: "6513",
    title: "6513 - Apartment building operators",
    description: "Apartment building operators",
  },
  {
    value: "6514",
    title: "6514 - Dwelling operators, exc. apartments",
    description: "Dwelling operators, exc. apartments",
  },
  {
    value: "6515",
    title: "6515 - Mobile home site operators",
    description: "Mobile home site operators",
  },
  {
    value: "6517",
    title: "6517 - Railroad property lessors",
    description: "Railroad property lessors",
  },
  {
    value: "6519",
    title: "6519 - Real property lessors, n.e.c.",
    description: "Real property lessors, n.e.c.",
  },
  {
    value: "6531",
    title: "6531 - Real estate agents and managers",
    description: "Real estate agents and managers",
  },
  {
    value: "6541",
    title: "6541 - Title abstract offices",
    description: "Title abstract offices",
  },
  {
    value: "6552",
    title: "6552 - Subdividers and developers, n.e.c.",
    description: "Subdividers and developers, n.e.c.",
  },
  {
    value: "6553",
    title: "6553 - Cemetery subdividers and developers",
    description: "Cemetery subdividers and developers",
  },
  {
    value: "6712",
    title: "6712 - Bank holding companies",
    description: "Bank holding companies",
  },
  {
    value: "6719",
    title: "6719 - Holding offices, n.e.c.",
    description: "Holding offices, n.e.c.",
  },
  {
    value: "6722",
    title: "6722 - Management investment, open-end",
    description: "Management investment, open-end",
  },
  {
    value: "6726",
    title: "6726 - Unit investment trusts",
    description: "Unit investment trusts",
  },
  {
    value: "6732",
    title: "6732 - Educational, religious, etc. trusts",
    description: "Educational, religious, etc. trusts",
  },
  {
    value: "6733",
    title: "6733 - Trusts, n.e.c.",
    description: "Trusts, n.e.c.",
  },
  {
    value: "6792",
    title: "6792 - Oil royalty traders",
    description: "Oil royalty traders",
  },
  {
    value: "6794",
    title: "6794 - Patent owners and lessors",
    description: "Patent owners and lessors",
  },
  {
    value: "6798",
    title: "6798 - Real Estate Investment Trusts",
    description: "Real Estate Investment Trusts",
  },
  {
    value: "6799",
    title: "6799 - Investors, n.e.c.",
    description: "Investors, n.e.c.",
  },
  {
    value: "7011",
    title: "7011 - Hotels, motels, and tourist courts",
    description: "Hotels, motels, and tourist courts",
  },
  {
    value: "7021",
    title: "7021 - Rooming and boarding houses",
    description: "Rooming and boarding houses",
  },
  {
    value: "7032",
    title: "7032 - Sporting and recreational camps",
    description: "Sporting and recreational camps",
  },
  {
    value: "7033",
    title: "7033 - Trailer parks and campsites",
    description: "Trailer parks and campsites",
  },
  {
    value: "7041",
    title: "7041 - Membership-basis organization hotels",
    description: "Membership-basis organization hotels",
  },
  {
    value: "7211",
    title: "7211 - Power laundries, family & commercial",
    description: "Power laundries, family & commercial",
  },
  {
    value: "7212",
    title: "7212 - Garment pressing & cleaners agents",
    description: "Garment pressing & cleaners agents",
  },
  { value: "7213", title: "7213 - Linen supply", description: "Linen supply" },
  {
    value: "7215",
    title: "7215 - Coin-operated laundries and cleaning",
    description: "Coin-operated laundries and cleaning",
  },
  {
    value: "7216",
    title: "7216 - Dry cleaning plants, except rug",
    description: "Dry cleaning plants, except rug",
  },
  {
    value: "7217",
    title: "7217 - Carpet and upholstery cleaning",
    description: "Carpet and upholstery cleaning",
  },
  {
    value: "7218",
    title: "7218 - Industrial launderers",
    description: "Industrial launderers",
  },
  {
    value: "7219",
    title: "7219 - Laundry and garment services, n.e.c.",
    description: "Laundry and garment services, n.e.c.",
  },
  {
    value: "7221",
    title: "7221 - Photographic studios, portrait",
    description: "Photographic studios, portrait",
  },
  { value: "7231", title: "7231 - Beauty shops", description: "Beauty shops" },
  { value: "7241", title: "7241 - Barber shops", description: "Barber shops" },
  {
    value: "7251",
    title: "7251 - Shoe repair and hat cleaning shops",
    description: "Shoe repair and hat cleaning shops",
  },
  {
    value: "7261",
    title: "7261 - Funeral service and crematories",
    description: "Funeral service and crematories",
  },
  {
    value: "7291",
    title: "7291 - Tax return preparation services",
    description: "Tax return preparation services",
  },
  {
    value: "7299",
    title: "7299 - Miscellaneous personal services, n.e.c.",
    description: "Miscellaneous personal services, n.e.c.",
  },
  {
    value: "7311",
    title: "7311 - Advertising agencies",
    description: "Advertising agencies",
  },
  {
    value: "7312",
    title: "7312 - Outdoor advertising services",
    description: "Outdoor advertising services",
  },
  {
    value: "7313",
    title: "7313 - Radio, TV, publisher representatives",
    description: "Radio, TV, publisher representatives",
  },
  {
    value: "7319",
    title: "7319 - Advertising, n.e.c.",
    description: "Advertising, n.e.c.",
  },
  {
    value: "7322",
    title: "7322 - Adjustment & collection services",
    description: "Adjustment & collection services",
  },
  {
    value: "7323",
    title: "7323 - Credit reporting services",
    description: "Credit reporting services",
  },
  {
    value: "7331",
    title: "7331 - Direct mail advertising services",
    description: "Direct mail advertising services",
  },
  {
    value: "7334",
    title: "7334 - Photocopying & duplicating services",
    description: "Photocopying & duplicating services",
  },
  {
    value: "7335",
    title: "7335 - Commercial photography",
    description: "Commercial photography",
  },
  {
    value: "7336",
    title: "7336 - Commercial art and graphic design",
    description: "Commercial art and graphic design",
  },
  {
    value: "7338",
    title: "7338 - Secretarial & court reporting",
    description: "Secretarial & court reporting",
  },
  {
    value: "7342",
    title: "7342 - Disinfecting & pest control services",
    description: "Disinfecting & pest control services",
  },
  {
    value: "7349",
    title: "7349 - Building maintenance services, n.e.c.",
    description: "Building maintenance services, n.e.c.",
  },
  {
    value: "7352",
    title: "7352 - Medical equipment rental",
    description: "Medical equipment rental",
  },
  {
    value: "7353",
    title: "7353 - Heavy construction equipment rental",
    description: "Heavy construction equipment rental",
  },
  {
    value: "7359",
    title: "7359 - Equipment rental & leasing, n.e.c.",
    description: "Equipment rental & leasing, n.e.c.",
  },
  {
    value: "7361",
    title: "7361 - Employment agencies",
    description: "Employment agencies",
  },
  {
    value: "7363",
    title: "7363 - Help supply services",
    description: "Help supply services",
  },
  {
    value: "7371",
    title: "7371 - Computer programming services",
    description: "Computer programming services",
  },
  {
    value: "7372",
    title: "7372 - Prepackaged software",
    description: "Prepackaged software",
  },
  {
    value: "7373",
    title: "7373 - Computer integrated systems design",
    description: "Computer integrated systems design",
  },
  {
    value: "7374",
    title: "7374 - Data processing and preparation",
    description: "Data processing and preparation",
  },
  {
    value: "7375",
    title: "7375 - Information retrieval services",
    description: "Information retrieval services",
  },
  {
    value: "7376",
    title: "7376 - Computer facilities management",
    description: "Computer facilities management",
  },
  {
    value: "7377",
    title: "7377 - Computer rental & leasing",
    description: "Computer rental & leasing",
  },
  {
    value: "7378",
    title: "7378 - Computer maintenance & repair",
    description: "Computer maintenance & repair",
  },
  {
    value: "7379",
    title: "7379 - Computer related services, n.e.c.",
    description: "Computer related services, n.e.c.",
  },
  {
    value: "7381",
    title: "7381 - Detective & armored car services",
    description: "Detective & armored car services",
  },
  {
    value: "7382",
    title: "7382 - Security systems services",
    description: "Security systems services",
  },
  {
    value: "7383",
    title: "7383 - News syndicates",
    description: "News syndicates",
  },
  {
    value: "7384",
    title: "7384 - Photofinishing laboratories",
    description: "Photofinishing laboratories",
  },
  {
    value: "7389",
    title: "7389 - Business services, n.e.c.",
    description: "Business services, n.e.c.",
  },
  {
    value: "7513",
    title: "7513 - Truck rental and leasing",
    description: "Truck rental and leasing",
  },
  {
    value: "7514",
    title: "7514 - Passenger car rental",
    description: "Passenger car rental",
  },
  {
    value: "7515",
    title: "7515 - Passenger car leasing",
    description: "Passenger car leasing",
  },
  {
    value: "7519",
    title: "7519 - Utility trailer rental",
    description: "Utility trailer rental",
  },
  {
    value: "7521",
    title: "7521 - Garages, automobile parking",
    description: "Garages, automobile parking",
  },
  {
    value: "7532",
    title: "7532 - Top & body repair & paint shops",
    description: "Top & body repair & paint shops",
  },
  {
    value: "7533",
    title: "7533 - Auto exhaust system repair shops",
    description: "Auto exhaust system repair shops",
  },
  {
    value: "7534",
    title: "7534 - Tire retreading and repair shops",
    description: "Tire retreading and repair shops",
  },
  {
    value: "7536",
    title: "7536 - Automotive glass replacement shops",
    description: "Automotive glass replacement shops",
  },
  {
    value: "7537",
    title: "7537 - Automotive transmission repair shops",
    description: "Automotive transmission repair shops",
  },
  {
    value: "7538",
    title: "7538 - General automotive repair shops",
    description: "General automotive repair shops",
  },
  {
    value: "7539",
    title: "7539 - Automotive repair shops, n.e.c.",
    description: "Automotive repair shops, n.e.c.",
  },
  { value: "7542", title: "7542 - Car washes", description: "Car washes" },
  {
    value: "7549",
    title: "7549 - Automotive services, n.e.c.",
    description: "Automotive services, n.e.c.",
  },
  {
    value: "7622",
    title: "7622 - Radio and television repair",
    description: "Radio and television repair",
  },
  {
    value: "7623",
    title: "7623 - Refrigeration service and repair",
    description: "Refrigeration service and repair",
  },
  {
    value: "7629",
    title: "7629 - Electrical repair shops, n.e.c.",
    description: "Electrical repair shops, n.e.c.",
  },
  {
    value: "7631",
    title: "7631 - Watch, clock, and jewelry repair",
    description: "Watch, clock, and jewelry repair",
  },
  {
    value: "7641",
    title: "7641 - Reupholstery and furniture repair",
    description: "Reupholstery and furniture repair",
  },
  {
    value: "7692",
    title: "7692 - Welding repair",
    description: "Welding repair",
  },
  {
    value: "7694",
    title: "7694 - Armature rewinding shops",
    description: "Armature rewinding shops",
  },
  {
    value: "7699",
    title: "7699 - Repair services, n.e.c.",
    description: "Repair services, n.e.c.",
  },
  {
    value: "7812",
    title: "7812 - Motion picture & video production",
    description: "Motion picture & video production",
  },
  {
    value: "7819",
    title: "7819 - Services allied to motion pictures",
    description: "Services allied to motion pictures",
  },
  {
    value: "7822",
    title: "7822 - Motion picture and tape distribution",
    description: "Motion picture and tape distribution",
  },
  {
    value: "7829",
    title: "7829 - Motion picture distribution services",
    description: "Motion picture distribution services",
  },
  {
    value: "7832",
    title: "7832 - Motion picture theaters, ex drive-in",
    description: "Motion picture theaters, ex drive-in",
  },
  {
    value: "7833",
    title: "7833 - Drive-in motion picture theaters",
    description: "Drive-in motion picture theaters",
  },
  {
    value: "7841",
    title: "7841 - Video tape rental",
    description: "Video tape rental",
  },
  {
    value: "7911",
    title: "7911 - Dance halls, studios, and schools",
    description: "Dance halls, studios, and schools",
  },
  {
    value: "7922",
    title: "7922 - Theatrical producers and services",
    description: "Theatrical producers and services",
  },
  {
    value: "7929",
    title: "7929 - Entertainers & entertainment groups",
    description: "Entertainers & entertainment groups",
  },
  {
    value: "7933",
    title: "7933 - Bowling alleys",
    description: "Bowling alleys",
  },
  {
    value: "7941",
    title: "7941 - Sports clubs, managers, & promoters",
    description: "Sports clubs, managers, & promoters",
  },
  {
    value: "7948",
    title: "7948 - Racing, including track operation",
    description: "Racing, including track operation",
  },
  {
    value: "7991",
    title: "7991 - Physical fitness facilities",
    description: "Physical fitness facilities",
  },
  {
    value: "7992",
    title: "7992 - Public golf courses",
    description: "Public golf courses",
  },
  {
    value: "7993",
    title: "7993 - Coin-operated amusement devices",
    description: "Coin-operated amusement devices",
  },
  {
    value: "7996",
    title: "7996 - Amusement parks",
    description: "Amusement parks",
  },
  {
    value: "7997",
    title: "7997 - Membership sports & recreation clubs",
    description: "Membership sports & recreation clubs",
  },
  {
    value: "7999",
    title: "7999 - Amusement and recreation, n.e.c.",
    description: "Amusement and recreation, n.e.c.",
  },
  {
    value: "8011",
    title: "8011 - Offices of physicians",
    description: "Offices of physicians",
  },
  {
    value: "8021",
    title: "8021 - Offices of dentists",
    description: "Offices of dentists",
  },
  {
    value: "8031",
    title: "8031 - Offices of osteopathic physicians",
    description: "Offices of osteopathic physicians",
  },
  {
    value: "8041",
    title: "8041 - Offices and clinics of chiropractors",
    description: "Offices and clinics of chiropractors",
  },
  {
    value: "8042",
    title: "8042 - Offices and clinics of optometrists",
    description: "Offices and clinics of optometrists",
  },
  {
    value: "8043",
    title: "8043 - Offices and clinics of podiatrists",
    description: "Offices and clinics of podiatrists",
  },
  {
    value: "8049",
    title: "8049 - Offices of health practitioners, n.e.c.",
    description: "Offices of health practitioners, n.e.c.",
  },
  {
    value: "8051",
    title: "8051 - Skilled nursing care facilities",
    description: "Skilled nursing care facilities",
  },
  {
    value: "8052",
    title: "8052 - Intermediate personal facilities",
    description: "Intermediate personal facilities",
  },
  {
    value: "8059",
    title: "8059 - Nursing and personal care, n.e.c.",
    description: "Nursing and personal care, n.e.c.",
  },
  {
    value: "8062",
    title: "8062 - General medical & surgical hospitals",
    description: "General medical & surgical hospitals",
  },
  {
    value: "8063",
    title: "8063 - Psychiatric hospitals",
    description: "Psychiatric hospitals",
  },
  {
    value: "8069",
    title: "8069 - Specialty hospitals, exc psychiatric",
    description: "Specialty hospitals, exc psychiatric",
  },
  {
    value: "8071",
    title: "8071 - Medical laboratories",
    description: "Medical laboratories",
  },
  {
    value: "8072",
    title: "8072 - Dental laboratories",
    description: "Dental laboratories",
  },
  {
    value: "8082",
    title: "8082 - Home health care services",
    description: "Home health care services",
  },
  {
    value: "8092",
    title: "8092 - Kidney Dialysis Centers",
    description: "Kidney Dialysis Centers",
  },
  {
    value: "8093",
    title: "8093 - Specialty Outpatient Clinics, n.e.c.",
    description: "Specialty Outpatient Clinics, n.e.c.",
  },
  {
    value: "8099",
    title: "8099 - Health and allied services, n.e.c.",
    description: "Health and allied services, n.e.c.",
  },
  {
    value: "8111",
    title: "8111 - Legal services",
    description: "Legal services",
  },
  {
    value: "8211",
    title: "8211 - Elementary and secondary schools",
    description: "Elementary and secondary schools",
  },
  {
    value: "8221",
    title: "8221 - Colleges and universities, n.e.c.",
    description: "Colleges and universities, n.e.c.",
  },
  {
    value: "8222",
    title: "8222 - Junior colleges",
    description: "Junior colleges",
  },
  {
    value: "8231",
    title: "8231 - Libraries and information centers",
    description: "Libraries and information centers",
  },
  {
    value: "8243",
    title: "8243 - Data processing schools",
    description: "Data processing schools",
  },
  {
    value: "8244",
    title: "8244 - Business and secretarial schools",
    description: "Business and secretarial schools",
  },
  {
    value: "8249",
    title: "8249 - Vocational schools, n.e.c.",
    description: "Vocational schools, n.e.c.",
  },
  {
    value: "8299",
    title: "8299 - Schools & educational services, n.e.c.",
    description: "Schools & educational services, n.e.c.",
  },
  {
    value: "8322",
    title: "8322 - Individual and family services",
    description: "Individual and family services",
  },
  {
    value: "8331",
    title: "8331 - Job training and related services",
    description: "Job training and related services",
  },
  {
    value: "8351",
    title: "8351 - Child day care services",
    description: "Child day care services",
  },
  {
    value: "8361",
    title: "8361 - Residential care",
    description: "Residential care",
  },
  {
    value: "8399",
    title: "8399 - Social services, n.e.c.",
    description: "Social services, n.e.c.",
  },
  {
    value: "8412",
    title: "8412 - Museums and art galleries",
    description: "Museums and art galleries",
  },
  {
    value: "8422",
    title: "8422 - Botanical and zoological gardens",
    description: "Botanical and zoological gardens",
  },
  {
    value: "8611",
    title: "8611 - Business associations",
    description: "Business associations",
  },
  {
    value: "8621",
    title: "8621 - Professional organizations",
    description: "Professional organizations",
  },
  {
    value: "8631",
    title: "8631 - Labor organizations",
    description: "Labor organizations",
  },
  {
    value: "8641",
    title: "8641 - Civic and social associations",
    description: "Civic and social associations",
  },
  {
    value: "8651",
    title: "8651 - Political organizations",
    description: "Political organizations",
  },
  {
    value: "8661",
    title: "8661 - Religious organizations",
    description: "Religious organizations",
  },
  {
    value: "8699",
    title: "8699 - Membership organizations, n.e.c.",
    description: "Membership organizations, n.e.c.",
  },
  {
    value: "8711",
    title: "8711 - Engineering services",
    description: "Engineering services",
  },
  {
    value: "8712",
    title: "8712 - Architectural services",
    description: "Architectural services",
  },
  {
    value: "8713",
    title: "8713 - Surveying services",
    description: "Surveying services",
  },
  {
    value: "8721",
    title: "8721 - Accounting, auditing, & bookkeeping",
    description: "Accounting, auditing, & bookkeeping",
  },
  {
    value: "8731",
    title: "8731 - Commercial physical research",
    description: "Commercial physical research",
  },
  {
    value: "8732",
    title: "8732 - Commercial nonphysical research",
    description: "Commercial nonphysical research",
  },
  {
    value: "8733",
    title: "8733 - Noncommercial research organizations",
    description: "Noncommercial research organizations",
  },
  {
    value: "8734",
    title: "8734 - Testing laboratories",
    description: "Testing laboratories",
  },
  {
    value: "8741",
    title: "8741 - Management services",
    description: "Management services",
  },
  {
    value: "8742",
    title: "8742 - Management consulting services",
    description: "Management consulting services",
  },
  {
    value: "8743",
    title: "8743 - Public relations services",
    description: "Public relations services",
  },
  {
    value: "8744",
    title: "8744 - Facilities support services",
    description: "Facilities support services",
  },
  {
    value: "8748",
    title: "8748 - Business consulting, n.e.c.",
    description: "Business consulting, n.e.c.",
  },
  {
    value: "8811",
    title: "8811 - Private households",
    description: "Private households",
  },
  {
    value: "8999",
    title: "8999 - Services, n.e.c.",
    description: "Services, n.e.c.",
  },
  {
    value: "9111",
    title: "9111 - Executive Offices",
    description: "Executive Offices",
  },
  {
    value: "9121",
    title: "9121 - Legislative Bodies",
    description: "Legislative Bodies",
  },
  {
    value: "9131",
    title: "9131 - Executive and Legislative Combined",
    description: "Executive and Legislative Combined",
  },
  {
    value: "9199",
    title: "9199 - General Government n.e.c.",
    description: "General Government n.e.c.",
  },
  { value: "9211", title: "9211 - Courts", description: "Courts" },
  {
    value: "9221",
    title: "9221 - Police protection",
    description: "Police protection",
  },
  {
    value: "9222",
    title: "9222 - Legal counsel and prosecution",
    description: "Legal counsel and prosecution",
  },
  {
    value: "9223",
    title: "9223 - Correctional institutions",
    description: "Correctional institutions",
  },
  {
    value: "9224",
    title: "9224 - Fire protection",
    description: "Fire protection",
  },
  {
    value: "9229",
    title: "9229 - Public order and safety, n.e.c.",
    description: "Public order and safety, n.e.c.",
  },
  {
    value: "9311",
    title: "9311 - Finance, taxation, & monetary policy",
    description: "Finance, taxation, & monetary policy",
  },
  {
    value: "9411",
    title: "9411 - Admin. of educational programs",
    description: "Admin. of educational programs",
  },
  {
    value: "9431",
    title: "9431 - Admin. of public health programs",
    description: "Admin. of public health programs",
  },
  {
    value: "9441",
    title: "9441 - Admin. of social & manpower programs",
    description: "Admin. of social & manpower programs",
  },
  {
    value: "9451",
    title: "9451 - Administration of veterans affairs",
    description: "Administration of veterans affairs",
  },
  {
    value: "9511",
    title: "9511 - Air, water, & solid waste management",
    description: "Air, water, & solid waste management",
  },
  {
    value: "9512",
    title: "9512 - Land, mineral, wildlife conservation",
    description: "Land, mineral, wildlife conservation",
  },
  {
    value: "9531",
    title: "9531 - Housing programs",
    description: "Housing programs",
  },
  {
    value: "9532",
    title: "9532 - Urban and community development",
    description: "Urban and community development",
  },
  {
    value: "9611",
    title: "9611 - Admin. of general economic programs",
    description: "Admin. of general economic programs",
  },
  {
    value: "9621",
    title: "9621 - Regulation, admin. of transportation",
    description: "Regulation, admin. of transportation",
  },
  {
    value: "9631",
    title: "9631 - Regulation, admin. of utilities",
    description: "Regulation, admin. of utilities",
  },
  {
    value: "9641",
    title: "9641 - Regulation of agricultural marketing",
    description: "Regulation of agricultural marketing",
  },
  {
    value: "9651",
    title: "9651 - Regulation misc. commercial sectors",
    description: "Regulation misc. commercial sectors",
  },
  {
    value: "9661",
    title: "9661 - Space research and technology",
    description: "Space research and technology",
  },
  {
    value: "9711",
    title: "9711 - National security",
    description: "National security",
  },
  {
    value: "9721",
    title: "9721 - International affairs",
    description: "International affairs",
  },
  {
    value: "9999",
    title: "9999 - Nonclassifiable establishments",
    description: "Nonclassifiable establishments",
  },
];

export const employeesTheCompanyWantsToOfferBenefitsToOptionsTitle = {
  [EmployeesTheCompanyWantsToOfferBenefitsToEnum.Contractors_1099]:
    "1099 Contractors",
  [EmployeesTheCompanyWantsToOfferBenefitsToEnum.FullTimeEmployees]:
    "Full-Time Employees",
  [EmployeesTheCompanyWantsToOfferBenefitsToEnum.PartTimeEmployees]:
    "Part-Time Employees",
};

export const employeesTheCompanyWantsToOfferBenefitsToOptions = Object.values(
  EmployeesTheCompanyWantsToOfferBenefitsToEnum
);

export const payrollCycleOptions = Object.values(PayrollCycleEnum);

export const payrollCycleTitle = {
  [PayrollCycleEnum.Annually]: "Annually",
  [PayrollCycleEnum.BiWeekly]: "Bi Weekly",
  [PayrollCycleEnum.Monthly]: "Monthly",
  [PayrollCycleEnum.SemiMonthly]: "Semi Monthly",
  [PayrollCycleEnum.Weekly]: "Weekly",
};

export const countries = [
  {
    code: "AD",
    label: "Andorra",
    phone: "376",
    title: "Andorra",
    value: "Andorra",
  },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
    title: "United Arab Emirates",
    value: "United Arab Emirates",
  },
  {
    code: "AF",
    label: "Afghanistan",
    phone: "93",
    title: "Afghanistan",
    value: "Afghanistan",
  },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
    title: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
  },
  {
    code: "AI",
    label: "Anguilla",
    phone: "1-264",
    title: "Anguilla",
    value: "Anguilla",
  },
  {
    code: "AL",
    label: "Albania",
    phone: "355",
    title: "Albania",
    value: "Albania",
  },
  {
    code: "AM",
    label: "Armenia",
    phone: "374",
    title: "Armenia",
    value: "Armenia",
  },
  {
    code: "AO",
    label: "Angola",
    phone: "244",
    title: "Angola",
    value: "Angola",
  },
  {
    code: "AQ",
    label: "Antarctica",
    phone: "672",
    title: "Antarctica",
    value: "Antarctica",
  },
  {
    code: "AR",
    label: "Argentina",
    phone: "54",
    title: "Argentina",
    value: "Argentina",
  },
  {
    code: "AS",
    label: "American Samoa",
    phone: "1-684",
    title: "American Samoa",
    value: "American Samoa",
  },
  {
    code: "AT",
    label: "Austria",
    phone: "43",
    title: "Austria",
    value: "Austria",
  },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    title: "Australia",
    value: "Australia",
  },
  {
    code: "AW",
    label: "Aruba",
    phone: "297",
    title: "Aruba",
    value: "Aruba",
  },
  {
    code: "AX",
    label: "Alland Islands",
    phone: "358",
    title: "Alland Islands",
    value: "Alland Islands",
  },
  {
    code: "AZ",
    label: "Azerbaijan",
    phone: "994",
    title: "Azerbaijan",
    value: "Azerbaijan",
  },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
    title: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    code: "BB",
    label: "Barbados",
    phone: "1-246",
    title: "Barbados",
    value: "Barbados",
  },
  {
    code: "BD",
    label: "Bangladesh",
    phone: "880",
    title: "Bangladesh",
    value: "Bangladesh",
  },
  {
    code: "BE",
    label: "Belgium",
    phone: "32",
    title: "Belgium",
    value: "Belgium",
  },
  {
    code: "BF",
    label: "Burkina Faso",
    phone: "226",
    title: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    code: "BG",
    label: "Bulgaria",
    phone: "359",
    title: "Bulgaria",
    value: "Bulgaria",
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    title: "Bahrain",
    value: "Bahrain",
  },
  {
    code: "BI",
    label: "Burundi",
    phone: "257",
    title: "Burundi",
    value: "Burundi",
  },
  {
    code: "BJ",
    label: "Benin",
    phone: "229",
    title: "Benin",
    value: "Benin",
  },
  {
    code: "BL",
    label: "Saint Barthelemy",
    phone: "590",
    title: "Saint Barthelemy",
    value: "Saint Barthelemy",
  },
  {
    code: "BM",
    label: "Bermuda",
    phone: "1-441",
    title: "Bermuda",
    value: "Bermuda",
  },
  {
    code: "BN",
    label: "Brunei Darussalam",
    phone: "673",
    title: "Brunei Darussalam",
    value: "Brunei Darussalam",
  },
  {
    code: "BO",
    label: "Bolivia",
    phone: "591",
    title: "Bolivia",
    value: "Bolivia",
  },
  {
    code: "BR",
    label: "Brazil",
    phone: "55",
    title: "Brazil",
    value: "Brazil",
  },
  {
    code: "BS",
    label: "Bahamas",
    phone: "1-242",
    title: "Bahamas",
    value: "Bahamas",
  },
  {
    code: "BT",
    label: "Bhutan",
    phone: "975",
    title: "Bhutan",
    value: "Bhutan",
  },
  {
    code: "BV",
    label: "Bouvet Island",
    phone: "47",
    title: "Bouvet Island",
    value: "Bouvet Island",
  },
  {
    code: "BW",
    label: "Botswana",
    phone: "267",
    title: "Botswana",
    value: "Botswana",
  },
  {
    code: "BY",
    label: "Belarus",
    phone: "375",
    title: "Belarus",
    value: "Belarus",
  },
  {
    code: "BZ",
    label: "Belize",
    phone: "501",
    title: "Belize",
    value: "Belize",
  },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    title: "Canada",
    value: "Canada",
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
    title: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
  },
  {
    code: "CD",
    label: "Congo",
    phone: "243",
    title: "Congo",
    value: "Congo",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
    title: "Central African Republic",
    value: "Central African Republic",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
    title: "Congo, Republic of the",
    value: "Congo, Republic of the",
  },
  {
    code: "CH",
    label: "Switzerland",
    phone: "41",
    title: "Switzerland",
    value: "Switzerland",
  },
  {
    code: "CI",
    label: "Cote d'Ivoire",
    phone: "225",
    title: "Cote d'Ivoire",
    value: "Cote d'Ivoire",
  },
  {
    code: "CK",
    label: "Cook Islands",
    phone: "682",
    title: "Cook Islands",
    value: "Cook Islands",
  },
  { code: "CL", label: "Chile", phone: "56", title: "Chile", value: "Chile" },
  {
    code: "CM",
    label: "Cameroon",
    phone: "237",
    title: "Cameroon",
    value: "Cameroon",
  },
  { code: "CN", label: "China", phone: "86", title: "China", value: "China" },
  {
    code: "CO",
    label: "Colombia",
    phone: "57",
    title: "Colombia",
    value: "Colombia",
  },
  {
    code: "CR",
    label: "Costa Rica",
    phone: "506",
    title: "Costa Rica",
    value: "Costa Rica",
  },
  { code: "CU", label: "Cuba", phone: "53", title: "Cuba", value: "Cuba" },
  {
    code: "CV",
    label: "Cape Verde",
    phone: "238",
    title: "Cape Verde",
    value: "Cape Verde",
  },
  {
    code: "CW",
    label: "Curacao",
    phone: "599",
    title: "Curacao",
    value: "Curacao",
  },
  {
    code: "CX",
    label: "Christmas Island",
    phone: "61",
    title: "Christmas Island",
    value: "Christmas Island",
  },
  {
    code: "CY",
    label: "Cyprus",
    phone: "357",
    title: "Cyprus",
    value: "Cyprus",
  },
  {
    code: "CZ",
    label: "Czech Republic",
    phone: "420",
    title: "Czech Republic",
    value: "Czech Republic",
  },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    title: "Germany",
    value: "Germany",
  },
  {
    code: "DJ",
    label: "Djibouti",
    phone: "253",
    title: "Djibouti",
    value: "Djibouti",
  },
  {
    code: "DK",
    label: "Denmark",
    phone: "45",
    title: "Denmark",
    value: "Denmark",
  },
  {
    code: "DM",
    label: "Dominica",
    phone: "1-767",
    title: "Dominica",
    value: "Dominica",
  },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
    title: "Dominican Republic",
    value: "Dominican Republic",
  },
  {
    code: "DZ",
    label: "Algeria",
    phone: "213",
    title: "Algeria",
    value: "Algeria",
  },
  {
    code: "EC",
    label: "Ecuador",
    phone: "593",
    title: "Ecuador",
    value: "Ecuador",
  },
  {
    code: "EE",
    label: "Estonia",
    phone: "372",
    title: "Estonia",
    value: "Estonia",
  },
  { code: "EG", label: "Egypt", phone: "20", title: "Egypt", value: "Egypt" },
  {
    code: "EH",
    label: "Western Sahara",
    phone: "212",
    title: "Western Sahara",
    value: "Western Sahara",
  },
  {
    code: "ER",
    label: "Eritrea",
    phone: "291",
    title: "Eritrea",
    value: "Eritrea",
  },
  { code: "ES", label: "Spain", phone: "34", title: "Spain", value: "Spain" },
  {
    code: "ET",
    label: "Ethiopia",
    phone: "251",
    title: "Ethiopia",
    value: "Ethiopia",
  },
  {
    code: "FI",
    label: "Finland",
    phone: "358",
    title: "Finland",
    value: "Finland",
  },
  { code: "FJ", label: "Fiji", phone: "679", title: "Fiji", value: "Fiji" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    title: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
    title: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
  },
  {
    code: "FO",
    label: "Faroe Islands",
    phone: "298",
    title: "Faroe Islands",
    value: "Faroe Islands",
  },
  {
    code: "FR",
    label: "France",
    phone: "33",
    title: "France",
    value: "France",
  },
  {
    code: "GA",
    label: "Gabon",
    phone: "241",
    title: "Gabon",
    value: "Gabon",
  },
  {
    code: "GB",
    label: "United Kingdom",
    phone: "44",
    title: "United Kingdom",
    value: "United Kingdom",
  },
  {
    code: "GD",
    label: "Grenada",
    phone: "1-473",
    title: "Grenada",
    value: "Grenada",
  },
  {
    code: "GE",
    label: "Georgia",
    phone: "995",
    title: "Georgia",
    value: "Georgia",
  },
  {
    code: "GF",
    label: "French Guiana",
    phone: "594",
    title: "French Guiana",
    value: "French Guiana",
  },
  {
    code: "GG",
    label: "Guernsey",
    phone: "44",
    title: "Guernsey",
    value: "Guernsey",
  },
  {
    code: "GH",
    label: "Ghana",
    phone: "233",
    title: "Ghana",
    value: "Ghana",
  },
  {
    code: "GI",
    label: "Gibraltar",
    phone: "350",
    title: "Gibraltar",
    value: "Gibraltar",
  },
  {
    code: "GL",
    label: "Greenland",
    phone: "299",
    title: "Greenland",
    value: "Greenland",
  },
  {
    code: "GM",
    label: "Gambia",
    phone: "220",
    title: "Gambia",
    value: "Gambia",
  },
  {
    code: "GN",
    label: "Guinea",
    phone: "224",
    title: "Guinea",
    value: "Guinea",
  },
  {
    code: "GP",
    label: "Guadeloupe",
    phone: "590",
    title: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    code: "GQ",
    label: "Equatorial Guinea",
    phone: "240",
    title: "Equatorial Guinea",
    value: "Equatorial Guinea",
  },
  {
    code: "GR",
    label: "Greece",
    phone: "30",
    title: "Greece",
    value: "Greece",
  },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    title: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "GT",
    label: "Guatemala",
    phone: "502",
    title: "Guatemala",
    value: "Guatemala",
  },
  { code: "GU", label: "Guam", phone: "1-671", title: "Guam", value: "Guam" },
  {
    code: "GW",
    label: "Guinea-Bissau",
    phone: "245",
    title: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    code: "GY",
    label: "Guyana",
    phone: "592",
    title: "Guyana",
    value: "Guyana",
  },
  {
    code: "HK",
    label: "Hong Kong",
    phone: "852",
    title: "Hong Kong",
    value: "Hong Kong",
  },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    title: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
  },
  {
    code: "HN",
    label: "Honduras",
    phone: "504",
    title: "Honduras",
    value: "Honduras",
  },
  {
    code: "HR",
    label: "Croatia",
    phone: "385",
    title: "Croatia",
    value: "Croatia",
  },
  {
    code: "HT",
    label: "Haiti",
    phone: "509",
    title: "Haiti",
    value: "Haiti",
  },
  {
    code: "HU",
    label: "Hungary",
    phone: "36",
    title: "Hungary",
    value: "Hungary",
  },
  {
    code: "ID",
    label: "Indonesia",
    phone: "62",
    title: "Indonesia",
    value: "Indonesia",
  },
  {
    code: "IE",
    label: "Ireland",
    phone: "353",
    title: "Ireland",
    value: "Ireland",
  },
  {
    code: "IL",
    label: "Israel",
    phone: "972",
    title: "Israel",
    value: "Israel",
  },
  {
    code: "IM",
    label: "Isle of Man",
    phone: "44",
    title: "Isle of Man",
    value: "Isle of Man",
  },
  { code: "IN", label: "India", phone: "91", title: "India", value: "India" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
    title: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  { code: "IQ", label: "Iraq", phone: "964", title: "Iraq", value: "Iraq" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
    title: "Iran, Islamic Republic of",
    value: "Iran, Islamic Republic of",
  },
  {
    code: "IS",
    label: "Iceland",
    phone: "354",
    title: "Iceland",
    value: "Iceland",
  },
  { code: "IT", label: "Italy", phone: "39", title: "Italy", value: "Italy" },
  {
    code: "JE",
    label: "Jersey",
    phone: "44",
    title: "Jersey",
    value: "Jersey",
  },
  {
    code: "JM",
    label: "Jamaica",
    phone: "1-876",
    title: "Jamaica",
    value: "Jamaica",
  },
  {
    code: "JO",
    label: "Jordan",
    phone: "962",
    title: "Jordan",
    value: "Jordan",
  },
  { code: "JP", label: "Japan", phone: "81", title: "Japan", value: "Japan" },
  {
    code: "KE",
    label: "Kenya",
    phone: "254",
    title: "Kenya",
    value: "Kenya",
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
    phone: "996",
    title: "Kyrgyzstan",
    value: "Kyrgyzstan",
  },
  {
    code: "KH",
    label: "Cambodia",
    phone: "855",
    title: "Cambodia",
    value: "Cambodia",
  },
  {
    code: "KI",
    label: "Kiribati",
    phone: "686",
    title: "Kiribati",
    value: "Kiribati",
  },
  {
    code: "KM",
    label: "Comoros",
    phone: "269",
    title: "Comoros",
    value: "Comoros",
  },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    title: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
    title: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of",
  },
  {
    code: "KR",
    label: "Korea, Republic of",
    phone: "82",
    title: "Korea, Republic of",
    value: "Korea, Republic of",
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    title: "Kuwait",
    value: "Kuwait",
  },
  {
    code: "KY",
    label: "Cayman Islands",
    phone: "1-345",
    title: "Cayman Islands",
    value: "Cayman Islands",
  },
  {
    code: "KZ",
    label: "Kazakhstan",
    phone: "7",
    title: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
    title: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  {
    code: "LB",
    label: "Lebanon",
    phone: "961",
    title: "Lebanon",
    value: "Lebanon",
  },
  {
    code: "LC",
    label: "Saint Lucia",
    phone: "1-758",
    title: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    code: "LI",
    label: "Liechtenstein",
    phone: "423",
    title: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    code: "LK",
    label: "Sri Lanka",
    phone: "94",
    title: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    code: "LR",
    label: "Liberia",
    phone: "231",
    title: "Liberia",
    value: "Liberia",
  },
  {
    code: "LS",
    label: "Lesotho",
    phone: "266",
    title: "Lesotho",
    value: "Lesotho",
  },
  {
    code: "LT",
    label: "Lithuania",
    phone: "370",
    title: "Lithuania",
    value: "Lithuania",
  },
  {
    code: "LU",
    label: "Luxembourg",
    phone: "352",
    title: "Luxembourg",
    value: "Luxembourg",
  },
  {
    code: "LV",
    label: "Latvia",
    phone: "371",
    title: "Latvia",
    value: "Latvia",
  },
  {
    code: "LY",
    label: "Libya",
    phone: "218",
    title: "Libya",
    value: "Libya",
  },
  {
    code: "MA",
    label: "Morocco",
    phone: "212",
    title: "Morocco",
    value: "Morocco",
  },
  {
    code: "MC",
    label: "Monaco",
    phone: "377",
    title: "Monaco",
    value: "Monaco",
  },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
    title: "Moldova, Republic of",
    value: "Moldova, Republic of",
  },
  {
    code: "ME",
    label: "Montenegro",
    phone: "382",
    title: "Montenegro",
    value: "Montenegro",
  },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
    title: "Saint Martin (French part)",
    value: "Saint Martin (French part)",
  },
  {
    code: "MG",
    label: "Madagascar",
    phone: "261",
    title: "Madagascar",
    value: "Madagascar",
  },
  {
    code: "MH",
    label: "Marshall Islands",
    phone: "692",
    title: "Marshall Islands",
    value: "Marshall Islands",
  },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
    title: "Macedonia, the Former Yugoslav Republic of",
    value: "Macedonia, the Former Yugoslav Republic of",
  },
  { code: "ML", label: "Mali", phone: "223", title: "Mali", value: "Mali" },
  {
    code: "MM",
    label: "Myanmar",
    phone: "95",
    title: "Myanmar",
    value: "Myanmar",
  },
  {
    code: "MN",
    label: "Mongolia",
    phone: "976",
    title: "Mongolia",
    value: "Mongolia",
  },
  {
    code: "MO",
    label: "Macao",
    phone: "853",
    title: "Macao",
    value: "Macao",
  },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
    title: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
  },
  {
    code: "MQ",
    label: "Martinique",
    phone: "596",
    title: "Martinique",
    value: "Martinique",
  },
  {
    code: "MR",
    label: "Mauritania",
    phone: "222",
    title: "Mauritania",
    value: "Mauritania",
  },
  {
    code: "MS",
    label: "Montserrat",
    phone: "1-664",
    title: "Montserrat",
    value: "Montserrat",
  },
  {
    code: "MT",
    label: "Malta",
    phone: "356",
    title: "Malta",
    value: "Malta",
  },
  {
    code: "MU",
    label: "Mauritius",
    phone: "230",
    title: "Mauritius",
    value: "Mauritius",
  },
  {
    code: "MV",
    label: "Maldives",
    phone: "960",
    title: "Maldives",
    value: "Maldives",
  },
  {
    code: "MW",
    label: "Malawi",
    phone: "265",
    title: "Malawi",
    value: "Malawi",
  },
  {
    code: "MX",
    label: "Mexico",
    phone: "52",
    title: "Mexico",
    value: "Mexico",
  },
  {
    code: "MY",
    label: "Malaysia",
    phone: "60",
    title: "Malaysia",
    value: "Malaysia",
  },
  {
    code: "MZ",
    label: "Mozambique",
    phone: "258",
    title: "Mozambique",
    value: "Mozambique",
  },
  {
    code: "NA",
    label: "Namibia",
    phone: "264",
    title: "Namibia",
    value: "Namibia",
  },
  {
    code: "NC",
    label: "New Caledonia",
    phone: "687",
    title: "New Caledonia",
    value: "New Caledonia",
  },
  {
    code: "NE",
    label: "Niger",
    phone: "227",
    title: "Niger",
    value: "Niger",
  },
  {
    code: "NF",
    label: "Norfolk Island",
    phone: "672",
    title: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    code: "NG",
    label: "Nigeria",
    phone: "234",
    title: "Nigeria",
    value: "Nigeria",
  },
  {
    code: "NI",
    label: "Nicaragua",
    phone: "505",
    title: "Nicaragua",
    value: "Nicaragua",
  },
  {
    code: "NL",
    label: "Netherlands",
    phone: "31",
    title: "Netherlands",
    value: "Netherlands",
  },
  {
    code: "NO",
    label: "Norway",
    phone: "47",
    title: "Norway",
    value: "Norway",
  },
  {
    code: "NP",
    label: "Nepal",
    phone: "977",
    title: "Nepal",
    value: "Nepal",
  },
  {
    code: "NR",
    label: "Nauru",
    phone: "674",
    title: "Nauru",
    value: "Nauru",
  },
  { code: "NU", label: "Niue", phone: "683", title: "Niue", value: "Niue" },
  {
    code: "NZ",
    label: "New Zealand",
    phone: "64",
    title: "New Zealand",
    value: "New Zealand",
  },
  { code: "OM", label: "Oman", phone: "968", title: "Oman", value: "Oman" },
  {
    code: "PA",
    label: "Panama",
    phone: "507",
    title: "Panama",
    value: "Panama",
  },
  { code: "PE", label: "Peru", phone: "51", title: "Peru", value: "Peru" },
  {
    code: "PF",
    label: "French Polynesia",
    phone: "689",
    title: "French Polynesia",
    value: "French Polynesia",
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    phone: "675",
    title: "Papua New Guinea",
    value: "Papua New Guinea",
  },
  {
    code: "PH",
    label: "Philippines",
    phone: "63",
    title: "Philippines",
    value: "Philippines",
  },
  {
    code: "PK",
    label: "Pakistan",
    phone: "92",
    title: "Pakistan",
    value: "Pakistan",
  },
  {
    code: "PL",
    label: "Poland",
    phone: "48",
    title: "Poland",
    value: "Poland",
  },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
    title: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    code: "PN",
    label: "Pitcairn",
    phone: "870",
    title: "Pitcairn",
    value: "Pitcairn",
  },
  {
    code: "PR",
    label: "Puerto Rico",
    phone: "1",
    title: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
    title: "Palestine, State of",
    value: "Palestine, State of",
  },
  {
    code: "PT",
    label: "Portugal",
    phone: "351",
    title: "Portugal",
    value: "Portugal",
  },
  {
    code: "PW",
    label: "Palau",
    phone: "680",
    title: "Palau",
    value: "Palau",
  },
  {
    code: "PY",
    label: "Paraguay",
    phone: "595",
    title: "Paraguay",
    value: "Paraguay",
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    title: "Qatar",
    value: "Qatar",
  },
  {
    code: "RE",
    label: "Reunion",
    phone: "262",
    title: "Reunion",
    value: "Reunion",
  },
  {
    code: "RO",
    label: "Romania",
    phone: "40",
    title: "Romania",
    value: "Romania",
  },
  {
    code: "RS",
    label: "Serbia",
    phone: "381",
    title: "Serbia",
    value: "Serbia",
  },
  {
    code: "RU",
    label: "Russian Federation",
    phone: "7",
    title: "Russian Federation",
    value: "Russian Federation",
  },
  {
    code: "RW",
    label: "Rwanda",
    phone: "250",
    title: "Rwanda",
    value: "Rwanda",
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    title: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    code: "SB",
    label: "Solomon Islands",
    phone: "677",
    title: "Solomon Islands",
    value: "Solomon Islands",
  },
  {
    code: "SC",
    label: "Seychelles",
    phone: "248",
    title: "Seychelles",
    value: "Seychelles",
  },
  {
    code: "SD",
    label: "Sudan",
    phone: "249",
    title: "Sudan",
    value: "Sudan",
  },
  {
    code: "SE",
    label: "Sweden",
    phone: "46",
    title: "Sweden",
    value: "Sweden",
  },
  {
    code: "SG",
    label: "Singapore",
    phone: "65",
    title: "Singapore",
    value: "Singapore",
  },
  {
    code: "SH",
    label: "Saint Helena",
    phone: "290",
    title: "Saint Helena",
    value: "Saint Helena",
  },
  {
    code: "SI",
    label: "Slovenia",
    phone: "386",
    title: "Slovenia",
    value: "Slovenia",
  },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
    title: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
  },
  {
    code: "SK",
    label: "Slovakia",
    phone: "421",
    title: "Slovakia",
    value: "Slovakia",
  },
  {
    code: "SL",
    label: "Sierra Leone",
    phone: "232",
    title: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    code: "SM",
    label: "San Marino",
    phone: "378",
    title: "San Marino",
    value: "San Marino",
  },
  {
    code: "SN",
    label: "Senegal",
    phone: "221",
    title: "Senegal",
    value: "Senegal",
  },
  {
    code: "SO",
    label: "Somalia",
    phone: "252",
    title: "Somalia",
    value: "Somalia",
  },
  {
    code: "SR",
    label: "Suriname",
    phone: "597",
    title: "Suriname",
    value: "Suriname",
  },
  {
    code: "SS",
    label: "South Sudan",
    phone: "211",
    title: "South Sudan",
    value: "South Sudan",
  },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
    title: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
  },
  {
    code: "SV",
    label: "El Salvador",
    phone: "503",
    title: "El Salvador",
    value: "El Salvador",
  },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
    title: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
    title: "Syrian Arab Republic",
    value: "Syrian Arab Republic",
  },
  {
    code: "SZ",
    label: "Swaziland",
    phone: "268",
    title: "Swaziland",
    value: "Swaziland",
  },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
    title: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
  },
  { code: "TD", label: "Chad", phone: "235", title: "Chad", value: "Chad" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
    title: "French Southern Territories",
    value: "French Southern Territories",
  },
  { code: "TG", label: "Togo", phone: "228", title: "Togo", value: "Togo" },
  {
    code: "TH",
    label: "Thailand",
    phone: "66",
    title: "Thailand",
    value: "Thailand",
  },
  {
    code: "TJ",
    label: "Tajikistan",
    phone: "992",
    title: "Tajikistan",
    value: "Tajikistan",
  },
  {
    code: "TK",
    label: "Tokelau",
    phone: "690",
    title: "Tokelau",
    value: "Tokelau",
  },
  {
    code: "TL",
    label: "Timor-Leste",
    phone: "670",
    title: "Timor-Leste",
    value: "Timor-Leste",
  },
  {
    code: "TM",
    label: "Turkmenistan",
    phone: "993",
    title: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    code: "TN",
    label: "Tunisia",
    phone: "216",
    title: "Tunisia",
    value: "Tunisia",
  },
  {
    code: "TO",
    label: "Tonga",
    phone: "676",
    title: "Tonga",
    value: "Tonga",
  },
  {
    code: "TR",
    label: "Turkey",
    phone: "90",
    title: "Turkey",
    value: "Turkey",
  },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
    title: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
  },
  {
    code: "TV",
    label: "Tuvalu",
    phone: "688",
    title: "Tuvalu",
    value: "Tuvalu",
  },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
    title: "Taiwan, Province of China",
    value: "Taiwan, Province of China",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
    title: "United Republic of Tanzania",
    value: "United Republic of Tanzania",
  },
  {
    code: "UA",
    label: "Ukraine",
    phone: "380",
    title: "Ukraine",
    value: "Ukraine",
  },
  {
    code: "UG",
    label: "Uganda",
    phone: "256",
    title: "Uganda",
    value: "Uganda",
  },
  {
    code: "US",
    label: "United States",
    phone: "1",
    title: "United States",
    value: "United States",
  },
  {
    code: "UY",
    label: "Uruguay",
    phone: "598",
    title: "Uruguay",
    value: "Uruguay",
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
    title: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
    title: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    title: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  {
    code: "VE",
    label: "Venezuela",
    phone: "58",
    title: "Venezuela",
    value: "Venezuela",
  },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
    title: "British Virgin Islands",
    value: "British Virgin Islands",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
    title: "US Virgin Islands",
    value: "US Virgin Islands",
  },
  {
    code: "VN",
    label: "Vietnam",
    phone: "84",
    title: "Vietnam",
    value: "Vietnam",
  },
  {
    code: "VU",
    label: "Vanuatu",
    phone: "678",
    title: "Vanuatu",
    value: "Vanuatu",
  },
  {
    code: "WF",
    label: "Wallis and Futuna",
    phone: "681",
    title: "Wallis and Futuna",
    value: "Wallis and Futuna",
  },
  {
    code: "WS",
    label: "Samoa",
    phone: "685",
    title: "Samoa",
    value: "Samoa",
  },
  {
    code: "YE",
    label: "Yemen",
    phone: "967",
    title: "Yemen",
    value: "Yemen",
  },
  {
    code: "YT",
    label: "Mayotte",
    phone: "262",
    title: "Mayotte",
    value: "Mayotte",
  },
  {
    code: "ZA",
    label: "South Africa",
    phone: "27",
    title: "South Africa",
    value: "South Africa",
  },
  {
    code: "ZM",
    label: "Zambia",
    phone: "260",
    title: "Zambia",
    value: "Zambia",
  },
  {
    code: "ZW",
    label: "Zimbabwe",
    phone: "263",
    title: "Zimbabwe",
    value: "Zimbabwe",
  },
];
