import { InfoOutlined } from "@mui/icons-material";
import {
  Grid,
  FormControlLabel,
  Radio,
  Typography,
  Theme,
  Box,
  Tooltip,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-mui";
import React, { useCallback, useMemo } from "react";

interface Props {
  options: {
    label: string;
    value: any;
  }[];
  name: string;
  label?: string;
  handleTransFormValue?: (value: any) => any;
  onChange?: (value: any) => any;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
}

export const Radios: React.FC<Props> = ({
  options,
  name,
  label,
  handleTransFormValue,
  onChange,
  disabled,
  required = false,
  tooltip,
}) => {
  const { setFieldValue, values } = useFormikContext<Record<string, unknown>>();

  const value = useMemo(() => values[name], [values, name]);

  const handleChange = useCallback(
    (event: { target: { value: any } }) => {
      const v = event.target.value;
      if (onChange) {
        onChange(handleTransFormValue ? handleTransFormValue(v) : v);
      } else {
        setFieldValue(name, handleTransFormValue ? handleTransFormValue(v) : v);
      }
    },
    [setFieldValue, name, handleTransFormValue, onChange]
  );

  return (
    <Field
      component={RadioGroup}
      name={name}
      sx={{
        padding: (theme: Theme) => theme.spacing(1, 0),
        width: "100%",
      }}
      onChange={handleChange}
      disabled={!!disabled}
    >
      <Grid container alignItems="center" spacing={2}>
        {label && (
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body2">
                  {label}
                  {required ? " *" : ""}
                </Typography>
              </Grid>
              {tooltip ? (
                <Grid item>
                  <Tooltip title={tooltip}>
                    <InfoOutlined color="primary" />
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2}>
            {options.map((option) => (
              <Grid item key={option.value}>
                <Box
                  sx={{
                    border:
                      value === option.value
                        ? "1px solid rgba(9, 89, 209, 1)"
                        : "1px solid rgba(189, 189, 189, 1)",
                    borderRadius: (theme) => theme.spacing(0.5),
                    background:
                      value === option.value
                        ? "rgba(241, 248, 255, 1)"
                        : "none",
                    paddingLeft: (theme) => theme.spacing(1),
                    cursor: "pointer",
                  }}
                >
                  <FormControlLabel
                    value={option.value}
                    label={option.label}
                    disabled={!!disabled}
                    control={
                      <Radio
                        size="small"
                        color="primary"
                        disabled={!!disabled}
                      />
                    }
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Field>
  );
};
