import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const fontSize = 18;

export const LepCalculatorPartDResult2 = () => {
  return (
    <Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <ArrowForward fontSize="small" /> Great news,{" "}
          <b>you pay no penalty</b> if you go{" "}
          <b>less than 2 months without creditable coverage</b>.
          <br />
          Be careful... once the penalty applies it never expires: you would
          have to pay it every month as long as you are enrolled in Medicare.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          Make sure to{" "}
          <b>
            avoid penalties by enrolling before you go over the 2 months mark
          </b>
          .
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize,
        }}
      >
        <u>
          <b>
            <i>Learn how to:</i>
          </b>
        </u>
      </Typography>
      <Typography
        sx={{
          fontSize,
          marginBottom: ({ spacing }) => spacing(2),
        }}
        component="ul"
      >
        <li>
          <i>Avoid penalties</i>
        </li>
        <li>
          <i>Enroll in Medicare</i>
        </li>
        <li>
          <i>Maximize your Medicare benefits</i>
        </li>
      </Typography>
      <Typography
        sx={{
          fontSize,
        }}
      >
        in our{" "}
        <b>
          Free eBook: &quot;Medicare: The Guide to Protecting your Future&quot;
        </b>
      </Typography>
    </Box>
  );
};
