import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const fontSize = 18;

export const LepCalculatorPartDResult1 = () => {
  return (
    <Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <ArrowForward fontSize="small" /> When you have Medicaid or you
          receive Supplemental Security Income (SSI) benefits from Social
          Security, <b>the penalty is waived for you</b>.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          However, this does not mean you shouldn&apos;t{" "}
          <b>benefit from Medicare as soon as possible</b>.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize,
        }}
      >
        <u>
          <b>
            <i>Learn how to:</i>
          </b>
        </u>
      </Typography>
      <Typography
        sx={{
          fontSize,
          marginBottom: ({ spacing }) => spacing(2),
        }}
        component="ul"
      >
        <li>
          <i>Make sure you avoid penalties</i>
        </li>
        <li>
          <i>Enroll in Medicare</i>
        </li>
        <li>
          <i>Maximize your Medicare benefits</i>
        </li>
      </Typography>
      <Typography
        sx={{
          fontSize,
        }}
      >
        in our{" "}
        <b>
          Free eBook: &quot;Medicare: The Guide to Protecting your Future&quot;
        </b>
      </Typography>
    </Box>
  );
};
