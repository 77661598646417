export enum QuoteRequestStepEnum {
  GET_QUOTE = "Get Quote",
  COMPANY_INFORMATION = "Company Information",
  PRODUCTS = "Products",
  ACTIVE_POLICY = "Active Policy",
  POLICY_REQUIREMENTS = "Policy Requirements",
  EMPLOYEES = "Employees",
  SIGN_HB2015_LETTER = "Sign HB2015 Letter",
}

export enum PolicyOptionEnum {
  New = "new-policy",
  Change = "policy-change",
}
