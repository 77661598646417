import { DialogActions } from "@mui/material";
import { Button, useCompanyForm } from "Components";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";

interface Props {
  onClose: () => void;
}

export const CompanyDetailsDialogActions = ({ onClose }: Props) => {
  const { isValid, isSubmitting } = useCompanyForm();
  const { handleSubmit } = useFormikContext();

  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);
  return (
    <DialogActions>
      <Button onClick={onClose}>CANCEL</Button>
      <Button
        disabled={!isValid || isSubmitting}
        type="submit"
        variant="contained"
        onClick={onSubmit}
      >
        UPDATE DETAILS
      </Button>
    </DialogActions>
  );
};
