import { ArrowDownward } from "@mui/icons-material";
import { Box, IconButton, Typography, Theme, Grid } from "@mui/material";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import React, { ChangeEventHandler } from "react";

export const EmployeeTableHeaderCell = ({
  label,
  onSort,
  customKey,
  handleSelectAll,
  selectAllValue,
}: {
  label: string;
  onSort?: () => void;
  customKey: string;
  selectAllValue: boolean;
  handleSelectAll: ChangeEventHandler<HTMLInputElement>;
}) => {
  if (customKey === "actions") {
    return (
      <Grid
        container
        flexWrap="nowrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item>
          <Box sx={{ width: (theme) => theme.spacing(2) }}>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              sx={{
                color: (theme: Theme) => theme.palette.primary,
              }}
              color="primary"
              onChange={handleSelectAll}
              value={selectAllValue}
              name="selectAll"
              checked={selectAllValue}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              width: 220,
            }}
          >
            <Typography variant="subtitle2">Coverage Type</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
      }}
    >
      {onSort ? (
        <IconButton onClick={onSort}>
          <ArrowDownward fontSize="small" />
        </IconButton>
      ) : null}
      <Typography variant="subtitle2">{label}</Typography>
    </Box>
  );
};
