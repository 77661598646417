import { IntegrationApp } from "HOC";

export const UpdateCensus = () => {
  return (
    <IntegrationApp
      path="buffer"
      desktopAppKey="updateCensus"
      appIntegrationSource="client-portal"
    />
  );
};
