import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  LepCalculatorPartBFields,
  LepCalculatorPartBResult,
  LepCalculatorTitle,
  Loader,
} from "Components";
import moment from "moment";
import { Form, Formik, FormikConfig } from "formik";
import * as yup from "yup";
import { LepCalculatorPartBFormValues } from "interfaces";
import { notifications } from "services";
import { AxiosError } from "axios";
import { calculatePartBPenalty, sendPostMessageToBufferWebsite } from "helpers";
import { ArrowBack } from "@mui/icons-material";
import { useResponsive } from "hooks";
import { postPartB } from "../request";

export const LepCalculatorPartB = () => {
  const { isMDDown } = useResponsive();
  const [loading, setLoading] = useState(false);
  const [calculatedPenalty, setCalculatedPenalty] = useState<string | null>(
    null
  );
  const [step, setStep] = useState<"form" | "result">("form");
  const [resultVariant, setResultVariant] = useState<number>(1);
  const [enrollmentStartDateString, setEnrollmentStartDateString] =
    useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [uuid, setUUid] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [enrollmentEndDateString, setEnrollmentEndDateString] = useState("");
  const [savedInitialValues, setSavedInitialValues] =
    useState<LepCalculatorPartBFormValues | null>(null);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );

  const initialValues: LepCalculatorPartBFormValues = useMemo(() => {
    return (
      savedInitialValues || {
        dateOfBirth: null,
        partBEnrollmentDate: null,
        isMarried: null,
        income: "",
        enrolledInMedicaid: null,
        receiveSupplementalSecurityIncome: null,
        haveEndStageRenalDisease: null,
        email,
      }
    );
  }, [email, savedInitialValues]);

  const validationSchema = useMemo(() => {
    return yup.lazy((values: any) => {
      const dateOfBirthValue: string = values.dateOfBirth;
      return yup.object({
        dateOfBirth: yup
          .string()
          .required("This field is required")
          .typeError("Please, provide a valid date of birth")
          .label("Date of birth")
          .test({
            name: "test date min",
            message: `Date of birth must be on or after ${moment(
              new Date("01/01/1900")
            ).format("YYYY-MM-DD")}`,
            test(dobAny: any) {
              const dob: string | null = dobAny;
              if (!dob) {
                return true;
              }
              return (
                moment(dob).diff(
                  moment(new Date("01/01/1900")),
                  "milliseconds"
                ) >= 0
              );
            },
          })
          .test({
            name: "test date max",
            message: `Date of birth must be on or before ${moment(
              new Date()
            ).format("YYYY-MM-DD")}`,
            test(dobAny: any) {
              const dob: string | null = dobAny;
              if (!dob) {
                return true;
              }
              return moment(dob).diff(moment(new Date()), "milliseconds") <= 0;
            },
          }),
        partBEnrollmentDate: yup
          .string()
          .required("This field is required")
          .typeError("Please, provide a valid date of enrollment")
          .label("Date of enrollment")
          .test({
            name: "test date min",
            message: `Date of enrollment must be on or after ${moment(
              new Date(dateOfBirthValue || "01/01/1964")
            ).format("YYYY-MM-DD")}`,
            test(dateOfEnrollmentAny: any) {
              const dateOfEnrollment: string | null = dateOfEnrollmentAny;
              if (!dateOfEnrollment) {
                return true;
              }
              return (
                moment(dateOfEnrollment).diff(
                  moment(new Date(dateOfBirthValue || "01/01/1964")),
                  "milliseconds"
                ) >= 0
              );
            },
          }),
        isMarried: yup
          .string()
          .required("This field is required")
          .typeError("This field is required"),
        income: yup
          .string()
          .required("This field is required")
          .typeError("This field is required"),
        enrolledInMedicaid: yup
          .string()
          .required("This field is required")
          .typeError("This field is required"),
        receiveSupplementalSecurityIncome: yup
          .string()
          .required("This field is required")
          .typeError("This field is required"),
        haveEndStageRenalDisease: yup
          .string()
          .required("This field is required")
          .typeError("This field is required"),
        email: yup
          .string()
          .email("Provide a valid email address")
          .required("This field is required")
          .typeError("Provide a valid email address"),
      });
    });
  }, []);

  const resizeParentWindow = useCallback(
    (height?: number) => {
      if (!wrapperElement) {
        return;
      }
      setTimeout(() => {
        sendPostMessageToBufferWebsite({
          action: "resize-iframe",
          height:
            (height || wrapperElement.offsetHeight) + (isMDDown ? 150 : 200),
          form: "b",
        });
      }, 300);
    },
    [wrapperElement, isMDDown]
  );

  const handleBackToForm = useCallback(() => {
    setStep("form");
    resizeParentWindow();
  }, [resizeParentWindow]);

  const handleSubmit: FormikConfig<LepCalculatorPartBFormValues>["onSubmit"] =
    useCallback(
      async (values, { setSubmitting }) => {
        setLoading(true);
        try {
          const time = String(Date.now());
          const uuidRes = time.substring(time.length - 9);
          const result = calculatePartBPenalty(values, uuidRes);
          const payload = {
            email: values.email,
            ssi: values.receiveSupplementalSecurityIncome,
            enrolledinMedicaid: values.enrolledInMedicaid,
            enrollmentDate: moment(values.partBEnrollmentDate).format(
              "YYYY-MM-DD"
            ),
            renalDisease: values.haveEndStageRenalDisease,
            medicaid: values.enrolledInMedicaid,
            uuid: uuidRes,
            birthday: moment(values.dateOfBirth).format("YYYY-MM-DD"),
            maritalStatus: values.isMarried,
            ...result,
            year: String(moment().year()),
          };
          await postPartB(payload);
          setCalculatedPenalty(result.calculatedPenalty);
          setResultVariant(result.resultVariant);
          setUUid(uuidRes);
          setDateOfBirth(payload.birthday);
          setEmail(payload.email || "");
          setEnrollmentStartDateString(payload.enrollmentStartDateString || "");
          setEnrollmentEndDateString(payload.enrollmentEndDateString || "");
          setStep("result");
          setSavedInitialValues(values);
          resizeParentWindow();
        } catch (error) {
          const err = error as AxiosError<{ message: string }>;
          notifications.notifyError(err.response?.data.message || err.message);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      },
      [resizeParentWindow]
    );

  const childrenWrapperRef = useCallback(
    (divElement: HTMLDivElement | null) => {
      if (divElement) {
        setWrapperElement(divElement);
        resizeParentWindow(divElement.offsetHeight);
      }
    },
    [resizeParentWindow]
  );

  return (
    <Box ref={childrenWrapperRef}>
      <Loader open={loading} />
      <Box pb={8}>
        <LepCalculatorTitle
          title={`Medicare Part B ${
            step === "result" ? "Calculation Results" : "Penalty Calculator"
          }`}
        />
      </Box>
      <Box pb={step === "form" ? 8 : 8}>
        {step === "form" ? (
          <Formik<LepCalculatorPartBFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
          >
            <Form>
              <LepCalculatorPartBFields
                resizeParentWindow={resizeParentWindow}
              />
            </Form>
          </Formik>
        ) : null}
        {step === "result" ? (
          <LepCalculatorPartBResult
            resultVariant={resultVariant}
            calculatedPenalty={calculatedPenalty}
            enrollmentStartDateString={enrollmentStartDateString}
            email={email}
            uuid={uuid}
            dateOfBirth={dateOfBirth}
            enrollmentEndDateString={enrollmentEndDateString}
          />
        ) : null}
      </Box>
      <Box display="flex" justifyContent="center">
        <Box maxWidth={500}>
          <Typography variant="caption" align="center" component="p">
            This calculator is intended to illustrate an estimated Late
            Enrollment Penalty only.
            <br />A person&apos;s actual LEP will be assessed by CMS.
          </Typography>
        </Box>
      </Box>

      {step === "result" ? (
        <Box pt={8} display="flex" justifyContent="center">
          <Button
            sx={{
              background: ({ palette: p }) => p.grey[200],
              color: ({ palette: p }) => p.grey[800],
              borderColor: ({ palette: p }) => p.grey[400],
              paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
              paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
            }}
            onClick={handleBackToForm}
            variant="outlined"
          >
            <ArrowBack
              fontSize="small"
              sx={{ marginRight: ({ spacing }) => spacing(1) }}
            />{" "}
            START OVER
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
