import { useCallback, useEffect, useMemo, useState } from "react";

import { Alert, Box, Container, Grid, Typography } from "@mui/material";
import { Button, Loader } from "Components";
import { useResponsive } from "hooks";
import {
  companyActions,
  companySelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import { WarningAmber } from "@mui/icons-material";
import {
  Company,
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
} from "gql/graphql";
import { notifications } from "services";
import { JobClassificationsDialog } from "./JobClassificationsDialog";
import { CompanyDetailsDialog } from "./CompanyDetailsDialog";

const handleNumber = (value?: string | number | null) => {
  if (value === undefined || value === null || value === "") {
    return "";
  }

  if (value === 0 || value === "0") {
    return "0";
  }
  return value;
};

const benefitsText: Record<
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  string
> = {
  [EmployeesTheCompanyWantsToOfferBenefitsToEnum.Contractors_1099]:
    "1099 Contractors",
  [EmployeesTheCompanyWantsToOfferBenefitsToEnum.PartTimeEmployees]:
    "Part-Time Employees",
  [EmployeesTheCompanyWantsToOfferBenefitsToEnum.FullTimeEmployees]:
    "Full-Time Employees",
};

export const CompanyDetails = () => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useResponsive();
  const [loading, setLoading] = useState(false);
  const [openJobClassificationsModal, setOpenJobClassificationsModal] =
    useState(false);
  const [openCompanyDetailsModal, setOpenCompanyDetailsModal] = useState(false);

  const company = useAppSelector(companySelectors.getCompany);

  const companyAddress = useMemo(() => {
    if (!company) {
      return "";
    }
    return `${company.physicalAddress?.street || ""}, ${
      company.physicalAddress?.city || ""
    }, ${company.physicalAddress?.pobox || ""} ${
      company.physicalAddress?.state || ""
    }, ${company.physicalAddress?.country || ""}`;
  }, [company]);

  const handleCloseJobClassificationsModal = useCallback(() => {
    setOpenJobClassificationsModal(false);
  }, []);

  const onUpdateJobClassification = useCallback(() => {
    setOpenJobClassificationsModal(true);
  }, []);

  const onUpdateDetails = useCallback(() => {
    setOpenCompanyDetailsModal(true);
  }, []);

  const handleCloseCompanyDetailsModal = useCallback(() => {
    setOpenCompanyDetailsModal(false);
  }, []);

  const fetchCompany = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(companyActions.fetchCompany());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const handleSaveJobClassifications = useCallback(
    async (newJobClassifications: string[]) => {
      try {
        setLoading(true);
        await dispatch(
          companyActions.updateJobClassifications({ newJobClassifications })
        );
        handleCloseJobClassificationsModal();
        notifications.notifySuccess("Job classifications updated successfully");
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [handleCloseJobClassificationsModal, dispatch]
  );

  const onSaveCompany = useCallback(
    async (values: Partial<Company>) => {
      await dispatch(companyActions.updateCompany({ values }));
      handleCloseCompanyDetailsModal();
      notifications.notifySuccess("Company updated successfully");
    },
    [handleCloseCompanyDetailsModal, dispatch]
  );

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return (
    <Container>
      <Box>
        <Loader open={loading} />
        <Typography
          sx={{
            fontSize: !isDesktop ? 24 : 32,
            fontWeight: 500,
          }}
        >
          Company Details
        </Typography>
        <Box mt={3}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            wrap="wrap"
          >
            <Grid item>
              <Button onClick={onUpdateDetails}>Update Details</Button>
            </Grid>
            <Grid item>
              <Button onClick={onUpdateJobClassification}>
                UPDATE JOB CLASSIFICATIONS
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Alert
            severity="warning"
            sx={{
              color: "white",
              background: (theme) => theme.palette.warning.main,
            }}
            icon={
              <WarningAmber
                fontSize="small"
                sx={{
                  color: "white",
                }}
              />
            }
          >
            Please provide all required information to be able to request a
            quote.
          </Alert>
        </Box>
        <Box
          mt={3}
          sx={{
            border: "1px solid rgba(189, 189, 189, 1)",
            background: "rgba(248, 248, 248, 1)",
            borderRadius: (theme) => theme.spacing(0.5),
            padding: (theme) => theme.spacing(2),
          }}
        >
          <Typography color="primary" variant="h6">
            Company Information
          </Typography>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            wrap="wrap"
          >
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">Company Name:</Typography>
              <Typography fontWeight={500}>{company?.name || ""}</Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">
                Company State of Incorporation:
              </Typography>
              <Typography fontWeight={500}>
                {company?.stateOfIncorporation || ""}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">SIC Code:</Typography>
              <Typography fontWeight={500}>{company?.SICCode || ""}</Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">Industry:</Typography>
              <Typography fontWeight={500}>
                {company?.industry || ""}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">Company Size:</Typography>
              <Typography fontWeight={500}>
                {handleNumber(company?.companySize)}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">Number of Employees:</Typography>
              <Typography fontWeight={500}>
                {handleNumber(company?.numberOfEmployees)}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">LinkedIn Page:</Typography>
              <Typography fontWeight={500}>
                {company?.linkedInCompanyPage ? (
                  <a
                    href={company.linkedInCompanyPage}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {company.linkedInCompanyPage}
                  </a>
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">Facebook Page:</Typography>
              <Typography fontWeight={500}>
                {company?.facebookCompanyPage ? (
                  <a
                    href={company.facebookCompanyPage}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {company.facebookCompanyPage}
                  </a>
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">
                Company Wants To Offer Benefits To:
              </Typography>
              <Typography fontWeight={500}>
                {company?.employeesTheCompanyWantsToOfferBenefitsTo
                  ?.map((benefit) => benefitsText[benefit])
                  .join(", ")}
              </Typography>
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Typography variant="caption">Company Address:</Typography>
              <Typography fontWeight={500}>{companyAddress}</Typography>
            </Grid>
          </Grid>
        </Box>
        <JobClassificationsDialog
          open={openJobClassificationsModal}
          onDone={handleSaveJobClassifications}
          onClose={handleCloseJobClassificationsModal}
          initialJobClassifications={company?.jobClassifications}
        />
        <CompanyDetailsDialog
          open={openCompanyDetailsModal}
          onClose={handleCloseCompanyDetailsModal}
          onSaveCompany={onSaveCompany}
        />
      </Box>
    </Container>
  );
};
