import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { MappedEmployeeDependent } from "./types";
import { dependentTableColumnsForTablet } from "./helpers";

interface Props {
  dependent: MappedEmployeeDependent;
}

export const DependentTableForTabletRow = ({ dependent }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  return (
    <Box
      sx={{
        borderTop: "1px solid rgba(189, 189, 189, 1)",
        padding: (theme) => theme.spacing(2),
        paddingLeft: (theme) => theme.spacing(8),
        background: "rgba(248, 248, 248, 1)",
      }}
    >
      <Box
        display="flex"
        gap={2}
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          gap={1}
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="flex-start"
          onClick={handleExpand}
          role="button"
        >
          <Box
            sx={{
              transformOrigin: "center",
              transition: "transform 0.3s",
              transform: `rotate(${isExpanded ? "0deg" : "-90deg"})`,
            }}
          >
            <KeyboardArrowDownOutlined
              color={isExpanded ? "primary" : undefined}
            />
          </Box>

          <Typography variant="subtitle2">
            {dependent.firstName} {dependent.lastName}
          </Typography>
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        <Box mt={2}>
          <Grid
            container
            flexWrap="wrap"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={2}
            pl={4}
          >
            {dependentTableColumnsForTablet.map((label) => (
              <Grid key={label.key} item xs={4}>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {label.label}:
                </Typography>
                <Typography>{(dependent as any)[label.key]}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};
