import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback, ChangeEventHandler } from "react";
import { EmployeeFormWidgetResValue, StateEmployee } from "store";
import { EmployeeTableHeaderCell } from "./EmployeeTableHeaderCell";
import { employeeTableColumns } from "./helpers";
import { EmployeeTableRow } from "./EmployeeTableRow";
import { MappedEmployee } from "./types";

interface Props {
  employees: MappedEmployee[];
  onSort: (key: string) => void;
  selectAllValue: boolean;
  handleSelectAll: ChangeEventHandler<HTMLInputElement>;
  name: string;
  employeesValues: EmployeeFormWidgetResValue[];
  employeesData: StateEmployee[];
}

export const EmployeesTableForDesktop = ({
  employees,
  onSort,
  selectAllValue,
  handleSelectAll,
  name,
  employeesValues,
  employeesData,
}: Props) => {
  const getLabelSorting = useCallback(
    (key: string) => {
      return () => {
        onSort(key);
      };
    },
    [onSort]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          {employeeTableColumns.map((label) => (
            <TableCell key={label.key}>
              <EmployeeTableHeaderCell
                label={label.label}
                onSort={
                  label.sortFunction ? getLabelSorting(label.key) : undefined
                }
                customKey={label.key}
                selectAllValue={selectAllValue}
                handleSelectAll={handleSelectAll}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {employees.map((employee) => (
          <EmployeeTableRow
            key={employee.id}
            employee={employee}
            name={`${name}.${employee.formKey}`}
            employeeValues={employeesValues[Number(employee.formKey)]}
            employeeData={employeesData.find((e) => e.id === employee.id)}
          />
        ))}
      </TableBody>
    </Table>
  );
};
