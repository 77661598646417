import React from "react";
import { MappedEmployeeDependent } from "./types";

interface Props {
  dependent: MappedEmployeeDependent;
  label: {
    label: string;
    key: string;
  };
}

export const DependentTableCell = ({ dependent, label }: Props) => {
  if (label.key === "actions") {
    return null;
  }
  return <>{(dependent as any)[label.key]}</>;
};
