import { Box, Grid } from "@mui/material";
import { Button } from "Components";
import Typography from "Components/elements/Typography/Typography";
import { sendDataToIntegrationApp } from "HOC";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  quoteRequestActions,
  quoteRequestSelectors,
  QuoteRequestStepEnum,
  useAppDispatch,
  useAppSelector,
} from "store";
import { GetQuote, GoToSubmitRequestParams } from "Views";

export const GetQuoteWrapper = () => {
  const dispatch = useAppDispatch();
  const [renderedApp, setRenderedApp] = useState("");
  const location = useLocation();
  const policy = useAppSelector(quoteRequestSelectors.getPolicy);
  const numberOfTeamMembersThatMightWantToEnroll = useAppSelector(
    quoteRequestSelectors.getNumberOfTeamMembersThatMightWantToEnroll
  );

  const goToGetQuoteStep = useMemo(
    () => location.state?.goToGetQuoteStep,
    [location.state?.goToGetQuoteStep]
  );

  const [quoteStep, setQuoteStep] = useState<"activity" | "get-quote">(
    policy || goToGetQuoteStep ? "get-quote" : "activity"
  );

  const setStep = useCallback(
    (value: QuoteRequestStepEnum) => {
      dispatch(quoteRequestActions.saveStep(value));
    },
    [dispatch]
  );

  const [showNav, setShowNav] = useState(false);

  const onGetQuote = useCallback(() => {
    setQuoteStep("get-quote");
  }, []);

  const initialValues = useMemo(() => {
    return {
      numberOfTeamMembersThatMightWantToEnroll,
      policy,
    };
  }, [policy, numberOfTeamMembersThatMightWantToEnroll]);

  const onGoToCompanyInformation = useCallback(
    (params: GoToSubmitRequestParams) => {
      setStep(QuoteRequestStepEnum.COMPANY_INFORMATION);
      dispatch(quoteRequestActions.saveGetQuoteData(params));
    },
    [setStep, dispatch]
  );

  const onHandleShowNav = useCallback((payload: boolean) => {
    setShowNav(payload);
  }, []);

  const handleHomeClick = useCallback(() => {
    dispatch(quoteRequestActions.reset());
    sendDataToIntegrationApp("GO_HOME", renderedApp);
    setQuoteStep("activity");
  }, [dispatch, renderedApp]);

  const onRenderedAppChange = useCallback((renderedAppKey: string) => {
    setRenderedApp(renderedAppKey);
  }, []);

  return (
    <>
      {showNav && (
        <Grid container alignItems="center" columnSpacing={1}>
          <Grid item>
            <Button
              sx={{
                textTransform: "capitalize",
                color: "rgba(0, 0, 0, 0.6)",
              }}
              variant="text"
              disableTouchRipple
              onClick={handleHomeClick}
            >
              Home
            </Button>
          </Grid>
          <Grid item>
            <Typography>/</Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{
                textTransform: "capitalize",
                color: "rgba(0, 0, 0, 0.87)",
              }}
              variant="text"
              disableTouchRipple
            >
              Get Quote
            </Button>
          </Grid>
        </Grid>
      )}

      <Box mt={2}>
        <GetQuote
          onGoToCompanyInformation={onGoToCompanyInformation}
          onHandleShowNav={onHandleShowNav}
          renderedApp={renderedApp}
          onRenderedAppChange={onRenderedAppChange}
          step={quoteStep}
          initialValues={initialValues}
          onGetQuote={onGetQuote}
        />
      </Box>
    </>
  );
};
