import { Close, Edit } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { MappedEmployee } from "./types";

interface Props {
  employee: MappedEmployee;
  label: {
    label: string;
    key: string;
  };
  onDelete: (employee: MappedEmployee) => void;
  onEdit: (employee: MappedEmployee) => void;
}

export const EmployeeTableCell = ({
  employee,
  label,
  onEdit,
  onDelete,
}: Props) => {
  const handleEdit = useCallback(() => {
    onEdit(employee);
  }, [onEdit, employee]);

  const handleDelete = useCallback(() => {
    onDelete(employee);
  }, [onDelete, employee]);

  if (label.key !== "actions") {
    return (employee as any)[label.key];
  }

  return (
    <Grid container wrap="nowrap" spacing={2} alignItems="center">
      <Grid item>
        <IconButton
          onClick={handleDelete}
          sx={{
            border: "0.75px solid rgba(0, 0, 0, 0.6)",
            borderRadius: (theme) => theme.spacing(0.25),
            padding: (theme) => theme.spacing(0.5),
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleEdit}
          sx={{
            border: "0.75px solid rgba(0, 0, 0, 0.6)",
            borderRadius: (theme) => theme.spacing(0.25),
            padding: (theme) => theme.spacing(0.5),
          }}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
