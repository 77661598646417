import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FormikAutocomplete,
  FormikDropzone,
  Radios,
  AddressFormFields,
} from "Components";
import { Field, useFormikContext } from "formik";
import {
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  YesNoEnum,
} from "gql/graphql";
import React from "react";

import { CheckboxWithLabel, Select, TextField } from "formik-mui";
import { useResponsive } from "hooks";
import { Clear, InfoOutlined } from "@mui/icons-material";

import { GroupInput, GroupInputItem } from "./GroupInput";
import {
  employeesTheCompanyWantsToOfferBenefitsToOptions,
  sicCodes,
  statesOfIncorporation,
  employeesTheCompanyWantsToOfferBenefitsToOptionsTitle,
  payrollCycleOptions,
  payrollCycleTitle,
} from "./helpers";
import { useCompanyForm } from "./useCompanyForm";
import { FormValues } from "./types";

export const CompanyForm = () => {
  const { isDesktop } = useResponsive();
  const { getDownloadUrl, handleDeleteFile, handleUploadFile, initialValues } =
    useCompanyForm();
  const formikProps = useFormikContext<FormValues>();
  const { values, setFieldValue } = formikProps;
  return (
    <Box
      p={2}
      sx={{
        border: "1px solid rgba(189, 189, 189, 1)",
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: "0px 0px 0px 1px rgba(224, 224, 224, 1)",
        background: "white",
      }}
    >
      <Grid
        container
        spacing={3}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography
            variant="h5"
            sx={{
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            Company Information
          </Typography>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                required
                size="small"
                name="legalName"
                component={TextField}
                label="Company Legal Name"
                fullWidth
              />
            </GroupInputItem>
            <GroupInputItem>
              <Field
                required
                size="small"
                name="stateOfIncorporation"
                component={FormikAutocomplete}
                label="State of Incorporation"
                fullWidth
                options={statesOfIncorporation}
              />
            </GroupInputItem>
          </GroupInput>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                required
                size="small"
                name="SICCode"
                component={FormikAutocomplete}
                label="SIC Code"
                fullWidth
                options={sicCodes}
                onCustomChange={(value: { description: string }) => {
                  if (!values.industry && !!value?.description) {
                    setFieldValue("industry", value.description);
                  }
                }}
              />
            </GroupInputItem>
            <GroupInputItem>
              <Field
                size="small"
                name="industry"
                component={TextField}
                label="Industry"
                fullWidth
              />
            </GroupInputItem>
          </GroupInput>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                component={TextField}
                name="companySize"
                size="small"
                integer="true"
                type="number"
                inputProps={{
                  min: 1,
                }}
                variant="outlined"
                required
                label="Company Size"
                fullWidth
              />
            </GroupInputItem>
            <GroupInputItem>
              <Field
                component={TextField}
                name="numberOfEmployees"
                size="small"
                integer="true"
                type="number"
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                required
                label="Total Number of Employees"
                fullWidth
              />
            </GroupInputItem>
          </GroupInput>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                component={TextField}
                name="numberOf1099Contractors"
                size="small"
                integer="true"
                type="number"
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                required
                label="Number of 1099 Contractors"
                fullWidth
              />
            </GroupInputItem>
            <GroupInputItem>
              <Field
                component={TextField}
                name="numberOfFullTimeEmployees"
                size="small"
                integer="true"
                type="number"
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                required
                label="Number of Full-Time Employees"
                fullWidth
              />
            </GroupInputItem>
          </GroupInput>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                component={TextField}
                name="numberOfPartTimeEmployees"
                size="small"
                integer="true"
                type="number"
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                required
                label="Number of Part-Time Employees"
                fullWidth
              />
            </GroupInputItem>
            {isDesktop ? <GroupInputItem>{/*  */}</GroupInputItem> : null}
          </GroupInput>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Radios
            name="isTheCompanyRequiredToHaveOSHATraining"
            label="Company is required to have OSHA training?"
            options={Object.values(YesNoEnum).map((option) => ({
              label: option === YesNoEnum.No ? "No" : "Yes",
              value: option,
            }))}
            required
          />
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <Field
                  name="employeesTheCompanyWantsToOfferBenefitsTo"
                  component={Select}
                  label="I Want to Offer Benefits to"
                  options={employeesTheCompanyWantsToOfferBenefitsToOptions}
                  fullWidth
                  multiple
                  renderValue={(
                    selected: EmployeesTheCompanyWantsToOfferBenefitsToEnum[]
                  ) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            employeesTheCompanyWantsToOfferBenefitsToOptionsTitle[
                              value
                            ]
                          }
                          sx={{
                            padding: (theme) => theme.spacing(0),
                            fontSize: (theme) => theme.spacing(1.5),
                            height: (theme) => theme.spacing(3),
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {employeesTheCompanyWantsToOfferBenefitsToOptions.map(
                    (option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {
                            employeesTheCompanyWantsToOfferBenefitsToOptionsTitle[
                              option
                            ]
                          }
                        </MenuItem>
                      );
                    }
                  )}
                </Field>
              </FormControl>
            </GroupInputItem>
            <GroupInputItem>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <Field
                  name="payrollCycle"
                  component={Select}
                  label="Payroll Cycle"
                  options={payrollCycleOptions}
                  fullWidth
                  IconComponent={
                    values.payrollCycle
                      ? () => (
                          <IconButton
                            size="small"
                            onClick={() => {
                              setFieldValue("payrollCycle", "");
                            }}
                            sx={{
                              marginRight: (theme) => theme.spacing(1),
                            }}
                          >
                            <Clear />
                          </IconButton>
                        )
                      : undefined
                  }
                >
                  {payrollCycleOptions.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {payrollCycleTitle[option]}
                      </MenuItem>
                    );
                  })}
                </Field>
              </FormControl>
            </GroupInputItem>
          </GroupInput>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                component={(props: any) => (
                  <TextField
                    {...props}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="When a new employee joins your company, how many days do you want to wait before allowing them to enroll into your insurance benefits?">
                          <InfoOutlined color="primary" />
                        </Tooltip>
                      ),
                      min: 0,
                    }}
                  />
                )}
                name="waitingPeriod"
                size="small"
                integer="true"
                type="number"
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                label="Waiting Period"
                fullWidth
              />
            </GroupInputItem>
            {isDesktop ? <GroupInputItem>{/*  */}</GroupInputItem> : null}
          </GroupInput>
        </Grid>
        <Grid item>
          <Radios
            name="doesTheCompanyHaveASection125PlanInPlace"
            label="Company has a section 125 plan in place?"
            tooltip="Section 125 Plans allow employee contributions to employer-sponsored benefit plans to be made with pre-tax dollars, automatically increasing take-home pay"
            options={Object.values(YesNoEnum).map((option) => ({
              label: option === YesNoEnum.No ? "No" : "Yes",
              value: option,
            }))}
          />
        </Grid>
        <Grid item>
          <Radios
            name="isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch"
            label="Is your group a church or will the policy be maintained by a church?"
            options={Object.values(YesNoEnum).map((option) => ({
              label: option === YesNoEnum.No ? "No" : "Yes",
              value: option,
            }))}
            required
          />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                component={TextField}
                name="linkedInCompanyPage"
                size="small"
                type="url"
                variant="outlined"
                label="Linkedin Page"
                fullWidth
              />
            </GroupInputItem>
            <GroupInputItem>
              <Field
                component={TextField}
                name="facebookCompanyPage"
                size="small"
                type="url"
                variant="outlined"
                label="Facebook Page"
                fullWidth
              />
            </GroupInputItem>
            <GroupInputItem>
              <Field
                component={TextField}
                name="website"
                size="small"
                type="url"
                variant="outlined"
                label="Company Website"
                fullWidth
              />
            </GroupInputItem>
          </GroupInput>
        </Grid>

        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Field
                component={FormikDropzone}
                initialFiles={
                  initialValues.logoS3Key
                    ? [
                        {
                          key: values.logoS3Key,
                          id: values.logoS3Key,
                          file: {
                            type: "image",
                            name: "",
                          },
                        },
                      ]
                    : undefined
                }
                getDownloadUrl={getDownloadUrl}
                name="logoS3Key"
                options={{
                  accept: {
                    "image/*": [".png", ".jpeg", ".jpg"],
                  },
                  multiple: false,
                }}
                onUpload={handleUploadFile}
                progressPosition="top"
                showStatus
                size="small"
                inputId="logoS3Key"
                fullWidth
                label="Upload Company Logo"
                onFileDeleted={handleDeleteFile}
                useSingleS3Key
              />
            </GroupInputItem>
            {isDesktop ? <GroupInputItem>{/*  */}</GroupInputItem> : null}
          </GroupInput>
        </Grid>
        <Grid item>
          <Grid container spacing={2} flexDirection="column">
            <Grid item>
              <Typography
                variant="h5"
                component="label"
                color="text"
                fontSize={16}
                fontWeight="500"
              >
                Company Mailing Address
              </Typography>
            </Grid>
            <Grid item>
              <AddressFormFields
                formikProps={formikProps}
                poBoxName="physicalAddress.pobox"
                cityName="physicalAddress.city"
                islandName="physicalAddress.state"
                countryName="physicalAddress.country"
                streetName="physicalAddress.street"
                notify
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <GroupInput>
            <GroupInputItem>
              <Box sx={{ display: "none" }}>
                <Field
                  component={TextField}
                  name="mailingAddress.differentToPhysicalAddress"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Field
                name="differentToPhysicalAddress"
                component={CheckboxWithLabel}
                type="checkbox"
                sx={{
                  color: (theme: Theme) => theme.palette.primary,
                }}
                Label={{
                  label: "Mailing Address Different from Physical Address",
                  sx: {
                    color: (theme: Theme) => theme.palette.primary,
                  },
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const v = !!event.target.checked;
                  setFieldValue("differentToPhysicalAddress", v);
                  setFieldValue(
                    "mailingAddress.differentToPhysicalAddress",
                    v ? YesNoEnum.Yes : YesNoEnum.No
                  );
                }}
                color="primary"
              />
            </GroupInputItem>
          </GroupInput>
        </Grid>
        {values.mailingAddress?.differentToPhysicalAddress === YesNoEnum.Yes ? (
          <Grid item>
            <Grid container spacing={2} flexDirection="column">
              <Grid item>
                <Typography
                  variant="h5"
                  component="label"
                  color="text"
                  fontSize={16}
                  fontWeight="500"
                >
                  Company Registered Address
                </Typography>
              </Grid>
              <Grid item>
                <AddressFormFields
                  formikProps={formikProps}
                  poBoxName="mailingAddress.pobox"
                  cityName="mailingAddress.city"
                  islandName="mailingAddress.state"
                  countryName="mailingAddress.country"
                  streetName="mailingAddress.street"
                  notify
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
