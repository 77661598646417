import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FormikDatePicker } from "Components/elements";
import { DATE_DISPLAY_FORMAT, DATE_TYPING_FORMAT } from "app-constants";
import { GenderEnum, MedicarePrimaryEnum, RelationshipEnum } from "gql/graphql";
import { useResponsive } from "hooks";
import { FormValues, FormValuesDependent } from "./types";
import { startOfToday } from "./helpers";
import { DeleteDependentDialog } from "./DeleteDependentDialog";

interface Props {
  name: string;
  dependent: FormValuesDependent;
  syncCoverageToDependents: (dependents: FormValuesDependent[]) => void;
}

export const DependentFields = ({
  name,
  dependent,
  syncCoverageToDependents,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { isDesktop, isTablet } = useResponsive();

  const [openRemoveDependentDialog, setOpenRemoveDependentDialog] =
    useState(false);

  const xs = useMemo(() => {
    if (isDesktop) {
      return undefined;
    }
    if (isTablet) {
      return 6;
    }
    return 12;
  }, [isDesktop, isTablet]);

  const flex = useMemo(() => {
    if (isDesktop) {
      return 1;
    }
    return undefined;
  }, [isDesktop]);

  const onRemoveDependent = useCallback(() => {
    const dependents = values.dependents.filter((d) => d.id !== dependent.id);
    setFieldValue("dependents", dependents);
    syncCoverageToDependents(dependents);
  }, [
    setFieldValue,
    values.dependents,
    dependent.id,
    syncCoverageToDependents,
  ]);

  const openRemovedDependentDialog = useCallback(() => {
    setOpenRemoveDependentDialog(true);
  }, []);

  const openCloseDependentDialog = useCallback(() => {
    setOpenRemoveDependentDialog(true);
  }, []);

  return (
    <>
      <Box
        mt={2}
        sx={{
          background: "rgba(241, 248, 255, 1)",
          border: "1px solid rgba(189, 189, 189, 1)",
          boxShadow: "0px 0px 0px 1px rgba(224, 224, 224, 1)",
          padding: (theme) => theme.spacing(2),
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="body1">
              {dependent.relationship === RelationshipEnum.Spouse
                ? "Spouse"
                : "Child"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="error"
              onClick={openRemovedDependentDialog}
              variant="contained"
            >
              REMOVE
            </Button>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item flex={flex} xs={xs}>
              <Field
                size="small"
                component={TextField}
                name={`${name}.firstName`}
                label="First Name"
                fullWidth
                required
              />
            </Grid>

            <Grid item flex={flex} xs={xs}>
              <Field
                size="small"
                component={TextField}
                name={`${name}.lastName`}
                fullWidth
                label="Last Name"
                required
              />
            </Grid>

            <Grid item flex={flex} xs={xs}>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <Field
                  component={TextField}
                  select
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  name={`${name}.gender`}
                  label="Gender"
                  required
                  size="small"
                >
                  <MenuItem value={GenderEnum.M}>Male</MenuItem>
                  <MenuItem value={GenderEnum.F}>Female</MenuItem>
                </Field>
              </FormControl>
            </Grid>

            <Grid item flex={flex} xs={xs}>
              <Field
                size="small"
                component={FormikDatePicker}
                disablePast={false}
                disableToolbar={false}
                disableFuture
                maxDate={startOfToday}
                label="Date of Birth"
                name={`${name}.dob`}
                required
                placeholder={DATE_TYPING_FORMAT.toUpperCase()}
                typingFormat={DATE_TYPING_FORMAT}
                displayFormat={DATE_DISPLAY_FORMAT}
                fullWidth
              />
            </Grid>

            <Grid item flex={flex} xs={xs}>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <Field
                  component={TextField}
                  select
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  name={`${name}.medicarePrimary`}
                  label="Medicare Primary"
                  size="small"
                >
                  <MenuItem value={MedicarePrimaryEnum.Yes}>Yes</MenuItem>
                  <MenuItem value={MedicarePrimaryEnum.No}>No</MenuItem>
                  <MenuItem value={MedicarePrimaryEnum.U}>Unknown</MenuItem>
                </Field>
              </FormControl>
            </Grid>

            <Grid item flex={flex} xs={xs}>
              <Field
                size="small"
                component={TextField}
                name={`${name}.zipCode`}
                fullWidth
                label="Zip Code"
                required
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DeleteDependentDialog
        open={openRemoveDependentDialog}
        onClose={openCloseDependentDialog}
        onRemove={onRemoveDependent}
        dependent={dependent}
      />
    </>
  );
};
