import { graphqlRequest, n8nAxiosRequest } from "api";
import axios from "axios";
import { GetSigningUrlForHb2015LetterResponse, Opportunity } from "gql/graphql";
import { gql } from "graphql-request";

export const getSigningInfoRequest = async (
  companyId: string,
  oppActivePolicyID: string,
  locationInfo: { ip: any; country: any; latitude: string; longitude: string }
) => {
  const res = await graphqlRequest.request<{
    getSigningUrlForHB2015Letter: GetSigningUrlForHb2015LetterResponse;
  }>(
    gql`
      query GetSigningUrlForHB2015Letter(
        $input: GetSigningUrlForHB2015LetterInput!
      ) {
        getSigningUrlForHB2015Letter(input: $input) {
          message
          type
          data {
            __typename
            ... on ReturnSigningTicket {
              id
              status
              expiresAt
              signingUrl
            }
            ... on NeedOTP {
              resendDelaySeconds
              needOtp
            }
          }
        }
      }
    `,
    {
      input: {
        oppActivePolicyID,
        inPerson: true,
        companyId,
        locationInfo,
      },
    }
  );

  return res.getSigningUrlForHB2015Letter;
};

export const getLocationRequest = async () => {
  const locationRes = await axios.get("https://ip-api.io/json");

  const { latitude, longitude, ip, country_name: country } = locationRes.data;
  return {
    ip,
    country,
    latitude: `${latitude}`,
    longitude: `${longitude}`,
  };
};

export const getOpportunityActivePolicySignedDocument = async (
  oppActivePolicyID: string
) => {
  const res = await n8nAxiosRequest.get<{ downloadUrl: string }>(
    `/get-hb2015-doc/${oppActivePolicyID}`
  );
  return res.data.downloadUrl;
};

export const getOpportunityActivePolicies = async (
  opportunityID: string,
  companyId: string
) => {
  const res = await graphqlRequest.request<{
    getOpportunity: Opportunity;
  }>(
    gql`
      query getOpportunity($getOpportunityId: ID!, $companyId: ID) {
        getOpportunity(
          id: $getOpportunityId
          isZohoId: false
          companyId: $companyId
        ) {
          id
          opportunityActivePolicies {
            id
            hb2015LetterSigned
          }
        }
      }
    `,
    {
      getOpportunityId: opportunityID,
      companyId,
    }
  );

  return res.getOpportunity;
};
