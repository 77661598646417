import { Box, Grid } from "@mui/material";
import { ROUTES } from "app-constants";
import { Button } from "Components";
import Typography from "Components/elements/Typography/Typography";
import React, { PropsWithChildren, useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface Props extends PropsWithChildren {
  pageTitle: string;
}

export const SingleRequestLayout = ({ children, pageTitle }: Props) => {
  const navigate = useNavigate();

  const handleHomeClick = useCallback(() => {
    navigate(ROUTES.requests.fullPath);
  }, [navigate]);

  return (
    <Grid
      wrap="nowrap"
      justifyContent="space-between"
      direction="column"
      alignItems="stretch"
      container
    >
      {pageTitle ? (
        <Grid item>
          <Grid container alignItems="center" columnSpacing={1}>
            <Grid item>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
                variant="text"
                disableTouchRipple
                onClick={handleHomeClick}
              >
                Home
              </Button>
            </Grid>
            <Grid item>
              <Typography>/</Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                variant="text"
                disableTouchRipple
              >
                {pageTitle}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Grid item flex={1}>
        <Box
          sx={{
            paddingTop: ({ spacing: s }) => s(pageTitle ? 2 : 0),
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
