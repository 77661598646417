import { Box, Grid, Theme, Typography } from "@mui/material";
import React from "react";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import { MappedEmployeeDependent } from "./types";
import { dependentTableColumnsForTablet } from "./helpers";

interface Props {
  dependent: MappedEmployeeDependent;
  name: string;
}

export const DependentTableForTabletRow = ({ dependent, name }: Props) => {
  return (
    <Box
      sx={{
        borderTop: "1px solid rgba(189, 189, 189, 1)",
        padding: (theme) => theme.spacing(2),
        paddingLeft: (theme) => theme.spacing(8),
        background: "rgba(248, 248, 248, 1)",
      }}
    >
      <Grid
        container
        flexWrap="nowrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item>
          <Box>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              sx={{
                color: (theme: Theme) => theme.palette.primary,
              }}
              color="primary"
              name={`${name}.isSelected`}
              disabled
              Label={{
                label: (
                  <Typography color="primary">
                    {dependent.firstName || ""} {dependent.lastName || ""}
                  </Typography>
                ),
                sx: {
                  color: (theme: Theme) => theme.palette.primary,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={1}>
        <Grid
          container
          flexWrap="wrap"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
          pl={4}
        >
          {dependentTableColumnsForTablet.map((label) => (
            <Grid key={label.key} item xs={4}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {label.label}:
              </Typography>
              <Typography>{(dependent as any)[label.key]}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
