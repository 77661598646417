import { Button, Grid } from "@mui/material";
import React from "react";

export const GetQuoteTBC = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        border: "1px solid rgba(189, 189, 189, 1)",
        padding: (theme) => theme.spacing(1),
        height: 300,
      }}
    >
      <Grid item>
        <Button LinkComponent="a" href="" target="_blank" rel="noreferrer">
          Activity (TBC)
        </Button>
      </Grid>
    </Grid>
  );
};
