import React from "react";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

interface Props {
  onClick: () => any;
  hideText?: true;
}

export const BackButton = ({ onClick, hideText }: Props) => {
  return (
    <Button onClick={onClick} variant="text" color="primary">
      <ArrowBack
        sx={{
          marginRight: ({ spacing: s }) => s(1),
        }}
      />
      {!hideText && "Back"}
    </Button>
  );
};
