import { Box } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { RelationshipEnum } from "gql/graphql";
import { FormValues, FormValuesDependent } from "./types";
import { DependentFields } from "./DependentFields";

interface Props {
  syncCoverageToDependents: (dependents: FormValuesDependent[]) => void;
}

export const SpouseFields = ({ syncCoverageToDependents }: Props) => {
  const { values } = useFormikContext<FormValues>();

  return (
    <Box>
      {values.dependents.map((dependent, index) =>
        dependent.relationship === RelationshipEnum.Spouse ? (
          <DependentFields
            key={dependent.id}
            name={`dependents.${index}`}
            dependent={dependent}
            syncCoverageToDependents={syncCoverageToDependents}
          />
        ) : null
      )}
    </Box>
  );
};
