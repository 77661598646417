import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  LepCalculatorPartDFields,
  LepCalculatorPartDResult,
  LepCalculatorTitle,
  Loader,
} from "Components";
import { Form, Formik, FormikConfig } from "formik";
import * as yup from "yup";
import { LepCalculatorPartDFormValues } from "interfaces";
import { notifications } from "services";
import { AxiosError } from "axios";
import { calculatePartDPenalty, sendPostMessageToBufferWebsite } from "helpers";
import { ArrowBack } from "@mui/icons-material";
import { useResponsive } from "hooks";
import { postPartD } from "../request";

export const LepCalculatorPartD = () => {
  const { isMDDown } = useResponsive();
  const [loading, setLoading] = useState(false);
  const [calculatedPenalty, setCalculatedPenalty] = useState<string | null>(
    null
  );
  const [step, setStep] = useState<"form" | "result">("form");
  const [resultVariant, setResultVariant] = useState<number>(1);
  useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [uuid, setUUid] = useState<string>("");
  const [yearsCalculated, setYearsCalculated] = useState<
    Record<number, string>
  >({});
  const [savedInitialValues, setSavedInitialValues] =
    useState<LepCalculatorPartDFormValues | null>(null);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );

  const initialValues: LepCalculatorPartDFormValues = useMemo(() => {
    return (
      savedInitialValues || {
        coverageNumberOfMonths: 12,
        enrolledInMedicaid: null,
        receiveSupplementalSecurityIncome: null,
        email,
      }
    );
  }, [email, savedInitialValues]);

  const validationSchema = useMemo(() => {
    return yup.object({
      coverageNumberOfMonths: yup
        .number()
        .min(0, "Must be greater than or equal to 0")
        .integer("Must be a whole number")
        .max(120, "Must be less than or equal to 120")
        .required("This field is required")
        .typeError("This field is required"),
      enrolledInMedicaid: yup
        .string()
        .required("This field is required")
        .typeError("This field is required"),
      receiveSupplementalSecurityIncome: yup
        .string()
        .required("This field is required")
        .typeError("This field is required"),
      email: yup
        .string()
        .email("Provide a valid email address")
        .required("This field is required")
        .typeError("Provide a valid email address"),
    });
  }, []);

  const resizeParentWindow = useCallback(
    (height?: number, avoidScrolling = false) => {
      if (!wrapperElement) {
        return;
      }
      setTimeout(() => {
        sendPostMessageToBufferWebsite({
          action: "resize-iframe",
          height:
            (height || wrapperElement.offsetHeight) + (isMDDown ? 100 : 150),
          form: "d",
          avoidScrolling,
        });
      }, 300);
    },
    [wrapperElement, isMDDown]
  );

  const handleBackToForm = useCallback(() => {
    setStep("form");
    resizeParentWindow();
  }, [resizeParentWindow]);

  const handleSubmit: FormikConfig<LepCalculatorPartDFormValues>["onSubmit"] =
    useCallback(
      async (values, { setSubmitting }) => {
        setLoading(true);
        try {
          const time = String(Date.now());
          const uuidRes = time.substring(time.length - 9);
          const result = calculatePartDPenalty(values);
          const payload = {
            email: values.email,
            ssi: values.receiveSupplementalSecurityIncome,
            enrolledinMedicaid: values.enrolledInMedicaid,
            monthsWithoutCoverage: values.coverageNumberOfMonths,
            uuid: uuidRes,
            calculatedPenalty: result,
          };
          await postPartD(payload);
          setCalculatedPenalty(result.calculatedPenalty);
          setResultVariant(result.resultVariant);
          setUUid(uuidRes);
          setEmail(payload.email || "");
          setYearsCalculated(result.yearsCalculated || {});
          setStep("result");
          setSavedInitialValues(values);
          resizeParentWindow();
        } catch (error) {
          const err = error as AxiosError<{ message: string }>;
          notifications.notifyError(err.response?.data.message || err.message);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      },
      [resizeParentWindow]
    );

  const childrenWrapperRef = useCallback(
    (divElement: HTMLDivElement | null) => {
      if (divElement) {
        setWrapperElement(divElement);
        resizeParentWindow(divElement.offsetHeight);
      }
    },
    [resizeParentWindow]
  );

  return (
    <Box ref={childrenWrapperRef}>
      <Loader open={loading} />
      <Box pb={8}>
        <LepCalculatorTitle
          title={`Medicare Part D ${
            step === "result" ? "Calculation Results" : "Penalty Calculator"
          }`}
        />
      </Box>
      <Box pb={step === "form" ? 8 : 8}>
        {step === "form" ? (
          <Formik<LepCalculatorPartDFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
          >
            <Form>
              <LepCalculatorPartDFields
                resizeParentWindow={resizeParentWindow}
              />
            </Form>
          </Formik>
        ) : null}
        {step === "result" ? (
          <LepCalculatorPartDResult
            resultVariant={resultVariant}
            calculatedPenalty={calculatedPenalty}
            email={email}
            uuid={uuid}
            yearsCalculated={yearsCalculated}
            resizeParentWindow={resizeParentWindow}
          />
        ) : null}
      </Box>
      <Box display="flex" justifyContent="center">
        <Box maxWidth={500}>
          <Typography variant="caption" align="center" component="p">
            This calculator is intended to illustrate an estimated Late
            Enrollment Penalty only.
            <br />A person&apos;s actual LEP will be assessed by CMS.
          </Typography>
        </Box>
      </Box>

      {step === "result" ? (
        <Box pt={8} display="flex" justifyContent="center">
          <Button
            sx={{
              background: ({ palette: p }) => p.grey[200],
              color: ({ palette: p }) => p.grey[800],
              borderColor: ({ palette: p }) => p.grey[400],
              paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
              paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
            }}
            onClick={handleBackToForm}
            variant="outlined"
          >
            <ArrowBack
              fontSize="small"
              sx={{ marginRight: ({ spacing }) => spacing(1) }}
            />{" "}
            START OVER
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
