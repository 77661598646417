import { ROUTES } from "app-constants";
import { RequestsLayout } from "Components";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Requests, TerminationLetterRequest } from "Views";

export const RequestsRoutes = () => {
  return (
    <RequestsLayout>
      <Routes>
        <Route index element={<Requests />} />
        <Route
          path={ROUTES.terminationLetterRequest.path}
          element={<TerminationLetterRequest />}
        />
        <Route path="/*" element={<Navigate to={ROUTES.requests.fullPath} />} />
      </Routes>
    </RequestsLayout>
  );
};
