import React, { useCallback, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { FieldProps } from "formik";

interface Option {
  title: string;
  value: string;
}

interface Props extends FieldProps {
  options: Option[];
  label?: string;
  multiline?: boolean;
  noHelperText?: boolean;
  onCustomChange?: (value: Option) => void;
}

export const FormikAutocomplete = ({
  field,
  form,
  options,
  label,
  multiline,
  noHelperText,
  onCustomChange,
  ...props
}: Props) => {
  const getOptionLabel = useMemo(() => {
    return (option: any) => {
      return (option.title ||
        options.find((o) => o.value === option)?.title ||
        option) as string;
    };
  }, [options]);

  const { name, value, onBlur } = field;
  const { error, touched } = form.getFieldMeta(name);

  const handleAutocompleteChange = useCallback(
    (event: any, item: Option) => {
      form.setFieldValue(name, item?.value || "", true);
      if (onCustomChange) {
        onCustomChange(item);
      }
    },
    [form, name, onCustomChange]
  );

  const isOptionEqualToValue = useCallback((option: Option, v: any) => {
    if (!v) {
      return false;
    }
    return option.value === v || option.value === v.value;
  }, []);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      value={value || null}
      onChange={handleAutocompleteChange}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            name={name}
            label={label}
            error={!!(touched && error)}
            helperText={noHelperText ? "" : (touched && error) || ""}
            multiline={multiline}
            fullWidth
            required
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
      onBlur={onBlur}
      {...props}
    />
  );
};
