import { DescriptionOutlined } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";

interface Props {
  onLoadFromCensusSheet: () => void;
  onAddEmployee: () => void;
}

export const EmployeeCensusHeader = ({
  onLoadFromCensusSheet,
  onAddEmployee,
}: Props) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      wrap="wrap"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h6">Employee Census</Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="flex-start"
          spacing={2}
          wrap="wrap"
          alignItems="center"
        >
          <Grid item>
            <Button variant="contained" onClick={onLoadFromCensusSheet}>
              <DescriptionOutlined
                sx={{ marginRight: (theme) => theme.spacing(1) }}
              />
              LOAD FROM CENSUS SHEET
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={onAddEmployee}>
              ADD EMPLOYEE
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
