import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useMemo,
  PropsWithChildren,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  DropzoneOptions,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { Box, Typography, Theme, FormHelperText } from "@mui/material";
import { FieldProps } from "formik";
import { useResponsive } from "hooks";
import { ReactComponent as UploadIcon } from "images/upload.svg";
import theme from "utils/theme";
import FileInfo from "./FileInfo";
import { UploadableFile, UploadedFile } from "./FormikDropzone.types";

export interface FormikDropzoneProps extends FieldProps, PropsWithChildren {
  options?: Omit<DropzoneOptions, "onDrop">;
  onUpload: (
    file: File,
    abortRequest: (instance: XMLHttpRequest) => void,
    trackProgress: (progressEvent: ProgressEvent<EventTarget>) => any
  ) => Promise<any>;
  progressPosition: "top" | "bottom";
  initialFiles?: UploadedFile[];
  showStatus?: boolean;
  size?: "small" | "large";
  inputId?: string;
  includeRejectedOrFailed?: boolean;
  /** this function should not change refrence so maybe it can be a helper function or with useCallback */
  mapFileData?: (file: UploadableFile | UploadedFile) => any;
  hideFieldOnUploaded?: boolean;
  onFileDeleted?: () => any;
  resetWhenFormFieldsReset?: boolean;
  updateFilesWithInitialFiles?: boolean;
  label?: string;
  required?: boolean;
  getDownloadUrl?: (s3Key: string) => Promise<string>;
  useSingleS3Key?: boolean;
}

const makeStyles = ({ spacing: s, palette: p }: Theme) => ({
  dropzoneWrapper: {
    position: "relative",
    zIndex: 1,
  },
  dropzone: {
    backgroundColor: p.grey[100],
    borderRadius: "4px",
    padding: s(1, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "&::after": {
      content: '" "',
      position: "absolute",
      border: `4px dashed ${p.grey[400]}`,
      borderRadius: "4px",
      top: -1,
      right: -1,
      bottom: -1,
      left: -1,
      zIndex: -1,
    },
    "&$dropzoneError": {
      "&::after": {
        borderColor: p.error.main,
      },
    },
  },
  dropzoneError: {},
  uploadIconSmall: {
    width: 30,
    marginRight: s(1),
  },
  dropzoneHelperTextSmall: {
    textAlign: "center",
  },
  dropzoneHelperText: {
    paddingTop: s(0.5),
    paddingBottom: s(3.5),
    maxWidth: 125,
    textAlign: "center",
  },
  browse: {
    color: "#2F80ED",
    textDecoration: "underline",
    fontSize: "inherit",
  },
  note: {
    fontSize: "0.625rem",
    textAlign: "center",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: s(1),
  },
});

const classes = makeStyles(theme);

const INITIAL_FILES: UploadedFile[] = [];

export const FormikDropzone: FC<FormikDropzoneProps> = ({
  form,
  field,
  options,
  children,
  onUpload,
  progressPosition,
  showStatus,
  size = "large",
  inputId,
  includeRejectedOrFailed = false,
  mapFileData,
  initialFiles = INITIAL_FILES,
  hideFieldOnUploaded,
  onFileDeleted,
  resetWhenFormFieldsReset,
  updateFilesWithInitialFiles,
  label,
  required,
  getDownloadUrl,
  useSingleS3Key,
}) => {
  const { isSMDown } = useResponsive();
  const { name } = field;
  const { setFieldValue, getFieldMeta, setFieldTouched, validateForm } = form;
  const { error, touched, value } = getFieldMeta(name);

  const isMultiple = !(options && options.multiple === false);

  const modifiedInitialFiles = useMemo(() => {
    return initialFiles && initialFiles.length > 0
      ? initialFiles
      : INITIAL_FILES;
  }, [initialFiles]);

  const initialFilesMap = useMemo(() => {
    return modifiedInitialFiles.reduce((acc, file) => {
      acc[file.id] = file;
      return acc;
    }, {} as { [key: string]: UploadedFile });
  }, [modifiedInitialFiles]);

  const [allFiles, setAllFiles] = useState<UploadableFile[]>(
    modifiedInitialFiles.map((file) => ({ ...file, isUploaded: true }))
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const mappedAcceptedFiles = acceptedFiles.map((file) => ({
        id: uuidv4(),
        file,
        errors: [],
        isUploaded: false,
      }));
      const mappedRejectedFiles = rejectedFiles.map(({ file, errors }) => ({
        id: uuidv4(),
        file,
        errors,
        isUploaded: false,
      }));
      if (isMultiple) {
        setAllFiles((prevVal) => [
          ...prevVal,
          ...mappedAcceptedFiles,
          ...mappedRejectedFiles,
        ]);
      } else {
        const file = mappedAcceptedFiles[0] || mappedRejectedFiles[0];
        setAllFiles([file]);
      }
    },
    [isMultiple]
  );

  const handleUploaded = useCallback(
    ({ id }: UploadableFile, key: string, res: { [x in string]: any } = {}) => {
      if (!includeRejectedOrFailed) {
        setFieldTouched(name);
      }
      setAllFiles((prevVal) =>
        prevVal.map((uploadableFile) => {
          if (uploadableFile.id === id) {
            return {
              ...uploadableFile,
              ...res,
              key,
              isUploaded: true,
            };
          }
          return uploadableFile;
        })
      );
    },
    [includeRejectedOrFailed, name, setFieldTouched]
  );

  const handleError = useCallback((file: File, fileError: FileError) => {
    setAllFiles((prevVal) =>
      prevVal.map((uploadableFile) => {
        if (uploadableFile.file === file) {
          return {
            ...uploadableFile,
            errors: [...(uploadableFile.errors || []), fileError],
          };
        }
        return uploadableFile;
      })
    );
  }, []);

  const handleDelete = useCallback(
    async (file: File) => {
      const allFilesCopy = Object.assign([], allFiles);

      try {
        setAllFiles((prevVal) =>
          prevVal.filter((uploadableFile) => uploadableFile.file !== file)
        );

        if (onFileDeleted) {
          await onFileDeleted();
        }
      } catch (err) {
        setAllFiles(allFilesCopy);
      }
    },
    [allFiles, onFileDeleted]
  );

  const { getRootProps, getInputProps } = useDropzone({
    ...(options || {}),
    onDrop,
  });

  useEffect(() => {
    if (includeRejectedOrFailed) {
      setFieldValue(
        name,
        mapFileData ? allFiles.map(mapFileData) : allFiles,
        true
      );
    } else {
      const uploadedFiles = allFiles.filter((file) => file.isUploaded);
      const v = mapFileData ? uploadedFiles.map(mapFileData) : uploadedFiles;
      setFieldValue(name, useSingleS3Key ? v[0]?.key || "" : v, true);
    }
    setTimeout(() => {
      validateForm();
    }, 0);
  }, [
    allFiles,
    includeRejectedOrFailed,
    mapFileData,
    name,
    setFieldValue,
    validateForm,
    useSingleS3Key,
  ]);

  useEffect(() => {
    const isFormHasFiles = useSingleS3Key
      ? !!value
      : !!(value as any[])?.length;
    if (resetWhenFormFieldsReset && !isFormHasFiles) {
      setAllFiles([]);
    }
  }, [resetWhenFormFieldsReset, value, useSingleS3Key]);

  useEffect(() => {
    if (updateFilesWithInitialFiles) {
      setAllFiles(
        modifiedInitialFiles.map((file) => ({ ...file, isUploaded: true }))
      );
    }
  }, [updateFilesWithInitialFiles, modifiedInitialFiles]);

  const renderFileInfo = useCallback(() => {
    return allFiles.map((uploadableFile) => (
      <FileInfo
        key={uploadableFile.id}
        uploadableFile={uploadableFile}
        onDelete={handleDelete}
        onUploaded={handleUploaded}
        onError={handleError}
        onUpload={onUpload}
        showStatus={showStatus}
        size={size}
        isInitial={!!initialFilesMap[uploadableFile.id]}
        getDownloadUrl={getDownloadUrl}
      />
    ));
  }, [
    allFiles,
    handleDelete,
    handleError,
    handleUploaded,
    initialFilesMap,
    onUpload,
    showStatus,
    size,
    getDownloadUrl,
  ]);

  const renderNote = useCallback(
    () => (
      <Typography variant="body2" color="textSecondary" sx={classes.note}>
        Uploading a new file will replace currently uploaded one
      </Typography>
    ),
    []
  );

  return (
    <Box>
      <Box sx={classes.labelContainer}>
        <Typography
          variant="h5"
          component="label"
          color="text"
          htmlFor={inputId || name}
          fontSize={16}
          fontWeight="500"
        >
          {label}
          {required && "*"}
        </Typography>
      </Box>
      {progressPosition === "top" && renderFileInfo()}
      {isMultiple && allFiles.length ? (
        <Typography variant="caption">Upload another document</Typography>
      ) : null}
      {(!hideFieldOnUploaded ||
        !allFiles.length ||
        !allFiles[0].isUploaded) && (
        <Box sx={classes.dropzoneWrapper}>
          <Box
            {...getRootProps({
              sx: [
                classes.dropzone,
                touched && error ? classes.dropzoneError : {},
              ],
            })}
          >
            <input {...getInputProps()} id={inputId || name} />
            {children || (
              <Box
                pb={0.5}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <UploadIcon style={classes.uploadIconSmall} />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  sx={classes.dropzoneHelperTextSmall}
                >
                  {isSMDown ? (
                    <Typography component="span" style={classes.browse}>
                      Browse
                    </Typography>
                  ) : (
                    <>
                      Drag&Drop files or{" "}
                      <Typography component="span" style={classes.browse}>
                        browse
                      </Typography>
                    </>
                  )}
                </Typography>
                {!isMultiple &&
                  size === "large" &&
                  allFiles.length > 0 &&
                  renderNote()}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {!isMultiple && allFiles.length > 0 && !hideFieldOnUploaded && (
        <Box pt={0.5}>{renderNote()}</Box>
      )}
      {progressPosition === "bottom" && renderFileInfo()}
      {touched && error && (
        <Box mx={1.5} my={0.5}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </Box>
  );
};
