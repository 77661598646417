import { Box, Button, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { LepCalculatorPartDFormValues } from "interfaces";
import { Loader } from "Components/elements";
import { triggerClickyGoal } from "helpers";
import { useResponsive } from "hooks";
import { LepCalculatorPartDFormA } from "./LepCalculatorPartDFormA";
import { EmailField } from "./EmailField";
import { LepCalculatorSpamText } from "../LepCalculatorSpamText";

interface Props {
  resizeParentWindow: () => void;
}

export const LepCalculatorPartDFields = ({ resizeParentWindow }: Props) => {
  const { isMDDown } = useResponsive();
  const {
    submitForm,
    errors,
    isValid,
    initialValues,
    values,
    touched,
    setTouched,
  } = useFormikContext<LepCalculatorPartDFormValues>();

  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const disableCalculate = useMemo(() => {
    if (errors.enrolledInMedicaid) {
      return true;
    }
    if (errors.coverageNumberOfMonths) {
      return true;
    }
    if (errors.receiveSupplementalSecurityIncome) {
      return true;
    }
    return false;
  }, [
    errors.enrolledInMedicaid,
    errors.coverageNumberOfMonths,
    errors.receiveSupplementalSecurityIncome,
  ]);

  const onCalculate = useCallback(() => {
    if (disableCalculate) {
      setTouched({
        ...touched,
        coverageNumberOfMonths: true,
        enrolledInMedicaid: true,
        receiveSupplementalSecurityIncome: true,
      });
      return;
    }
    if (initialValues.email) {
      submitForm();
      triggerClickyGoal("9834", values.email || "", "d");
      return;
    }
    triggerClickyGoal("9830", values.email || "", "d");
    setLoading(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 5000);
    }).then(() => {
      setStep(1);
      setLoading(false);
      resizeParentWindow();
    });
  }, [
    initialValues.email,
    submitForm,
    values.email,
    disableCalculate,
    touched,
    setTouched,
    resizeParentWindow,
  ]);

  const onViewResults = useCallback(() => {
    if (!isValid) {
      setTouched({
        ...touched,
        email: true,
      });
      return;
    }
    submitForm();
    triggerClickyGoal("9834", values.email || "", "d");
  }, [submitForm, isValid, touched, setTouched, values.email]);

  return (
    <Box>
      <Loader open={loading} />
      <Box pb={8}>
        {step === 0 ? <LepCalculatorPartDFormA /> : null}
        {step === 1 ? <EmailField /> : null}
      </Box>

      <Box display="flex" justifyContent="center">
        <Grid item>
          {step === 0 ? (
            <Button
              onClick={onCalculate}
              variant="contained"
              sx={{
                paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
              }}
            >
              CALCULATE PART D PENALTY
            </Button>
          ) : null}
          {step === 1 ? (
            <Button
              onClick={onViewResults}
              type="submit"
              variant="contained"
              sx={{
                paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
              }}
            >
              VIEW RESULTS
            </Button>
          ) : null}
        </Grid>
      </Box>
      {step === 1 ? (
        <Box pt={4}>
          <LepCalculatorSpamText />
        </Box>
      ) : null}
    </Box>
  );
};
