import { pkAuthActions } from "@deep-consulting-solutions/auth-web-muiv5";
import { authActions, companyActions, quoteRequestActions } from "./reducers";
import { store } from "./store";

export const resetStore = async () => {
  await store.dispatch(pkAuthActions.logout());
  store.dispatch(authActions.resetAuth());
  store.dispatch(companyActions.reset());
  store.dispatch(quoteRequestActions.reset());
};
