import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

interface Props {
  enrollmentEndDateString: string;
}
const fontSize = 18;

export const LepCalculatorPartBResult4 = ({
  enrollmentEndDateString,
}: Props) => {
  return (
    <Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <ArrowForward fontSize="small" /> You haven&apos;t accumulated any
          penalties.{" "}
          <b>Penalties apply if you spend at least 1 year without coverage</b>{" "}
          after your Initial Enrollment Period. The end of your Initial
          Enrollment Period is {enrollmentEndDateString}.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          If you enroll more than a year after that,{" "}
          <b>you would have to pay penalties every month</b>... Be careful,
          penalties never expire: you would have to pay them as long as you are
          enrolled in Medicare.
        </Typography>
      </Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <b>Don&apos;t wait until it&apos;s too late...</b>
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize,
        }}
      >
        <b>
          Book a free consultation with our expert and get the most suitable
          plans carefully picked for you!
        </b>
      </Typography>
    </Box>
  );
};
