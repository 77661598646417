import { Box, Button, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { LepCalculatorPartBFormValues } from "interfaces";
import { Loader } from "Components/elements";
import { ArrowBack } from "@mui/icons-material";
import { triggerClickyGoal } from "helpers";
import { useResponsive } from "hooks";
import { LepCalculatorPartBFormA } from "./LepCalculatorPartBFormA";
import { LepCalculatorPartBFormB } from "./LepCalculatorPartBFormB";
import { EmailField } from "./EmailField";
import { LepCalculatorSpamText } from "../LepCalculatorSpamText";

interface Props {
  resizeParentWindow: () => void;
}

export const LepCalculatorPartBFields = ({ resizeParentWindow }: Props) => {
  const { isMDDown } = useResponsive();
  const {
    submitForm,
    errors,
    isValid,
    initialValues,
    values,
    setTouched,
    touched,
  } = useFormikContext<LepCalculatorPartBFormValues>();

  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const disableContinue = useMemo(() => {
    if (errors.dateOfBirth) {
      return true;
    }
    if (errors.partBEnrollmentDate) {
      return true;
    }
    if (errors.isMarried) {
      return true;
    }
    if (errors.income) {
      return true;
    }
    return false;
  }, [
    errors.dateOfBirth,
    errors.isMarried,
    errors.income,
    errors.partBEnrollmentDate,
  ]);

  const disableCalculate = useMemo(() => {
    if (errors.enrolledInMedicaid) {
      return true;
    }
    if (errors.haveEndStageRenalDisease) {
      return true;
    }
    if (errors.receiveSupplementalSecurityIncome) {
      return true;
    }
    return false;
  }, [
    errors.enrolledInMedicaid,
    errors.haveEndStageRenalDisease,
    errors.receiveSupplementalSecurityIncome,
  ]);

  const handleContinue = useCallback(() => {
    if (disableContinue) {
      setTouched({
        ...touched,
        dateOfBirth: true,
        partBEnrollmentDate: true,
        isMarried: true,
        income: true,
      });
      return;
    }
    setStep(1);
    resizeParentWindow();
    triggerClickyGoal("9831", values.email || "", "b");
  }, [disableContinue, setTouched, touched, resizeParentWindow, values.email]);

  const onCalculate = useCallback(() => {
    if (disableCalculate) {
      setTouched({
        ...touched,
        enrolledInMedicaid: true,
        receiveSupplementalSecurityIncome: true,
        haveEndStageRenalDisease: true,
      });
      return;
    }
    if (initialValues.email) {
      submitForm();
      triggerClickyGoal("9835", values.email || "", "b");
      return;
    }
    triggerClickyGoal("9831", values.email || "", "b");
    setLoading(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 5000);
    }).then(() => {
      setStep(2);
      resizeParentWindow();
      setLoading(false);
    });
  }, [
    initialValues.email,
    submitForm,
    values.email,
    disableCalculate,
    setTouched,
    touched,
    resizeParentWindow,
  ]);

  const handleBackToStep0 = useCallback(() => {
    setStep(0);
    resizeParentWindow();
  }, [resizeParentWindow]);

  const onViewResults = useCallback(() => {
    if (!isValid) {
      setTouched({
        ...touched,
        email: true,
      });
      return;
    }
    submitForm();
    triggerClickyGoal("9835", values.email || "", "b");
  }, [submitForm, isValid, setTouched, touched, values.email]);

  return (
    <Box>
      <Loader open={loading} />
      <Box pb={8}>
        {step === 0 ? <LepCalculatorPartBFormA /> : null}
        {step === 1 ? <LepCalculatorPartBFormB /> : null}
        {step === 2 ? <EmailField /> : null}
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction={isMDDown ? "column-reverse" : "row"}
        spacing={isMDDown ? 4 : 0}
        wrap="nowrap"
      >
        {step === 1 ? (
          <Grid item flex={1}>
            <Button
              sx={{
                color: ({ palette: p }) => p.grey[800],
              }}
              onClick={handleBackToStep0}
            >
              <ArrowBack
                fontSize="small"
                sx={{ marginRight: ({ spacing }) => spacing(1) }}
              />{" "}
              Back
            </Button>
          </Grid>
        ) : null}
        <Grid item flex={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            wrap="nowrap"
          >
            <Grid item>
              {step === 0 ? (
                <Button
                  onClick={handleContinue}
                  variant="contained"
                  sx={{
                    paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                    paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                  }}
                >
                  CONTINUE
                </Button>
              ) : null}
              {step === 1 ? (
                <Button
                  onClick={onCalculate}
                  variant="contained"
                  sx={{
                    paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                    paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                  }}
                >
                  CALCULATE PART B PENALTY
                </Button>
              ) : null}
              {step === 2 ? (
                <Button
                  onClick={onViewResults}
                  type="submit"
                  variant="contained"
                  sx={{
                    paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                    paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
                  }}
                >
                  VIEW RESULTS
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {step === 1 && !isMDDown ? (
          <Grid item flex={1}>
            {/*  */}
          </Grid>
        ) : null}
      </Grid>
      {step === 2 ? (
        <Box pt={4}>
          <LepCalculatorSpamText />
        </Box>
      ) : null}
    </Box>
  );
};
