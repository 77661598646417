import { init } from "@deep-consulting-solutions/auth-web-muiv5";
import {
  axiosRequest,
  silentAxiosRequest,
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "api";

export const initializePkAuth = () => {
  init({
    appName: "Buffer", // name of the app (like BE, BMH...)
    apiClient: axiosRequest, // main axios apiClient instance
    silentClient: silentAxiosRequest, // axios apiClient instance that wont throw error
    removeAuthorizationHeader, // remove token from apiClient
    setAuthorizationHeader, // add token to apiClient
    disabledActions: {
      facebook: true,
    },
  });
};
