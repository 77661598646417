import React from "react";
import { Box, Typography } from "@mui/material";

const classes = {
  main: {
    position: "relative",
  },
  rad: {
    position: "absolute",
    top: 4,
    right: -15,
    backgroundColor: "#F2994A",
    boxShadow: "0px 0px 6px 1px #f2994a",
    borderRadius: 4,
    width: 8,
    height: 8,
    zIndex: 1,
  },
};

export const LabelNotify = ({
  name,
  label,
  show,
}: {
  name?: string;
  label: string;
  show: { path?: string } | string | boolean | any;
}) => {
  return (
    <Box sx={classes.main}>
      {label}
      {typeof show === "object" && show?.path === name && (
        <Typography component="span" sx={classes.rad} />
      )}
      {["boolean", "string"].includes(typeof show) && show && (
        <Typography component="span" sx={classes.rad} />
      )}
    </Box>
  );
};
