import { Step, StepLabel, Stepper } from "@mui/material";
import { useResponsive } from "hooks";
import React, { useMemo } from "react";
import {
  quoteRequestSelectors,
  QuoteRequestStepEnum,
  useAppSelector,
} from "store";

export const QuoteStepper = () => {
  const { isDesktop } = useResponsive();
  const activeStep = useAppSelector(quoteRequestSelectors.getStep);

  const steps = useMemo(
    () =>
      Object.values(QuoteRequestStepEnum).filter(
        (value) =>
          ![
            QuoteRequestStepEnum.GET_QUOTE,
            QuoteRequestStepEnum.SIGN_HB2015_LETTER,
          ].includes(value) && typeof value === "string"
      ),
    []
  );

  const activeStepNumber = useMemo(
    () => steps.indexOf(activeStep),
    [steps, activeStep]
  );

  if (isDesktop) {
    return (
      <Stepper activeStep={activeStepNumber}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }
  return null;
};
