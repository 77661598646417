import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useResponsive } from "hooks";
import React, { useCallback, useMemo } from "react";
import { StateEmployee } from "store";

interface Props {
  onClose: () => void;
  onRemove: () => Promise<void>;
  employee: StateEmployee | null;
  open: boolean;
}

export const DeleteEmployeeDialog = ({
  onClose,
  employee,
  open,
  onRemove,
}: Props) => {
  const { isDesktop } = useResponsive();

  const align = useMemo(() => (isDesktop ? "center" : "left"), [isDesktop]);

  const handleRemove = useCallback(() => {
    onRemove();
  }, [onRemove]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{
          background: "rgba(246, 246, 246, 1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Remove Employee
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(3),
          }}
        >
          <Typography fontWeight={500} textAlign={align}>
            Are you sure you want to{" "}
            <Typography color="error.main" component="span" fontWeight={700}>
              Remove {employee?.contact?.firstName || ""}{" "}
              {employee?.contact?.lastName || ""}
            </Typography>{" "}
            as an Employee?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button onClick={handleRemove} color="error" variant="contained">
          REMOVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
