import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  EmployeeFormWidgetRes,
  companySelectors,
  quoteRequestActions,
  quoteRequestSelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { Loader, EmployeeCensus } from "Components";
import { Field, Form, Formik, FormikConfig } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import { CoverageTypeEnum } from "gql/graphql";
import { useResponsive } from "hooks";
import * as yup from "yup";
import { OnGoToRequestsFromEmployee } from "../types";
import { EmployeeForm } from "./EmployeeForm";
import { mapStateActivePoliciesToActivePolicyWidgetRes } from "../helpers";
import { syncCoverageAndSelection } from "./helpers";

const SAME_TEAM = "SAME_TEAM";

const getConditionForValidation = (id: string, sameTeam: boolean) =>
  (sameTeam && id === SAME_TEAM) || (!sameTeam && id !== SAME_TEAM);

const getEmployeesValidation = (required: boolean) => {
  return yup
    .array()
    .of(
      yup.object().shape({
        coverage: yup.string().when(["isSelected"], {
          is: (isSelected: boolean) => required && isSelected,
          then: yup
            .string()
            .typeError("Coverage is required")
            .required("Coverage is required"),
          otherwise: yup.string().nullable(),
        }),
      })
    )
    .test({
      name: "check selected employees",
      message: "At least one employee should be selected",
      test(employees: any) {
        if (!required) {
          return true;
        }
        return !!(employees as { isSelected: boolean }[])?.some(
          (employee) => !!employee?.isSelected
        );
      },
    });
};

const productsValidation = (sameTeam: boolean) => {
  return {
    employees: yup.array().when("id", {
      is: (id: string) => getConditionForValidation(id, sameTeam),
      then: getEmployeesValidation(true),
      otherwise: getEmployeesValidation(false),
    }),
  };
};

const validationSchema = yup.object().shape({
  products: yup.array().when("sameTeam", {
    is: true,
    then: yup.array().of(yup.object().shape(productsValidation(true))),
    otherwise: yup.array().of(yup.object().shape(productsValidation(false))),
  }),
});

interface EmployeeFormProps {
  onSubmit: OnGoToRequestsFromEmployee;
}

export const EmployeesForm = ({ onSubmit }: EmployeeFormProps) => {
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySelectors.getCompany);
  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );
  const employees = useAppSelector(quoteRequestSelectors.getEmployees);
  const activePolicies = useAppSelector(
    quoteRequestSelectors.getActivePolicies
  );
  const [openCensus, setOpenCensus] = useState(false);
  const policyRequirements = useAppSelector(
    quoteRequestSelectors.getPolicyRequirements
  );
  const companyDetails = useAppSelector(quoteRequestSelectors.getCompany);
  const employeeForm = useAppSelector(quoteRequestSelectors.getEmployeeFormRes);
  const products = useAppSelector(quoteRequestSelectors.getProducts);

  const [loadingCount, setLoadingCount] = useState(0);

  const loading = useMemo(() => loadingCount > 0, [loadingCount]);

  const initialValues: EmployeeFormWidgetRes = useMemo(() => {
    const defaultProductsValue =
      products?.map((product) => {
        const existingProduct = employeeForm?.products?.find(
          (p) => p.id === product.id
        );
        return {
          id: product.id,
          employees:
            employees?.map((employee) => {
              const existingProductEmployee = existingProduct?.employees.find(
                (e) => e.id === employee.id
              );
              return syncCoverageAndSelection(
                employee,
                existingProductEmployee?.coverage || ("" as CoverageTypeEnum),
                !!existingProductEmployee?.isSelected
              );
            }) || [],
        };
      }) || [];
    const sameTeamProductsValue = {
      id: SAME_TEAM,
      employees: employeeForm?.sameTeam
        ? defaultProductsValue[0]?.employees || []
        : employees?.map((employee) => {
            return syncCoverageAndSelection(
              employee,
              "" as CoverageTypeEnum,
              false
            );
          }) || [],
    };
    return {
      sameTeam: !!employeeForm?.sameTeam,
      products: [sameTeamProductsValue, ...defaultProductsValue],
    };
  }, [employeeForm, products, employees]);

  const hasInvalidActivePolicyField = useMemo(() => {
    try {
      const activePoliciesInitialValues =
        mapStateActivePoliciesToActivePolicyWidgetRes(activePolicies);
      const activePoliciesValidationSchema = yup.object().shape({
        activePolicies: yup
          .array()
          .required()
          .of(
            yup.object().shape({
              policyNumber: yup.string().required(),
              insuranceProvider: yup.string().required(),
              insuranceType: yup.string().required(),
            })
          ),
      });
      const isActivePoliciesValid = activePoliciesValidationSchema.validateSync(
        activePoliciesInitialValues
      );
      return !isActivePoliciesValid;
    } catch (error) {
      return true;
    }
  }, [activePolicies]);

  const hasInvalidPolicyRequirementField = useMemo(() => {
    const policyRequirementsValidationSchema = yup.object().shape({
      products: yup.array().of(
        yup.object().shape({
          plannedEffectiveDate: yup
            .date()
            .typeError("Planned effective date is invalid")
            .required("Planned effective date is required"),
          numberOfTeamMembersThatMightWantToEnroll: yup
            .number()
            .integer()
            .positive()
            .required(),
        })
      ),
    });

    const policyRequirementsInitialValues = {
      products:
        products?.map((product) => {
          const policyRequirement = policyRequirements?.find((p) => {
            return p.id === product.id;
          });
          return {
            id: product.id,
            approximateEmployerContribution:
              policyRequirement?.approximateEmployerContribution || "",
            maximumMonthlyContributionByEmployee:
              policyRequirement?.maximumMonthlyContributionByEmployee || "",
            numberOfTeamMembersThatMightWantToEnroll:
              policyRequirement?.numberOfTeamMembersThatMightWantToEnroll ||
              ("" as unknown as number),
            otherDetails: policyRequirement?.otherDetails || "",
            otherSpecificCoverageRelatedRequests:
              policyRequirement?.otherSpecificCoverageRelatedRequests || "",
            plannedEffectiveDate: policyRequirement?.plannedEffectiveDate || "",
          };
        }) || [],
    };
    try {
      const policyRequirementsValid =
        policyRequirementsValidationSchema.validateSync(
          policyRequirementsInitialValues
        );
      return !policyRequirementsValid;
    } catch (error) {
      return true;
    }
  }, [policyRequirements, products]);

  const hasInvalidPolicyRequirementOrActivePolicyField = useMemo(() => {
    return hasInvalidActivePolicyField || hasInvalidPolicyRequirementField;
  }, [hasInvalidActivePolicyField, hasInvalidPolicyRequirementField]);

  const clientHasActivePolicy = useMemo(() => {
    /**
     * TODO: REQUEST FOR VALUE
     */
    return true;
  }, []);

  const requiresHB2015Signing = useMemo(() => {
    return (
      companyDetails?.companySize &&
      companyDetails?.companySize > 50 &&
      clientHasActivePolicy
    );
  }, [clientHasActivePolicy, companyDetails?.companySize]);

  const allSelectedProductsLabel = useMemo(() => {
    const productNames =
      products
        ?.map((p) => {
          const productsOffer = productsOffers.find((pO) => pO.id === p.id);
          return productsOffer?.formattedName;
        })
        ?.filter((p) => !!p) || [];

    if (productNames.length === 1) {
      return productNames[0];
    }
    const firstPart = productNames.slice(0, productNames.length - 1).join(", ");
    const lastPart = productNames[productNames.length - 1];
    return `${firstPart} or ${lastPart}`;
  }, [products, productsOffers]);

  const setLoading = useCallback((value: boolean) => {
    setLoadingCount((current) => current + (value === true ? 1 : -1));
  }, []);

  const handleOpenCensusDialog = useCallback(() => {
    setOpenCensus(true);
  }, []);

  const mapValues = useCallback((values: EmployeeFormWidgetRes) => {
    const res: EmployeeFormWidgetRes = {
      ...values,
      products: values.products?.filter((v) => v.id !== SAME_TEAM) || [],
    };
    if (values.sameTeam) {
      const allSameProduct = values.products.find((v) => v.id === SAME_TEAM)!;

      return {
        ...res,
        products:
          res.products?.map(({ id }) => ({ ...allSameProduct, id })) || [],
      };
    }
    return res;
  }, []);

  const handleSubmit: FormikConfig<EmployeeFormWidgetRes>["onSubmit"] =
    useCallback(
      (values) => {
        onSubmit(mapValues(values), false);
      },
      [onSubmit, mapValues]
    );

  const handleSaveAsDraft = useCallback(
    (values: EmployeeFormWidgetRes) => {
      onSubmit(mapValues(values), true);
    },
    [onSubmit, mapValues]
  );

  const fetchProductsOffers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchProductsOffers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading]);

  const fetchEmployees = useCallback(async () => {
    try {
      if (!company?.id) {
        return;
      }
      setLoading(true);
      await dispatch(
        quoteRequestActions.fetchEmployees({ companyId: company.id })
      );
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading, company?.id]);

  const handleCloseCensusDialog = useCallback(() => {
    setOpenCensus(false);
    fetchEmployees();
  }, [fetchEmployees]);

  useEffect(() => {
    if (!productsOffers?.length) {
      fetchProductsOffers();
    }
  }, [productsOffers?.length, fetchProductsOffers]);

  useEffect(() => {
    if (!employees?.length) {
      fetchEmployees();
    }
  }, [employees?.length, fetchEmployees]);

  if (!company?.id) {
    return null;
  }

  return (
    <>
      <Box pt={3}>
        <Loader open={loading} />
        {employees?.length ? (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={1.5}
            flexDirection="column"
          >
            <Typography variant="h6">
              Census Info: {employees.length} Employee
              {employees.length > 1 ? "s" : ""}, 0 Dependents
            </Typography>
            <Button variant="outlined" onClick={handleOpenCensusDialog}>
              UPDATE CENSUS
            </Button>
          </Box>
        ) : null}
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Select Employees and Dependents that are interested in enrollment
        </Typography>
        <Formik<EmployeeFormWidgetRes>
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isValid, values, isSubmitting }) => {
            return (
              <Form>
                <Box mt={2}>
                  {products?.length && products.length > 1 ? (
                    <Field
                      name="sameTeam"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      sx={{
                        color: (theme: Theme) => theme.palette.primary,
                      }}
                      Label={{
                        label: (
                          <Typography variant="body2">
                            The same team members are interested in enrolling in
                            all products Products
                          </Typography>
                        ),
                      }}
                      color="primary"
                    />
                  ) : null}
                  <Box mt={isDesktop ? 3 : 2}>
                    <Grid container spacing={3} flexDirection="column">
                      {values.products?.map((product, index) =>
                        (values.sameTeam && product.id === SAME_TEAM) ||
                        (!values.sameTeam && product.id !== SAME_TEAM) ? (
                          <Grid key={product.id} item>
                            <EmployeeForm
                              product={product}
                              name={`products.${index}.employees`}
                              defaultExpanded={values.sameTeam || index === 1}
                            />
                          </Grid>
                        ) : null
                      )}
                    </Grid>
                  </Box>
                </Box>
                {requiresHB2015Signing ||
                hasInvalidPolicyRequirementOrActivePolicyField ? (
                  <Box mt={3}>
                    {requiresHB2015Signing ? (
                      <Alert severity="info">
                        You are required to sign an <b>HB2015 Letter</b> because
                        your company&apos;s size is greater than 50 and you have
                        an existing policy that is {allSelectedProductsLabel}.
                      </Alert>
                    ) : (
                      <Alert severity="info">
                        <AlertTitle>
                          Some required fields are not filled.
                        </AlertTitle>
                        You can save the quote request as draft and submit it
                        later.
                      </Alert>
                    )}
                  </Box>
                ) : null}
                <Box mt={3}>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="contained"
                        disabled={!isValid || isSubmitting}
                        onClick={() => handleSaveAsDraft(values)}
                        color="success"
                      >
                        SAVE AS DRAFT
                      </Button>
                    </Grid>

                    {!hasInvalidPolicyRequirementOrActivePolicyField ? (
                      <Grid item>
                        <Button
                          variant="contained"
                          disabled={!isValid || isSubmitting}
                          type="submit"
                        >
                          {requiresHB2015Signing
                            ? "PROCEED TO HB2015 LETTER SIGNING"
                            : "SUBMIT"}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <EmployeeCensus open={openCensus} onClose={handleCloseCensusDialog} />
    </>
  );
};
