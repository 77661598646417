import { Button as B, ButtonProps } from "@mui/material";

interface ButtonPropsExt extends ButtonProps {
  text?: string;
}

const Button = ({ text, children, ...rest }: ButtonPropsExt) => {
  return (
    <div>
      <B variant="outlined" color="primary" {...rest}>
        {text || children}
      </B>
    </div>
  );
};

export default Button;
