import React, { useCallback, useMemo } from "react";
import { TextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import {
  NumericFormat,
  NumericFormatProps,
  NumberFormatValues,
  InputAttributes,
} from "react-number-format";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
    Omit<
      MuiTextFieldProps,
      "name" | "value" | "error" | "ref" | "defaultValue" | "type"
    > {}

export interface FormikNumberFieldProps extends TextFieldProps {
  /**
   * - https://github.com/s-yadav/react-number-format#props
   * - exclude displayType
   */
  valueType?: "string" | "number" | "formatted";
  numberFormatProps?: Omit<NumericFormatProps, "displayType">;
  hideErrorMessage?: boolean;
}

/**
 * - Input format is customizable as it uses https://www.npmjs.com/package/react-number-format internally.
 * - Should be used with formik and material ui.
 * - The component can receive any of material ui TextField props https://material-ui.com/api/text-field/#props
 */
export const FormikNumberField = ({
  form: { errors, touched, setFieldValue, isSubmitting },
  field: { name, value, onBlur },
  numberFormatProps,
  disabled,
  helperText,
  size,
  hideErrorMessage,
  valueType = "number",
  ...textFieldProps
}: FormikNumberFieldProps) => {
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const onValueChange = useCallback(
    (values: NumberFormatValues) => {
      let newValue: number | string | undefined;
      if (valueType === "number") {
        newValue =
          typeof values.floatValue === "number" ? values.floatValue : "";
      } else if (valueType === "string") {
        newValue = values.value;
      } else if (valueType === "formatted") {
        newValue = values.formattedValue;
      }
      setFieldValue(name, newValue);
    },
    [name, setFieldValue, valueType]
  );

  const InputField: React.ComponentType<InputAttributes> = useMemo(
    () =>
      ({ color, size: s, ...props }) => {
        return (
          <TextField
            {...props}
            size="small"
            color="primary"
            error={showError}
            helperText={
              showError && !hideErrorMessage ? fieldError : helperText
            }
          />
        );
      },
    [showError, fieldError, helperText, hideErrorMessage]
  );

  return (
    <NumericFormat
      autoComplete="off"
      size={size as any}
      {...textFieldProps}
      value={value}
      name={name}
      disabled={disabled ?? isSubmitting}
      onBlur={onBlur}
      customInput={InputField}
      allowNegative={false}
      {...numberFormatProps}
      onValueChange={onValueChange}
    />
  );
};
