import { SingleRequestLayout } from "Components";
import { IntegrationApp } from "HOC";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { companySelectors, useAppSelector } from "store";

export const TerminationLetterRequest = () => {
  const company = useAppSelector(companySelectors.getCompany);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const { opportunityID } = useParams();
  const onHandleCustomListenerHandler = useCallback(
    (payload?: Record<string, unknown>) => {
      switch (payload?.action) {
        case "resize-iframe":
          setHeight((current) => (payload?.height as number) || current);
          break;
        default:
          break;
      }
    },
    []
  );
  const extraData = useMemo(
    () =>
      company && opportunityID
        ? { companyID: company.id, opportunityID }
        : undefined,
    [company, opportunityID]
  );

  return (
    <SingleRequestLayout pageTitle="Sign Termination Letter">
      <IntegrationApp
        path="buffer"
        mobileAppKey="signTerminationLetterMobile"
        desktopAppKey="signTerminationLetter"
        appIntegrationSource="client-portal"
        onHandleCustomListenerHandler={onHandleCustomListenerHandler}
        recordID={opportunityID}
        extraData={extraData}
        waitForExtraData
        waitForRecordID
        style={{ height }}
      />
    </SingleRequestLayout>
  );
};
