import React, { useMemo } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ROUTES } from "app-constants";
import { AuthLayout } from "Components";
import { useAuth } from "hooks";
import { Login } from "Views/Login";
import { ForgotPassword } from "Views";

export const AuthRoutes = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const redirectPage = useMemo(() => {
    if (isAuthenticated) {
      if (location.state?.from) {
        const previousPath = `${location.state.from}${
          location.state.hash ? `?${location.state.hash}` : ""
        }`;
        return previousPath;
      }
      return ROUTES.dashboard.fullPath;
    }
    return null;
  }, [location.state?.from, location.state?.hash, isAuthenticated]);

  if (isAuthenticated && redirectPage) {
    return <Navigate to={redirectPage} />;
  }

  return (
    <AuthLayout>
      <Routes>
        <Route index element={<Login />} />
        <Route path={ROUTES.login.path} element={<Login />} />
        <Route path={ROUTES.forgetPassword.path} element={<ForgotPassword />} />
        <Route path="/*" element={<Navigate to={ROUTES.auth.fullPath} />} />
      </Routes>
    </AuthLayout>
  );
};
