import { Button } from "Components";
import {
  OpportunityActivePolicy,
  OpportunityStageEnum,
  OpportunityTypeEnum,
} from "gql/graphql";
import React, { useMemo } from "react";

interface Props {
  stage: OpportunityStageEnum;
  type: OpportunityTypeEnum;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  requiresHB2015Signing: boolean;
  opportunityActivePolicies: OpportunityActivePolicy[];
}

const stageText: Record<OpportunityStageEnum, string> = {
  [OpportunityStageEnum.PendingInformationCollection]: "CONTINUE",
  [OpportunityStageEnum.PendingLetterSigning]: "CONTINUE",
} as Record<OpportunityStageEnum, string>;

const getStageText = (
  stage: OpportunityStageEnum,
  requiresHB2015Signing: boolean,
  opportunityActivePolicies: OpportunityActivePolicy[],
  type: OpportunityTypeEnum
) => {
  if (
    type === OpportunityTypeEnum.QuoteRequest &&
    requiresHB2015Signing &&
    stage === OpportunityStageEnum.Submitted &&
    opportunityActivePolicies.some(
      (opportunityActivePolicy) => !opportunityActivePolicy.hb2015LetterSigned
    )
  ) {
    return "CONTINUE";
  }
  return stageText[stage];
};

export const StageButton = ({
  stage,
  type,
  onClick,
  requiresHB2015Signing,
  opportunityActivePolicies,
}: Props) => {
  const text = useMemo(() => {
    if (type === OpportunityTypeEnum.AorChange) {
      return "SIGN AGENT OF RECORD CHANGE LETTER(S)";
    }
    return getStageText(
      stage,
      requiresHB2015Signing,
      opportunityActivePolicies,
      type
    );
  }, [stage, type, opportunityActivePolicies, requiresHB2015Signing]);
  if (!text) {
    return null;
  }
  return (
    <Button variant="contained" onClick={onClick}>
      {text}
    </Button>
  );
};
