import { Chip } from "@mui/material";
import {
  OpportunityActivePolicy,
  OpportunityStageEnum,
  OpportunityTypeEnum,
} from "gql/graphql";
import React from "react";

interface Props {
  status: OpportunityStageEnum;
  requiresHB2015Signing: boolean;
  opportunityActivePolicies: OpportunityActivePolicy[];
  type: OpportunityTypeEnum;
}

const stageText: Record<OpportunityStageEnum, string> = {
  [OpportunityStageEnum.PendingInformationCollection]:
    "Pending Information Collection",
  [OpportunityStageEnum.PendingLetterSigning]: "Pending HB2015 Signing",
} as Record<OpportunityStageEnum, string>;

const getStageText = (
  stage: OpportunityStageEnum,
  requiresHB2015Signing: boolean,
  opportunityActivePolicies: OpportunityActivePolicy[],
  type: OpportunityTypeEnum
) => {
  if (
    type === OpportunityTypeEnum.QuoteRequest &&
    requiresHB2015Signing &&
    stage === OpportunityStageEnum.Submitted &&
    opportunityActivePolicies.some(
      (opportunityActivePolicy) => !opportunityActivePolicy.hb2015LetterSigned
    )
  ) {
    return "Pending HB2015 Signing";
  }
  return stageText[stage] || stage.replace(/_/g, " ");
};

const stageColors: Record<OpportunityStageEnum, string> = {
  [OpportunityStageEnum.PendingInformationCollection]: "rgba(254, 231, 71, 1)",
  [OpportunityStageEnum.PendingLetterSigning]: "rgba(174, 219, 255, 1)",
} as Record<OpportunityStageEnum, string>;

const getStageColor = (
  stage: OpportunityStageEnum,
  requiresHB2015Signing: boolean,
  opportunityActivePolicies: OpportunityActivePolicy[],
  type: OpportunityTypeEnum
) => {
  if (
    type === OpportunityTypeEnum.QuoteRequest &&
    requiresHB2015Signing &&
    stage === OpportunityStageEnum.Submitted &&
    opportunityActivePolicies.some(
      (opportunityActivePolicy) => !opportunityActivePolicy.hb2015LetterSigned
    )
  ) {
    return "rgba(174, 219, 255, 1)";
  }
  return stageColors[stage];
};

export const OpportunityStatus = ({
  status,
  requiresHB2015Signing,
  opportunityActivePolicies,
  type,
}: Props) => {
  return (
    <Chip
      label={getStageText(
        status,
        requiresHB2015Signing,
        opportunityActivePolicies,
        type
      )}
      sx={{
        background: getStageColor(
          status,
          requiresHB2015Signing,
          opportunityActivePolicies,
          type
        ),
      }}
    />
  );
};
