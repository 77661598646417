import { Box, Grid } from "@mui/material";
import { ROUTES } from "app-constants";
import { Company } from "gql/graphql";
import { BackButton, Loader } from "Components";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmployeeFormWidgetRes,
  quoteRequestActions,
  quoteRequestSelectors,
  QuoteRequestStepEnum,
  useAppDispatch,
  useAppSelector,
} from "store";
import { SavePolicyRequirementsAction } from "store/reducers/quote-request/types";
import { ActivePolicyForm } from "./ActivePolicyForm";
import { CompanyInformationForm } from "./CompanyInformationForm";
import { HB2015Letter } from "./HB2015Letter";
import { ProductInformationForm } from "./ProductInformationForm";
import { QuoteStepper } from "./QuoteStepper";
import { PolicyRequirementsForm } from "./PolicyRequirementsForm";
import { EmployeesForm } from "./EmployeesForm";

import {
  OnGoToRequestsFromActivePolicy,
  OnGoToPolicyRequirementsFromActivePolicy,
  OnGoToActivePolicyFromProducts,
  ProductInformationFormRes,
} from "./types";
import { mapActivePolicyWidgetResToStateActivePolicies } from "./helpers";

export const StepWrapper = () => {
  const step = useAppSelector(quoteRequestSelectors.getStep);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const setStep = useCallback(
    (value: QuoteRequestStepEnum) => {
      dispatch(quoteRequestActions.saveStep(value));
    },
    [dispatch]
  );

  const onGoToProducts = useCallback(
    (params: Partial<Company>) => {
      dispatch(quoteRequestActions.saveCompanyInformation(params));
      setStep(QuoteRequestStepEnum.PRODUCTS);
    },
    [setStep, dispatch]
  );

  const handleGoBack = useCallback(() => {
    switch (step) {
      case QuoteRequestStepEnum.COMPANY_INFORMATION:
        setStep(QuoteRequestStepEnum.GET_QUOTE);
        break;

      case QuoteRequestStepEnum.PRODUCTS:
        setStep(QuoteRequestStepEnum.COMPANY_INFORMATION);
        break;

      case QuoteRequestStepEnum.ACTIVE_POLICY:
        setStep(QuoteRequestStepEnum.PRODUCTS);
        break;

      case QuoteRequestStepEnum.POLICY_REQUIREMENTS:
        setStep(QuoteRequestStepEnum.ACTIVE_POLICY);
        break;

      case QuoteRequestStepEnum.EMPLOYEES:
        setStep(QuoteRequestStepEnum.POLICY_REQUIREMENTS);
        break;

      default:
        break;
    }
  }, [step, setStep]);

  const onCancel = useCallback(() => {
    dispatch(quoteRequestActions.reset());
  }, [dispatch]);

  const onGoToRequests = useCallback(() => {
    navigate(ROUTES.requests.fullPath);
    dispatch(quoteRequestActions.reset());
  }, [navigate, dispatch]);

  const onGoToProductsFromActivePolicy = useCallback(() => {
    setStep(QuoteRequestStepEnum.PRODUCTS);
  }, [setStep]);

  const onGoToRequestsFromActivePolicy: OnGoToRequestsFromActivePolicy =
    useCallback(
      async (params) => {
        try {
          setLoading(true);
          dispatch(
            quoteRequestActions.saveActivePolicies(
              mapActivePolicyWidgetResToStateActivePolicies(params)
            )
          );
          const res = await dispatch(
            quoteRequestActions.submitQuote({ isDraft: true })
          );
          if (quoteRequestActions.submitQuote.fulfilled.match(res)) {
            onGoToRequests();
          }
        } catch {
          //
        } finally {
          setLoading(false);
        }
      },
      [onGoToRequests, dispatch]
    );

  const onGoToRequestsFromPolicyRequirements = useCallback(
    async (params: SavePolicyRequirementsAction) => {
      try {
        setLoading(true);
        dispatch(quoteRequestActions.savePolicyRequirements(params));
        const res = await dispatch(
          quoteRequestActions.submitQuote({ isDraft: true })
        );
        if (quoteRequestActions.submitQuote.fulfilled.match(res)) {
          onGoToRequests();
        }
      } catch {
        //
      } finally {
        setLoading(false);
      }
    },
    [onGoToRequests, dispatch]
  );

  const onGoToHb2015Signing = useCallback(() => {
    setStep(QuoteRequestStepEnum.SIGN_HB2015_LETTER);
  }, [setStep]);

  const onSubmitEmployeeForm = useCallback(
    async (payload: EmployeeFormWidgetRes, isDraft: boolean) => {
      try {
        setLoading(true);
        dispatch(quoteRequestActions.saveEmployeeFormRes(payload));
        const res = await dispatch(
          quoteRequestActions.submitQuote({ isDraft })
        );
        if (quoteRequestActions.submitQuote.fulfilled.match(res)) {
          if (res.payload?.opportunity?.requiresHB2015Signing) {
            onGoToHb2015Signing();
          } else {
            onGoToRequests();
          }
        }
      } catch {
        //
      } finally {
        setLoading(false);
      }
    },
    [dispatch, onGoToHb2015Signing, onGoToRequests]
  );

  const onGoToPolicyRequirements: OnGoToPolicyRequirementsFromActivePolicy =
    useCallback(
      (params) => {
        dispatch(
          quoteRequestActions.saveActivePolicies(
            mapActivePolicyWidgetResToStateActivePolicies(params)
          )
        );
        setStep(QuoteRequestStepEnum.POLICY_REQUIREMENTS);
      },
      [setStep, dispatch]
    );

  const onGoToActivePolicy: OnGoToActivePolicyFromProducts = useCallback(
    (params: ProductInformationFormRes) => {
      dispatch(quoteRequestActions.saveProducts(params));
      setStep(QuoteRequestStepEnum.ACTIVE_POLICY);
    },
    [setStep, dispatch]
  );

  const onGoToEmployees = useCallback(
    (params: SavePolicyRequirementsAction) => {
      dispatch(quoteRequestActions.savePolicyRequirements(params));
      setStep(QuoteRequestStepEnum.EMPLOYEES);
    },
    [dispatch, setStep]
  );

  const onBackFromHB2015Letter = useCallback(() => {
    onGoToRequests();
  }, [onGoToRequests]);

  return (
    <>
      <Loader open={loading} />
      <Grid container alignItems="center" columnSpacing={1}>
        <Grid item>
          <BackButton onClick={handleGoBack} />
        </Grid>
      </Grid>

      <Box mt={2}>
        <QuoteStepper />
      </Box>

      <Box mt={3} pt={3}>
        {step === QuoteRequestStepEnum.COMPANY_INFORMATION && (
          <CompanyInformationForm
            onCancel={onCancel}
            onGoToProducts={onGoToProducts}
          />
        )}

        {step === QuoteRequestStepEnum.PRODUCTS && (
          <ProductInformationForm
            onCancel={onCancel}
            onGoToActivePolicy={onGoToActivePolicy}
          />
        )}

        {step === QuoteRequestStepEnum.ACTIVE_POLICY && (
          <ActivePolicyForm
            onGoToPolicyRequirements={onGoToPolicyRequirements}
            onGoToRequests={onGoToRequestsFromActivePolicy}
            onGoToProducts={onGoToProductsFromActivePolicy}
          />
        )}

        {step === QuoteRequestStepEnum.POLICY_REQUIREMENTS && (
          <PolicyRequirementsForm
            onGoToEmployeeForm={onGoToEmployees}
            onSaveAsDraft={onGoToRequestsFromPolicyRequirements}
          />
        )}

        {step === QuoteRequestStepEnum.EMPLOYEES && (
          <EmployeesForm onSubmit={onSubmitEmployeeForm} />
        )}

        {step === QuoteRequestStepEnum.SIGN_HB2015_LETTER && (
          <HB2015Letter
            onGoBack={onBackFromHB2015Letter}
            onNext={onGoToRequests}
          />
        )}
      </Box>
    </>
  );
};
