import {
  Grid,
  FormControlLabel,
  Radio,
  Box,
  FormHelperText,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-mui";
import React, { useCallback } from "react";
import { LepCalculatorLabel } from "./LepCalculatorLabel";

interface Props {
  options: {
    label: string;
    value: any;
  }[];
  name: string;
  label?: { title?: string; subTitle?: string };
  handleTransFormValue?: (value: any) => any;
  onChange?: (value: any) => any;
  disabled?: boolean;
  errorMessage?: string;
}

export const LepCalculatorRadios: React.FC<Props> = ({
  options,
  name,
  label,
  handleTransFormValue,
  onChange,
  disabled,
  errorMessage,
}) => {
  const { setFieldValue } = useFormikContext<Record<string, unknown>>();

  const handleChange = useCallback(
    (event: { target: { value: any } }) => {
      const v = event.target.value;
      if (onChange) {
        onChange(handleTransFormValue ? handleTransFormValue(v) : v);
      } else {
        setFieldValue(name, handleTransFormValue ? handleTransFormValue(v) : v);
      }
    },
    [setFieldValue, name, handleTransFormValue, onChange]
  );

  return (
    <>
      <Box>
        <LepCalculatorLabel title={label?.title} subTitle={label?.subTitle} />
      </Box>
      <Field
        component={RadioGroup}
        name={name}
        sx={{
          width: "100%",
        }}
        onChange={handleChange}
        disabled={!!disabled}
      >
        <Grid container spacing={2}>
          {options.map((option) => (
            <Grid item key={option.value}>
              <Box
                sx={{
                  cursor: "pointer",
                }}
              >
                <FormControlLabel
                  value={option.value}
                  label={option.label}
                  disabled={!!disabled}
                  control={
                    <Radio size="small" color="primary" disabled={!!disabled} />
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Field>
      {errorMessage ? (
        <Box
          sx={{
            position: "relative",
            height: 0,
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <FormHelperText
              error
              sx={{
                paddingLeft: 0,
                marginLeft: 0,
                fontSize: "0.8rem",
              }}
            >
              {errorMessage}
            </FormHelperText>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
