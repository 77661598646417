import { Alert, Box } from "@mui/material";
import { CompanyFormProvider, CompanyForm } from "Components";
import { Company } from "gql/graphql";
import React, { useCallback } from "react";
import {
  companySelectors,
  quoteRequestActions,
  quoteRequestSelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import { CompanyInformationFormActions } from "./CompanyInformationFormActions";

interface CompanyInformationFormProps {
  onCancel: () => void;
  onGoToProducts: (params: Partial<Company>) => void;
}

export const CompanyInformationForm = ({
  onCancel,
  onGoToProducts,
}: CompanyInformationFormProps) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySelectors.getCompany);
  const companyImageBase64 = useAppSelector(
    quoteRequestSelectors.getCompanyImageBase64
  );
  const quoteCompanyInformation = useAppSelector(
    quoteRequestSelectors.getQuoteCompanyInformation
  );

  const onSaveBase64Image = useCallback(
    (params: string) => {
      dispatch(quoteRequestActions.saveCompanyImageBase64(params));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (payload: Partial<Company>) => {
      await new Promise((resolve) => {
        resolve("");
      });
      onGoToProducts(payload);
    },
    [onGoToProducts]
  );

  return (
    <Box>
      <Alert severity="info">
        Please make sure your company details are up-to-date before continuing.
      </Alert>
      <Box mt={3}>
        <CompanyFormProvider
          onCancel={onCancel}
          onSubmit={onSubmit}
          company={
            quoteCompanyInformation.legalName
              ? quoteCompanyInformation
              : company
          }
          onSaveBase64Image={onSaveBase64Image}
          companyImageBase64={companyImageBase64}
        >
          <CompanyForm />
          <CompanyInformationFormActions onCancel={onCancel} />
        </CompanyFormProvider>
      </Box>
    </Box>
  );
};
