import React, { useCallback, useMemo } from "react";
import { FieldProps } from "formik";
import { FormHelperText } from "@mui/material";
import { CountrySelectInput } from "./CountrySelectInput";
import { getValueFromObject } from "./helpers";

export type FormikCountrySelectInputProps = FieldProps & {
  onCountryChange: (selectedCountry: string) => any;
};

export const FormikCountrySelectInput: React.FC<
  FormikCountrySelectInputProps
> = ({ field, form, onCountryChange, ...props }) => {
  const { name } = field;
  const { errors, touched, setFieldValue } = form;
  const error = useMemo(
    () =>
      getValueFromObject(name.split("."), touched) &&
      getValueFromObject(name.split("."), errors),
    [touched, errors, name]
  );

  const onCountryChanged = useCallback(
    (selectedCountry: string) => {
      setFieldValue(name, selectedCountry);
    },
    [setFieldValue, name]
  );

  const errorText = useMemo(() => {
    if (typeof error === "string") {
      return error;
    }
  }, [error]);

  return (
    <div>
      <CountrySelectInput
        error={!!error}
        {...field}
        {...props}
        onChange={onCountryChange || onCountryChanged}
      />
      <FormHelperText error variant="filled">
        {errorText}
      </FormHelperText>
    </div>
  );
};
