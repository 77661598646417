import { useTheme, useMediaQuery } from "@mui/material";

export const useResponsive = () => {
  const theme = useTheme();
  const { breakpoints: b } = theme;
  const isSMDown = useMediaQuery(b.down("sm"));
  const isLGDown = useMediaQuery(b.down("lg"));

  return {
    theme,
    useMediaQuery,

    isXSDown: useMediaQuery(b.down("xs")),
    isXSUp: useMediaQuery(b.up("xs")),
    isXSOnly: useMediaQuery(b.only("xs")),

    isSMDown,
    isSMUp: useMediaQuery(b.up("sm")),
    isSMOnly: useMediaQuery(b.only("sm")),

    isMDDown: useMediaQuery(b.down("md")),
    isMDUp: useMediaQuery(b.up("md")),
    isMDOnly: useMediaQuery(b.only("md")),

    isLGDown,
    isLGUp: useMediaQuery(b.up("lg")),
    isLGOnly: useMediaQuery(b.only("lg")),

    isXLDown: useMediaQuery(b.down("xl")),
    isXLUp: useMediaQuery(b.up("xl")),
    isXLOnly: useMediaQuery(b.only("xl")),

    isMobile: isSMDown,
    isTablet: isLGDown && !isSMDown,
    isDesktop: !isLGDown,
  };
};
