import React, { PropsWithChildren, useCallback, useState } from "react";
import { Box, Container, Dialog, DialogContent, Grid } from "@mui/material";
import { DashboardHeader } from "Components/molecules";

export const MinimalViewLayout = ({ children }: PropsWithChildren) => {
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const childrenWrapperRef = useCallback(
    (divElement: HTMLDivElement | null) => {
      if (divElement) {
        setWrapperHeight(divElement.offsetHeight);
      }
    },
    []
  );

  const childrenWrapperRef2 = useCallback(
    (divElement: HTMLDivElement | null) => {
      if (divElement) {
        setHeaderHeight(divElement.offsetHeight);
      }
    },
    []
  );

  return (
    <Dialog fullScreen open>
      <DialogContent>
        <Grid
          wrap="nowrap"
          justifyContent="space-between"
          direction="column"
          alignItems="stretch"
          sx={{
            height: "100%",
            maxHeight: "100%",
          }}
          container
          ref={childrenWrapperRef}
        >
          <Grid item ref={childrenWrapperRef2}>
            <DashboardHeader showInfo={false} showToggle={false} />
          </Grid>
          <Grid item flex={1}>
            <Box
              sx={{
                height: wrapperHeight - headerHeight,
                padding: ({ spacing: s }) => s(2),
                overflow: "hidden auto",
              }}
            >
              <Container>{children}</Container>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
