import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StateProducts,
  quoteRequestActions,
  quoteRequestSelectors,
  useAppDispatch,
  useAppSelector,
} from "store";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import { Button, Loader } from "Components";
import { Field, Form, Formik, FormikConfig } from "formik";
import { CheckboxWithLabel, Select } from "formik-mui";
import { NetworkTypeEnum, PolicyContributionTypeEnum } from "gql/graphql";
import { useResponsive } from "hooks";
import * as yup from "yup";
import { OnGoToActivePolicyFromProducts } from "../types";
import {
  networkTypeOptions,
  networkTypeTitle,
  policyContributionTypeOptions,
  policyContributionTypeTitle,
} from "./helpers";

type FormValues = Record<string, StateProducts & { isSelected: boolean }>;
interface ProductInformationFormProps {
  onCancel: () => void;
  onGoToActivePolicy: OnGoToActivePolicyFromProducts;
}

export const ProductInformationForm = ({
  onCancel,
  onGoToActivePolicy,
}: ProductInformationFormProps) => {
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const isExistingPolicy = useAppSelector(
    quoteRequestSelectors.getIsExistingPolicy
  );
  const products = useAppSelector(quoteRequestSelectors.getProducts);
  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );

  const initialValues = useMemo(() => {
    return productsOffers.reduce<FormValues>((total, current) => {
      const initialValue = products?.find((p) => p.id === current.id);
      return {
        ...total,
        [current.id]: {
          id: current.id,
          isSelected: !!initialValue,
          networkType: initialValue?.networkType || [],
          policyContributionType: initialValue?.policyContributionType || [],
        },
      };
    }, {});
  }, [productsOffers, products]);

  const validationSchema = useMemo(() => {
    const schemaObj = productsOffers.reduce<Record<string, any>>(
      (total, current) => {
        return {
          ...total,
          [current.id]: yup.object().shape({
            policyContributionType: yup.array().when("isSelected", {
              is: true,
              then: yup
                .array()
                .required("Policy Contribution Type is required")
                .min(1, "Policy Contribution Type is required"),
              otherwise: yup.array(),
            }),
            networkType: yup.array().when("isSelected", {
              is: (val: boolean) =>
                val &&
                current.name &&
                networkTypeOptions[current.name]?.length &&
                isExistingPolicy,
              then: yup
                .array()
                .required("Network Type is required")
                .min(1, "Network Type is required"),
              otherwise: yup.array(),
            }),
          }),
        };
      },
      {}
    );
    return yup.object().shape(schemaObj);
  }, [productsOffers, isExistingPolicy]);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values) => {
      onGoToActivePolicy(Object.values(values).filter((v) => v.isSelected));
    },
    [onGoToActivePolicy]
  );

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const fetchProductsOffers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchProductsOffers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!productsOffers.length) {
      fetchProductsOffers();
    }
  }, [productsOffers.length, fetchProductsOffers]);

  return (
    <Box>
      <Loader open={loading} />
      <Typography
        variant="h5"
        sx={{
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        Select Products
      </Typography>
      <Formik<FormValues>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isValid, values }) => {
          return (
            <Form>
              <Grid
                container
                justifyContent="flex-start"
                flexDirection="row"
                alignItems="stretch"
                sx={{
                  marginTop: (theme) => theme.spacing(3),
                }}
                spacing={isDesktop ? 3 : 1}
              >
                {productsOffers.map((productsOffer) => (
                  <Grid item xs={isDesktop ? 6 : 12} key={productsOffer.id}>
                    <Box
                      p={2}
                      border={
                        values[productsOffer.id]?.isSelected
                          ? "1px solid rgba(9, 89, 209, 1)"
                          : "1px solid rgba(0, 0, 0, 0.6)"
                      }
                      height="100%"
                      sx={{
                        borderRadius: (theme) => theme.spacing(1),
                        background: values[productsOffer.id]?.isSelected
                          ? "rgba(241, 248, 255, 1)"
                          : "none",
                      }}
                    >
                      <Field
                        name={`${productsOffer.id}.isSelected`}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        sx={{
                          color: (theme: Theme) => theme.palette.primary,
                        }}
                        Label={{
                          label: (
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 700,
                                // fontSize: 20,
                                color: (theme) =>
                                  values[productsOffer.id]?.isSelected
                                    ? theme.palette.primary.main
                                    : theme.palette.text.primary,
                              }}
                            >
                              {productsOffer.formattedName}
                            </Typography>
                          ),
                          sx: {
                            color: (theme: Theme) => theme.palette.primary,
                          },
                        }}
                        color="primary"
                      />
                      <Box
                        sx={{
                          paddingLeft: (theme) => theme.spacing(4),
                        }}
                      >
                        <Box>
                          <Typography variant="body2">
                            {productsOffer.description}
                          </Typography>
                        </Box>
                        {values[productsOffer.id]?.isSelected ? (
                          <Box mt={1.5}>
                            <FormControl
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Field
                                name={`${productsOffer.id}.policyContributionType`}
                                component={Select}
                                label={
                                  !values[productsOffer.id]
                                    ?.policyContributionType?.length
                                    ? "Do you have a preference for Policy Contribution Type? *"
                                    : "Policy Contribution Type *"
                                }
                                options={
                                  policyContributionTypeOptions[
                                    productsOffer.name
                                  ] || policyContributionTypeOptions.OTHERS
                                }
                                fullWidth
                                multiple
                                color="primary"
                                renderValue={(
                                  selected: PolicyContributionTypeEnum[]
                                ) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value, index) => (
                                      <Typography key={value}>
                                        {policyContributionTypeTitle[value]}
                                        {index !== selected.length - 1
                                          ? ", "
                                          : ""}
                                      </Typography>
                                    ))}
                                  </Box>
                                )}
                              >
                                {(
                                  policyContributionTypeOptions[
                                    productsOffer.name
                                  ] || policyContributionTypeOptions.OTHERS
                                ).map((option) => {
                                  return (
                                    <MenuItem key={option} value={option}>
                                      {policyContributionTypeTitle[option]}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </FormControl>
                          </Box>
                        ) : null}

                        {values[productsOffer.id]?.isSelected &&
                        productsOffer.name &&
                        networkTypeOptions[productsOffer.name]?.length &&
                        isExistingPolicy ? (
                          <Box mt={1}>
                            <FormControl
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Field
                                name={`${productsOffer.id}.networkType`}
                                component={Select}
                                label={
                                  !values[productsOffer.id]?.networkType?.length
                                    ? "Do you have a preference for Network Type? *"
                                    : "Network Type *"
                                }
                                options={networkTypeOptions[productsOffer.name]}
                                fullWidth
                                color="primary"
                                multiple
                                renderValue={(selected: NetworkTypeEnum[]) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value, index) => (
                                      <Typography key={value}>
                                        {networkTypeTitle[value]}
                                        {index !== selected.length - 1
                                          ? ", "
                                          : ""}
                                      </Typography>
                                    ))}
                                  </Box>
                                )}
                              >
                                {networkTypeOptions[productsOffer.name].map(
                                  (option) => {
                                    return (
                                      <MenuItem key={option} value={option}>
                                        {networkTypeTitle[option]}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Field>
                            </FormControl>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box mt={3}>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Button onClick={handleCancel}>CANCEL</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={
                        !isValid ||
                        !Object.values(values).some((v) => v.isSelected)
                      }
                      type="submit"
                    >
                      NEXT
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
