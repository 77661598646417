/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
};

export type ActivePoliciesInput = {
  activePolicyDocument?: InputMaybe<ActivePolicyDocumentInput>;
  carrierId?: InputMaybe<Scalars['String']>;
  clientNeedsToSignHB2015Letter?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  insuranceType: ActivePolicyTypeEnum;
  number: Scalars['String'];
  otherDetails?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  summaryOfMonthlycost?: InputMaybe<Scalars['String']>;
  termDate?: InputMaybe<Scalars['String']>;
};

export type ActivePoliciesTerminationInput = {
  id?: InputMaybe<Scalars['String']>;
  insuranceProviderAddress?: InputMaybe<Scalars['String']>;
  insuranceProviderName?: InputMaybe<Scalars['String']>;
  policyName: Scalars['String'];
  policyNumber: Scalars['String'];
  termDate?: InputMaybe<Scalars['String']>;
  terminationDate?: InputMaybe<Scalars['String']>;
};

export type ActivePolicy = {
  __typename?: 'ActivePolicy';
  activePolicyDocuments?: Maybe<Array<ActivePolicyDocument>>;
  carrier?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceType: ActivePolicyTypeEnum;
  number?: Maybe<Scalars['String']>;
  otherDetails?: Maybe<Scalars['String']>;
  relatedCarrier?: Maybe<Carrier>;
  summaryOfMonthlycost?: Maybe<Scalars['String']>;
  termDate?: Maybe<Scalars['String']>;
};

export type ActivePolicyDocument = {
  __typename?: 'ActivePolicyDocument';
  expiryDate?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  id: Scalars['String'];
  s3Key: Scalars['String'];
  type: ActivePolicyDocumentEnum;
};

export enum ActivePolicyDocumentEnum {
  ClaimsData = 'CLAIMS_DATA',
  CurrentPlanSummary = 'CURRENT_PLAN_SUMMARY',
  Invoice = 'INVOICE',
  PolicyDocument = 'POLICY_DOCUMENT'
}

export type ActivePolicyDocumentInput = {
  expiryDate?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  s3Key: Scalars['String'];
};

export enum ActivePolicyTypeEnum {
  Dental = 'DENTAL',
  LifeAndAdAndD = 'LIFE_AND_AD_AND_D',
  LongTermDisability = 'LONG_TERM_DISABILITY',
  Medical = 'MEDICAL',
  ShortTermDisability = 'SHORT_TERM_DISABILITY',
  Vision = 'VISION'
}

export type AddDependentToCensusInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  medicarePrimary?: InputMaybe<MedicarePrimaryEnum>;
  relationship?: InputMaybe<RelationshipEnum>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AddEmployeeToCensusInput = {
  coverageType?: InputMaybe<CoverageTypeEnum>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  dateOfHire?: InputMaybe<Scalars['String']>;
  dependents?: InputMaybe<Array<InputMaybe<AddDependentToCensusInput>>>;
  email?: InputMaybe<Scalars['String']>;
  employeeType?: InputMaybe<EmployeesTheCompanyWantsToOfferBenefitsToEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum>;
  jobType?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  medicarePrimary?: InputMaybe<MedicarePrimaryEnum>;
  salary?: InputMaybe<Scalars['Int']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AddEmployeeToCensusResponse = {
  __typename?: 'AddEmployeeToCensusResponse';
  employment?: Maybe<Employment>;
};

export type AgeBrandedMonthlyPremiumInput = {
  premiums: Array<InputMaybe<AgeBrandedPremiumsInput>>;
  rateForDependents?: InputMaybe<Scalars['String']>;
};

export type AgeBrandedPremiumsInput = {
  ageRange?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

export type BasicAddress = {
  __typename?: 'BasicAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  pobox?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type BasicAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type BenefitterPlanName = {
  __typename?: 'BenefitterPlanName';
  carrierName: CarrierNameEnum;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  networkType: NetworkTypeEnum;
  policyType: PolicyTypeEnum;
  zohoID: Scalars['String'];
};

export type BenefitterQuoteRetrievalProgressData = {
  __typename?: 'BenefitterQuoteRetrievalProgressData';
  benefitterCompanyId?: Maybe<Scalars['String']>;
  benefitterProposalId?: Maybe<Scalars['String']>;
  quoteRequestFetchStatus: FetchBenefitterQuoteStatusEnum;
  stageDetails: Array<StageData>;
};

export type CalendlyManagedEvent = {
  __typename?: 'CalendlyManagedEvent';
  admin_managed: Scalars['Boolean'];
  booking_method: Scalars['String'];
  name: Scalars['String'];
  scheduling_url: Scalars['String'];
  uri: Scalars['String'];
};

export type CalendlyScheduledEvent = {
  __typename?: 'CalendlyScheduledEvent';
  calendar_event?: Maybe<CalendlyScheduledEventCalendarEvent>;
  created_at: Scalars['String'];
  end_time: Scalars['String'];
  location?: Maybe<CalendlyScheduledEventLocation>;
  name: Scalars['String'];
  start_time: Scalars['String'];
  status?: Maybe<CalendlyScheduledEventStatusEnum>;
};

export type CalendlyScheduledEventCalendarEvent = {
  __typename?: 'CalendlyScheduledEventCalendarEvent';
  external_id: Scalars['String'];
  kind?: Maybe<CalendlyScheduledEventExternalEventKindEnum>;
};

export enum CalendlyScheduledEventExternalEventKindEnum {
  Google = 'GOOGLE'
}

export type CalendlyScheduledEventLocation = {
  __typename?: 'CalendlyScheduledEventLocation';
  join_url?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum CalendlyScheduledEventStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type Carrier = {
  __typename?: 'Carrier';
  accountManagerHasAccessToCarrierPortal?: Maybe<Scalars['Boolean']>;
  availableOnBenefitter?: Maybe<Scalars['Boolean']>;
  canConnectOnEase?: Maybe<Scalars['Boolean']>;
  emailForQuotes?: Maybe<Scalars['String']>;
  emailForQuotesCC?: Maybe<Scalars['String']>;
  emailForQuotesCCContactPerson?: Maybe<Scalars['String']>;
  emailForQuotesContactPerson?: Maybe<Scalars['String']>;
  engagedDirectly?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  linesOfInsuranceOffered?: Maybe<Array<ActivePolicyTypeEnum>>;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  zohoID: Scalars['String'];
};

export type CarrierDetails = {
  desiredCommissionRate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeMessage?: InputMaybe<Scalars['Boolean']>;
  linesOfInsurance: Array<InputMaybe<LinesOfInsurance>>;
  message?: InputMaybe<Scalars['String']>;
  proposalName?: InputMaybe<Scalars['String']>;
  typeOfRequest: QuoteRequestSourceEnum;
  zohoID?: InputMaybe<Scalars['String']>;
  zohoIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum CarrierNameEnum {
  BlueCrossBlueShieldOfTexas = 'BLUE_CROSS_BLUE_SHIELD_OF_TEXAS',
  UnitedHealth = 'UNITED_HEALTH'
}

export type CollectedQuote = {
  __typename?: 'CollectedQuote';
  collectedOn: Scalars['String'];
  document?: Maybe<Document>;
  id: Scalars['String'];
  manuallyUploaded: Scalars['Boolean'];
  quoteDocumentUrl?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type CollectedQuoteInput = {
  documentId?: InputMaybe<Scalars['String']>;
  s3Key?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  EIN?: Maybe<Scalars['String']>;
  SICCode?: Maybe<Scalars['String']>;
  SICCodeDescription?: Maybe<Scalars['String']>;
  activePolicies?: Maybe<Array<Maybe<ActivePolicy>>>;
  areTheOwnerAndHisOrHerSpouseTheOnlyPeopleEnrollingInThePolicies?: Maybe<YesNoEnum>;
  businessOwner?: Maybe<Contact>;
  companySigningAuthority?: Maybe<Contact>;
  companySize?: Maybe<Scalars['Int']>;
  contactPersons?: Maybe<Array<Contact>>;
  doesTheCompanyHaveASection125PlanInPlace?: Maybe<YesNoEnum>;
  employeesTheCompanyWantsToOfferBenefitsTo?: Maybe<Array<EmployeesTheCompanyWantsToOfferBenefitsToEnum>>;
  facebookCompanyPage?: Maybe<Scalars['String']>;
  fullInfoForQuoteRequestCollected?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  isGroupAGovernmentAgencyAndEmployeesInTheGroupIPsArePublicSector?: Maybe<YesNoEnum>;
  isTheCompanyAHusbandWifeCompany?: Maybe<YesNoEnum>;
  isTheCompanyRequiredToHaveOSHATraining?: Maybe<YesNoEnum>;
  isYourCompanyOwnedCompletelyByAHusbandWifeCouple?: Maybe<YesNoEnum>;
  isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch?: Maybe<YesNoEnum>;
  jobClassifications?: Maybe<Array<CompanyJobClassification>>;
  legalName?: Maybe<Scalars['String']>;
  linkedInCompanyPage?: Maybe<Scalars['String']>;
  logoS3Key?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<CompanyMailingAddress>;
  name: Scalars['String'];
  numberOf1099Contractors?: Maybe<Scalars['Int']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfFullTimeEmployees?: Maybe<Scalars['Int']>;
  numberOfPartTimeEmployees?: Maybe<Scalars['Int']>;
  opportunities?: Maybe<Array<Opportunity>>;
  otherInformation?: Maybe<Scalars['String']>;
  payrollCycle?: Maybe<PayrollCycleEnum>;
  physicalAddress?: Maybe<BasicAddress>;
  portalStatus?: Maybe<Scalars['String']>;
  primaryContactPerson?: Maybe<Contact>;
  signingAuthorityPosition?: Maybe<Scalars['String']>;
  stateOfIncorporation?: Maybe<Scalars['String']>;
  waitingPeriod?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
  zohoID: Scalars['String'];
};

export type CompanyDetailsInput = {
  SICCode?: InputMaybe<Scalars['String']>;
  companySize?: InputMaybe<Scalars['Int']>;
  differentToPhysicalAddress?: InputMaybe<YesNoEnum>;
  doesTheCompanyHaveASection125PlanInPlace?: InputMaybe<YesNoEnum>;
  employeesTheCompanyWantsToOfferBenefitsTo?: InputMaybe<Array<EmployeesTheCompanyWantsToOfferBenefitsToEnum>>;
  facebookCompanyPage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  isTheCompanyRequiredToHaveOSHATraining?: InputMaybe<YesNoEnum>;
  isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch?: InputMaybe<YesNoEnum>;
  legalName?: InputMaybe<Scalars['String']>;
  linkedInCompanyPage?: InputMaybe<Scalars['String']>;
  logoS3Key?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<BasicAddressInput>;
  numberOf1099Contractors?: InputMaybe<Scalars['Int']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']>;
  numberOfFullTimeEmployees?: InputMaybe<Scalars['Int']>;
  numberOfPartTimeEmployees?: InputMaybe<Scalars['Int']>;
  payrollCycle?: InputMaybe<PayrollCycleEnum>;
  physicalAddress?: InputMaybe<BasicAddressInput>;
  stateOfIncorporation?: InputMaybe<Scalars['String']>;
  waitingPeriod?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyJobClassification = {
  __typename?: 'CompanyJobClassification';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CompanyMailingAddress = {
  __typename?: 'CompanyMailingAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  differentToPhysicalAddress?: Maybe<YesNoEnum>;
  pobox?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type ComparisonTablePolicy = {
  __typename?: 'ComparisonTablePolicy';
  firmQuoteRequest?: Maybe<FirmQuoteRequest>;
  id: Scalars['ID'];
  networkType?: Maybe<NetworkTypeEnum>;
  policyCode?: Maybe<Scalars['String']>;
  policyContributionType?: Maybe<PolicyContributionTypeEnum>;
  policyName?: Maybe<Scalars['String']>;
  policyType?: Maybe<PolicyTypeEnum>;
  productType?: Maybe<ActivePolicyTypeEnum>;
  quoteRequest?: Maybe<QuoteRequest>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type ComparisonTablePolicyInput = {
  Copayments?: InputMaybe<Scalars['String']>;
  aDDRate?: InputMaybe<Scalars['String']>;
  aDDVolume?: InputMaybe<Scalars['Float']>;
  acceleratedBenefit?: InputMaybe<Scalars['String']>;
  annualBenefitMaximum?: InputMaybe<Scalars['Float']>;
  basicCoinsurance?: InputMaybe<Scalars['String']>;
  beginDate?: InputMaybe<Scalars['String']>;
  benefitIncrement?: InputMaybe<Scalars['String']>;
  benefitReduction?: InputMaybe<Scalars['String']>;
  carryoverRolloverAmount?: InputMaybe<Scalars['String']>;
  carryoverRolloverLimit?: InputMaybe<Scalars['String']>;
  carryoverRolloverThreshold?: InputMaybe<Scalars['String']>;
  childBenefit?: InputMaybe<Scalars['String']>;
  collectedQuoteId: Scalars['String'];
  contactLensesBenefit?: InputMaybe<Scalars['String']>;
  contactLensesFrequency?: InputMaybe<Scalars['String']>;
  conversion?: InputMaybe<Scalars['String']>;
  coverageType?: InputMaybe<Scalars['String']>;
  durationOfBenefits?: InputMaybe<Scalars['String']>;
  earningsDefinition?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['Date'];
  emergencyRoom?: InputMaybe<Scalars['String']>;
  employeeBenefit?: InputMaybe<Scalars['String']>;
  evidenceOfInsurability?: InputMaybe<Scalars['String']>;
  examsBenefit?: InputMaybe<Scalars['String']>;
  examsFrequency?: InputMaybe<Scalars['String']>;
  familyDeductible?: InputMaybe<Scalars['Float']>;
  familyOutOfPocketMaximum?: InputMaybe<Scalars['String']>;
  framesBenefit?: InputMaybe<Scalars['String']>;
  framesFrequency?: InputMaybe<Scalars['String']>;
  guaranteedIssue?: InputMaybe<Scalars['String']>;
  hearingServicesDiscount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imaging?: InputMaybe<Scalars['String']>;
  individualDeductible?: InputMaybe<Scalars['Float']>;
  individualOutOfPocketMaximum?: InputMaybe<Scalars['String']>;
  inpatientHospitalStay?: InputMaybe<Scalars['String']>;
  insuranceProviderId: Scalars['String'];
  integrationMethod?: InputMaybe<Scalars['String']>;
  labXRay?: InputMaybe<Scalars['String']>;
  lensesBenefit?: InputMaybe<Scalars['String']>;
  lensesFrequency?: InputMaybe<Scalars['String']>;
  lifeRate?: InputMaybe<Scalars['String']>;
  lifeVolume?: InputMaybe<Scalars['Float']>;
  majorCoinsurance?: InputMaybe<Scalars['String']>;
  maximumWeeklyBenefit?: InputMaybe<Scalars['Float']>;
  minimumEmployeeParticipation?: InputMaybe<Scalars['String']>;
  minimumEmployerContributionAmount?: InputMaybe<Scalars['String']>;
  minimumEmployerContributionType?: InputMaybe<Scalars['String']>;
  minimumWeeklyBenefit?: InputMaybe<Scalars['Float']>;
  monthlyPremium?: InputMaybe<MonthlyPremiumInput>;
  networkType?: InputMaybe<NetworkTypeEnum>;
  orthodontiaCoinsurance?: InputMaybe<Scalars['String']>;
  outpatient?: InputMaybe<Scalars['String']>;
  partialDisabilityCalculation?: InputMaybe<Scalars['String']>;
  policyCode?: InputMaybe<Scalars['String']>;
  policyContributionType?: InputMaybe<PolicyContributionTypeEnum>;
  policyName: Scalars['String'];
  policyType: PolicyTypeEnum;
  preExistingCondition?: InputMaybe<Scalars['String']>;
  premiumType: Array<PolicyMonthlyPremiumType>;
  prescriptionDrugsRx?: InputMaybe<Scalars['String']>;
  preventiveCoinsurance?: InputMaybe<Scalars['String']>;
  primaryCareProviderVisit?: InputMaybe<Scalars['String']>;
  probability?: InputMaybe<Scalars['String']>;
  ratePer10WeeklyIndemnity?: InputMaybe<Scalars['String']>;
  rehabilitationServices?: InputMaybe<Scalars['String']>;
  repatriationBenefit?: InputMaybe<Scalars['String']>;
  returnToWork?: InputMaybe<Scalars['String']>;
  seatbeltAirbag?: InputMaybe<Scalars['String']>;
  specialistVisit?: InputMaybe<Scalars['String']>;
  spouseBenefit?: InputMaybe<Scalars['String']>;
  totalWeeklyBenefit?: InputMaybe<Scalars['Float']>;
  urgentCare?: InputMaybe<Scalars['String']>;
  waiverOfPremiumEliminationPeriod?: InputMaybe<Scalars['String']>;
  waiverOfPremiumQualifyingAges?: InputMaybe<Scalars['String']>;
};

export type CompleteLinesOfInsuranceInput = {
  approximateEmployerContribution?: InputMaybe<Scalars['String']>;
  maximumMonthlyContributionByEmployee?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<ActivePolicyTypeEnum>;
  networkType?: InputMaybe<Array<InputMaybe<NetworkTypeEnum>>>;
  numberOfTeamMembersThatMightWantToEnroll: Scalars['Int'];
  otherSpecificCoverageRelatedRequests?: InputMaybe<Scalars['String']>;
  plannedEffectiveDate: Scalars['String'];
  policyContributionType?: InputMaybe<Array<InputMaybe<PolicyContributionTypeEnum>>>;
  policyType?: InputMaybe<Array<InputMaybe<PolicyTypeEnum>>>;
};

export type CompositeMonthlyPremiumInput = {
  employeeChildEnrollingNumber?: InputMaybe<Scalars['String']>;
  employeeChildRate?: InputMaybe<Scalars['Float']>;
  employeeFamilyEnrollingNumber?: InputMaybe<Scalars['String']>;
  employeeFamilyRate?: InputMaybe<Scalars['Float']>;
  employeeOnlyEnrollingNumber?: InputMaybe<Scalars['String']>;
  employeeOnlyRate?: InputMaybe<Scalars['Float']>;
  employeeSpouseEnrollingNumber?: InputMaybe<Scalars['String']>;
  employeeSpouseRate?: InputMaybe<Scalars['Float']>;
};

export type ConfirmFinalPolicySelectionInput = {
  companyId?: InputMaybe<Scalars['String']>;
  comparisonTablePolicyDetails: Array<ConfirmPlanSelectionComparisonTablePolicyInput>;
  opportunityId: Scalars['String'];
  requestSource: RequestSourceEnum;
};

export type ConfirmFinalPolicySelectionResponse = {
  __typename?: 'ConfirmFinalPolicySelectionResponse';
  policies: Array<Policy>;
};

export type ConfirmInterestActivePolicyInput = {
  carrier: Scalars['String'];
  carrierId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  insuranceType: ActivePolicyTypeEnum;
  number: Scalars['String'];
  termDate?: InputMaybe<Scalars['String']>;
};

export type ConfirmInterestCompanyDetailsInput = {
  SICCode?: InputMaybe<Scalars['String']>;
  companySize?: InputMaybe<Scalars['Int']>;
  differentToPhysicalAddress?: InputMaybe<YesNoEnum>;
  doesTheCompanyHaveASection125PlanInPlace?: InputMaybe<YesNoEnum>;
  employeesTheCompanyWantsToOfferBenefitsTo?: InputMaybe<Array<InputMaybe<EmployeesTheCompanyWantsToOfferBenefitsToEnum>>>;
  facebookCompanyPage?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  industry?: InputMaybe<Scalars['String']>;
  isTheCompanyRequiredToHaveOSHATraining?: InputMaybe<YesNoEnum>;
  isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch?: InputMaybe<YesNoEnum>;
  legalName?: InputMaybe<Scalars['String']>;
  linkedInCompanyPage?: InputMaybe<Scalars['String']>;
  logoS3Key?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<OptionalBasicAddressInput>;
  name: Scalars['String'];
  numberOf1099Contractors?: InputMaybe<Scalars['Int']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']>;
  numberOfFullTimeEmployees?: InputMaybe<Scalars['Int']>;
  numberOfPartTimeEmployees?: InputMaybe<Scalars['Int']>;
  otherInformation?: InputMaybe<Scalars['String']>;
  payrollCycle?: InputMaybe<PayrollCycleEnum>;
  physicalAddress?: InputMaybe<OptionalBasicAddressInput>;
  stateOfIncorporation?: InputMaybe<Scalars['String']>;
  waitingPeriod?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ConfirmInterestInput = {
  activePolicies?: InputMaybe<Array<InputMaybe<ConfirmInterestActivePolicyInput>>>;
  aorChangeRequestDate?: InputMaybe<Scalars['String']>;
  clientHasActivePolicy: Scalars['Boolean'];
  companyDetails: ConfirmInterestCompanyDetailsInput;
  companySigningAuthorityDetails?: InputMaybe<ConfirmInterestSigningAuthorityDetailsInput>;
  insuranceProviderAddress?: InputMaybe<Scalars['String']>;
  insuranceProviderName?: InputMaybe<Scalars['String']>;
  numberOfTeamMembersThatMightWantToEnroll?: InputMaybe<Scalars['Int']>;
  opportunityInterest?: InputMaybe<OpportunityInterestEnum>;
  policyNumber?: InputMaybe<Scalars['String']>;
  saveAsDraft: Scalars['Boolean'];
};

export type ConfirmInterestResponse = {
  __typename?: 'ConfirmInterestResponse';
  opportunity: Opportunity;
};

export type ConfirmInterestSigningAuthorityDetailsInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  homePhone?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  otherPhone?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  position: Scalars['String'];
  zohoID?: InputMaybe<Scalars['String']>;
};

export type ConfirmPlanSelectionComparisonTablePolicyInput = {
  id?: InputMaybe<Scalars['String']>;
  monthlyPremiumType?: InputMaybe<PolicyMonthlyPremiumType>;
};

export type ConfirmPlanSelectionInput = {
  companyAdminContactDetails: ContactIdOrDetailsInput;
  companyId?: InputMaybe<Scalars['String']>;
  comparisonTablePolicyDetails: Array<ConfirmPlanSelectionComparisonTablePolicyInput>;
  opportunityId: Scalars['String'];
  requestSource: RequestSourceEnum;
};

export type ConfirmPlanSelectionResponse = {
  __typename?: 'ConfirmPlanSelectionResponse';
  companyAdminContact: Contact;
  policies: Array<Policy>;
};

export type Contact = {
  __typename?: 'Contact';
  contactForCompany?: Maybe<Company>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  homePhone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  medicarePrimary?: Maybe<MedicarePrimaryEnum>;
  mobile?: Maybe<Scalars['String']>;
  otherPhone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryContactForCompany?: Maybe<Company>;
  zohoID: Scalars['String'];
};

export type ContactIdOrDetailsInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['String']>;
};

export enum CountryEnum {
  UnitedStates = 'UNITED_STATES'
}

export enum CoverageTypeEnum {
  Cobra = 'COBRA',
  CobraOnly = 'COBRA_ONLY',
  CobraPlusChildren = 'COBRA_PLUS_CHILDREN',
  CobraPlusFamily = 'COBRA_PLUS_FAMILY',
  CobraPlusSpouse = 'COBRA_PLUS_SPOUSE',
  EmployeeOnly = 'EMPLOYEE_ONLY',
  EmployeePlusChildren = 'EMPLOYEE_PLUS_CHILDREN',
  EmployeePlusFamily = 'EMPLOYEE_PLUS_FAMILY',
  EmployeePlusSpouse = 'EMPLOYEE_PLUS_SPOUSE'
}

export type CreateBenefitterPlanNameFromCrmInput = {
  carrierName: CarrierNameEnum;
  name: Scalars['String'];
  networkType: NetworkTypeEnum;
  policyType: PolicyTypeEnum;
};

export type CreateBenefitterPlanNameFromCrmResponse = {
  __typename?: 'CreateBenefitterPlanNameFromCRMResponse';
  benefitterPlanName: BenefitterPlanName;
};

export type CreateOpportunityCompanyDetailsInput = {
  SICCode?: InputMaybe<Scalars['String']>;
  companySize?: InputMaybe<Scalars['Int']>;
  employeesTheCompanyWantsToOfferBenefitsTo?: InputMaybe<Array<EmployeesTheCompanyWantsToOfferBenefitsToEnum>>;
  facebookCompanyPage?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  linkedInCompanyPage?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  numberOfEmployees?: InputMaybe<Scalars['Int']>;
  otherInformation?: InputMaybe<Scalars['String']>;
  stateOfIncorporation?: InputMaybe<Scalars['String']>;
};

export type CreateOpportunityForCompanyInput = {
  companyDetails?: InputMaybe<CreateOpportunityCompanyDetailsInput>;
  primaryContactDetails: CreateOpportunityPrimaryContactInput;
  requestSource?: InputMaybe<RequestSourceEnum>;
  sourceForm?: InputMaybe<CreateOpportunityForCompanySourceFormEnum>;
  useExistingPrimaryContact: Scalars['Boolean'];
};

export type CreateOpportunityForCompanyResponse = {
  __typename?: 'CreateOpportunityForCompanyResponse';
  crmRecordLink?: Maybe<Scalars['String']>;
  opportunity: Opportunity;
};

export enum CreateOpportunityForCompanySourceFormEnum {
  CreateOpportunityForNewCompany = 'CREATE_OPPORTUNITY_FOR_NEW_COMPANY'
}

export type CreateOpportunityInput = {
  companyDetails: CreateOpportunityCompanyDetailsInput;
  primaryContactDetails: CreateOpportunityPrimaryContactInput;
};

export type CreateOpportunityPrimaryContactInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['ID']>;
};

export type CreateOpportunityResponse = {
  __typename?: 'CreateOpportunityResponse';
  crmRecordLink?: Maybe<Scalars['String']>;
  opportunity: Opportunity;
};

export type Document = {
  __typename?: 'Document';
  fileName: Scalars['String'];
  id: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  s3Key: Scalars['String'];
};

export type EmailRecipientInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type Employee = {
  __typename?: 'Employee';
  contact?: Maybe<Contact>;
  employeeDependents?: Maybe<Array<Maybe<EmployeeDependent>>>;
  id?: Maybe<Scalars['String']>;
};

export type EmployeeDependent = {
  __typename?: 'EmployeeDependent';
  contact?: Maybe<Contact>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
  relationship?: Maybe<RelationshipEnum>;
};

export type EmployeesInterestedInput = {
  coverageType: CoverageTypeEnum;
  dependentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  employeeId: Scalars['String'];
};

export enum EmployeesTheCompanyWantsToOfferBenefitsToEnum {
  Contractors_1099 = 'CONTRACTORS_1099',
  FullTimeEmployees = 'FULL_TIME_EMPLOYEES',
  PartTimeEmployees = 'PART_TIME_EMPLOYEES'
}

export type Employment = {
  __typename?: 'Employment';
  commencementDate?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  coverageType?: Maybe<CoverageTypeEnum>;
  employee?: Maybe<Employee>;
  employeeType?: Maybe<EmployeesTheCompanyWantsToOfferBenefitsToEnum>;
  id?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
};

export enum FetchBenefitterQuoteStageEnum {
  CompanyCreation = 'COMPANY_CREATION',
  CompanyUpdate = 'COMPANY_UPDATE',
  CreateProposal = 'CREATE_PROPOSAL',
  FilterMedicalPlans = 'FILTER_MEDICAL_PLANS',
  SelectMedicalPlans = 'SELECT_MEDICAL_PLANS',
  UpdateCompanyCensus = 'UPDATE_COMPANY_CENSUS'
}

export enum FetchBenefitterQuoteStatusEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export enum FetchBenefitterQuoteTypeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type FirmQuoteRequest = {
  __typename?: 'FirmQuoteRequest';
  collectedQuotes?: Maybe<Array<CollectedQuote>>;
  comparisonTablePolicy?: Maybe<ComparisonTablePolicy>;
  declineMessage?: Maybe<Scalars['String']>;
  healthDetailsShared?: Maybe<Scalars['Boolean']>;
  healthFormDocumentIds?: Maybe<Array<Scalars['String']>>;
  healthOnlineFormDetails?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  opportunity?: Maybe<Opportunity>;
  quoteRequest?: Maybe<QuoteRequest>;
  responseMessage?: Maybe<Scalars['String']>;
  status?: Maybe<FirmQuoteRequestStatusEnum>;
  zohoID?: Maybe<Scalars['String']>;
};

export type FirmQuoteRequestAndTaskResponse = {
  __typename?: 'FirmQuoteRequestAndTaskResponse';
  firmQuoteRequest: FirmQuoteRequest;
  task: TaskInFirmQuoteRequest;
};

export type FirmQuoteRequestResponse = {
  __typename?: 'FirmQuoteRequestResponse';
  firmQuoteRequest: FirmQuoteRequest;
};

export enum FirmQuoteRequestStatusEnum {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  WithGaCarrier = 'WITH_GA_CARRIER'
}

export enum GenderEnum {
  F = 'F',
  M = 'M'
}

export type GeneralAgency = {
  __typename?: 'GeneralAgency';
  emailForQuotes?: Maybe<Scalars['String']>;
  emailForQuotesCC?: Maybe<Scalars['String']>;
  emailForQuotesCCContactPerson?: Maybe<Scalars['String']>;
  emailForQuotesContactPerson?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  zohoID: Scalars['String'];
};

export type GetBenefitterPlanNamesResponse = {
  __typename?: 'GetBenefitterPlanNamesResponse';
  benefitterPlanNames: Array<BenefitterPlanName>;
  count: Scalars['Int'];
};

export type GetCarriersAndGAsRecord = Carrier | GeneralAgency;

export type GetCarriersAndGAsResponse = {
  __typename?: 'GetCarriersAndGAsResponse';
  count: Scalars['Int'];
  records: Array<GetCarriersAndGAsRecord>;
};

export type GetHb2015SigningTicketStatusResponse = {
  __typename?: 'GetHB2015SigningTicketStatusResponse';
  data?: Maybe<ReturnSigningTicket>;
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type GetOpportunityDuplicateScenarioResponse = {
  __typename?: 'GetOpportunityDuplicateScenarioResponse';
  companyProvidedInitially: Scalars['Boolean'];
  matchingCompany?: Maybe<Company>;
  matchingCompanyCrmRecordLink?: Maybe<Scalars['String']>;
  matchingCompanyFound: Scalars['Boolean'];
  matchingPerson?: Maybe<Contact>;
  matchingPersonCrmRecordLink?: Maybe<Scalars['String']>;
  matchingPersonFound: Scalars['Boolean'];
  matchingPersonRelatedCompanies?: Maybe<Array<RelatedCompany>>;
};

export type GetSigningUrlForHb2015LetterInput = {
  accessLinkOtp?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  inPerson: Scalars['Boolean'];
  locationInfo: LocationInfo;
  oppActivePolicyID: Scalars['String'];
  otp?: InputMaybe<Scalars['ID']>;
};

export type GetSigningUrlForHb2015LetterResponse = {
  __typename?: 'GetSigningUrlForHB2015LetterResponse';
  data?: Maybe<SigningUrlDataResult>;
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type GetSigningUrlForTerminationLetterInput = {
  accessLinkOtp?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  inPerson: Scalars['Boolean'];
  locationInfo: LocationInfo;
  oppActivePolicyID: Scalars['String'];
  otp?: InputMaybe<Scalars['ID']>;
};

export type HealthApplicationDetailsInput = {
  firmQuoteRequestID: Scalars['String'];
  healthFormDocumentS3Keys?: InputMaybe<Array<Scalars['String']>>;
  healthOnlineFormDetails?: InputMaybe<Scalars['String']>;
  isOnlineForm: Scalars['Boolean'];
};

export type LinesOfInsurance = {
  name?: InputMaybe<ActivePolicyTypeEnum>;
  networkType?: InputMaybe<Array<InputMaybe<NetworkTypeEnum>>>;
  policyContributionType?: InputMaybe<Array<InputMaybe<PolicyContributionTypeEnum>>>;
  policyType?: InputMaybe<Array<InputMaybe<PolicyTypeEnum>>>;
};

export type LocationInfo = {
  country: Scalars['String'];
  ip: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export enum LocationStateEnum {
  Alaska = 'ALASKA'
}

export type MarkOpportunityAsIrrelevantResponse = {
  __typename?: 'MarkOpportunityAsIrrelevantResponse';
  opportunity?: Maybe<Opportunity>;
};

export enum MedicarePrimaryEnum {
  No = 'NO',
  U = 'U',
  Yes = 'YES'
}

export type Meeting = {
  __typename?: 'Meeting';
  allDay: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  meetingLocationIsInPerson?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<ZohoCrmUser>;
  title: Scalars['String'];
  to?: Maybe<Scalars['Date']>;
  zohoID: Scalars['String'];
};

export type MonthlyPremiumInput = {
  ageBanded?: InputMaybe<AgeBrandedMonthlyPremiumInput>;
  composite?: InputMaybe<CompositeMonthlyPremiumInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEmployeeToCensus: AddEmployeeToCensusResponse;
  addFirmQuoteRequestHealthApplicationDetailsCarrierPortal: FirmQuoteRequestAndTaskResponse;
  addFirmQuoteRequestHealthApplicationDetailsCrm: FirmQuoteRequestResponse;
  assignOpportunityAccountCoordinator: OpportunityTaskResponse;
  assignOpportunityAccountManager: Opportunity;
  confirmFinalPolicySelection: ConfirmFinalPolicySelectionResponse;
  confirmInterest: ConfirmInterestResponse;
  confirmPlanSelection: ConfirmPlanSelectionResponse;
  createBenefitterPlanNameFromCRM: CreateBenefitterPlanNameFromCrmResponse;
  createOpportunity: CreateOpportunityResponse;
  createOpportunityForCompany: CreateOpportunityForCompanyResponse;
  duplicateOpportunityFromCrm: OpportunityResponse;
  duplicateOpportunityFromPortal: OpportunityResponse;
  makeOpportunityFinalPlanSelectionAvailable: OpportunityResponse;
  markCreateOrUpdateCompanyAsComplete: QuoteRequest;
  markCreateProposalAsComplete: QuoteRequest;
  markFilterMedicalPlansAsComplete: QuoteRequest;
  markOpportunityAsIrrelevant: MarkOpportunityAsIrrelevantResponse;
  markSelectMedicalPlansAsComplete: QuoteRequest;
  markUpdateCompanyCensusAsComplete: QuoteRequest;
  mockAssociateOpportunityAndProducts: OpportunityResponse;
  removeBenefitterPlanNameFromCRM: RemoveBenefitterPlanNameFromCrmResponse;
  requestTerminationLetter: OpportunityWithOpportunityActivePoliciesResponse;
  resendEmailToSignHB2015Letters?: Maybe<ResendEmailToSignHb2015LettersResponse>;
  resendFirmQuoteRequest: ResendFirmQuoteRequestResponse;
  resendQuoteRequestFromCrm: ResendQuoteRequestResponse;
  respondToFirmQuoteRequest: RespondToFirmQuoteRequestResponse;
  saveComparisonTable: SaveComparisonTableResponse;
  saveOpportunityAsDraft: SaveOpportunityAsDraftResponse;
  sendCalSchedulingLink: SendCalSchedulingLinkResponse;
  sendUpdatedQuoteRequestToGAOrCarrier: SendUpdatedQuoteRequestToGaOrCarrierResponse;
  shareFirmQuoteRequestHealthApplication: FirmQuoteRequestResponse;
  sharePlanComparisonTable: OpportunityResponse;
  submitBenefitterQuoteRequest: SubmitBenefitterQuoteRequestResponse;
  submitOpportunity: OpportunityWithOpportunityActivePoliciesResponse;
  submitQuoteRequest: SubmitQuoteRequestResponse;
  updateBenefitterPlanNameFromCRM: UpdateBenefitterPlanNameFromCrmResponse;
  updateCompanyFromPortal: UpdateCompanyFromPortalResponse;
  updateCompanyJobClassificationsFromPortal: UpdateCompanyJobClassificationsFromPortalResponse;
  updateContact?: Maybe<Contact>;
  updateEmployeeToCensus: UpdateEmployeeToCensusResponse;
  updateFirmQuoteRequestHealthApplicationDetailsCarrierPortal: FirmQuoteRequestAndTaskResponse;
  updateFirmQuoteRequestHealthApplicationDetailsCrm: FirmQuoteRequestResponse;
  updateOpportunity: Opportunity;
  updateQuoteRequestByGAOrCarrier: UpdateQuoteRequestByGaOrCarrierResponse;
};


export type MutationAddEmployeeToCensusArgs = {
  companyId: Scalars['ID'];
  input: AddEmployeeToCensusInput;
  isZohoId: Scalars['Boolean'];
};


export type MutationAddFirmQuoteRequestHealthApplicationDetailsCarrierPortalArgs = {
  accessLinkToken: Scalars['String'];
  input?: InputMaybe<HealthApplicationDetailsInput>;
};


export type MutationAddFirmQuoteRequestHealthApplicationDetailsCrmArgs = {
  input: HealthApplicationDetailsInput;
  share?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssignOpportunityAccountCoordinatorArgs = {
  accountCoordinatorId: Scalars['String'];
  opportunityZohoId: Scalars['String'];
};


export type MutationAssignOpportunityAccountManagerArgs = {
  accountManagerId: Scalars['String'];
  updateBlueprint?: InputMaybe<Scalars['Boolean']>;
  zohoId: Scalars['String'];
};


export type MutationConfirmFinalPolicySelectionArgs = {
  input: ConfirmFinalPolicySelectionInput;
};


export type MutationConfirmInterestArgs = {
  input: ConfirmInterestInput;
  opportunityZohoId: Scalars['String'];
};


export type MutationConfirmPlanSelectionArgs = {
  input: ConfirmPlanSelectionInput;
};


export type MutationCreateBenefitterPlanNameFromCrmArgs = {
  input: CreateBenefitterPlanNameFromCrmInput;
};


export type MutationCreateOpportunityArgs = {
  input: CreateOpportunityInput;
};


export type MutationCreateOpportunityForCompanyArgs = {
  companyId: Scalars['ID'];
  input: CreateOpportunityForCompanyInput;
};


export type MutationDuplicateOpportunityFromCrmArgs = {
  opportunityZohoId: Scalars['ID'];
  products: Array<ProductNameEnum>;
};


export type MutationDuplicateOpportunityFromPortalArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  opportunityId: Scalars['ID'];
  products: Array<ProductNameEnum>;
};


export type MutationMakeOpportunityFinalPlanSelectionAvailableArgs = {
  opportunityID: Scalars['String'];
};


export type MutationMarkCreateOrUpdateCompanyAsCompleteArgs = {
  quoteRequestId: Scalars['String'];
  url: Scalars['String'];
};


export type MutationMarkCreateProposalAsCompleteArgs = {
  quoteRequestId: Scalars['String'];
  url: Scalars['String'];
};


export type MutationMarkFilterMedicalPlansAsCompleteArgs = {
  quoteRequestId: Scalars['String'];
};


export type MutationMarkOpportunityAsIrrelevantArgs = {
  opportunityId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationMarkSelectMedicalPlansAsCompleteArgs = {
  quoteRequestId: Scalars['String'];
};


export type MutationMarkUpdateCompanyCensusAsCompleteArgs = {
  quoteRequestId: Scalars['String'];
};


export type MutationMockAssociateOpportunityAndProductsArgs = {
  opportunityZohoId: Scalars['ID'];
  products: Array<ProductNameEnum>;
};


export type MutationRemoveBenefitterPlanNameFromCrmArgs = {
  benefitterPlanNameId: Scalars['ID'];
};


export type MutationRequestTerminationLetterArgs = {
  input: RequestTerminationLetterInput;
};


export type MutationResendEmailToSignHb2015LettersArgs = {
  opportunityZohoId: Scalars['ID'];
};


export type MutationResendFirmQuoteRequestArgs = {
  input: ResendFirmQuoteRequestInput;
};


export type MutationResendQuoteRequestFromCrmArgs = {
  input: ResendQuoteRequestInput;
};


export type MutationRespondToFirmQuoteRequestArgs = {
  input: RespondToFirmQuoteRequestInput;
};


export type MutationSaveComparisonTableArgs = {
  input: SaveComparisonTableInput;
};


export type MutationSaveOpportunityAsDraftArgs = {
  input: SaveOpportunityAsDraftInput;
};


export type MutationSendCalSchedulingLinkArgs = {
  input: SendCalSchedulingLinkInput;
};


export type MutationSendUpdatedQuoteRequestToGaOrCarrierArgs = {
  input: SendUpdatedQuoteRequestToGaOrCarrierInput;
};


export type MutationShareFirmQuoteRequestHealthApplicationArgs = {
  firmQuoteRequestID: Scalars['String'];
};


export type MutationSharePlanComparisonTableArgs = {
  opportunityID: Scalars['String'];
};


export type MutationSubmitBenefitterQuoteRequestArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  retry?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSubmitOpportunityArgs = {
  input: SubmitOpportunityInput;
};


export type MutationSubmitQuoteRequestArgs = {
  input: SubmitQuoteRequestInput;
};


export type MutationUpdateBenefitterPlanNameFromCrmArgs = {
  benefitterPlanNameId: Scalars['ID'];
  input: UpdateBenefitterPlanNameFromCrmInput;
};


export type MutationUpdateCompanyFromPortalArgs = {
  companyId: Scalars['ID'];
  input: UpdateCompanyFromPortalInput;
};


export type MutationUpdateCompanyJobClassificationsFromPortalArgs = {
  companyId: Scalars['ID'];
  input: UpdateCompanyJobClassificationsFromPortalInput;
};


export type MutationUpdateContactArgs = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateEmployeeToCensusArgs = {
  companyId: Scalars['ID'];
  input: UpdateEmployeeToCensusInput;
  isZohoId: Scalars['Boolean'];
};


export type MutationUpdateFirmQuoteRequestHealthApplicationDetailsCarrierPortalArgs = {
  accessLinkToken: Scalars['String'];
  input?: InputMaybe<HealthApplicationDetailsInput>;
};


export type MutationUpdateFirmQuoteRequestHealthApplicationDetailsCrmArgs = {
  input: HealthApplicationDetailsInput;
  share?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOpportunityArgs = {
  input: UpdateOpportunityInput;
};


export type MutationUpdateQuoteRequestByGaOrCarrierArgs = {
  input: UpdateQuoteRequestByGaOrCarrierInput;
};

export type NeedOtp = {
  __typename?: 'NeedOTP';
  needOtp: Scalars['Boolean'];
  resendDelaySeconds: Scalars['Int'];
};

export enum NetworkTypeEnum {
  EyeMed = 'EYE_MED',
  Hmo = 'HMO',
  HsaQualified = 'HSA_QUALIFIED',
  Mac = 'MAC',
  NoPreferenceDontKnow = 'NO_PREFERENCE_DONT_KNOW',
  Pos = 'POS',
  Ppo = 'PPO',
  Vsp = 'VSP'
}

export type Opportunity = {
  __typename?: 'Opportunity';
  agentOfRecordChangeConfirmed?: Maybe<Scalars['Boolean']>;
  assignedAccountCoordinator?: Maybe<ZohoCrmUser>;
  assignedAccountManager?: Maybe<ZohoCrmUser>;
  clientHasActivePolicy?: Maybe<Scalars['Boolean']>;
  clientWantsToConsiderOtherPolicyOptions?: Maybe<Scalars['Boolean']>;
  company: Company;
  comparisonTableAvailableOnClientPortal?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['Date']>;
  createdOnCrm?: Maybe<Scalars['Boolean']>;
  finalPolicySelectionAvailableInClientPortal?: Maybe<Scalars['Boolean']>;
  finalPolicySelectionCompleted?: Maybe<Scalars['Boolean']>;
  hb2015LettersSigned?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  meetings?: Maybe<Array<Maybe<Meeting>>>;
  mightBeInterestedWhenCurrentPolicyExpires?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numberOfTeamMembersThatMightWantToEnroll?: Maybe<Scalars['Int']>;
  opportunityActivePolicies?: Maybe<Array<OpportunityActivePolicy>>;
  opportunityProducts?: Maybe<Array<OpportunityProduct>>;
  otherPolicyRequirements?: Maybe<OtherPolicyRequirements>;
  pendingFinalPolicySelection?: Maybe<Scalars['Boolean']>;
  pendingTerminationDecision?: Maybe<Scalars['Boolean']>;
  quoteRequests?: Maybe<Array<QuoteRequest>>;
  requiresHB2015Signing?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<OpportunityStageEnum>;
  terminationLettersRequested?: Maybe<Scalars['Boolean']>;
  terminationLettersSigned?: Maybe<Scalars['Boolean']>;
  type?: Maybe<OpportunityTypeEnum>;
  updatedAt?: Maybe<Scalars['Date']>;
  zohoID: Scalars['String'];
};

export type OpportunityActivePoliciesResponse = {
  __typename?: 'OpportunityActivePoliciesResponse';
  id?: Maybe<Scalars['String']>;
};

export type OpportunityActivePolicy = {
  __typename?: 'OpportunityActivePolicy';
  activePolicy: ActivePolicy;
  hb2015LetterRequested?: Maybe<Scalars['Boolean']>;
  hb2015LetterSigned?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  terminationLetterSigned?: Maybe<Scalars['Boolean']>;
  terminationRequested?: Maybe<Scalars['Boolean']>;
};

export type OpportunityActivePolicyResponse = {
  __typename?: 'OpportunityActivePolicyResponse';
  hb2015LetterRequested?: Maybe<Scalars['Boolean']>;
  hb2015LetterSigned?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  terminationLetterSigned?: Maybe<Scalars['Boolean']>;
  terminationRequested?: Maybe<Scalars['Boolean']>;
};

export type OpportunityInput = {
  accessLinkOtp?: InputMaybe<Scalars['String']>;
  agentOfRecordChangeConfirmed?: InputMaybe<Scalars['Boolean']>;
  aorChangeDate?: InputMaybe<Scalars['Date']>;
  benefitsDescriptionAndVideoURLAddedToEase?: InputMaybe<Scalars['Boolean']>;
  benefitsGuideState?: InputMaybe<Scalars['String']>;
  benefitsOverviewVideoURL?: InputMaybe<Scalars['String']>;
  brokerCommissionDisclosureStatementRequired?: InputMaybe<Scalars['Boolean']>;
  clientHasActivePolicy?: InputMaybe<Scalars['Boolean']>;
  clientWantsToConsiderOtherPolicyOptions?: InputMaybe<Scalars['Boolean']>;
  comparisonTableAvailableOnClientPortal?: InputMaybe<Scalars['Boolean']>;
  createdFromProLifeGuideDownload?: InputMaybe<Scalars['Boolean']>;
  createdOnCrm?: InputMaybe<Scalars['Boolean']>;
  crmAPIName?: InputMaybe<Scalars['String']>;
  documentsState?: InputMaybe<Scalars['String']>;
  easeInfoProvided?: InputMaybe<Scalars['Boolean']>;
  employeesFinishedEnrollment?: InputMaybe<Scalars['Boolean']>;
  finalPolicySelectionAvailableInClientPortal?: InputMaybe<Scalars['Boolean']>;
  finalPolicySelectionCompleted?: InputMaybe<Scalars['Boolean']>;
  form1ID?: InputMaybe<Scalars['Int']>;
  hb2015LettersSigned?: InputMaybe<Scalars['Boolean']>;
  lastReminderDate?: InputMaybe<Scalars['Date']>;
  leadSource?: InputMaybe<Scalars['String']>;
  linkToProLifeGuideDownload?: InputMaybe<Scalars['String']>;
  mightBeInterestedWhenCurrentPolicyExpires?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nonApplicableReasonForSection125Plan?: InputMaybe<Scalars['String']>;
  numberOfTeamMembersThatMightWantToEnroll?: InputMaybe<Scalars['Int']>;
  openEnrollmentDateState?: InputMaybe<Scalars['String']>;
  openEnrollmentEndDate?: InputMaybe<Scalars['Date']>;
  openEnrollmentStartDate?: InputMaybe<Scalars['Date']>;
  pendingFinalPolicySelection?: InputMaybe<Scalars['Boolean']>;
  pendingTerminationDecision?: InputMaybe<Scalars['Boolean']>;
  personForEnrollmentProcess?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  productsRequirementWhatIsImportantToClientAndEmployees?: InputMaybe<Scalars['String']>;
  reasonForCancellation?: InputMaybe<Scalars['String']>;
  reasonForMarkingAsIrrelevant?: InputMaybe<Scalars['String']>;
  requiresHB2015Signing?: InputMaybe<Scalars['Boolean']>;
  saleType?: InputMaybe<Scalars['String']>;
  section125PlanState?: InputMaybe<Scalars['String']>;
  spdDocumentState?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
  terminationLettersRequested?: InputMaybe<Scalars['Boolean']>;
  terminationLettersSigned?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  zohoID?: InputMaybe<Scalars['String']>;
};

export enum OpportunityInterestEnum {
  AgentOfRecordChange = 'AgentOfRecordChange',
  ConsiderOtherPolicyOptions = 'ConsiderOtherPolicyOptions',
  InterestedWhenCurrnetPolicyExpires = 'InterestedWhenCurrnetPolicyExpires'
}

export type OpportunityProduct = {
  __typename?: 'OpportunityProduct';
  approximateEmployerContribution?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  maximumMonthlyContributionByEmployee?: Maybe<Scalars['String']>;
  networkType?: Maybe<Array<NetworkTypeEnum>>;
  numberOfTeamMembersThatMightWantToEnroll?: Maybe<Scalars['Int']>;
  opportunityProductInterestedEmployees?: Maybe<Array<OpportunityProductInterestedEmployee>>;
  otherSpecificCoverageRelatedRequests?: Maybe<Scalars['String']>;
  plannedEffectiveDate?: Maybe<Scalars['Date']>;
  policyContributionType?: Maybe<Array<PolicyContributionTypeEnum>>;
  policyType?: Maybe<PolicyTypeEnum>;
  product: Product;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type OpportunityProductInterestedEmployee = {
  __typename?: 'OpportunityProductInterestedEmployee';
  coverageType?: Maybe<CoverageTypeEnum>;
  createdAt?: Maybe<Scalars['Date']>;
  employee: Employee;
  id: Scalars['String'];
  interestedEmployeeDependentIds?: Maybe<Array<Scalars['String']>>;
  opportunityProduct?: Maybe<OpportunityProduct>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum OpportunityStageEnum {
  AgreedToMoveForward = 'AGREED_TO_MOVE_FORWARD',
  AllEnrolled = 'ALL_ENROLLED',
  AorChangeCompleted = 'AOR_CHANGE_COMPLETED',
  AorChangeLetterSigned = 'AOR_CHANGE_LETTER_SIGNED',
  ApplicationReady = 'APPLICATION_READY',
  AssessmentBooked = 'ASSESSMENT_BOOKED',
  AttemptedToContact = 'ATTEMPTED_TO_CONTACT',
  AwaitingQuotes = 'AWAITING_QUOTES',
  Cancelled = 'CANCELLED',
  ClientDecidedOnRenewAction = 'CLIENT_DECIDED_ON_RENEW_ACTION',
  ClosedLost = 'CLOSED_LOST',
  CompanyEnquired = 'COMPANY_ENQUIRED',
  CompanyRequestedAssessment = 'COMPANY_REQUESTED_ASSESSMENT',
  ContactInFuture = 'CONTACT_IN_FUTURE',
  EmployeesFinishedEnrollment = 'EMPLOYEES_FINISHED_ENROLLMENT',
  EnrollmentCompleted = 'ENROLLMENT_COMPLETED',
  EnrollmentSubmitted = 'ENROLLMENT_SUBMITTED',
  IdentifiedNeededInsuranceTypes = 'IDENTIFIED_NEEDED_INSURANCE_TYPES',
  Interested = 'INTERESTED',
  InUnderwriting = 'IN_UNDERWRITING',
  IrrelevantOpportunity = 'IRRELEVANT_OPPORTUNITY',
  NewLeadCompanyEnquired = 'NEW_LEAD_COMPANY_ENQUIRED',
  NewLeadProspectiveCompanyEnquired = 'NEW_LEAD_PROSPECTIVE_COMPANY_ENQUIRED',
  NewLeadProLifeGuideRequested = 'NEW_LEAD_PRO_LIFE_GUIDE_REQUESTED',
  NotInterested = 'NOT_INTERESTED',
  NotReady = 'NOT_READY',
  PendingAorChange = 'PENDING_AOR_CHANGE',
  PendingAorChangeLetterSigning = 'PENDING_AOR_CHANGE_LETTER_SIGNING',
  PendingAssigningAccountManager = 'PENDING_ASSIGNING_ACCOUNT_MANAGER',
  PendingEmployeeSelections = 'PENDING_EMPLOYEE_SELECTIONS',
  PendingEnrollment = 'PENDING_ENROLLMENT',
  PendingEnrollmentInEase = 'PENDING_ENROLLMENT_IN_EASE',
  PendingFollowUpSale = 'PENDING_FOLLOW_UP_SALE',
  PendingInformationCollection = 'PENDING_INFORMATION_COLLECTION',
  PendingLetterSigning = 'PENDING_LETTER_SIGNING',
  PendingMedicalUnderwriting = 'PENDING_MEDICAL_UNDERWRITING',
  PendingOfflineEnrollmentPrep = 'PENDING_OFFLINE_ENROLLMENT_PREP',
  PendingOnlineEnrollmentPrep = 'PENDING_ONLINE_ENROLLMENT_PREP',
  PendingPolicySelection = 'PENDING_POLICY_SELECTION',
  PendingRenewalEnrollment = 'PENDING_RENEWAL_ENROLLMENT',
  PendingSubmittingToCarriers = 'PENDING_SUBMITTING_TO_CARRIERS',
  PendingSummaryAndInvoice = 'PENDING_SUMMARY_AND_INVOICE',
  PendingTerminationLetterSigning = 'PENDING_TERMINATION_LETTER_SIGNING',
  ProspectiveCompanyEnquired = 'PROSPECTIVE_COMPANY_ENQUIRED',
  ProspectiveCompanyIdentified = 'PROSPECTIVE_COMPANY_IDENTIFIED',
  ProLifeGuideRequested = 'PRO_LIFE_GUIDE_REQUESTED',
  QuotesPrepared = 'QUOTES_PREPARED',
  QuotesPresented = 'QUOTES_PRESENTED',
  ReadyToQuote = 'READY_TO_QUOTE',
  RenewedSuccessfully = 'RENEWED_SUCCESSFULLY',
  RequirementsChanged = 'REQUIREMENTS_CHANGED',
  Submitted = 'SUBMITTED',
  ToQuote = 'TO_QUOTE',
  UnderwritingDeclined = 'UNDERWRITING_DECLINED',
  WaitingForActivePolicyExploration = 'WAITING_FOR_ACTIVE_POLICY_EXPLORATION'
}

export enum OpportunityTypeEnum {
  AorChange = 'AOR_CHANGE',
  InterestNotConfirmed = 'INTEREST_NOT_CONFIRMED',
  QuoteRequest = 'QUOTE_REQUEST'
}

export type OpportunityWithOpportunityActivePoliciesObject = {
  __typename?: 'OpportunityWithOpportunityActivePoliciesObject';
  company?: Maybe<Company>;
  hb2015LettersSigned?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  opportunityActivePolicies?: Maybe<Array<Maybe<OpportunityActivePolicy>>>;
  requiresHB2015Signing?: Maybe<Scalars['Boolean']>;
  terminationLettersRequested?: Maybe<Scalars['Boolean']>;
  terminationLettersSigned?: Maybe<Scalars['Boolean']>;
};

export type OpportunityWithOpportunityActivePoliciesResponse = {
  __typename?: 'OpportunityWithOpportunityActivePoliciesResponse';
  accessLink?: Maybe<Scalars['String']>;
  opportunity?: Maybe<OpportunityWithOpportunityActivePoliciesObject>;
};

export type OptionalBasicAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type OtherPolicyRequirements = {
  __typename?: 'OtherPolicyRequirements';
  clientEmployeesCoveragePriorities?: Maybe<Scalars['String']>;
  currentBrokerTasksThatClientWantsContinued?: Maybe<Scalars['String']>;
  whatWouldClientLikeBufferToStartDoing?: Maybe<Scalars['String']>;
};

export type PaginatedOpportunityResponse = {
  __typename?: 'PaginatedOpportunityResponse';
  opportunities: Array<Maybe<Opportunity>>;
  paginationInfo: PaginationInfo;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PayrollCycleEnum {
  Annually = 'ANNUALLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY'
}

export type Policy = {
  __typename?: 'Policy';
  carrier?: Maybe<Carrier>;
  company?: Maybe<Company>;
  comparisonTablePolicy?: Maybe<ComparisonTablePolicy>;
  id: Scalars['ID'];
  insuranceType?: Maybe<ActivePolicyTypeEnum>;
  monthlyPremiumType?: Maybe<PolicyMonthlyPremiumType>;
  networkType?: Maybe<NetworkTypeEnum>;
  opportunity?: Maybe<Opportunity>;
  policyCode?: Maybe<Scalars['String']>;
  policyContributionType?: Maybe<PolicyBenefitContributionTypeEnum>;
  policyName?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyType?: Maybe<PolicyTypeEnum>;
  quoteRequest?: Maybe<QuoteRequest>;
  status?: Maybe<PolicyStatusEnum>;
  zohoID?: Maybe<Scalars['String']>;
};

export enum PolicyBenefitContributionTypeEnum {
  Contributory = 'CONTRIBUTORY',
  DontKnow = 'DONT_KNOW',
  EmployerSponsored = 'EMPLOYER_SPONSORED',
  Voluntary = 'VOLUNTARY'
}

export enum PolicyContributionTypeEnum {
  Contributory = 'CONTRIBUTORY',
  DontKnow = 'DONT_KNOW',
  EmployeeSponsored = 'EMPLOYEE_SPONSORED',
  Voluntary = 'VOLUNTARY'
}

export enum PolicyMonthlyPremiumType {
  AgeBanded = 'AGE_BANDED',
  Composite = 'COMPOSITE'
}

export type PolicyRequirementsInput = {
  approximateEmployerContribution?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  maximumMonthlyContributionByEmployee?: InputMaybe<Scalars['String']>;
  numberOfTeamMembersThatMightWantToEnroll?: InputMaybe<Scalars['Int']>;
  otherDetails?: InputMaybe<Scalars['String']>;
  otherSpecificCoverageRelatedRequests?: InputMaybe<Scalars['String']>;
  plannedEffectiveDate?: InputMaybe<Scalars['String']>;
};

export enum PolicyStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  PendingActivation = 'PENDING_ACTIVATION',
  PendingEnrollment = 'PENDING_ENROLLMENT',
  PendingMedicalUnderwriting = 'PENDING_MEDICAL_UNDERWRITING',
  PendingRenewal = 'PENDING_RENEWAL',
  Terminated = 'TERMINATED'
}

export enum PolicyTypeEnum {
  FullyInsured = 'FULLY_INSURED',
  LevelFunded = 'LEVEL_FUNDED',
  SelfFunded = 'SELF_FUNDED'
}

export type Product = {
  __typename?: 'Product';
  description: Scalars['String'];
  formattedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: ProductNameEnum;
};

export enum ProductNameEnum {
  Dental = 'DENTAL',
  LifeAndAdAndD = 'LIFE_AND_AD_AND_D',
  LongTermDisability = 'LONG_TERM_DISABILITY',
  Medical = 'MEDICAL',
  ShortTermDisability = 'SHORT_TERM_DISABILITY',
  Vision = 'VISION'
}

export type Query = {
  __typename?: 'Query';
  getBenefitterPlanNames: GetBenefitterPlanNamesResponse;
  getCarriersAndGAs: GetCarriersAndGAsResponse;
  getCompany: Company;
  getCompanyEmployeesByCompanyId: Array<Maybe<Employment>>;
  getContact?: Maybe<Contact>;
  getContacts?: Maybe<Array<Maybe<Contact>>>;
  getHB2015SigningTicketStatus: GetHb2015SigningTicketStatusResponse;
  getOpportunities: PaginatedOpportunityResponse;
  getOpportunity: Opportunity;
  getOpportunityActivePoliciesFromAccessLink: OpportunityWithOpportunityActivePoliciesResponse;
  getOpportunityActivePolicy: OpportunityActivePolicyResponse;
  getOpportunityDuplicateScenario: GetOpportunityDuplicateScenarioResponse;
  getPortalUserCompany: Company;
  getProducts: Array<Product>;
  getSigningUrlForHB2015Letter: GetSigningUrlForHb2015LetterResponse;
  getSigningUrlForTerminationLetter: GetSigningUrlForHb2015LetterResponse;
  getTerminationSigningTicketStatus: GetHb2015SigningTicketStatusResponse;
  getZohoCrmUsers: Array<ZohoCrmUser>;
  getZohoCrmUsersWithCalendlyEvents: Array<ZohoCrmUserWithCalendlyEvents>;
  verifyQuoteRequestaccessLink: VerifyQuoteRequestaccessLinkResponse;
  viewBenefitterQuoteRetrievalProgress: BenefitterQuoteRetrievalProgressData;
};


export type QueryGetBenefitterPlanNamesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};


export type QueryGetCarriersAndGAsArgs = {
  engagedDirectly?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCompanyArgs = {
  id?: InputMaybe<Scalars['String']>;
  isZohoId?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCompanyEmployeesByCompanyIdArgs = {
  id?: InputMaybe<Scalars['String']>;
  isZohoId?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetContactArgs = {
  id: Scalars['ID'];
};


export type QueryGetHb2015SigningTicketStatusArgs = {
  accessLinkOtp?: InputMaybe<Scalars['String']>;
  ticketId: Scalars['String'];
};


export type QueryGetOpportunitiesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetOpportunityArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  isZohoId?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetOpportunityActivePoliciesFromAccessLinkArgs = {
  otp?: InputMaybe<Scalars['String']>;
};


export type QueryGetOpportunityActivePolicyArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryGetOpportunityDuplicateScenarioArgs = {
  input: CreateOpportunityInput;
};


export type QueryGetSigningUrlForHb2015LetterArgs = {
  input: GetSigningUrlForHb2015LetterInput;
};


export type QueryGetSigningUrlForTerminationLetterArgs = {
  input: GetSigningUrlForTerminationLetterInput;
};


export type QueryGetTerminationSigningTicketStatusArgs = {
  accessLinkOtp?: InputMaybe<Scalars['String']>;
  ticketId: Scalars['String'];
};


export type QueryGetZohoCrmUsersArgs = {
  hasCalendly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetZohoCrmUsersWithCalendlyEventsArgs = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<ZohoCrmUserProfilesEnum>;
};


export type QueryVerifyQuoteRequestaccessLinkArgs = {
  opportunityID?: InputMaybe<Scalars['String']>;
  quoteRequestID?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryViewBenefitterQuoteRetrievalProgressArgs = {
  quoteRequestId: Scalars['String'];
};

export type QuoteRequest = {
  __typename?: 'QuoteRequest';
  accessLink?: Maybe<Scalars['String']>;
  accessLinkToken?: Maybe<Scalars['String']>;
  accessLinkTokenDisabled?: Maybe<Scalars['Boolean']>;
  benefitterProposalId?: Maybe<Scalars['String']>;
  benefitterQuoteFetchStatus?: Maybe<FetchBenefitterQuoteStatusEnum>;
  benefitterQuoteFetchType?: Maybe<FetchBenefitterQuoteTypeEnum>;
  carrier?: Maybe<Carrier>;
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  collectedQuotes?: Maybe<Array<CollectedQuote>>;
  comparisonTablePolicies?: Maybe<Array<ComparisonTablePolicy>>;
  createdAt?: Maybe<Scalars['Date']>;
  desiredCommisionRate?: Maybe<Scalars['String']>;
  generalAgency?: Maybe<GeneralAgency>;
  id: Scalars['ID'];
  linesOfInsurance?: Maybe<Array<QuoteRequestLineOfInsurance>>;
  message?: Maybe<Scalars['String']>;
  offeredCommissionRate?: Maybe<Scalars['String']>;
  opportunity?: Maybe<Opportunity>;
  proposalName?: Maybe<Scalars['String']>;
  quoteRequestActivePolicyDocuments?: Maybe<Array<QuoteRequestActivePolicyDocument>>;
  quoteRequestDeclineMessage?: Maybe<Scalars['String']>;
  quoteRequestInterestedEmployees?: Maybe<Array<QuoteRequestInterestedEmployees>>;
  quoteRequestedChangesDeclineMessage?: Maybe<Scalars['String']>;
  quoteResponseMessage?: Maybe<Scalars['String']>;
  requestedChangesDeclined?: Maybe<Scalars['Boolean']>;
  source: QuoteRequestSourceEnum;
  status?: Maybe<QuoteRequestStatusEnum>;
  updatedAt?: Maybe<Scalars['Date']>;
  version?: Maybe<Scalars['String']>;
  zohoID: Scalars['String'];
};

export type QuoteRequestActivePolicyDocument = {
  __typename?: 'QuoteRequestActivePolicyDocument';
  activePolicyDocument: ActivePolicyDocument;
  id: Scalars['String'];
};

export type QuoteRequestInterestedEmployees = {
  __typename?: 'QuoteRequestInterestedEmployees';
  employee: Employee;
  id: Scalars['String'];
  interestedEmployeeDependents?: Maybe<Array<EmployeeDependent>>;
  product?: Maybe<Product>;
};

export type QuoteRequestLineOfInsurance = {
  __typename?: 'QuoteRequestLineOfInsurance';
  approximateEmployerContribution?: Maybe<Scalars['String']>;
  maximumMonthlyContributionByEmployee?: Maybe<Scalars['String']>;
  name: ActivePolicyTypeEnum;
  networkType?: Maybe<Array<NetworkTypeEnum>>;
  numberOfTeamMembersThatMightWantToEnroll?: Maybe<Scalars['Int']>;
  otherSpecificCoverageRelatedRequests?: Maybe<Scalars['String']>;
  plannedEffectiveDate?: Maybe<Scalars['String']>;
  policyContributionType?: Maybe<Array<PolicyContributionTypeEnum>>;
  policyType?: Maybe<Array<PolicyTypeEnum>>;
};

export enum QuoteRequestSourceEnum {
  Benefitter = 'BENEFITTER',
  Carrier = 'CARRIER',
  GeneralAgency = 'GENERAL_AGENCY'
}

export enum QuoteRequestStatusEnum {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Outdated = 'OUTDATED',
  PendingCollection = 'PENDING_COLLECTION',
  PendingReview = 'PENDING_REVIEW',
  WithGaCarrier = 'WITH_GA_CARRIER'
}

export type RelatedCompany = {
  __typename?: 'RelatedCompany';
  company: Company;
  companyCrmRecordLink: Scalars['String'];
  isBusinessOwner: Scalars['Boolean'];
  isPrimaryContact: Scalars['Boolean'];
};

export enum RelationshipEnum {
  Child = 'CHILD',
  Spouse = 'SPOUSE'
}

export type RemoveBenefitterPlanNameFromCrmResponse = {
  __typename?: 'RemoveBenefitterPlanNameFromCRMResponse';
  benefitterPlanName: BenefitterPlanName;
  deleted: Scalars['Boolean'];
};

export enum RequestSourceEnum {
  AccessLink = 'ACCESS_LINK',
  Crm = 'CRM',
  CustomerPortal = 'CUSTOMER_PORTAL'
}

export type RequestTerminationLetterInput = {
  accountManagerId: Scalars['String'];
  activePolicies: Array<InputMaybe<ActivePoliciesTerminationInput>>;
  id: Scalars['String'];
};

export type ResendFirmQuoteRequestInput = {
  cc?: InputMaybe<Array<ResendQuoteRequestSender>>;
  firmQuoteRequestID: Scalars['String'];
  recipients: Array<ResendQuoteRequestSender>;
};

export type ResendFirmQuoteRequestResponse = {
  __typename?: 'ResendFirmQuoteRequestResponse';
  done: Scalars['Boolean'];
};

export type ResendFirmQuoteRequestSender = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type ResendQuoteRequestInput = {
  cc?: InputMaybe<Array<ResendQuoteRequestSender>>;
  quoteRequestID: Scalars['String'];
  recipients: Array<ResendQuoteRequestSender>;
};

export type ResendQuoteRequestResponse = {
  __typename?: 'ResendQuoteRequestResponse';
  done?: Maybe<Scalars['Boolean']>;
};

export type ResendQuoteRequestSender = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum RespondToFirmQuoteRequestActionEnum {
  DeclineRequest = 'DECLINE_REQUEST',
  SendQuoteResponse = 'SEND_QUOTE_RESPONSE'
}

export type RespondToFirmQuoteRequestInput = {
  accessLinkToken?: InputMaybe<Scalars['String']>;
  action: RespondToFirmQuoteRequestActionEnum;
  collectedQuotes?: InputMaybe<Array<CollectedQuoteInput>>;
  id: Scalars['String'];
  requestSource: RequestSourceEnum;
  responseMessage: Scalars['String'];
};

export type RespondToFirmQuoteRequestResponse = {
  __typename?: 'RespondToFirmQuoteRequestResponse';
  firmQuoteRequest?: Maybe<FirmQuoteRequest>;
};

export type ReturnSigningTicket = {
  __typename?: 'ReturnSigningTicket';
  expiresAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  signingUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum SicCodeEnum {
  Cashgrains_0119 = 'CASHGRAINS_0119',
  Corn_0115 = 'CORN_0115',
  Cotton_0131 = 'COTTON_0131',
  Rice_0112 = 'RICE_0112',
  Soybeans_0116 = 'SOYBEANS_0116',
  Wheat_0111 = 'WHEAT_0111'
}

export type SaveComparisonTableInput = {
  asDraft: Scalars['Boolean'];
  opportunityId: Scalars['String'];
  policies: Array<InputMaybe<ComparisonTablePolicyInput>>;
  productType?: InputMaybe<ActivePolicyTypeEnum>;
};

export type SaveComparisonTableResponse = {
  __typename?: 'SaveComparisonTableResponse';
  opportunityId: Scalars['String'];
};

export type SaveOpportunityAsDraftInput = {
  activePolicies?: InputMaybe<Array<InputMaybe<ActivePoliciesInput>>>;
  companyDetails?: InputMaybe<CompanyDetailsInput>;
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  numberOfTeamMembersThatMightWantToEnroll?: InputMaybe<Scalars['Int']>;
  otherDetails?: InputMaybe<SaveOpportunityOtherDetailsInput>;
  policyChange?: InputMaybe<Scalars['Boolean']>;
  productDetails: Array<InputMaybe<SaveOpportunityAsDraftProductDetailsInput>>;
  requestSource: RequestSourceEnum;
};

export type SaveOpportunityAsDraftProductDetailsInput = {
  id: Scalars['String'];
  networkType?: InputMaybe<Array<NetworkTypeEnum>>;
  policyContributionType?: InputMaybe<Array<PolicyContributionTypeEnum>>;
  policyRequirements?: InputMaybe<PolicyRequirementsInput>;
};

export type SaveOpportunityAsDraftResponse = {
  __typename?: 'SaveOpportunityAsDraftResponse';
  activePolicies?: Maybe<Array<ActivePolicy>>;
  opportunity?: Maybe<Opportunity>;
};

export type SaveOpportunityOtherDetailsInput = {
  clientEmployeesCoveragePriorities?: InputMaybe<Scalars['String']>;
  currentBrokerTasksThatClientWantsContinued?: InputMaybe<Scalars['String']>;
  whatWouldClientLikeBufferToStartDoing?: InputMaybe<Scalars['String']>;
};

export type SchedulingLinkCompanyGuestInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createPersonRecord: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SchedulingLinkInviteeInput = {
  contactId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SendCalSchedulingLinkInput = {
  bufferGuests: Array<InputMaybe<ZohoCrmUserInput>>;
  calEventId: Scalars['String'];
  companyGuests: Array<InputMaybe<SchedulingLinkCompanyGuestInput>>;
  host: ZohoCrmUserInput;
  invitee: SchedulingLinkInviteeInput;
  opportunityId: Scalars['String'];
  skipSendingEmailToInvitee?: InputMaybe<Scalars['Boolean']>;
};

export type SendCalSchedulingLinkResponse = {
  __typename?: 'SendCalSchedulingLinkResponse';
  schedulingLink: Scalars['String'];
};

export type SendUpdatedQuoteRequestToGaOrCarrierInput = {
  ccEmails?: InputMaybe<Array<EmailRecipientInput>>;
  claimsDataActivePolicyDocumentIds?: InputMaybe<Array<Scalars['String']>>;
  desiredCommissionRate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  linesOfInsurance?: InputMaybe<Array<CompleteLinesOfInsuranceInput>>;
  messageToGAOrCarrier?: InputMaybe<Scalars['String']>;
  toEmails?: InputMaybe<Array<EmailRecipientInput>>;
};

export type SendUpdatedQuoteRequestToGaOrCarrierResponse = {
  __typename?: 'SendUpdatedQuoteRequestToGAOrCarrierResponse';
  newQuoteRequest?: Maybe<QuoteRequest>;
  oldQuoteRequest?: Maybe<QuoteRequest>;
};

export type SigningTicket = {
  __typename?: 'SigningTicket';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type SigningUrlDataResult = NeedOtp | ReturnSigningTicket;

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StageData = {
  __typename?: 'StageData';
  errorMessage?: Maybe<Scalars['String']>;
  stage: FetchBenefitterQuoteStageEnum;
  status: FetchBenefitterQuoteStatusEnum;
};

export type SubmitBenefitterQuoteRequestResponse = {
  __typename?: 'SubmitBenefitterQuoteRequestResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type SubmitOpportunityInput = {
  activePolicies: Array<InputMaybe<ActivePoliciesInput>>;
  companyDetails: CompanyDetailsInput;
  fromCRM: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  numberOfTeamMembersThatMightWantToEnroll?: InputMaybe<Scalars['Int']>;
  otherDetails?: InputMaybe<SaveOpportunityOtherDetailsInput>;
  policyChange?: InputMaybe<Scalars['Boolean']>;
  productDetails: Array<InputMaybe<SubmitOpportunityProductDetailsInput>>;
  saveAndSubmit: Scalars['Boolean'];
  saveAsDraft: Scalars['Boolean'];
};

export type SubmitOpportunityProductDetailsInput = {
  employeesInterested?: InputMaybe<Array<EmployeesInterestedInput>>;
  id: Scalars['String'];
  networkType?: InputMaybe<Array<NetworkTypeEnum>>;
  policyContributionType?: InputMaybe<Array<PolicyContributionTypeEnum>>;
  policyRequirements: PolicyRequirementsInput;
};

export type SubmitQuoteRequestInput = {
  carrierDetails: Array<InputMaybe<CarrierDetails>>;
  fromCRM?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  zohoID?: InputMaybe<Scalars['String']>;
};

export type SubmitQuoteRequestResponse = {
  __typename?: 'SubmitQuoteRequestResponse';
  id?: Maybe<Scalars['String']>;
  quoteRequests?: Maybe<Array<QuoteRequest>>;
};

export type TaskInFirmQuoteRequest = {
  __typename?: 'TaskInFirmQuoteRequest';
  id: Scalars['String'];
  zohoID: Scalars['String'];
};

export type UpdateBenefitterPlanNameFromCrmInput = {
  carrierName: CarrierNameEnum;
  name: Scalars['String'];
  networkType: NetworkTypeEnum;
  policyType: PolicyTypeEnum;
};

export type UpdateBenefitterPlanNameFromCrmResponse = {
  __typename?: 'UpdateBenefitterPlanNameFromCRMResponse';
  benefitterPlanName: BenefitterPlanName;
};

export type UpdateCompanyFromPortalInput = {
  SICCode?: InputMaybe<Scalars['String']>;
  areTheOwnerAndHisOrHerSpouseTheOnlyPeopleEnrollingInThePolicies?: InputMaybe<YesNoEnum>;
  companySize?: InputMaybe<Scalars['Int']>;
  companyWantsToOfferBenefitsTo?: InputMaybe<Array<EmployeesTheCompanyWantsToOfferBenefitsToEnum>>;
  differentToPhysicalAddress?: InputMaybe<YesNoEnum>;
  doesTheCompanyHaveASection125PlanInPlace?: InputMaybe<YesNoEnum>;
  facebookCompanyPage?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  isGroupAGovernmentAgencyAndEmployeesInTheGroupIPsArePublicSector?: InputMaybe<YesNoEnum>;
  isTheCompanyAHusbandWifeCompany?: InputMaybe<YesNoEnum>;
  isTheCompanyRequiredToHaveOSHATraining?: InputMaybe<YesNoEnum>;
  isYourCompanyOwnedCompletelyByAHusbandWifeCouple?: InputMaybe<YesNoEnum>;
  isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch?: InputMaybe<YesNoEnum>;
  legalName?: InputMaybe<Scalars['String']>;
  linkedInCompanyPage?: InputMaybe<Scalars['String']>;
  logoS3Key?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<BasicAddressInput>;
  numberOf1099Contractors?: InputMaybe<Scalars['Int']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']>;
  numberOfFullTimeEmployees?: InputMaybe<Scalars['Int']>;
  numberOfPartTimeEmployees?: InputMaybe<Scalars['Int']>;
  payrollCycle?: InputMaybe<PayrollCycleEnum>;
  registeredAddress?: InputMaybe<BasicAddressInput>;
  stateOfIncorporation?: InputMaybe<Scalars['String']>;
  waitingPeriod?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyFromPortalResponse = {
  __typename?: 'UpdateCompanyFromPortalResponse';
  company: Company;
};

export type UpdateCompanyJobClassificationsFromPortalInput = {
  jobClassifications: Array<Scalars['String']>;
};

export type UpdateCompanyJobClassificationsFromPortalResponse = {
  __typename?: 'UpdateCompanyJobClassificationsFromPortalResponse';
  company: Company;
};

export type UpdateDependentToCensusInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  medicarePrimary?: InputMaybe<MedicarePrimaryEnum>;
  relationship?: InputMaybe<RelationshipEnum>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployeeToCensusInput = {
  coverageType?: InputMaybe<CoverageTypeEnum>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  dateOfHire?: InputMaybe<Scalars['String']>;
  dependents?: InputMaybe<Array<InputMaybe<UpdateDependentToCensusInput>>>;
  email?: InputMaybe<Scalars['String']>;
  employeeType?: InputMaybe<EmployeesTheCompanyWantsToOfferBenefitsToEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum>;
  id: Scalars['String'];
  jobType?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  medicarePrimary?: InputMaybe<MedicarePrimaryEnum>;
  salary?: InputMaybe<Scalars['Int']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployeeToCensusResponse = {
  __typename?: 'UpdateEmployeeToCensusResponse';
  employment?: Maybe<Employment>;
};

export type UpdateOpportunityInput = {
  opportunityData: OpportunityInput;
  opportunityId: Scalars['String'];
};

export enum UpdateQuoteRequestByGaOrCarrierActionEnum {
  DeclineQuoteRequest = 'DECLINE_QUOTE_REQUEST',
  DeclineRequestedChanges = 'DECLINE_REQUESTED_CHANGES',
  SendQuoteResponse = 'SEND_QUOTE_RESPONSE'
}

export type UpdateQuoteRequestByGaOrCarrierEmployeesInterestedInput = {
  data: Array<EmployeesInterestedInput>;
  productId: Scalars['String'];
};

export type UpdateQuoteRequestByGaOrCarrierInput = {
  accessLinkToken?: InputMaybe<Scalars['String']>;
  action: UpdateQuoteRequestByGaOrCarrierActionEnum;
  claimsDataActivePolicyDocumentIds?: InputMaybe<Array<Scalars['String']>>;
  collectedQuotes?: InputMaybe<Array<CollectedQuoteInput>>;
  desiredCommissionRate?: InputMaybe<Scalars['String']>;
  employeesInterested?: InputMaybe<Array<UpdateQuoteRequestByGaOrCarrierEmployeesInterestedInput>>;
  id: Scalars['String'];
  linesOfInsurance?: InputMaybe<Array<CompleteLinesOfInsuranceInput>>;
  messageToGAOrCarrier?: InputMaybe<Scalars['String']>;
  offeredCommissionRate?: InputMaybe<Scalars['String']>;
  requestSource: RequestSourceEnum;
  responseMessage: Scalars['String'];
};

export type UpdateQuoteRequestByGaOrCarrierResponse = {
  __typename?: 'UpdateQuoteRequestByGAOrCarrierResponse';
  quoteRequest?: Maybe<QuoteRequest>;
};

export type VerifyQuoteRequestaccessLinkResponse = FirmQuoteRequest | QuoteRequest;

export enum YesNoEnum {
  No = 'NO',
  Yes = 'YES'
}

export enum ZohoCrmUserProfilesEnum {
  AccountCoordinatorB2B = 'ACCOUNT_COORDINATOR_B2B',
  AccountManager = 'ACCOUNT_MANAGER',
  AccountManagerB2C = 'ACCOUNT_MANAGER_B2C',
  Administrator = 'ADMINISTRATOR',
  AgentSuccessB2CAm = 'AGENT_SUCCESS_B2C_AM',
  ColdCaller = 'COLD_CALLER',
  GroupSalesRep = 'GROUP_SALES_REP',
  IndependentAgent = 'INDEPENDENT_AGENT',
  IndependentAgentPc = 'INDEPENDENT_AGENT_PC',
  IndependentAgentProfessional = 'INDEPENDENT_AGENT_PROFESSIONAL',
  MarketingCoordinator = 'MARKETING_COORDINATOR',
  OfficeAdministrator = 'OFFICE_ADMINISTRATOR',
  Standard = 'STANDARD'
}

export type ZohoCrmUser = {
  __typename?: 'ZohoCrmUser';
  calendlyPage?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  zohoID: Scalars['String'];
};

export type ZohoCrmUserInput = {
  zohoCrmUserId: Scalars['String'];
};

export type ZohoCrmUserWithCalendlyEvents = {
  __typename?: 'ZohoCrmUserWithCalendlyEvents';
  calendlyEvents: Array<CalendlyScheduledEvent>;
  calendlyManagedEvents: Array<Maybe<CalendlyManagedEvent>>;
  calendlyPage?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCalendlyHost?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  zohoID: Scalars['String'];
};

export type OpportunityResponse = {
  __typename?: 'opportunityResponse';
  opportunity: Opportunity;
};

export type OpportunityTaskResponse = {
  __typename?: 'opportunityTaskResponse';
  opportunity: Opportunity;
  task: TaskInOpportunity;
};

export type ResendEmailToSignHb2015LettersResponse = {
  __typename?: 'resendEmailToSignHB2015LettersResponse';
  opportunity?: Maybe<OpportunityWithOpportunityActivePoliciesObject>;
};

export type TaskInOpportunity = {
  __typename?: 'taskInOpportunity';
  id: Scalars['String'];
  zohoID: Scalars['String'];
};

export type CreateOpportunityMutationVariables = Exact<{
  input: CreateOpportunityInput;
}>;


export type CreateOpportunityMutation = { __typename?: 'Mutation', createOpportunity: { __typename?: 'CreateOpportunityResponse', opportunity: { __typename?: 'Opportunity', id: string } } };

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = { __typename?: 'Query', getContacts?: Array<{ __typename?: 'Contact', email?: string | null, firstName?: string | null, mobile?: string | null } | null> | null };


export const CreateOpportunityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOpportunity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateOpportunityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOpportunity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"opportunity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<CreateOpportunityMutation, CreateOpportunityMutationVariables>;
export const GetContactsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetContacts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getContacts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"mobile"}}]}}]}}]} as unknown as DocumentNode<GetContactsQuery, GetContactsQueryVariables>;