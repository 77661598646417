import { ROUTES } from "app-constants";
import { LepCalculatorLayout } from "Components";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LepCalculatorPartB, LepCalculatorPartD } from "Views";

const DefaultLepCalculatorPage = () => (
  <Navigate to={ROUTES.lepCalculator.fullPath} />
);

export const LepCalculatorRoutes = () => {
  return (
    <LepCalculatorLayout>
      <Routes>
        <Route index element={<LepCalculatorPartB />} />
        <Route
          path={ROUTES.lepCalculatorPartB.path}
          element={<LepCalculatorPartB />}
        />
        <Route
          path={ROUTES.lepCalculatorPartD.path}
          element={<LepCalculatorPartD />}
        />
        <Route path="/*" element={<DefaultLepCalculatorPage />} />
      </Routes>
    </LepCalculatorLayout>
  );
};
