import React from "react";
import {
  quoteRequestSelectors,
  QuoteRequestStepEnum,
  useAppSelector,
} from "store";
import { GetQuoteWrapper } from "./GetQuoteWrapper";
import { StepWrapper } from "./StepWrapper";

export const QuoteRequest = () => {
  const step = useAppSelector(quoteRequestSelectors.getStep);

  if (step === QuoteRequestStepEnum.GET_QUOTE) {
    return <GetQuoteWrapper />;
  }

  return <StepWrapper />;
};
