/* eslint-disable no-underscore-dangle */
import { CheckCircleOutline, Warning } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import {
  GetSigningUrlForHb2015LetterResponse,
  OpportunityActivePolicy,
} from "gql/graphql";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SigningIframe } from "./SigningIframe";
import { FileIframe } from "./FileIframe";

interface Props {
  index: number;
  opportunityActivePolicy: OpportunityActivePolicy;
  value: number;
  fetchSigningInfo: (
    opportunityActivePolicyId: string,
    silent: boolean
  ) => Promise<GetSigningUrlForHb2015LetterResponse | null>;
  updateSignedDocument: (id: string) => void;
  fetchSigningDocumentInfo: (id: string) => Promise<string>;
  fetchSigningStatus: (id: string) => Promise<boolean>;
}

interface SigningInfo {
  isSigned: boolean;
  signingUrl: string;
  downloadUrl: string;
}

export const SigningTab = ({
  value,
  index,
  opportunityActivePolicy,
  fetchSigningInfo,
  updateSignedDocument,
  fetchSigningDocumentInfo,
  fetchSigningStatus,
  ...others
}: Props) => {
  const [signingInfo, setSigningInfo] = useState<SigningInfo>({
    isSigned: !!opportunityActivePolicy.hb2015LetterSigned,
    signingUrl: "",
    downloadUrl: "",
  });
  const [, setIframeLoadEventCalled] = useState(false);

  const isActive = useMemo(() => value === index, [value, index]);

  const fetchCurrentDocumentUrl = useCallback(async () => {
    if (
      !signingInfo?.isSigned ||
      signingInfo?.downloadUrl ||
      !opportunityActivePolicy?.id ||
      !isActive
    ) {
      return "";
    }
    const res = await fetchSigningDocumentInfo(opportunityActivePolicy?.id);

    setSigningInfo((current) => {
      return {
        ...current,
        downloadUrl: res,
      };
    });
  }, [
    signingInfo?.isSigned,
    signingInfo?.downloadUrl,
    opportunityActivePolicy?.id,
    fetchSigningDocumentInfo,
    isActive,
  ]);

  const getSigningInfo = useCallback(async () => {
    if (
      signingInfo?.isSigned ||
      signingInfo?.signingUrl ||
      !opportunityActivePolicy?.id ||
      !!opportunityActivePolicy.hb2015LetterSigned ||
      !isActive
    ) {
      return "";
    }
    const res = await fetchSigningInfo(opportunityActivePolicy.id, false);

    if (!res?.data) {
      return;
    }
    setSigningInfo((current) => {
      return {
        ...current,
        signingUrl:
          current.signingUrl ||
          (res.data?.__typename === "ReturnSigningTicket"
            ? res.data?.signingUrl || ""
            : current.signingUrl),
        isSigned:
          res.data?.__typename === "ReturnSigningTicket" &&
          ["signed", "completed"].includes(
            res.data?.status?.toLowerCase() || ""
          ),
      };
    });
  }, [
    signingInfo?.isSigned,
    signingInfo?.signingUrl,
    opportunityActivePolicy?.id,
    fetchSigningInfo,
    opportunityActivePolicy?.hb2015LetterSigned,
    isActive,
  ]);

  const checkForSigningStatus = useCallback(async () => {
    if (!opportunityActivePolicy?.id || !isActive) {
      return false;
    }
    const res = await fetchSigningStatus(opportunityActivePolicy.id);

    setSigningInfo((current) => {
      return {
        ...current,
        isSigned: res,
      };
    });
    if (!res) {
      return new Promise<boolean>((resolve) => {
        setTimeout(() => {
          checkForSigningStatus().then((r) => {
            resolve(r);
          });
        }, 3000);
      });
    }

    return true;
  }, [opportunityActivePolicy?.id, fetchSigningStatus, isActive]);

  const onIframeLoad: React.ReactEventHandler<HTMLIFrameElement> =
    useCallback(() => {
      setIframeLoadEventCalled((current) => {
        if (current) {
          return current;
        }
        checkForSigningStatus();
        return true;
      });
    }, [checkForSigningStatus]);

  useEffect(() => {
    getSigningInfo();
  }, [getSigningInfo]);

  useEffect(() => {
    if (signingInfo.isSigned) {
      updateSignedDocument(opportunityActivePolicy.id!);
    }
  }, [signingInfo.isSigned, updateSignedDocument, opportunityActivePolicy.id]);

  useEffect(() => {
    fetchCurrentDocumentUrl();
  }, [fetchCurrentDocumentUrl]);

  if (!isActive) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`active-policy-tabpanel-${index}`}
      aria-labelledby={`active-policy-tab-${index}`}
      {...others}
    >
      <Box
        p={2}
        borderRadius={1}
        sx={{
          background: "rgba(246, 246, 246, 1)",
        }}
      >
        <Grid
          container
          alignItems="center"
          columnSpacing={2}
          justifyContent="flex-start"
          wrap="nowrap"
        >
          <Grid item>
            {signingInfo?.isSigned ? (
              <CheckCircleOutline color="success" fontSize="small" />
            ) : (
              <Warning color="warning" fontSize="small" />
            )}
          </Grid>
          <Grid item>
            <Typography>
              {signingInfo?.isSigned
                ? "HB2015 Letter Signed"
                : "Read and Sign HB2015 Letter"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: (theme) => theme.palette.grey[100],
          height: 530,
        }}
      >
        {signingInfo.downloadUrl ? (
          <FileIframe
            title={`Policy ${opportunityActivePolicy.activePolicy.number} - ${opportunityActivePolicy.activePolicy.insuranceType} Letter`}
            fileUrl={signingInfo?.downloadUrl}
          />
        ) : (
          <SigningIframe
            title={`Policy ${opportunityActivePolicy.activePolicy.number} - ${opportunityActivePolicy.activePolicy.insuranceType} Letter`}
            signingUrl={signingInfo?.signingUrl}
            onLoad={onIframeLoad}
          />
        )}
      </Box>
    </div>
  );
};
