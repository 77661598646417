import { Backdrop, BackdropProps, CircularProgress } from "@mui/material";
import React from "react";

interface Props extends BackdropProps {
  open: boolean;
  absolute?: boolean;
  transparent?: boolean;
  size?: number;
}

export const Loader: React.FC<Props> = React.memo(
  ({ open, absolute, transparent, size, ...props }) => {
    return (
      <Backdrop
        sx={{
          zIndex: 9999,
          background: transparent ? "transparent" : "rgba(255, 255, 255, 0.7)",
          position: absolute ? "absolute" : undefined,
        }}
        open={open}
        {...props}
      >
        <CircularProgress size={size} color="primary" />
      </Backdrop>
    );
  }
);
