import { Box, FormControl, Grid, Typography } from "@mui/material";
import React from "react";
import { TextField } from "formik-mui";
import { Field } from "formik";
import { LepCalculatorLabel } from "../Fields";

export const EmailField = () => {
  return (
    <Box>
      <Box pb={4} pt={2}>
        <Typography
          variant="h3"
          sx={{
            fontSize: 20,
            color: "rgba(0, 133, 255, 1)",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
          align="center"
        >
          Calculation Completed!
        </Typography>
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="row"
        wrap="nowrap"
      >
        <Grid item>
          <FormControl>
            <Box pb={1}>
              <LepCalculatorLabel title="Please enter your email address to get the results:" />
            </Box>
            <Box height={23}>
              <Field
                size="small"
                component={TextField}
                name="email"
                required
                type="email"
                fullWidth
                autoComplete="email"
                sx={{
                  ".Mui-error": {
                    marginLeft: 0,
                    paddingLeft: 0,
                    fontSize: "0.8rem",
                  },
                }}
              />
            </Box>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
