import { createContext } from "react";
import { PayrollCycleEnum, YesNoEnum } from "gql/graphql";
import { FormValues } from "./types";

interface CompanyFormContextProps {
  getDownloadUrl: () => Promise<string | undefined>;
  handleDeleteFile: () => void;
  handleUploadFile: (
    file: File,
    abortRequest: (instance: XMLHttpRequest) => void,
    trackProgress: (progressEvent: ProgressEvent<EventTarget>) => any
  ) => Promise<any>;
  handleCancel: () => void;
  initialValues: FormValues;
  isValid: boolean;
  isSubmitting: boolean;
}

export const CompanyFormContext = createContext<CompanyFormContextProps>({
  getDownloadUrl: () => new Promise((resolve) => resolve("")),
  handleDeleteFile: () => {},
  handleUploadFile: () => new Promise((resolve) => resolve("")),
  handleCancel: () => {},
  initialValues: {
    legalName: "",
    stateOfIncorporation: "",
    SICCode: "",
    industry: "",
    companySize: "" as unknown as number,
    numberOfEmployees: "" as unknown as number,
    numberOf1099Contractors: "" as unknown as number,
    numberOfFullTimeEmployees: "" as unknown as number,
    numberOfPartTimeEmployees: "" as unknown as number,
    isTheCompanyRequiredToHaveOSHATraining: "" as YesNoEnum,
    employeesTheCompanyWantsToOfferBenefitsTo: [],
    payrollCycle: "" as PayrollCycleEnum,
    waitingPeriod: "" as unknown as number,
    doesTheCompanyHaveASection125PlanInPlace: "" as YesNoEnum,
    isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch: "" as YesNoEnum,
    linkedInCompanyPage: "",
    facebookCompanyPage: "",
    website: "",
    logoS3Key: "",
    physicalAddress: {
      city: "",
      country: "",
      pobox: "",
      state: "",
      street: "",
    },
    mailingAddress: {
      city: "",
      country: "",
      pobox: "",
      state: "",
      street: "",
      differentToPhysicalAddress: "" as YesNoEnum,
    },
    differentToPhysicalAddress: false,
  },
  isValid: false,
  isSubmitting: false,
});
