import React, { useEffect, useState } from "react";

interface Props {
  title: string;
  fileUrl?: string;
}

export const FileIframe = ({ fileUrl, title }: Props) => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    setSrc((current) => {
      if (current || !fileUrl) {
        return current;
      }
      return fileUrl;
    });
  }, [src, fileUrl]);

  if (!src) {
    return null;
  }

  return (
    <iframe
      src={src}
      height="100%"
      width="100%"
      style={{
        outline: 0,
        border: 0,
      }}
      title={title}
    />
  );
};
