import { Box, Grid } from "@mui/material";
import { Button, useCompanyForm } from "Components";
import React, { useCallback } from "react";

interface Props {
  onCancel: () => void;
}

export const CompanyInformationFormActions = ({ onCancel }: Props) => {
  const { isValid, isSubmitting } = useCompanyForm();

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);
  return (
    <Box mt={3}>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button onClick={handleCancel}>CANCEL</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={!isValid || isSubmitting}
            type="submit"
          >
            NEXT
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
