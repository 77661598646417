import { ROUTES } from "app-constants";
import { DashboardLayout } from "Components";
import { useAuth } from "hooks";
import React, { useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { CompanyDetails, UpdateCensus } from "Views";

import { DashboardHomeRoutes } from "./DashboardHome.routes";
import { RequestsRoutes } from "./Requests.routes";

const DefaultHomePage = () => <Navigate to={ROUTES.dashboardHome.fullPath} />;

export const DashboardRoutes = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const redirectPage = useMemo(() => {
    if (!isAuthenticated) {
      return {
        path: ROUTES.index.fullPath,
        state: {
          from: location.pathname,
          hash: location.hash,
        },
      };
    }

    return null;
  }, [location.hash, location.pathname, isAuthenticated]);

  if (!isAuthenticated && redirectPage) {
    return <Navigate to={redirectPage.path} state={redirectPage.state} />;
  }

  return (
    <DashboardLayout>
      <Routes>
        <Route index element={<DefaultHomePage />} />
        <Route
          path={`${ROUTES.dashboardHome.path}/*`}
          element={<DashboardHomeRoutes />}
        />
        <Route path={ROUTES.companyDetails.path} element={<CompanyDetails />} />
        <Route
          path={`${ROUTES.requests.path}/*`}
          element={<RequestsRoutes />}
        />
        <Route
          path={`${ROUTES.updateCensus.path}/*`}
          element={<UpdateCensus />}
        />
        <Route
          path="/*"
          element={<Navigate to={ROUTES.dashboard.fullPath} />}
        />
      </Routes>
    </DashboardLayout>
  );
};
