import { Form, Formik, FormikConfig } from "formik";
import React, { useCallback, useMemo } from "react";
import { StateEmployee } from "store";
import * as yup from "yup";
import {
  CoverageTypeEnum,
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  GenderEnum,
  MedicarePrimaryEnum,
  PayrollCycleEnum,
  RelationshipEnum,
} from "gql/graphql";
import { EmployeeDetailsFormDialog } from "./EmployeeDetailsFormDialog";
import { FormValues } from "./types";
import { getRelationShipEnum, startOfToday } from "./helpers";

interface Props {
  onClose: () => void;
  onSave: (values: FormValues) => Promise<void>;
  onCreateNew?: () => void;
  open: boolean;
  employee?: StateEmployee | null;
  title: string;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().label("First name").required(),
  lastName: yup.string().label("Last name").required(),
  dob: yup
    .date()
    .label("Date of birth")
    .max(startOfToday.toISOString())
    .required(),
  gender: yup.string().label("Gender").required(),
  medicarePrimary: yup.string().label("Medicare primary").notRequired(),
  employeeType: yup.string().label("Employee type").required(),
  zipCode: yup.string().label("Zip code").required(),
  dateOfHire: yup.date().label("Date of hire").max(startOfToday).notRequired(),
  jobTitle: yup.string().label("Job title").notRequired(),
  salary: yup
    .number()
    .min(0)
    .positive()
    .integer()
    .label("Salary")
    .notRequired(),
  payrollCycle: yup.string().label("Payroll cycle").notRequired(),
  email: yup.string().label("Email").email().notRequired(),
  coverage: yup
    .string()
    .label("Coverage Type")
    .required()
    .test({
      name: "test children",
      message: "At least one Child needs to be added",
      test(coverage: any) {
        const shouldIncludeChild = [
          CoverageTypeEnum.CobraPlusChildren,
          CoverageTypeEnum.EmployeePlusChildren,
        ].includes(coverage as CoverageTypeEnum);
        const dependents: any[] = this.parent?.dependents;
        const isIncludeChild = dependents.some(
          (dependent) => dependent.relationship === RelationshipEnum.Child
        );

        return !(shouldIncludeChild && !isIncludeChild);
      },
    })
    .test({
      name: "test spouse",
      message: "A Spouse needs to be added",
      test(coverage: any) {
        const shouldIncludeSpouse = [
          CoverageTypeEnum.CobraPlusSpouse,
          CoverageTypeEnum.EmployeePlusSpouse,
        ].includes(coverage as CoverageTypeEnum);
        const dependents: any[] = this.parent?.dependents;
        const isIncludeSpouse = dependents.some(
          (dependent) => dependent.relationship === RelationshipEnum.Spouse
        );

        return !(shouldIncludeSpouse && !isIncludeSpouse);
      },
    })
    .test({
      name: "test family",
      message: "A Spouse and at least one Child needs to be added",
      test(coverage: any) {
        const shouldIncludeFamily = [
          CoverageTypeEnum.CobraPlusFamily,
          CoverageTypeEnum.EmployeePlusFamily,
        ].includes(coverage as CoverageTypeEnum);
        const dependents: any[] = this.parent?.dependents;
        const isIncludeSpouse = dependents.some(
          (dependent) => dependent.relationship === RelationshipEnum.Spouse
        );
        const isIncludeChild = dependents.some(
          (dependent) => dependent.relationship === RelationshipEnum.Child
        );

        const isIncludeFamily = isIncludeSpouse || isIncludeChild;

        return !(shouldIncludeFamily && !isIncludeFamily);
      },
    }),
  dependents: yup.array().of(
    yup.object().shape({
      firstName: yup.string().label("First name").required(),
      lastName: yup.string().label("Last name").required(),
      relationship: yup.string().label("Last name").required(),
      dob: yup.date().label("Date of birth").max(startOfToday).required(),
      gender: yup.string().label("Gender").required(),
      zipCode: yup.string().label("Zip code").required(),
      medicarePrimary: yup.string().label("Medicare primary").notRequired(),
    })
  ),
});

export const EmployeeDetailsForm = ({
  onClose,
  open,
  onSave,
  employee,
  title,
  onCreateNew,
}: Props) => {
  const initialValues: FormValues = useMemo(() => {
    return {
      firstName: employee?.contact?.firstName || "",
      lastName: employee?.contact?.lastName || "",
      dob: employee?.contact?.dateOfBirth || "",
      gender: employee?.contact?.gender || ("" as GenderEnum),
      medicarePrimary:
        employee?.contact?.medicarePrimary || ("" as MedicarePrimaryEnum),
      employeeType:
        employee?.employeeType ||
        ("" as EmployeesTheCompanyWantsToOfferBenefitsToEnum),
      zipCode: employee?.contact?.zipCode || "",
      dateOfHire: employee?.dateOfHire || "",
      jobTitle: employee?.jobTitle || "",
      salary: employee?.salary || ("" as unknown as number),
      payrollCycle: employee?.payrollCycle || ("" as PayrollCycleEnum),
      email: employee?.contact?.email || "",
      coverage: employee?.coverage || ("" as CoverageTypeEnum),
      dependents:
        employee?.employeeDependents?.map((employeeDependent) => ({
          id: employeeDependent?.id || "",
          isCustomId: false,
          firstName: employeeDependent?.contact?.firstName || "",
          lastName: employeeDependent?.contact?.lastName || "",
          dob: employeeDependent?.contact?.dateOfBirth || "",
          gender: employeeDependent?.contact?.gender || ("" as GenderEnum),
          medicarePrimary:
            employeeDependent?.contact?.medicarePrimary ||
            ("" as MedicarePrimaryEnum),
          zipCode:
            ((employeeDependent?.contact as any)?.zipCode as string) || "",
          relationship: getRelationShipEnum(employeeDependent?.relationship),
        })) || [],
    };
  }, [employee]);

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    (values, helpers) => {
      onSave(values);
      helpers.setSubmitting(false);
    },
    [onSave]
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <EmployeeDetailsFormDialog
          onClose={onClose}
          open={open}
          onSave={onSave}
          title={title}
          onCreateNew={onCreateNew}
        />
      </Form>
    </Formik>
  );
};
