import React from "react";
import { initializePkAuth } from "services/pkAuth";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthRoutes, DashboardRoutes, LepCalculatorRoutes } from "routes";
import { ROUTES } from "app-constants";
import { useAuth } from "hooks";
import { AuthLayout, Loader } from "Components";
import { ActivationForm } from "Views";

initializePkAuth();

function App() {
  const { isChecked } = useAuth();

  if (!isChecked) {
    return <Loader open />;
  }
  return (
    <Routes>
      <Route
        path={`${ROUTES.dashboard.path}/*`}
        element={<DashboardRoutes />}
      />
      <Route
        path={ROUTES.activation.path}
        element={
          <AuthLayout>
            <ActivationForm />
          </AuthLayout>
        }
      />
      <Route
        path={`${ROUTES.lepCalculator.path}/*`}
        element={<LepCalculatorRoutes />}
      />
      <Route path={`${ROUTES.auth.path}/*`} element={<AuthRoutes />} />
      <Route path="*" element={<Navigate to={ROUTES.auth.path} />} />
    </Routes>
  );
}

export default App;
