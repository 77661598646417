import { StateActivePolicies, StateActivePolicy } from "store";
import { ActivePolicyWidgetRes } from "./types";

export const mapActivePolicyWidgetResToStateActivePolicies = (
  params: ActivePolicyWidgetRes
): StateActivePolicies => ({
  otherDetails: params.otherDetails,
  activePolicies: params.activePolicies.map(
    ({
      insuranceProvider,
      insuranceType,
      otherDetails,
      policyNumber,
      summary,
      activePolicyTermDate,
      document,
      id,
      isCustomId,
    }): StateActivePolicy => ({
      carrier: insuranceProvider,
      insuranceType,
      otherDetails,
      number: policyNumber,
      summaryOfMonthlycost: summary,
      termDate: activePolicyTermDate,
      activePolicyDocuments:
        document?.map(({ fileName, s3Key }) => ({
          fileName,
          s3Key,
        })) || [],
      id,
      isCustomId,
    })
  ),
});

export const mapStateActivePoliciesToActivePolicyWidgetRes = (
  params: StateActivePolicies
): ActivePolicyWidgetRes => ({
  otherDetails: params.otherDetails,
  activePolicies: params.activePolicies.map(
    ({
      carrier,
      insuranceType,
      otherDetails,
      number,
      summaryOfMonthlycost,
      termDate,
      activePolicyDocuments,
      id,
      isCustomId,
    }) => ({
      insuranceProvider: carrier,
      insuranceType,
      otherDetails,
      policyNumber: number,
      summary: summaryOfMonthlycost,
      activePolicyTermDate: termDate,
      document: activePolicyDocuments.map(({ fileName, s3Key }) => ({
        fileName,
        s3Key,
      })),
      id,
      isCustomId: !!isCustomId,
    })
  ),
});
