import { Box, Button, Collapse, Grid, Switch, Typography } from "@mui/material";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useResponsive } from "hooks";
import { LepCalculatorPartDResult2 } from "./LepCalculatorPartDResult2";
import { LepCalculatorPartDResult1 } from "./LepCalculatorPartDResult1";
import { LepCalculatorPartDResult3 } from "./LepCalculatorPartDResult3";
import { Group } from "./Group";

interface Props {
  resultVariant: number;
  calculatedPenalty: string | null;
  uuid: string;
  email: string;
  yearsCalculated: Record<number, string>;
  resizeParentWindow: (height?: number, avoidScrolling?: boolean) => void;
}

const headers = [1, 2, 3];
const mobileHeaders = [1, 2];

export const LepCalculatorPartDResult = ({
  resultVariant,
  calculatedPenalty,
  uuid,
  email,
  yearsCalculated,
  resizeParentWindow,
}: Props) => {
  const { isMDDown } = useResponsive();
  const [showPreviousYears, setShowPreviousYears] = useState(false);

  const penaltyScore = useMemo(() => {
    if (Number(calculatedPenalty) === 0) {
      return 0;
    }
    return calculatedPenalty;
  }, [calculatedPenalty]);

  const imageUrl = useMemo(() => {
    return "https://bufferinsurance.com/wp-content/uploads/2023/02/1.png";
  }, []);

  const ebookUrl = useMemo(() => {
    const params = `?email=${email}&form1_id=${uuid}`;
    return `https://bufferinsurance.com/get-medicare-ebook${params}`;
  }, [uuid, email]);

  const buttonText = useMemo(() => {
    return "GET MY FREE EBOOK!";
  }, []);

  const year = useMemo(() => {
    return moment().year();
  }, []);

  const handleChange = useCallback(() => {
    setShowPreviousYears((current) => !current);
    resizeParentWindow(undefined, true);
  }, [resizeParentWindow]);

  return (
    <Box>
      <Box pb={5.25} display="flex" justifyContent="center">
        <Box maxWidth={500}>
          <Typography
            variant="h3"
            sx={{
              fontSize: 24,
              color: "rgba(0, 133, 255, 1)",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            align="center"
            lineHeight={1.4}
          >
            In {year} your estimated late enrollment
            <br /> penalty for Medicare Part D<br /> is ${penaltyScore} per
            month
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems={isMDDown ? "center" : "flex-start"}
        gap={4}
        flexDirection={isMDDown ? "column" : "row"}
        flexWrap="nowrap"
        component="div"
        pb={4}
      >
        <Box flex={1} maxWidth={700}>
          {resultVariant === 1 ? <LepCalculatorPartDResult1 /> : null}
          {resultVariant === 2 ? <LepCalculatorPartDResult2 /> : null}
          {resultVariant === 3 ? <LepCalculatorPartDResult3 /> : null}
        </Box>
        <Box position="relative">
          <Box display="flex" justifyContent="center">
            <Box
              display="block"
              component="a"
              href={ebookUrl}
              target="_blank"
              rel="noreferrer"
              width="fit-content"
            >
              <Box
                width={isMDDown ? "100%" : "auto"}
                maxWidth={isMDDown ? 300 : undefined}
                height={isMDDown ? "auto" : 340}
                component="img"
                src={imageUrl}
                alt="buffer lep ebook"
                display="block"
                sx={{
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          component="a"
          href={ebookUrl}
          target="_blank"
          rel="noreferrer"
          sx={{
            paddingLeft: ({ spacing }) => spacing(isMDDown ? 5 : 10),
            paddingRight: ({ spacing }) => spacing(isMDDown ? 5 : 10),
          }}
        >
          {buttonText}
        </Button>
      </Box>

      {resultVariant === 3 ? (
        <Box pt={4}>
          <Box
            display="flex"
            justifyContent="center"
            component="div"
            gap={1}
            alignItems="center"
          >
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: ({ palette }) => palette.grey[800],
                }}
              >
                Show Previous Years
              </Typography>
              <Switch
                checked={showPreviousYears}
                onChange={handleChange}
                inputProps={{ "aria-label": "Show Previous Years" }}
              />
            </Box>
          </Box>
          <Collapse in={showPreviousYears}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={4}
            >
              <Box
                component="div"
                border={({ palette }) => `1px solid ${palette.grey[200]}`}
                p={2}
                borderRadius={({ spacing }) => spacing(0.5)}
                maxWidth={600}
              >
                <Box pb={2}>
                  <Typography
                    align="center"
                    sx={{
                      fontStyle: "italic",
                      color: "rgba(0, 133, 255, 1)",
                      fontSize: 18,
                    }}
                    fontWeight={700}
                  >
                    Past Late Enrollment Penalties in Situations like Yours
                  </Typography>
                </Box>
                <Box
                  component="div"
                  border={({ palette }) => `1px solid ${palette.grey[200]}`}
                  p={2}
                  borderRadius={({ spacing }) => spacing(0.5)}
                >
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    {(isMDDown ? mobileHeaders : headers).map((item) => (
                      <Grid item key={item} xs={isMDDown ? 6 : 4}>
                        <Group left="Year" right="$/month" isHeading />
                      </Grid>
                    ))}
                    {[
                      {
                        left: 2019,
                        right: yearsCalculated[2019],
                      },
                      {
                        left: 2021,
                        right: yearsCalculated[2021],
                      },
                      {
                        left: 2023,
                        right: yearsCalculated[2023],
                      },
                      {
                        left: 2020,
                        right: yearsCalculated[2020],
                      },
                      {
                        left: 2022,
                        right: yearsCalculated[2022],
                      },
                      {
                        left: 2024,
                        right: yearsCalculated[2024],
                        isBold: true,
                      },
                    ].map((item) => (
                      <Grid item key={item.left} xs={isMDDown ? 6 : 4}>
                        <Group
                          left={item.left}
                          right={`$${item.right}`}
                          isBold={item.isBold}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Box>
      ) : null}
    </Box>
  );
};
