import { Box, FormControl, Grid, MenuItem } from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { LepCalculatorPartBFormValues } from "interfaces";
import moment from "moment";
import { Select } from "formik-mui";
import { useResponsive } from "hooks";
import { LepCalculatorLabel } from "../Fields/LepCalculatorLabel";
import { LepCalculatorRadios } from "../Fields/LepCalculatorRadios";
import { LepCalculatorDateField } from "../Fields";

const incomeTaxOptions = [
  "$103,000 or less",
  "$103,001 up to $129,000",
  "$129,001 up to $161,000",
  "$161,001 up to $193,000",
  "$193,001 up to $500,000",
  "$500,000 or above",
];

const houseHoldIncomeTaxOptions = [
  "$206,000 or less",
  "$206,001 up to $258,000",
  "$258,001 up to $322,000",
  "$322,001 up to $386,000",
  "$386,001 up to $750,000",
  "$750,000 or above",
];
export const LepCalculatorPartBFormA = () => {
  const { isMDDown } = useResponsive();
  const { values, setFieldValue, errors, touched } =
    useFormikContext<LepCalculatorPartBFormValues>();

  const incomeOptions = useMemo(() => {
    return values.isMarried === "yes"
      ? houseHoldIncomeTaxOptions
      : incomeTaxOptions;
  }, [values.isMarried]);

  useEffect(() => {
    if (
      values.isMarried &&
      values.income &&
      !incomeOptions.includes(values.income)
    ) {
      setFieldValue("income", "");
    }
  }, [setFieldValue, values.isMarried, incomeOptions, values.income]);

  return (
    <Box>
      <Box pb={4}>
        <FormControl>
          <Box pb={1}>
            <LepCalculatorLabel
              title="Please enter your date of Birth"
              subTitle="(we need this to know when your enrollment period begins)"
            />
          </Box>
          <Box>
            <Field
              size="small"
              component={LepCalculatorDateField}
              maxYear={moment().year()}
              minYear={1900}
              name="dateOfBirth"
              required
              autoCompleteYear="bday-year"
              autoCompleteMonth="bday-month"
              autoCompleteDay="bday-day"
              maxWidth={isMDDown ? 200 : 170}
            />
          </Box>
        </FormControl>
      </Box>
      <Box pb={4}>
        <FormControl>
          <Box pb={1}>
            <LepCalculatorLabel
              title="When did you enroll into Medicare Part B?"
              subTitle="(If you are yet to enroll, enter the date you plan on enrolling)"
            />
          </Box>
          <Box>
            <Field
              size="small"
              component={LepCalculatorDateField}
              minYear={1964}
              name="partBEnrollmentDate"
              required
              maxWidth={isMDDown ? 200 : 170}
            />
          </Box>
        </FormControl>
      </Box>
      <Box>
        <Grid
          container
          spacing={isMDDown ? 2 : 16}
          wrap="nowrap"
          direction={isMDDown ? "column" : "row"}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item>
            <LepCalculatorRadios
              name="isMarried"
              options={[
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              label={{
                title: "Are you married?",
              }}
              errorMessage={
                errors.isMarried && touched.isMarried
                  ? errors.isMarried
                  : undefined
              }
            />
          </Grid>
          {values.isMarried === "yes" || values.isMarried === "no" ? (
            <Grid item>
              <FormControl
                sx={{
                  width: "100%",
                }}
                size="small"
              >
                <Box pb={1}>
                  <LepCalculatorLabel
                    title={`What was your ${
                      values.isMarried === "yes" ? "household's " : ""
                    } taxable income in ${moment().year() - 2} (2 years ago)?`}
                  />
                </Box>
                <Box height={23}>
                  <Field
                    name="income"
                    component={Select}
                    options={incomeOptions}
                    sx={{
                      width: 250,
                    }}
                    size="small"
                    formHelperText={{
                      sx: {
                        marginLeft: 0,
                        paddingLeft: 0,
                        fontSize: "0.8rem",
                      },
                    }}
                  >
                    {incomeOptions.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Box>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};
