import {
  ActivePoliciesInput,
  ActivePolicyTypeEnum,
  CoverageTypeEnum,
  EmployeesInterestedInput,
  Opportunity,
  OpportunityTypeEnum,
  PayrollCycleEnum,
  SubmitOpportunityInput,
  SubmitOpportunityProductDetailsInput,
  YesNoEnum,
} from "gql/graphql";
import {
  EmployeeFormWidgetRes,
  EmployeeFormWidgetResValue,
  QuoteRequestState,
} from "../types";
import { PolicyOptionEnum, QuoteRequestStepEnum } from "../enums";

const handleNumberOptional = (value: any) => {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    Number.isNaN(Number(value))
  ) {
    return null as unknown as number;
  }
  return Number(value);
};

const mapStateActivePoliciesToActivePoliciesInput = (
  activePolicies: QuoteRequestState["activePolicies"]["activePolicies"]
): ActivePoliciesInput[] => {
  if (!activePolicies) {
    return [];
  }
  return activePolicies.map(
    ({
      termDate,
      summaryOfMonthlycost,
      carrier,
      otherDetails,
      number,
      insuranceType,
      activePolicyDocuments,
    }): ActivePoliciesInput => {
      const activePolicyDocument = activePolicyDocuments
        ? activePolicyDocuments[0]
        : null;
      return {
        insuranceType: insuranceType || ("" as ActivePolicyTypeEnum),
        number: String(number),
        otherDetails,
        provider: carrier || "",
        summaryOfMonthlycost,
        termDate: termDate || null,
        ...(activePolicyDocument
          ? {
              activePolicyDocument: {
                fileName: activePolicyDocument.fileName,
                s3Key: activePolicyDocument.s3Key,
              },
            }
          : {}),
      };
    }
  );
};

const createCompanyPayload = (
  {
    mailingAddress,
    physicalAddress: registeredAddress,
    ...company
  }: QuoteRequestState["company"],
  companyId: string
): SubmitOpportunityInput["companyDetails"] => {
  return {
    SICCode: company.SICCode,
    companySize: company.companySize || null,
    differentToPhysicalAddress: mailingAddress?.differentToPhysicalAddress
      ? YesNoEnum.Yes
      : YesNoEnum.No,
    doesTheCompanyHaveASection125PlanInPlace:
      company.doesTheCompanyHaveASection125PlanInPlace || ("" as YesNoEnum),
    employeesTheCompanyWantsToOfferBenefitsTo:
      company.employeesTheCompanyWantsToOfferBenefitsTo || [],
    facebookCompanyPage: company.facebookCompanyPage || "",
    id: companyId || "",
    industry: company.industry || "",
    isTheCompanyRequiredToHaveOSHATraining:
      company.isTheCompanyRequiredToHaveOSHATraining || ("" as YesNoEnum),
    isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch:
      company.isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch ||
      ("" as YesNoEnum),
    legalName: company.legalName || "",
    linkedInCompanyPage: company.linkedInCompanyPage || "",
    logoS3Key: company.logoS3Key || "",
    mailingAddress: {
      state: mailingAddress?.state || "",
      city: mailingAddress?.city || "",
      country: mailingAddress?.country || "",
      zipCode: mailingAddress?.pobox || "",
      street: mailingAddress?.street || "",
    },
    numberOf1099Contractors: handleNumberOptional(
      company.numberOf1099Contractors
    ),
    numberOfEmployees: handleNumberOptional(company.numberOfEmployees),
    numberOfFullTimeEmployees: handleNumberOptional(
      company.numberOfFullTimeEmployees
    ),
    numberOfPartTimeEmployees: handleNumberOptional(
      company.numberOfPartTimeEmployees
    ),
    payrollCycle: company.payrollCycle || ("" as PayrollCycleEnum),
    physicalAddress: {
      state: registeredAddress?.state || "",
      city: registeredAddress?.city || "",
      country: registeredAddress?.country || "",
      zipCode: registeredAddress?.pobox || "",
      street: registeredAddress?.street || "",
    },
    stateOfIncorporation: company.stateOfIncorporation || "",
    waitingPeriod: handleNumberOptional(company.waitingPeriod),
    website: company.website || "",
  };
};

const mapOtherDetails = (
  otherDetails: QuoteRequestState["otherDetails"]
): SubmitOpportunityInput["otherDetails"] => {
  return otherDetails;
};

const mapEmployeeAndDependents = (
  employees?: EmployeeFormWidgetResValue[]
): SubmitOpportunityProductDetailsInput["employeesInterested"] | undefined => {
  return employees?.reduce<EmployeesInterestedInput[]>(
    (accumulated, current) => {
      if (!current.isSelected) {
        return accumulated;
      }
      const previous = accumulated.find(
        (item) => item.employeeId === current.id
      );

      if (previous) {
        return accumulated.map((item: EmployeesInterestedInput) => {
          if (item.employeeId !== current.id) {
            return item;
          }
          return {
            ...item,
            coverageType: current.coverage,
          };
        });
      }

      return accumulated.concat({
        employeeId: current.id,
        dependentIds: current.dependents
          .filter((d) => d.isSelected)
          .map((d) => d.id),
        coverageType: current.coverage,
      });
    },
    []
  );
};

const getEmployeesInterested = (
  productId: string,
  employeesInterested: QuoteRequestState["employeeForm"]
): SubmitOpportunityProductDetailsInput["employeesInterested"] => {
  if (!employeesInterested) {
    return [];
  }
  return mapEmployeeAndDependents(
    employeesInterested.products.find((p) => p.id === productId)?.employees ||
      []
  );
};

const mapStateProductsToProductDetailsForSubmitOpportunity = (
  products: QuoteRequestState["products"],
  policyRequirements: QuoteRequestState["policyRequirements"],
  employeesInterested: QuoteRequestState["employeeForm"]
): SubmitOpportunityProductDetailsInput[] => {
  if (!products) {
    return [];
  }
  return products.map((product): SubmitOpportunityProductDetailsInput => {
    const policyRequirement = policyRequirements?.find(
      ({ id }) => id === product.id
    );
    const mappedEmployeesInterested = getEmployeesInterested(
      product.id,
      employeesInterested
    );
    return {
      id: product.id || "",
      ...(product.networkType && product.networkType.length
        ? { networkType: product.networkType }
        : {}),
      ...(product.policyContributionType
        ? { policyContributionType: product.policyContributionType }
        : {}),
      policyRequirements: !policyRequirement?.id
        ? {}
        : {
            approximateEmployerContribution:
              policyRequirement?.approximateEmployerContribution || "",
            id: policyRequirement?.id || "",
            maximumMonthlyContributionByEmployee:
              policyRequirement?.maximumMonthlyContributionByEmployee || "",
            numberOfTeamMembersThatMightWantToEnroll: handleNumberOptional(
              policyRequirement?.numberOfTeamMembersThatMightWantToEnroll
            ),
            otherDetails: policyRequirement?.otherDetails || "",
            otherSpecificCoverageRelatedRequests:
              policyRequirement?.otherSpecificCoverageRelatedRequests || "",
            plannedEffectiveDate: policyRequirement?.plannedEffectiveDate || "",
          },
      employeesInterested: mappedEmployeesInterested || [],
    };
  });
};

export const mapQuoteRequestToSubmitOpportunityInput = (
  {
    products,
    numberOfTeamMembersThatMightWantToEnroll,
    policy,
    company,
    activePolicies: { activePolicies },
    opportunityID,
    policyRequirements,
    otherDetails,
    employeeForm,
  }: QuoteRequestState,
  companyId: string,
  saveAsDraft: boolean
): SubmitOpportunityInput => {
  const payload = {
    activePolicies: mapStateActivePoliciesToActivePoliciesInput(activePolicies),
    companyDetails: createCompanyPayload(company, companyId),
    fromCRM: false,
    ...(opportunityID ? { id: opportunityID } : {}),
    numberOfTeamMembersThatMightWantToEnroll: handleNumberOptional(
      numberOfTeamMembersThatMightWantToEnroll
    ),
    otherDetails: mapOtherDetails(otherDetails),
    policyChange: policy === PolicyOptionEnum.Change,
    productDetails: mapStateProductsToProductDetailsForSubmitOpportunity(
      products,
      policyRequirements,
      employeeForm
    ),
    saveAndSubmit: !saveAsDraft,
    saveAsDraft,
  };
  return payload;
};

const mapEmployeesForEmployeesWidget = (
  products: Opportunity["opportunityProducts"] | undefined | null
): EmployeeFormWidgetRes["products"] => {
  if (!products) {
    return [];
  }

  return products.map((product) => {
    const employees =
      product.opportunityProductInterestedEmployees?.map((employee) => {
        return {
          isSelected: true,
          id: employee.employee.id!,
          coverage: (employee.coverageType || "") as CoverageTypeEnum,
          dependents:
            employee.interestedEmployeeDependentIds?.map((dId) => ({
              id: dId,
              isSelected: true,
            })) || [],
        };
      }) || [];
    return { id: product.id!, employees };
  });
};

export const mapQuoteRequestToState = (
  initialState: QuoteRequestState,
  payload: Opportunity,
  step: QuoteRequestStepEnum
) => {
  const mappedState = { ...initialState };
  mappedState.step = step;
  mappedState.opportunityID = payload.id;
  mappedState.numberOfTeamMembersThatMightWantToEnroll =
    payload.numberOfTeamMembersThatMightWantToEnroll || undefined;
  mappedState.policy =
    payload.type === OpportunityTypeEnum.AorChange
      ? PolicyOptionEnum.Change
      : PolicyOptionEnum.New;
  mappedState.products =
    payload.opportunityProducts?.map((product) => {
      return {
        id: product.id!,
        policyContributionType: product.policyContributionType || [],
        networkType: product.networkType || [],
        name: product.product?.name,
      };
    }) || [];
  mappedState.activePolicies = {
    activePolicies:
      payload.opportunityActivePolicies?.map((activePolicy) => {
        return {
          id: activePolicy.id!,
          activePolicyDocuments:
            activePolicy.activePolicy.activePolicyDocuments?.map(
              (activePolicyDocument) => {
                return {
                  fileName: activePolicyDocument.fileName,
                  s3Key: activePolicyDocument.s3Key,
                  id: activePolicyDocument.id,
                };
              }
            ) || [],
          carrier: activePolicy.activePolicy.carrier || "",
          insuranceType: activePolicy.activePolicy.insuranceType,
          number: String(
            handleNumberOptional(typeof activePolicy.activePolicy.number)
          ),
          otherDetails: activePolicy.activePolicy.otherDetails || "",
          relatedCarrier: activePolicy.activePolicy.relatedCarrier?.id || "",
          summaryOfMonthlycost:
            activePolicy.activePolicy.summaryOfMonthlycost || "",
          termDate: activePolicy.activePolicy.termDate || "",
          isCustomId: false,
        };
      }) || [],
    otherDetails: {
      continueToDo:
        payload.otherPolicyRequirements
          ?.currentBrokerTasksThatClientWantsContinued || "",
      startDoing:
        payload.otherPolicyRequirements
          ?.whatWouldClientLikeBufferToStartDoing || "",
    },
  };

  mappedState.otherDetails = {
    currentBrokerTasksThatClientWantsContinued:
      payload.otherPolicyRequirements
        ?.currentBrokerTasksThatClientWantsContinued || "",
    whatWouldClientLikeBufferToStartDoing:
      payload.otherPolicyRequirements?.whatWouldClientLikeBufferToStartDoing ||
      "",
    clientEmployeesCoveragePriorities:
      payload.otherPolicyRequirements?.clientEmployeesCoveragePriorities || "",
  };

  mappedState.policyRequirements =
    payload.opportunityProducts?.map((product) => {
      return {
        approximateEmployerContribution:
          product.approximateEmployerContribution || "",
        id: product.id || "",
        maximumMonthlyContributionByEmployee:
          product.maximumMonthlyContributionByEmployee || "",
        numberOfTeamMembersThatMightWantToEnroll:
          handleNumberOptional(
            product.numberOfTeamMembersThatMightWantToEnroll
          ) || undefined,
        otherDetails: "",
        otherSpecificCoverageRelatedRequests:
          product.otherSpecificCoverageRelatedRequests || "",
        plannedEffectiveDate: product.plannedEffectiveDate || "",
      };
    }) || [];

  mappedState.employeeForm = {
    sameTeam: false,
    products: mapEmployeesForEmployeesWidget(payload.opportunityProducts),
  };

  mappedState.opportunity = payload;

  return mappedState;
};
