import { Box, FormControl, Grid, MenuItem, Theme } from "@mui/material";
import React, { ChangeEventHandler, useCallback } from "react";
import { Field, useFormikContext } from "formik";
import {
  EmployeeFormWidgetRes,
  EmployeeFormWidgetResValue,
  StateEmployee,
} from "store";
import { CheckboxWithLabel, Select } from "formik-mui";
import { CoverageTypeEnum } from "gql/graphql";
import { MappedEmployee } from "./types";
import {
  coverageTypeOptions,
  coverageTypeTitles,
  syncCoverageAndSelection,
} from "./helpers";

interface Props {
  employee: MappedEmployee;
  label: {
    label: string;
    key: string;
  };
  name: string;
  employeeValues: EmployeeFormWidgetResValue;
  employeeData?: StateEmployee;
}

export const EmployeeTableCell = ({
  employee,
  label,
  name,
  employeeValues,
  employeeData,
}: Props) => {
  const { setFieldValue } = useFormikContext<EmployeeFormWidgetRes>();

  const handleSelectionChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const isSelected = e.target.checked;
        if (employeeData) {
          setFieldValue(
            `${name}`,
            syncCoverageAndSelection(
              employeeData,
              employeeValues?.coverage,
              isSelected
            )
          );
        }
      },
      [setFieldValue, employeeData, employeeValues?.coverage, name]
    );

  const handleCoverageChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const coverage = e.target.value;
        if (employeeData) {
          setFieldValue(
            `${name}`,
            syncCoverageAndSelection(
              employeeData,
              coverage as CoverageTypeEnum,
              employeeValues?.isSelected
            )
          );
        }
      },
      [setFieldValue, employeeData, employeeValues?.isSelected, name]
    );

  if (label.key !== "actions") {
    return (employee as any)[label.key];
  }

  return (
    <Grid
      container
      flexWrap="nowrap"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid item>
        <Box sx={{ width: (theme) => theme.spacing(2) }}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            sx={{
              color: (theme: Theme) => theme.palette.primary,
            }}
            color="primary"
            name={`${name}.isSelected`}
            onChange={handleSelectionChange}
          />
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            width: 220,
          }}
        >
          <FormControl
            sx={{
              width: "100%",
            }}
            size="small"
          >
            <Field
              name={`${name}.coverage`}
              component={Select}
              label={
                employeeValues?.coverage
                  ? "Coverage Type"
                  : "Select Coverage Type"
              }
              options={coverageTypeOptions}
              fullWidth
              color="primary"
              // size="small"
              required={employeeValues?.isSelected}
              onChange={handleCoverageChange}
            >
              {coverageTypeOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {coverageTypeTitles[option]}
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};
