import { Alert, Box } from "@mui/material";
import React from "react";
import { StateEmployee } from "store";
import { EmployeesTable } from "./EmployeesTable";

interface Props {
  employees: StateEmployee[];
  onDeleteEmployee: (employee: StateEmployee) => void;
  onEditEmployee: (employee: StateEmployee) => void;
  employeesCount: number;
  onEmployeesPageChange: (page: number) => void;
  onEmployeesRowsPerPageChange: (page: number) => void;
  rowsPerPage: number;
  page: number;
}

export const EmployeesTablePage = ({
  employees,
  onDeleteEmployee,
  onEditEmployee,
  employeesCount,
  rowsPerPage,
  page,
  onEmployeesPageChange,
  onEmployeesRowsPerPageChange,
}: Props) => {
  return (
    <Box mt={3}>
      <Alert severity="info">
        The <strong>Job Title</strong> and <strong>Salary data</strong> are
        required if you want to use the Long Term Disability or Short Term
        Disability products. The <strong>Salary data</strong> is also required
        if you want to use the Life and AD&D product. Employees that don&apos;t
        have this data will not be selectable when requesting for a quote of the
        above products.
      </Alert>
      <EmployeesTable
        employees={employees}
        onDeleteEmployee={onDeleteEmployee}
        onEditEmployee={onEditEmployee}
        employeesCount={employeesCount}
        onEmployeesPageChange={onEmployeesPageChange}
        rowsPerPage={rowsPerPage}
        page={page}
        onEmployeesRowsPerPageChange={onEmployeesRowsPerPageChange}
      />
    </Box>
  );
};
