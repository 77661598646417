import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useCallback } from "react";
import { EmployeeTableHeaderCell } from "./EmployeeTableHeaderCell";
import { employeeTableColumns } from "./helpers";
import { EmployeeTableRow } from "./EmployeeTableRow";
import { MappedEmployee } from "./types";

interface Props {
  employees: MappedEmployee[];
  onDeleteEmployee: (employee: MappedEmployee) => void;
  onEditEmployee: (employee: MappedEmployee) => void;
  onSort: (key: string) => void;
  employeesCount: number;
  onEmployeesPageChange: (page: number) => void;
  onEmployeesRowsPerPageChange: (page: number) => void;
  rowsPerPage: number;
  page: number;
}

export const EmployeesTableForDesktop = ({
  employees,
  onDeleteEmployee,
  onEditEmployee,
  onSort,
  employeesCount,
  rowsPerPage,
  page,
  onEmployeesPageChange,
  onEmployeesRowsPerPageChange,
}: Props) => {
  const getLabelSorting = useCallback(
    (key: string) => {
      return () => {
        onSort(key);
      };
    },
    [onSort]
  );

  const handlePageChange = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      onEmployeesPageChange(newPage + 1);
    },
    [onEmployeesPageChange]
  );

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      onEmployeesRowsPerPageChange(Number(event.target.value));
    },
    [onEmployeesRowsPerPageChange]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          {employeeTableColumns.map((label) => (
            <TableCell key={label.key}>
              <EmployeeTableHeaderCell
                label={label.label}
                onSort={
                  label.sortFunction ? getLabelSorting(label.key) : undefined
                }
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {employees.map((employee) => (
          <EmployeeTableRow
            key={employee.id}
            employee={employee}
            onDelete={onDeleteEmployee}
            onEdit={onEditEmployee}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={employeesCount}
            onPageChange={handlePageChange}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};
