import { Box, FormHelperText } from "@mui/material";
import { FieldProps } from "formik";
import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
  FocusEventHandler,
  ChangeEventHandler,
} from "react";

const styles = {
  container: {
    margin: "0px",
    maxWidth: "100%",
    width: "100%",
  },
  inputDiv: {
    background: "#f2f4f7",
    border: "1px solid #2a5aee",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 4px",
    borderRadius: "4px",
    width: "100%",
    flexFlow: "row nowrap",
  },
  input: {
    MozAppearance: "textfield",
    appearance: "none",
    border: "0px",
    outline: "0px",
    display: "block",
    fontWeight: "400",
    lineHeight: "1.25rem",
    background: "none",
    color: "#303645",
    fontSize: 16,
    maxWidth: "100%",
    textAlign: "center",
    "&::-webkit-outer-spin-button": {
      webkitAppearance: "none",
      WebkitAppearance: "none",
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      webkitAppearance: "none",
    },
  },
  slash: {
    margin: "0px 1px",
    width: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorDiv: {
    borderColor: "red",
  },
  focusDiv: {
    borderColor: "#1737a4",
    background: "#ffffff",
  },
  inputContainer: {
    flex: "1",
    width: "30%",
  },
  empty: {},
};

export interface LepCalculatorDateFieldProps
  extends FieldProps<string | null | undefined> {
  maxYear?: number;
  minYear?: number;
  autocompleteYear?: string;
  autocompleteMonth?: string;
  autocompleteDay?: string;
  maxWidth?: number;
}

export const LepCalculatorDateField = ({
  maxYear = 99999999,
  minYear = 0,
  autocompleteYear,
  autocompleteMonth,
  autocompleteDay,
  maxWidth,
  field: { name, value: fieldValue },
  form: { setFieldTouched, setFieldValue, errors, touched },
}: LepCalculatorDateFieldProps) => {
  const [isInputDivFocused, setIsInputDivFocused] = useState(false);
  const [month, setMonth] = useState("");
  const [stillOnMonth, setStillOnMonth] = useState(false);
  const [monthTouched, setMonthTouched] = useState(false);
  const [day, setDay] = useState("");
  const [stillOnDay, setStillOnDay] = useState(false);
  const [dayTouched, setDayTouched] = useState(false);
  const [year, setYear] = useState("");
  const [stillOnYear, setStillOnYear] = useState(false);
  const [yearTouched, setYearTouched] = useState(false);

  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const monthError = useMemo(() => {
    const value = Number(month);
    if (!value) {
      return "Month is required";
    }
    if (value <= 0 || value > 12) {
      return "Please, provide a valid month";
    }
    return null;
  }, [month]);

  const isLeapYear = useMemo(() => {
    if (!year) {
      return false;
    }
    const yearValue = Number(year);
    return (
      yearValue % 400 === 0 || (yearValue % 100 !== 0 && yearValue % 4 === 0)
    );
  }, [year]);

  const maxDay = useMemo(() => {
    const monthValue = Number(month);
    if (monthValue && [9, 4, 6, 11].includes(monthValue)) {
      return 30;
    }
    if (monthValue && monthValue === 2) {
      if (!year || isLeapYear) {
        return 29;
      }
      return 28;
    }

    return 31;
  }, [isLeapYear, month, year]);

  const dayError = useMemo(() => {
    const v = day;
    if (!v) {
      return "Day is required";
    }
    const value = Number(v);
    if (value <= 0) {
      return "Please, provide a valid day";
    }
    const m = month;
    if (!m && value > 31) {
      return "Please, provide a valid day";
    }
    const monthValue = Number(m);
    if (monthValue && [9, 4, 6, 11].includes(monthValue) && value > 30) {
      return "Please, provide a valid day";
    }

    if (monthValue && monthValue === 2 && value > 29) {
      return "Please, provide a valid day";
    }

    if (monthValue && monthValue === 2 && year && !isLeapYear && value > 28) {
      return "Please, provide a valid day";
    }
    return null;
  }, [month, day, isLeapYear, year]);

  const yearError = useMemo(() => {
    const v = year;
    if (!v) {
      return "Year is required";
    }
    const value = Number(v);
    if (value <= 0) {
      return "Please, provide a valid year";
    }
    if (value > maxYear) {
      return `Please, provide a valid year before ${maxYear}`;
    }

    if (value < minYear) {
      return `Please, provide a valid year after ${minYear}`;
    }
    return null;
  }, [year, maxYear, minYear]);

  const inputError: string | undefined = useMemo(() => {
    return errors[name] as string;
  }, [errors, name]);

  const showError = useMemo(() => {
    return (
      (monthTouched && !!monthError) ||
      (dayTouched && !!dayError) ||
      (yearTouched && !!yearError) ||
      (inputError && touched[name])
    );
  }, [
    monthError,
    monthTouched,
    dayError,
    dayTouched,
    yearTouched,
    yearError,
    inputError,
    touched,
    name,
  ]);

  const focusOnDay = useCallback(() => {
    setTimeout(() => {
      if (dayRef.current) {
        dayRef.current.focus();
      }
    }, 0);
  }, []);

  const focusOnYear = useCallback(() => {
    setTimeout(() => {
      if (yearRef.current) {
        yearRef.current.focus();
      }
    }, 0);
  }, []);

  const onMonthFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.target.select();
      setIsInputDivFocused(true);
    },
    []
  );

  const onMonthBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setIsInputDivFocused(false);
    setStillOnMonth(false);
    if (month === "00") {
      setMonth("01");
    }
    if (month) {
      setMonthTouched(true);
    }
  }, [month]);

  const onMonthInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let v = event.target.value;
      if (stillOnMonth) {
        setStillOnMonth(false);
        if (v === "") {
          v = "";
        } else if (v.length > 1) {
          v = `${v}`;
        } else if (month === "00") {
          setMonth(`0${v}`);
          focusOnDay();
          return;
        } else if (month === "01") {
          setMonth(`1${Number(v) > 2 ? 2 : v}`);
          focusOnDay();
          return;
        } else {
          v = `${month}${v}`;
        }
      }
      if (v === "" || v === undefined || v === null) {
        setMonth("");
        return;
      }

      const value = Number(v);
      if (value < 0) {
        setMonth("");
        return;
      }

      if (value === 0) {
        setMonth("00");
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnMonth(true);
        return;
      }

      if (value === 1) {
        setMonth("01");
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnMonth(true);
        return;
      }
      if (value < 10) {
        setMonth(`0${value}`);
        focusOnDay();

        return;
      }
      if (value < 13) {
        setMonth(`${value}`);
        focusOnDay();
        return;
      }

      setMonth("12");
      focusOnDay();
    },
    [month, stillOnMonth, focusOnDay]
  );

  const onDayFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.target.select();
      setIsInputDivFocused(true);
    },
    []
  );

  const onDayBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setIsInputDivFocused(false);
    setStillOnDay(false);
    if (day === "00") {
      setDay("01");
    }
    if (day) {
      setDayTouched(true);
    }
  }, [day]);

  const onDayInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let v = event.target.value;
      if (stillOnDay) {
        setStillOnDay(false);
        if (v === "") {
          v = "";
        } else if (v.length > 1) {
          v = `${v}`;
        } else if (day === "00") {
          setDay(`0${v}`);
          focusOnYear();
          return;
        } else if (Number(day[1]) < (maxDay > 29 ? 4 : 3)) {
          const newValue = `${day[1]}${v}`;
          if (Number(newValue) > maxDay) {
            setDay(String(maxDay));
          } else {
            setDay(newValue);
          }
          focusOnYear();
          return;
        } else {
          v = `${day}${v}`;
        }
      }
      if (v === "" || v === undefined || v === null) {
        setDay("");
        return;
      }

      const value = Number(v);
      if (value < 0) {
        setDay("");
        return;
      }

      if (value === 0) {
        setDay("00");
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnDay(true);
        return;
      }

      if (value < (maxDay > 29 ? 4 : 3)) {
        setDay(`0${value}`);
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnDay(true);
        return;
      }
      if (value < 10) {
        setDay(`0${value}`);
        focusOnYear();
        return;
      }

      if (value <= maxDay) {
        setDay(`${value}`);
        focusOnYear();
        return;
      }

      setDay(`${maxDay}`);
      focusOnYear();
    },
    [focusOnYear, day, maxDay, stillOnDay]
  );

  const onYearFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.target.select();
      setIsInputDivFocused(true);
    },
    []
  );

  const onYearBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setIsInputDivFocused(false);
    setStillOnYear(false);
    if (year === "0000") {
      setYear("0001");
    }
    if (year) {
      setYearTouched(true);
    }
  }, [year]);

  const onYearInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let v = event.target.value;
      if (stillOnYear) {
        if (v === "") {
          v = "";
        } else if (v.length > 1) {
          v = `${v}`;
        } else {
          v = `${year}${v}`;
        }
      } else {
        v = `${v}`;
        setStillOnYear(true);
      }
      if (v === "" || v === undefined || v === null) {
        setYear("");
        return;
      }

      const value = Number(v);
      if (value < 0) {
        setYear("");
        return;
      }

      if (value === 0) {
        setYear("0000");
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnYear(true);
        return;
      }

      if (value < 10) {
        setYear(`000${value}`);
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnYear(true);
        return;
      }

      if (value < 100) {
        setYear(`00${value}`);
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnYear(true);
        return;
      }
      if (value < 1000) {
        setYear(`0${value}`);
        setTimeout(() => {
          event.target.select();
        }, 0);
        setStillOnYear(true);
        return;
      }

      setYear(`${value}`);
      setTimeout(() => {
        event.target.select();
      }, 0);
      setStillOnYear(true);
    },
    [year, stillOnYear]
  );

  useEffect(() => {
    setFieldTouched(name, monthTouched || dayTouched || yearTouched);
  }, [monthTouched, dayTouched, yearTouched, setFieldTouched, name]);

  useEffect(() => {
    if (month && day && year) {
      setFieldValue(name, `${year}-${month}-${day}T00:00:00.000Z`);
    }
  }, [month, day, year, setFieldValue, name]);

  useEffect(() => {
    if (fieldValue) {
      const [date] = fieldValue.split("T");
      const [initialYear, initialMonth, initialDay] = date.split("-");
      if (initialMonth) {
        setMonth(initialMonth);
        setMonthTouched(true);
      }
      if (initialDay) {
        setDay(initialDay);
        setDayTouched(true);
      }

      if (initialDay) {
        setYear(initialYear);
        setYearTouched(true);
      }
    }
  }, [fieldValue]);

  return (
    <Box sx={styles.container}>
      <Box
        sx={[
          styles.inputDiv,
          isInputDivFocused ? styles.focusDiv : styles.empty,
          showError ? styles.errorDiv : styles.empty,
          {
            maxWidth,
          },
        ]}
        id="input-div"
      >
        <Box sx={styles.inputContainer}>
          <Box
            component="input"
            ref={monthRef}
            onFocus={onMonthFocus}
            sx={styles.input}
            onBlur={onMonthBlur}
            autoComplete={autocompleteMonth}
            onInput={onMonthInput}
            value={month}
            type="number"
            name="month"
            id="month"
            placeholder="MM"
          />
        </Box>
        <Box sx={styles.slash}>/</Box>
        <Box sx={styles.inputContainer}>
          <Box
            component="input"
            ref={dayRef}
            value={day}
            onFocus={onDayFocus}
            autoComplete={autocompleteDay}
            onBlur={onDayBlur}
            onInput={onDayInput}
            type="number"
            name="day"
            id="day"
            placeholder="DD"
            sx={styles.input}
          />
        </Box>
        <Box sx={styles.slash}>/</Box>
        <Box sx={styles.inputContainer}>
          <Box
            component="input"
            ref={yearRef}
            onFocus={onYearFocus}
            autoComplete={autocompleteYear}
            onBlur={onYearBlur}
            sx={styles.input}
            value={year}
            type="number"
            name="year"
            id="year"
            placeholder="YYYY"
            onInput={onYearInput}
          />
        </Box>
      </Box>
      {showError ? (
        <Box
          sx={{
            position: "relative",
            height: 0,
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <FormHelperText
              error
              sx={{
                paddingLeft: 0,
                marginLeft: 0,
                fontSize: "0.8rem",
              }}
            >
              {inputError}
            </FormHelperText>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
