import React from "react";
import {
  AttentionSnippet,
  Button,
  ElementsGroup,
  RadioCard,
  RadioCardGroup,
  Text,
} from "Components/elements";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Box, styled } from "@mui/material";
import { pxToEm } from "utils";

const CustomButton = styled(Button)`
  &:hover {
    background: none;
  }
`;

export const HandleOpportunityDuplication = ({
  onBackClick,
}: {
  onBackClick?: () => void;
}) => {
  return (
    <div>
      <CustomButton
        disableRipple
        color="primary"
        variant="text"
        onClick={onBackClick}
        sx={{
          fontSize: pxToEm("14px"),
          padding: 0,
          "& :hover": {
            background: "none",
          },
        }}
      >
        <ArrowBackOutlinedIcon fontSize="small" />
        <Box component="span" sx={{ ml: 1 }}>
          back
        </Box>
      </CustomButton>
      <ElementsGroup
        label="Handle Duplication"
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <AttentionSnippet
            type="info"
            content="Company already exists. Select if a new Company should be created or to continue with the existing company."
          />
          <RadioCardGroup name="event" defaultValue="first">
            <RadioCard value="first">
              {({ color }) => (
                <Text sx={{ color }} fontSize={18} fontWeight={700}>
                  Continue by Creating New Company
                </Text>
              )}
            </RadioCard>
            <RadioCard value="2nd">
              {({ color }) => (
                <Box>
                  <Text fontSize={18} fontWeight={700} sx={{ color }}>
                    Continue with Existing Company
                  </Text>
                  <Text fontSize={14}>
                    Existing Company Name: <span>Apple Inc.</span>
                  </Text>
                </Box>
              )}
            </RadioCard>
          </RadioCardGroup>
          <AttentionSnippet
            type="warning"
            content={
              <Box
                component="ul"
                sx={{
                  paddingLeft: "20px",
                  fontSize: pxToEm("14px"),
                  margin: 0,
                }}
              >
                <li>
                  <b>Existing Company</b> will be updated with the latest data
                  provided.
                </li>
                <li>
                  The new Opportunity to be created will be associated with the
                  exiting Company.
                </li>
              </Box>
            }
          />
        </Box>
      </ElementsGroup>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F8F8F8",
          padding: "12px",
          gap: 1,
          my: 2,
        }}
      >
        <Text fontFamily="Poppins">Person Info</Text>
        <AttentionSnippet content="This Person already exists. Click on the name to open the Person record." />
        <Box>
          <span>
            <Text content="Name:" color="secondary" fontSize={12} />
            <Text />
          </span>
          <span>
            <Text content="Related to Companies:" fontSize={12} />
            <Text />
          </span>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" sx={{ gap: 1 }}>
        <Button onClick={() => {}}>cancel</Button>
        <Button type="submit" variant="contained">
          create opportunity
        </Button>
      </Box>
    </div>
  );
};
