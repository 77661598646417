import React from "react";
import { Typography as T, TypographyProps } from "@mui/material";
import { pxToEm } from "utils";

export interface TypographyPropsExt extends TypographyProps {
  content?: string;
  fontSize?: string | number | Record<any, any>;
  fontFamily?: "Poppins" | "Roboto";
}

const Typography = ({
  children,
  fontSize,
  fontFamily,
  content,
  sx,
  ...rest
}: TypographyPropsExt) => {
  return (
    <T
      {...rest}
      sx={{
        ...sx,
        fontFamily: fontFamily ? `${fontFamily}, sans-serif` : undefined,
        // eslint-disable-next-line no-nested-ternary
        fontSize: fontSize
          ? typeof fontSize === "object"
            ? fontSize
            : pxToEm(fontSize as string)
          : undefined,
      }}
    >
      {content || children}
    </T>
  );
};

export default Typography;
