import { axiosRequest, graphqlRequest } from "api";
import {
  CreateOpportunityForCompanyInput,
  CreateOpportunityForCompanyResponse,
  Employee,
  GetCarriersAndGAsResponse,
  MutationSubmitOpportunityArgs,
  Opportunity,
  OpportunityWithOpportunityActivePoliciesResponse,
  Product,
  SaveOpportunityAsDraftInput,
  SaveOpportunityAsDraftResponse,
  SubmitOpportunityInput,
} from "gql/graphql";
import { GraphQLError } from "graphql";
import { gql } from "graphql-request";
import { notifications } from "services/notifications";

interface AllGraphQLError extends GraphQLError, Error {
  response?: { errors?: { message: string }[] | string[] | string };
}

const handleGraphQLError = (e: unknown) => {
  const error = e as AllGraphQLError;
  const response = error.response;
  const errors = response?.errors;
  let message = "";
  if (typeof errors === "string") {
    message = errors;
  } else if (Array.isArray(errors)) {
    const firstError = errors[0];
    if (typeof firstError === "string") {
      message = firstError;
    } else {
      message = firstError.message || "";
    }
  }
  if (message) {
    notifications.notifyError(message);
  }
};

export const createOpportunity = async (
  input: CreateOpportunityForCompanyInput,
  companyId: string
) => {
  try {
    const mutation = gql`
      mutation CreateOpportunityForCompany(
        $companyId: ID!
        $input: CreateOpportunityForCompanyInput!
      ) {
        createOpportunityForCompany(companyId: $companyId, input: $input) {
          opportunity {
            id
          }
        }
      }
    `;

    const variables = {
      companyId,
      input,
    };

    const res = await graphqlRequest.request<{
      createOpportunityForCompany: CreateOpportunityForCompanyResponse;
    }>(mutation, variables);

    return res.createOpportunityForCompany;
  } catch (e) {
    handleGraphQLError(e);

    return null;
  }
};

export const saveQuoteRequestAsDraft = async (
  payload: SaveOpportunityAsDraftInput
) => {
  try {
    const mutation = gql`
      mutation saveOpportunityAsDraft($input: SaveOpportunityAsDraftInput!) {
        saveOpportunityAsDraft(input: $input) {
          opportunity {
            id
          }
        }
      }
    `;

    const variables = {
      input: payload,
    };

    const res = await graphqlRequest.request<{
      saveOpportunityAsDraft: SaveOpportunityAsDraftResponse;
    }>(mutation, variables);

    return res.saveOpportunityAsDraft;
  } catch (e) {
    handleGraphQLError(e);
    return null;
  }
};

export const submitOpportunity = async (payload: SubmitOpportunityInput) => {
  try {
    const mutation = gql`
      mutation SubmitOpportunity($input: SubmitOpportunityInput!) {
        submitOpportunity(input: $input) {
          accessLink
          opportunity {
            id
            requiresHB2015Signing
            company {
              id
            }
            opportunityActivePolicies {
              id
            }
          }
        }
      }
    `;

    const variables: MutationSubmitOpportunityArgs = {
      input: payload,
    };

    const res = await graphqlRequest.request<{
      submitOpportunity: OpportunityWithOpportunityActivePoliciesResponse;
    }>(mutation, variables);

    return res.submitOpportunity;
  } catch (e) {
    handleGraphQLError(e);
    return null;
  }
};

export const saveQuoteRequest = async (
  payload: SaveOpportunityAsDraftInput
) => {
  const mutation = gql`
    mutation saveOpportunityAsDraft($input: SaveOpportunityAsDraftInput!) {
      saveOpportunityAsDraft(input: $input) {
        opportunity {
          id
        }
      }
    }
  `;

  const variables = {
    input: payload,
  };

  const res = await graphqlRequest.request<{
    saveOpportunityAsDraft: SaveOpportunityAsDraftResponse;
  }>(mutation, variables);

  return res.saveOpportunityAsDraft;
};

export const fetchS3SignedUrlForUpload = async (
  fileName: string,
  contentType: string,
  bucketType = "Company Logo"
) => {
  const queryParams: Record<string, string> = {
    contentType,
    bucketType,
    fileName,
  };

  const query =
    queryParams && Object.keys(queryParams).length > 0
      ? Object.entries(queryParams).reduce((acc, [key, value]) => {
          let q = acc;
          q += `${q ? "&" : "?"}${key}=${value}`;
          return q;
        }, "")
      : "";

  const res = await axiosRequest.get<{
    data: { url: string; key: string };
  }>(`/file-uploads/presigned-url/portal${query}`);
  return res.data.data;
};

export const fetchEmployeesRequest = async (
  companyId: string,
  page = 1,
  rowsPerPage = 10
) => {
  const res = await axiosRequest.get<{
    data: { data: Employee[]; count: number; total: number };
  }>(
    `/package/employment/employees/companyClientPortal/${companyId}?page=${page}&size=${rowsPerPage}`
  );
  return res.data.data;
};

export const uploadFileToS3 = async (
  file: File,
  url: string,
  abortRequest: (instance: XMLHttpRequest) => void,
  trackProgress?: (progressEvent: ProgressEvent<EventTarget>) => any
) => {
  function handleProgress(
    this: XMLHttpRequest,
    ev: ProgressEvent<EventTarget>
  ) {
    if (trackProgress) trackProgress(ev);
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.upload.onprogress = handleProgress;
    xhr.onload = (e) => {
      resolve(e);
    };
    xhr.onerror = reject;
    xhr.send(file);
    abortRequest(xhr);
  });
};

export const fetchCompanyLogo = async (companyID: string) => {
  const res = await axiosRequest.get<{
    data: { url: string };
  }>(`/companies/${companyID}/logo-url`);
  return res.data.data;
};

export const getS3SignedUrl = async (file: File, bucketType = "Document") => {
  const res = await axiosRequest.get<{
    data: { url: string };
  }>(
    `/file-uploads/presigned-url/portal?contentType=${file.type}&bucketType=${bucketType}`
  );
  return res.data;
};

export const getProductsOffersRequest = async () => {
  const res = await graphqlRequest.request<{
    getProducts: Product[];
  }>(gql`
    query getProducts {
      getProducts {
        id
        name
        formattedName
        description
      }
    }
  `);

  return res.getProducts;
};

export const getCarriersRequest = async () => {
  const res = await graphqlRequest.request<{
    getCarriersAndGAs: GetCarriersAndGAsResponse;
  }>(gql`
    query GetCarriersAndGAs(
      $page: Int
      $perPage: Int
      $engagedDirectly: Boolean
    ) {
      getCarriersAndGAs(
        page: $page
        perPage: $perPage
        engagedDirectly: $engagedDirectly
      ) {
        count
        records {
          ... on Carrier {
            id
            name
            zohoID
            engagedDirectly
            shortName
          }
        }
      }
    }
  `);

  return res.getCarriersAndGAs.records;
};

export const getOpportunityRequest = async (
  opportunityID: string,
  companyId: string
) => {
  const res = await graphqlRequest.request<{
    getOpportunity: Opportunity;
  }>(
    gql`
      query getOpportunity($getOpportunityId: ID!, $companyId: ID) {
        getOpportunity(
          id: $getOpportunityId
          isZohoId: false
          companyId: $companyId
        ) {
          id
          name
          opportunityActivePolicies {
            id
            terminationLetterSigned
            terminationRequested
            hb2015LetterSigned
            hb2015LetterRequested
            activePolicy {
              id
              number
              termDate
              otherDetails
              insuranceType
              carrier
              activePolicyDocuments {
                id
                s3Key
                type
                fileName
                expiryDate
              }
            }
          }
          company {
            name
            id
          }
        }
      }
    `,
    {
      getOpportunityId: opportunityID,
      companyId,
    }
  );

  return res.getOpportunity;
};
