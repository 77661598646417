import { Close, Edit, KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { MappedEmployee } from "./types";
import { employeeTableColumnsForTablet } from "./helpers";
import { DependentTableForTabletRow } from "./DependentTableForTabletRow";

interface Props {
  isLast: boolean;
  isFirst: boolean;
  employee: MappedEmployee;
  onDeleteEmployee: (employee: MappedEmployee) => void;
  onEditEmployee: (employee: MappedEmployee) => void;
}

export const EmployeesTableForTabletRow = ({
  isLast,
  isFirst,
  onDeleteEmployee,
  onEditEmployee,
  employee,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(isFirst);

  const handleExpand = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  const handleEdit = useCallback(() => {
    onEditEmployee(employee);
  }, [onEditEmployee, employee]);

  const handleDelete = useCallback(() => {
    onDeleteEmployee(employee);
  }, [onDeleteEmployee, employee]);

  return (
    <Box
      sx={{
        borderBottom: isLast ? "" : "1px solid rgba(189, 189, 189, 1)",
        gap: (theme) => theme.spacing(3),
      }}
    >
      <Box
        display="flex"
        gap={2}
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Box
          display="flex"
          gap={1}
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="flex-start"
          onClick={handleExpand}
          role="button"
        >
          <Box
            sx={{
              transformOrigin: "center",
              transition: "transform 0.3s",
              transform: `rotate(${isExpanded ? "0deg" : "-90deg"})`,
            }}
          >
            <KeyboardArrowDownOutlined
              color={isExpanded ? "primary" : undefined}
            />
          </Box>

          <Typography variant="subtitle2" color="primary.main">
            {employee.firstName} {employee.lastName}
          </Typography>
        </Box>

        <Box>
          <Grid container wrap="nowrap" spacing={2} alignItems="center">
            <Grid item>
              <IconButton
                onClick={handleDelete}
                sx={{
                  border: "0.75px solid rgba(0, 0, 0, 0.6)",
                  borderRadius: (theme) => theme.spacing(0.25),
                  padding: (theme) => theme.spacing(0.25),
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleEdit}
                sx={{
                  border: "0.75px solid rgba(0, 0, 0, 0.6)",
                  borderRadius: (theme) => theme.spacing(0.25),
                  padding: (theme) => theme.spacing(0.25),
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        <Box mt={2}>
          <Grid
            container
            flexWrap="wrap"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={2}
            p={2}
            pl={4}
          >
            {employeeTableColumnsForTablet.map((label) => (
              <Grid key={label.key} item xs={4}>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {label.label}:
                </Typography>
                <Typography>{(employee as any)[label.key]}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
      {employee.dependents.map((dependent) => (
        <DependentTableForTabletRow key={dependent.id} dependent={dependent} />
      ))}
    </Box>
  );
};
