import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { CoverageTypeEnum, RelationshipEnum } from "gql/graphql";
import { notifications } from "services";
import { FormValues, FormValuesDependent } from "./types";
import { EmployeeFields } from "./EmployeeFields";
import { createDependent } from "./helpers";
import { SpouseFields } from "./SpouseFields";
import { ChildrenFields } from "./ChildrenFields";

interface Props {
  onClose: () => void;
  onSave: (values: FormValues) => Promise<void>;
  onCreateNew?: () => void;
  open: boolean;
  title: string;
}

export const EmployeeDetailsFormDialog = ({
  onClose,
  open,
  onSave,
  title,
  onCreateNew,
}: Props) => {
  const {
    handleSubmit,
    isValid,
    isSubmitting,
    setSubmitting,
    values,
    resetForm,
    setFieldValue,
  } = useFormikContext<FormValues>();

  const hasSpouse = useMemo(() => {
    return values.dependents.some(
      (dependent) => dependent.relationship === RelationshipEnum.Spouse
    );
  }, [values.dependents]);

  const handleSave = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleSaveAndCreateNew = useCallback(async () => {
    setSubmitting(true);
    await onSave(values);
    if (onCreateNew) {
      onCreateNew();
    }
    setSubmitting(false);
  }, [onCreateNew, onSave, setSubmitting, values]);

  const syncCoverageToDependents = useCallback(
    (dependents: FormValuesDependent[]) => {
      const coverage = values.coverage;

      const isIncludeSpouse = dependents.some(
        (dependent) => dependent.relationship === RelationshipEnum.Spouse
      );
      const isIncludeChild = dependents.some(
        (dependent) => dependent.relationship === RelationshipEnum.Child
      );
      const shouldBeFamily = isIncludeChild && isIncludeSpouse;
      const shouldBeEmployeeOnly = !isIncludeChild && !isIncludeSpouse;

      const isFamily = [
        CoverageTypeEnum.EmployeePlusFamily,
        CoverageTypeEnum.CobraPlusFamily,
      ].includes(coverage);
      const isSpouse = [
        CoverageTypeEnum.EmployeePlusSpouse,
        CoverageTypeEnum.CobraPlusSpouse,
      ].includes(coverage);
      const isChild = [
        CoverageTypeEnum.EmployeePlusChildren,
        CoverageTypeEnum.CobraPlusChildren,
      ].includes(coverage);
      const isEmployeeOnly = [
        CoverageTypeEnum.EmployeeOnly,
        CoverageTypeEnum.CobraOnly,
        CoverageTypeEnum.Cobra,
      ].includes(coverage);

      let newCoverage = coverage;

      if (shouldBeFamily && !isFamily) {
        newCoverage = CoverageTypeEnum.EmployeePlusFamily;
      } else if (isIncludeSpouse && !isSpouse) {
        newCoverage = CoverageTypeEnum.EmployeePlusSpouse;
      } else if (isIncludeChild && !isChild) {
        newCoverage = CoverageTypeEnum.EmployeePlusChildren;
      } else if (shouldBeEmployeeOnly && !isEmployeeOnly) {
        newCoverage = CoverageTypeEnum.EmployeeOnly;
      }

      if (newCoverage !== coverage) {
        setFieldValue("coverage", newCoverage);
        notifications.notifyInfo("Coverage Type Changed");
      }
    },
    [values.coverage, setFieldValue]
  );

  const handleAddSpouse = useCallback(() => {
    const dependents = values.dependents.concat(
      createDependent(RelationshipEnum.Spouse)
    );
    setFieldValue("dependents", dependents);
    syncCoverageToDependents(dependents);
  }, [setFieldValue, values.dependents, syncCoverageToDependents]);

  const handleAddChild = useCallback(() => {
    const dependents = values.dependents.concat(
      createDependent(RelationshipEnum.Child)
    );
    setFieldValue("dependents", dependents);
    syncCoverageToDependents(dependents);
  }, [setFieldValue, values.dependents, syncCoverageToDependents]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [resetForm, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle
        sx={{
          background: "rgba(246, 246, 246, 1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(3),
          }}
        >
          <Typography variant="h6">Employee Information</Typography>
          <Box
            mt={3}
            sx={{
              border: "1px solid rgba(130, 130, 130, 1)",
              boxShadow: "0px 0px 0px 1px rgba(224, 224, 224, 1)",
              padding: (theme) => theme.spacing(2),
              borderRadius: (theme) => theme.spacing(0.5),
            }}
          >
            <EmployeeFields />
            <Box mt={3}>
              <SpouseFields
                syncCoverageToDependents={syncCoverageToDependents}
              />
              <ChildrenFields
                syncCoverageToDependents={syncCoverageToDependents}
              />
            </Box>
            <Box mt={3}>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                {hasSpouse ? null : (
                  <Grid item>
                    <Button variant="contained" onClick={handleAddSpouse}>
                      ADD SPOUSE
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="contained" onClick={handleAddChild}>
                    ADD CHILD
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color={onCreateNew ? "success" : "primary"}
          disabled={!isValid || isSubmitting}
          type="submit"
        >
          SAVE AND CLOSE
        </Button>
        {onCreateNew ? (
          <Button
            onClick={() => {
              handleSaveAndCreateNew();
            }}
            variant="contained"
            disabled={!isValid || isSubmitting}
          >
            SAVE AND CREATE NEW
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
