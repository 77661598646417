import { FormLabel, Typography } from "@mui/material";
import React from "react";

interface Props {
  title?: string;
  subTitle?: string;
}

export const LepCalculatorLabel = ({ title, subTitle }: Props) => {
  if (!title && !subTitle) {
    return null;
  }
  return (
    <FormLabel
      sx={{
        color: "rgb(48, 54, 69)",
      }}
      component="label"
    >
      {title ? (
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "bold",
            display: "block",
          }}
          component="span"
        >
          {title}
        </Typography>
      ) : null}
      {subTitle ? (
        <Typography
          sx={{
            fontSize: 16,
            display: "block",
          }}
          component="span"
        >
          {subTitle}
        </Typography>
      ) : null}
    </FormLabel>
  );
};
