import { INTEGRATION_APP_URL } from "app-constants";
import { HostPortalHandlers } from "./types";

export const addIntegrationPortalActionListeners = (
  handlers: HostPortalHandlers[],
  appKey: string
) => {
  const listener = (event: any) => {
    try {
      if (event.origin === INTEGRATION_APP_URL) {
        const data = JSON.parse(event.data as string) as {
          message: string;
          data: Record<string, unknown>;
          appKey: string;
        };
        if (appKey === data.appKey) {
          handlers.forEach(({ message, handler }) => {
            if (data.message === message) {
              handler(data.data);
            }
          });
        }
      }
    } catch {
      //
    }
  };

  window.addEventListener("message", listener);

  return () => {
    window.removeEventListener("message", listener);
  };
};

export const integrationAppWindows: Record<string, Window | null> = {};

export const pendingDataToSend: Record<
  string,
  {
    message: string;
    data?: Record<string, unknown>;
  }[]
> = {};

export const sendDataToIntegrationApp = (
  message: string,
  appKey: string,
  data?: Record<string, unknown>
) => {
  const integrationAppWindow = integrationAppWindows[appKey];

  if (integrationAppWindow) {
    integrationAppWindow.postMessage(
      JSON.stringify({
        message: "UI_BAKERY_TRIGGER",
        appKey,
        data: {
          message,
          data,
        },
      }),
      INTEGRATION_APP_URL
    );
    return () => {};
  }
  pendingDataToSend[appKey] = (pendingDataToSend[appKey] || []).concat({
    message,
    data,
  });

  return () => {
    pendingDataToSend[appKey] = [];
  };
};

export const setIntegrationAppWindow = (
  appKey: string,
  integrationAppWindow: Window | null
) => {
  integrationAppWindows[appKey] = integrationAppWindow;
  if (integrationAppWindows[appKey]) {
    pendingDataToSend[appKey]?.forEach((d) => {
      sendDataToIntegrationApp(d.message, appKey, d.data);
    });
  }
  pendingDataToSend[appKey] = [];
};
