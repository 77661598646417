import React, { useState } from "react";
import {
  Button,
  ElementsGroup,
  FormikSelectField,
  FormikTextInput,
  Modal,
  Loader,
} from "Components/elements";
import { Box, Grid } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import {
  EmployeesTheCompanyWantsToOfferBenefitsToEnum,
  LocationStateEnum,
} from "gql/graphql";
import { camelCaseToSentence } from "utils/helpers";
import { pick } from "lodash";
import { HandleOpportunityDuplication } from "./HandleDuplication";
import { reqToCreateOpportunity } from "./request";

const stateOfIncOptions = Object.entries(
  LocationStateEnum as Record<any, string>
).map(([label, value]) => ({ label: camelCaseToSentence(label), value }));

const employeeCompanyWantsOption = Object.entries(
  EmployeesTheCompanyWantsToOfferBenefitsToEnum as Record<any, string>
).map(([label, value]) => ({ label: camelCaseToSentence(label), value }));

export const NewOpportunityDialogue = () => {
  const [show, setShow] = useState(false);
  const [possibleDuplication, setPossibleDuplication] = useState(true);

  const createOpportunity = useMutation({
    mutationFn: reqToCreateOpportunity,
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      companyDetails: {
        SICCode: "",
        companySize: "" as any,
        employeesTheCompanyWantsToOfferBenefitsTo: "" as any,
        facebookCompanyPage: "",
        industry: "",
        legalName: "",
        linkedInCompanyPage: "",
        numberOfEmployees: "" as any,
        otherInformation: "",
        stateOfIncorporation: "" as any,
      },
    },
    onSubmit: (values, { resetForm }) => {
      createOpportunity.mutate(
        {
          ...values,
          primaryContactDetails: {
            ...pick(values, ["email", "firstName", "lastName"]),
          },
          companyDetails: {
            ...values.companyDetails,
            name: values.companyName,
            numberOfEmployees: +values.companyDetails.numberOfEmployees,
            companySize: +values.companyDetails.companySize,
          },
        },
        {
          onSuccess: () => {
            resetForm();
            setShow(false);
          },
        }
      );
    },
  });

  const clearAndResetForm = () => {
    formik.resetForm();
    setShow(false);
  };

  return (
    <>
      <Button onClick={() => setShow(true)}>New Opportunity</Button>
      <Modal
        modalTitle="New Opportunity"
        open={show}
        onClose={() => setShow(false)}
        maxWidth={700}
      >
        <Loader absolute open={createOpportunity.isLoading} />
        {possibleDuplication ? (
          <HandleOpportunityDuplication
            onBackClick={() => setPossibleDuplication(false)}
          />
        ) : (
          <form action="" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <ElementsGroup label="Prospect Details" mb={4}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={6}>
                    <FormikTextInput name="firstName" required />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput name="lastName" required />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput name="email" type="email" required />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      name="companyName"
                      required
                      helperText={`Doing Business As `}
                    />
                  </Grid>
                </Grid>
              </ElementsGroup>
              <ElementsGroup label="Company Information" mb={4} bordered>
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={6}>
                    <FormikTextInput
                      name="companyDetails.legalName"
                      label="Company Legal Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikSelectField
                      name="companyDetails.stateOfIncorporation"
                      required
                      options={stateOfIncOptions}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      name="companyDetails.SICCode"
                      label="SIC Code"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput name="companyDetails.industry" required />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      numbersOnly
                      name="companyDetails.companySize"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      numbersOnly
                      name="companyDetails.numberOfEmployees"
                      label="Total Number Of Employees"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikSelectField
                      name="companyDetails.employeesTheCompanyWantsToOfferBenefitsTo"
                      required
                      options={employeeCompanyWantsOption}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mt: { xs: 0, md: 0 } }}
                >
                  <Grid item xs={6}>
                    <FormikTextInput
                      name="companyDetails.linkedInCompanyPage"
                      label="LinkedIn Page"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      name="companyDetails.facebookCompanyPage"
                      label="Facebook Page"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      name="companyDetails.otherInformation"
                      required
                      multiline
                      rows={3}
                      helperText="Other Information about the company and the Owner"
                    />
                  </Grid>
                </Grid>
              </ElementsGroup>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 1 }}>
                <Button onClick={clearAndResetForm}>cancel</Button>
                <Button type="submit" variant="contained">
                  create opportunity
                </Button>
              </Box>
            </FormikProvider>
          </form>
        )}
      </Modal>
    </>
  );
};
