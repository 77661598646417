import { Form, Formik, FormikConfig } from "formik";
import { Company, PayrollCycleEnum, YesNoEnum } from "gql/graphql";
import React, { PropsWithChildren, useCallback, useMemo } from "react";

import {
  fetchCompanyLogo,
  fetchS3SignedUrlForUpload,
  uploadFileToS3,
} from "services";
import * as yup from "yup";
import { QuoteRequestState } from "store";
import { CompanyFormContext } from "./CompanyFormContext";
import { FormValues } from "./types";

const companyToFormValues = (
  company: Partial<Company> | null | QuoteRequestState["company"]
): FormValues => {
  return {
    legalName: company?.legalName || "",
    stateOfIncorporation: company?.stateOfIncorporation || "",
    SICCode: company?.SICCode || "",
    industry: company?.industry || "",
    companySize: company?.companySize || ("" as unknown as number),
    numberOfEmployees: company?.numberOfEmployees || ("" as unknown as number),
    numberOf1099Contractors:
      company?.numberOf1099Contractors || ("" as unknown as number),
    numberOfFullTimeEmployees:
      company?.numberOfFullTimeEmployees || ("" as unknown as number),
    numberOfPartTimeEmployees:
      company?.numberOfPartTimeEmployees || ("" as unknown as number),
    isTheCompanyRequiredToHaveOSHATraining:
      company?.isTheCompanyRequiredToHaveOSHATraining || ("" as YesNoEnum),
    employeesTheCompanyWantsToOfferBenefitsTo:
      company?.employeesTheCompanyWantsToOfferBenefitsTo || [],
    payrollCycle: company?.payrollCycle || ("" as PayrollCycleEnum),
    waitingPeriod: company?.waitingPeriod || ("" as unknown as number),
    doesTheCompanyHaveASection125PlanInPlace:
      company?.doesTheCompanyHaveASection125PlanInPlace || ("" as YesNoEnum),
    isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch:
      company?.isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch ||
      ("" as YesNoEnum),
    linkedInCompanyPage: company?.linkedInCompanyPage || "",
    facebookCompanyPage: company?.facebookCompanyPage || "",
    website: company?.website || "",
    logoS3Key: company?.logoS3Key || "",
    physicalAddress: {
      city: company?.physicalAddress?.city || "",
      country: company?.physicalAddress?.country || "",
      pobox: company?.physicalAddress?.pobox || "",
      state: company?.physicalAddress?.state || "",
      street: company?.physicalAddress?.street || "",
    },
    mailingAddress: {
      city: company?.mailingAddress?.city || "",
      country: company?.mailingAddress?.country || "",
      pobox: company?.mailingAddress?.pobox || "",
      state: company?.mailingAddress?.state || "",
      street: company?.mailingAddress?.street || "",
      differentToPhysicalAddress:
        company?.mailingAddress?.differentToPhysicalAddress ||
        ("" as YesNoEnum),
    },
    differentToPhysicalAddress:
      company?.mailingAddress?.differentToPhysicalAddress === YesNoEnum.Yes,
  };
};

const validationSchema = yup.object().shape({
  legalName: yup
    .string()
    .typeError("Legal name is required")
    .required("Company legal name is required"),
  SICCode: yup
    .string()
    .typeError("SIC code is required")
    .required("SIC code is required"),
  stateOfIncorporation: yup
    .string()
    .typeError("State of incorporation is required")
    .required("State of incorporation is required"),
  industry: yup.string().typeError("Industry is required").nullable(),
  companySize: yup
    .number()
    .integer("Company size must be a whole number")
    .positive("Company size must be a positive number")
    .typeError("Company size must be a number")
    .required("Company size is required"),
  numberOfEmployees: yup
    .number()
    .integer("Number of employees must be a whole number")
    .min(0, "Number of employees must be a positive number")
    .typeError("Number of employees must be a number")
    .required("Number of employees is required"),
  numberOf1099Contractors: yup
    .number()
    .integer("Number of 1099 contractors must be a whole number")
    .min(0, "Number of 1099 contractors must be a positive number")
    .typeError("Number of 1099 contractors must be a number")
    .required("Number of 1099 contractors is required"),
  numberOfPartTimeEmployees: yup
    .number()
    .integer("Number of part-time must be a whole number")
    .min(0, "Number of part-time employees must be a positive number")
    .typeError("Number of part-time employees must be a number")
    .required("Number of part-time employees is required"),
  numberOfFullTimeEmployees: yup
    .number()
    .integer("Number of full-time must be a whole number")
    .min(0, "Number of full-time employees must be a positive number")
    .typeError("Number of full-time employees must be a number")
    .required("Number of full-time employees is required"),
  isTheCompanyRequiredToHaveOSHATraining: yup
    .string()
    .typeError("Is the company required to have OSHA training must be a string")
    .required("Is the company required to have OSHA training is required"),
  companyWantsToOfferBenefitsTo: yup
    .array()
    .of(yup.string().typeError("Employee type is required"))
    .nullable(),
  payrollCycle: yup.string().typeError("Payroll cycle is required").nullable(),
  waitingPeriod: yup
    .number()
    .positive("Waiting period must be a positive number")
    .typeError("Waiting period must be a number")
    .nullable(),
  doesTheCompanyHaveASection125PlanInPlace: yup
    .string()
    .typeError(
      "Does the company have a section 125 plan in place must be a string"
    )
    .nullable(),
  isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch: yup
    .string()
    .typeError(
      "Is your group a church or will the policy be maintained by a church must be a string"
    )
    .required(
      "Is your group a church or will the policy be maintained by a church is required"
    ),
  linkedInCompanyPage: yup
    .string()
    .nullable()
    .url("LinkedIn Page must be a valid url"),
  facebookCompanyPage: yup
    .string()
    .nullable()
    .url("Facebook Page must be a valid url"),
  website: yup.string().nullable().url("Website must be a valid url"),
  logoS3Key: yup.string().nullable(),
  physicalAddress: yup.object().shape({
    country: yup
      .string()
      .typeError("Country is required")
      .required("Country is required"),
    state: yup
      .string()
      .typeError("State is required")
      .required("State is required"),
    city: yup
      .string()
      .typeError("City is required")
      .required("City is required"),
    street: yup
      .string()
      .typeError("Street is required")
      .required("Street is required"),
    pobox: yup
      .string()
      .typeError("Zip code should be a string")
      .required("Zip code is required"),
  }),
  mailingAddress: yup.object().when("differentToPhysicalAddress", {
    is: true,
    then: yup.object().shape({
      country: yup
        .string()
        .typeError("Country is required")
        .required("Country is required"),
      state: yup
        .string()
        .typeError("State is required")
        .required("State is required"),
      city: yup
        .string()
        .typeError("City is required")
        .required("City is required"),
      street: yup
        .string()
        .typeError("Street is required")
        .required("Street is required"),
      pobox: yup
        .string()
        .typeError("Zip code should be a string")
        .required("Zip code is required"),
    }),
    otherwise: yup.object().shape({
      country: yup.string().typeError("Country is required").nullable(),
      state: yup.string().typeError("State is required").nullable(),
      city: yup.string().typeError("City is required").nullable(),
      street: yup.string().typeError("Street is required").nullable(),
      pobox: yup.string().typeError("Zip code should be a string").nullable(),
    }),
  }),
});

interface CompanyFormProps {
  onCancel: () => void;
  onSubmit: (params: Partial<Company>) => Promise<void>;
  company: Partial<Company> | null | QuoteRequestState["company"];
  onSaveBase64Image?: (file: string) => void;
  companyImageBase64?: string;
}

export const CompanyFormProvider = ({
  onCancel,
  onSubmit,
  company,
  onSaveBase64Image,
  companyImageBase64,
  children,
}: CompanyFormProps & PropsWithChildren) => {
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      await onSubmit(values);
      setSubmitting(false);
    },
    [onSubmit]
  );

  const initialValues: FormValues = useMemo(() => {
    return companyToFormValues(company);
  }, [company]);

  const saveFileLocally = useCallback(
    async (file: File | null) => {
      if (!file) {
        if (onSaveBase64Image) onSaveBase64Image("");
        localStorage.removeItem("company-logo");
        return;
      }
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            if (!e.target) {
              throw new Error("No file to save");
            }
            const fileContent = e.target.result;
            if (!fileContent) {
              throw new Error("No file to save");
            }
            const base64Content = btoa(fileContent as unknown as string);
            try {
              if (onSaveBase64Image) onSaveBase64Image(base64Content);
              localStorage.setItem("company-logo", base64Content);
            } catch (error) {
              //
            }
            resolve(base64Content);
          } catch (error) {
            reject((error as Error).message);
          }
        };
        reader.readAsBinaryString(file);
      });
    },
    [onSaveBase64Image]
  );

  const handleUploadFile = useCallback(
    async (
      file: File,
      abortRequest: (instance: XMLHttpRequest) => void,
      trackProgress: (progressEvent: ProgressEvent<EventTarget>) => any
    ) => {
      const s3Data = await fetchS3SignedUrlForUpload(file.name, file.type);
      await uploadFileToS3(file, s3Data.url, abortRequest, trackProgress);
      saveFileLocally(file);
      return s3Data.key;
    },
    [saveFileLocally]
  );

  const handleDeleteFile = useCallback(() => {
    if (saveFileLocally) {
      saveFileLocally(null);
    }
  }, [saveFileLocally]);

  const getSavedFileLocally = useCallback(() => {
    if (companyImageBase64) {
      return `data:image/*;base64,${companyImageBase64}`;
    }
    return "";
  }, [companyImageBase64]);

  const getDownloadUrl = useCallback(async () => {
    const localFile = getSavedFileLocally();
    if (localFile) {
      return localFile;
    }
    if (!company?.id) {
      return;
    }
    const { url } = await fetchCompanyLogo(company?.id);
    return url;
  }, [company?.id, getSavedFileLocally]);

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <CompanyFormContext.Provider
            value={{
              getDownloadUrl,
              handleDeleteFile,
              handleUploadFile,
              handleCancel,
              initialValues,
              isValid,
              isSubmitting,
            }}
          >
            {children}
          </CompanyFormContext.Provider>
        </Form>
      )}
    </Formik>
  );
};
