import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { MappedEmployeeDependent } from "./types";
import { employeeTableColumns } from "./helpers";
import { DependentTableCell } from "./DependentTableCell";

interface Props {
  dependent: MappedEmployeeDependent;
}

export const DependentTableRow = ({ dependent }: Props) => {
  return (
    <TableRow
      sx={{
        background: "rgba(249, 249, 250, 1)",
      }}
    >
      {employeeTableColumns.map((label) => (
        <TableCell key={label.key}>
          <DependentTableCell dependent={dependent} label={label} />
        </TableCell>
      ))}
    </TableRow>
  );
};
