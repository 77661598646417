import React, { ReactNode, useCallback } from "react";
import { SharedProps, ProviderContext } from "notistack";
import { IconButton, Box } from "@mui/material";
import {
  CheckCircleOutline,
  ReportProblemOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  Close,
} from "@mui/icons-material";

const getVariantData = (variant: SharedProps["variant"]) => {
  if (variant === "success") {
    return {
      color: "#4FB056",
      textColor: "white",
      Icon: CheckCircleOutline,
    };
  }
  if (variant === "warning") {
    return {
      color: "#FF9800",
      textColor: "white",
      Icon: ReportProblemOutlined,
    };
  }
  if (variant === "error") {
    return {
      color: "#F44336",
      textColor: "white",
      Icon: ErrorOutlineOutlined,
    };
  }
  return {
    color: "#263E7F",
    textColor: "white",
    Icon: InfoOutlined,
  };
};

interface Props {
  variant: SharedProps["variant"];
  message: ReactNode;
  messageKey: number | string;
  notiRef: ProviderContext | null;
}

const NotificationDisplay = React.forwardRef<any, Props>(
  ({ variant, message, notiRef, messageKey }, ref) => {
    const { color, textColor, Icon } = getVariantData(variant);

    const onCloseClick = useCallback(() => {
      notiRef?.closeSnackbar(messageKey);
    }, [messageKey, notiRef]);

    return (
      <Box
        ref={ref}
        sx={({ breakpoints: b, spacing: s }) => ({
          background: color,
          padding: s(0, 1),
          borderLeftWidth: 10,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderRightWidth: 1,
          borderColor: color,
          borderStyle: "solid",
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          color: textColor,

          [b.down("sm")]: {
            marginLeft: s(1),
            maxWidth: "300px",
          },
        })}
      >
        <Icon
          sx={{
            marginRight: ({ spacing: s }) => s(1),
            color: textColor,
          }}
        />
        <Box
          sx={{
            flex: 1,
            wordWrap: "break-word",
            overflow: "hidden",
          }}
        >
          {message}
        </Box>
        <Box
          sx={{
            marginLeft: "auto",
          }}
        >
          <IconButton
            sx={({ spacing: s }) => ({
              marginRight: s(-1),
              marginLeft: s(1),
              color: textColor,
            })}
            onClick={onCloseClick}
            aria-label="close notification"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  }
);

export default NotificationDisplay;
