import { NetworkTypeEnum, PolicyContributionTypeEnum } from "gql/graphql";

export const policyContributionTypeTitle = {
  [PolicyContributionTypeEnum.Contributory]: "Contributory",
  [PolicyContributionTypeEnum.DontKnow]: "Don't Know",
  [PolicyContributionTypeEnum.EmployeeSponsored]: "Employee Sponsored",
  [PolicyContributionTypeEnum.Voluntary]: "Voluntary",
};

export const networkTypeTitle = {
  [NetworkTypeEnum.EyeMed]: "Eye Med",
  [NetworkTypeEnum.Hmo]: "HMO",
  [NetworkTypeEnum.Mac]: "MAC",
  [NetworkTypeEnum.Pos]: "POS",
  [NetworkTypeEnum.Ppo]: "PPO",
  [NetworkTypeEnum.Vsp]: "VSP",
  [NetworkTypeEnum.HsaQualified]: "Has Qualified",
  [NetworkTypeEnum.NoPreferenceDontKnow]: "No Preference / Don't Know",
};

export const networkTypeOptions: Record<string, NetworkTypeEnum[]> = {
  MEDICAL: [
    NetworkTypeEnum.Hmo,
    NetworkTypeEnum.Ppo,
    NetworkTypeEnum.HsaQualified,
    NetworkTypeEnum.NoPreferenceDontKnow,
  ],
  DENTAL: [
    NetworkTypeEnum.Hmo,
    NetworkTypeEnum.Ppo,
    NetworkTypeEnum.Mac,
    NetworkTypeEnum.NoPreferenceDontKnow,
  ],
};

export const policyContributionTypeOptions: Record<
  string,
  PolicyContributionTypeEnum[]
> = {
  DENTAL: [
    PolicyContributionTypeEnum.Contributory,
    PolicyContributionTypeEnum.Voluntary,
    PolicyContributionTypeEnum.DontKnow,
  ],
  OTHERS: [
    PolicyContributionTypeEnum.EmployeeSponsored,
    PolicyContributionTypeEnum.Voluntary,
    PolicyContributionTypeEnum.DontKnow,
  ],
};
