import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const fontSize = 18;

export const LepCalculatorPartDResult3 = () => {
  return (
    <Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <ArrowForward fontSize="small" /> This is the amount you would have to
          pay{" "}
          <b>
            every month as a penalty on top of your monthly Medicare D premium
          </b>{" "}
          in 2024. This amount changes slightly every year after your
          enrollment.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          The penalty <b>never expires</b> and you have to pay it as long as you
          are enrolled.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <b>
            The longer you spend without coverage, the higher the penalty goes!
          </b>
        </Typography>
      </Box>

      <Typography
        sx={{
          fontSize,
        }}
      >
        <u>
          <b>
            <i>Learn how to:</i>
          </b>
        </u>
      </Typography>
      <Typography
        sx={{
          fontSize,
          marginBottom: ({ spacing }) => spacing(2),
        }}
        component="ul"
      >
        <li>
          <i>Minimize the penalty</i>
        </li>
        <li>
          <i>Enroll in Medicare</i>
        </li>
        <li>
          <i>Maximize your benefits</i>
        </li>
      </Typography>
      <Typography
        sx={{
          fontSize,
        }}
      >
        in our{" "}
        <b>
          Free eBook: &quot;Medicare: The Guide to Protecting your Future&quot;
        </b>
      </Typography>
    </Box>
  );
};
