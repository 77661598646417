import moment, { Moment } from "moment";
import {
  LepCalculatorPartBFormValues,
  LepCalculatorPartDFormValues,
} from "interfaces";

const premiums = {
  no: {
    "$103,000 or less": "174.70",
    "$103,001 up to $129,000": "244.60",
    "$129,001 up to $161,000": "349.40",
    "$161,001 up to $193,000": "454.20",
    "$193,001 up to $500,000": "559.00",
    "$500,000 or above": "594.00",
  },
  yes: {
    "$206,000 or less": "174.70",
    "$206,001 up to $258,000": "244.60",
    "$258,001 up to $322,000": "349.40",
    "$322,001 up to $386,000": "454.20",
    "$386,001 up to $750,000": "559.00",
    "$750,000 or above": "594.00",
  },
};

const getPartBPremium = (
  maritalStatus: "yes" | "no" | null,
  taxableIncome: string
) => {
  if (maritalStatus === "yes") {
    return premiums.yes[taxableIncome as keyof typeof premiums.yes];
  }
  return premiums.no[taxableIncome as keyof typeof premiums.no];
};

export const calculatePartBPenalty = (
  formValues: LepCalculatorPartBFormValues,
  uuid: string
) => {
  const values = {
    email: formValues.email,
    ssi: formValues.receiveSupplementalSecurityIncome,
    enrolledinMedicaid: formValues.enrolledInMedicaid,
    enrollmentDate: moment(formValues.partBEnrollmentDate).format("YYYY-MM-DD"),
    renalDisease: formValues.haveEndStageRenalDisease,
    medicaid: formValues.enrolledInMedicaid,
    uuid,
    taxableIncome: formValues.income,
    birthday: moment(formValues.dateOfBirth).format("YYYY-MM-DD"),
    maritalStatus: formValues.isMarried,
  };

  const calculatedPenaltyNumber = 0;
  const calculatedPenalty = calculatedPenaltyNumber.toFixed(1);
  let yearsWithoutCoverage = 0;
  const today = moment();
  const dob = moment(values.birthday);
  const ageInMonths = today.diff(dob, "months");
  const enrollmentDate = moment(values.enrollmentDate);
  let enrollmentStart: Moment | null = null;
  let enrollmentEnd: Moment | null = null;
  const yearTurned65 = dob.add(65, "years");
  enrollmentStart = moment(yearTurned65).subtract(3, "month");
  enrollmentEnd = moment(yearTurned65).add(3, "month");
  if (
    values.medicaid === "yes" ||
    values.ssi === "yes" ||
    values.renalDisease === "yes"
  ) {
    return {
      calculatedPenalty,
      ageinMonths: ageInMonths,
      taxableIncome: values.maritalStatus === "no" ? values.taxableIncome : "",
      householdTaxableIncome:
        values.maritalStatus === "yes" ? values.taxableIncome : "",
      IEPFromDate: moment(enrollmentStart).format("YYYY-MM-DD"),
      IEPToDate: moment(enrollmentEnd).format("YYYY-MM-DD"),
      premium: "",
      yearsWithoutCoverage,
      resultVariant: 1,
      ledScore: "$0",
    };
  }
  if (ageInMonths < 776 && enrollmentEnd.diff(enrollmentDate, "days") > 0) {
    return {
      calculatedPenalty,
      ageinMonths: ageInMonths,
      taxableIncome: values.maritalStatus === "no" ? values.taxableIncome : "",
      householdTaxableIncome:
        values.maritalStatus === "yes" ? values.taxableIncome : "",
      IEPFromDate: moment(enrollmentStart).format("YYYY-MM-DD"),
      IEPToDate: moment(enrollmentEnd).format("YYYY-MM-DD"),
      premium: "",
      yearsWithoutCoverage,
      resultVariant: 2,
      enrollmentStartDateString:
        moment(enrollmentStart).format("MMMM DD, YYYY"),
    };
  }
  if (
    ageInMonths > 783 &&
    enrollmentDate.diff(enrollmentStart, "days") > 0 &&
    enrollmentEnd.diff(enrollmentDate, "days") > 0
  ) {
    return {
      calculatedPenalty,
      ageinMonths: ageInMonths,
      taxableIncome: values.maritalStatus === "no" ? values.taxableIncome : "",
      householdTaxableIncome:
        values.maritalStatus === "yes" ? values.taxableIncome : "",
      IEPFromDate: moment(enrollmentStart).format("YYYY-MM-DD"),
      IEPToDate: moment(enrollmentEnd).format("YYYY-MM-DD"),
      premium: "",
      yearsWithoutCoverage,
      resultVariant: 3,
    };
  }
  if (
    ageInMonths <= 783 &&
    ageInMonths >= 776 &&
    enrollmentDate.diff(enrollmentStart, "days") > 0 &&
    enrollmentEnd.diff(enrollmentDate, "days") > 0
  ) {
    return {
      calculatedPenalty,
      ageinMonths: ageInMonths,
      taxableIncome: values.maritalStatus === "no" ? values.taxableIncome : "",
      householdTaxableIncome:
        values.maritalStatus === "yes" ? values.taxableIncome : "",
      IEPFromDate: moment(enrollmentStart).format("YYYY-MM-DD"),
      IEPToDate: moment(enrollmentEnd).format("YYYY-MM-DD"),
      premium: "",
      yearsWithoutCoverage,
      resultVariant: 4,
      enrollmentEndDateString: moment(enrollmentEnd).format("MMMM DD, YYYY"),
    };
  }

  const premium = getPartBPremium(values.maritalStatus, values.taxableIncome);
  yearsWithoutCoverage = (() => {
    if (enrollmentEnd.diff(enrollmentDate, "days") > 0) {
      return 0;
    }
    let differenceInMonths = enrollmentDate.diff(enrollmentEnd, "months");
    const a = moment(enrollmentDate);
    const b = moment(enrollmentEnd).add(differenceInMonths, "months");
    const days = a.diff(b, "days");
    if (days > 0) {
      differenceInMonths += 1;
    }
    const yearDifferenceFallenInto = Math.ceil(differenceInMonths / 12);
    if (yearDifferenceFallenInto === 0) {
      return 0;
    }
    return yearDifferenceFallenInto - 1;
  })();
  if (yearsWithoutCoverage === 0) {
    return {
      calculatedPenalty,
      ageinMonths: ageInMonths,
      taxableIncome: values.maritalStatus === "no" ? values.taxableIncome : "",
      householdTaxableIncome:
        values.maritalStatus === "yes" ? values.taxableIncome : "",
      IEPFromDate: moment(enrollmentStart).format("YYYY-MM-DD"),
      IEPToDate: moment(enrollmentEnd).format("YYYY-MM-DD"),
      premium: "",
      yearsWithoutCoverage: yearsWithoutCoverage + 1,
      resultVariant: 4,
      enrollmentEndDateString: moment(enrollmentEnd).format("MMMM DD, YYYY"),
    };
  }
  const penalty = (yearsWithoutCoverage * 10 * Number(premium)) / 100;
  return {
    calculatedPenalty: Number(penalty.toFixed(1)).toFixed(2),
    ageinMonths: ageInMonths,
    taxableIncome: values.maritalStatus === "no" ? values.taxableIncome : "",
    householdTaxableIncome:
      values.maritalStatus === "yes" ? values.taxableIncome : "",
    IEPFromDate: moment(enrollmentStart).format("YYYY-MM-DD"),
    IEPToDate: moment(enrollmentEnd).format("YYYY-MM-DD"),
    premium,
    yearsWithoutCoverage: yearsWithoutCoverage + 1,
    resultVariant: 5,
  };
};

export const sendPostMessageToBufferWebsite = (
  payload: Record<string, any>
) => {
  if (window.parent) {
    window.parent.postMessage(
      JSON.stringify(payload),
      "https://bufferinsurance.com"
    );
  }
};

export const triggerClickyGoal = (
  goalNumber: string | number,
  email: string,
  form: "b" | "d"
) => {
  sendPostMessageToBufferWebsite({
    action: "clicky-goal",
    goalNumber,
    email,
    mouseFlowSessionID: window.mouseflow
      ? window.mouseflow.getSessionId()
      : null,
    form,
  });
};

declare global {
  interface Window {
    mouseflow?: {
      getSessionId: () => number | string;
    };
  }
}

export const calculatePartDPenalty = (
  formValues: LepCalculatorPartDFormValues
) => {
  const calculatedPenaltyNumber = 0;
  const calculatedPenalty = calculatedPenaltyNumber.toFixed(2);

  const values = {
    monthsWithoutCoverage: formValues.coverageNumberOfMonths!,
    email: formValues.email,
    ssi: formValues.receiveSupplementalSecurityIncome,
    enrolledinMedicaid: formValues.enrolledInMedicaid,
  };

  if (values.enrolledinMedicaid === "yes" || values.ssi === "yes") {
    return { calculatedPenalty, resultVariant: 1 };
  }

  if (Number(values.monthsWithoutCoverage) <= 2) {
    return { calculatedPenalty, resultVariant: 2 };
  }

  const baseNational = 34.7;

  const penalty = Number(
    (baseNational * 0.01 * values.monthsWithoutCoverage).toFixed(1)
  ).toFixed(2);

  const yearsCalculated = {
    2018: Number(
      (35.02 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
    2019: Number(
      (33.19 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
    2020: Number(
      (32.74 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
    2021: Number(
      (33.06 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
    2022: Number(
      (33.37 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
    2023: Number(
      (32.74 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
    2024: Number(
      (34.7 * 0.01 * values.monthsWithoutCoverage).toFixed(1)
    ).toFixed(2),
  };

  return { calculatedPenalty: penalty, resultVariant: 3, yearsCalculated };
};
