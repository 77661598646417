import React, { useCallback } from "react";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  TextField,
  StandardTextFieldProps,
} from "@mui/material";
import { matchSorter } from "match-sorter";

import theme from "utils/theme";
import { COUNTRIES, CountryType } from "./helpers";

const {
  codeMap: COUNTRY_CODE_MAP,
  codeList: COUNTRY_CODE_LIST,
  nameList: COUNTRY_NAME_LIST,
} = COUNTRIES.reduce(
  (
    data: {
      codeMap: { [code: string]: CountryType };
      codeList: string[];
      nameMap: { [name: string]: CountryType };
      nameList: string[];
    },
    country
  ) => {
    data.codeMap[country.code] = country;
    data.codeList.push(country.code);
    data.nameMap[country.label] = country;
    data.nameList.push(country.label);
    return data;
  },
  { codeMap: {}, codeList: [], nameMap: {}, nameList: [] }
);

export { COUNTRIES };
export type { CountryType };

export interface CountrySelectInputProps
  extends Omit<StandardTextFieldProps, "onChange"> {
  useCountryCode?: boolean;
  error?: boolean;
  name: string;
  value: string;
  onChange: (countryCode: string) => any;
  onBlur: (e: React.ChangeEvent<any>) => any;
}

export const CountrySelectInput: React.FC<CountrySelectInputProps> = ({
  useCountryCode = true,
  value,
  onChange,
  onBlur,
  name,
  error,
  label,
  variant,
  inputProps = {},
  disabled,
  ...props
}) => {
  const onInputChange = useCallback(
    (
      ___: any,
      nextValue: string | null,
      reason: AutocompleteChangeReason | AutocompleteInputChangeReason
    ) => {
      if (reason === "clear") {
        onChange("");
        return;
      }
      onChange(nextValue || "");
    },
    [onChange]
  );

  const filterOptions = useCallback(
    (options: string[]) => {
      return value
        ? matchSorter(options, value, {
            threshold: matchSorter.rankings.CONTAINS,
          })
        : options;
    },
    [value]
  );

  return (
    <Autocomplete
      freeSolo={!useCountryCode}
      filterOptions={useCountryCode ? undefined : filterOptions}
      options={useCountryCode ? COUNTRY_CODE_LIST : COUNTRY_NAME_LIST}
      onChange={useCountryCode ? onInputChange : undefined}
      onInputChange={useCountryCode ? undefined : onInputChange}
      onBlur={onBlur}
      autoHighlight
      forcePopupIcon
      sx={{
        option: {
          ...theme.typography.subtitle1,
          color: theme.palette.text.primary,
          "& span": {
            fontSize: theme.spacing(2),
            marginRight: theme.spacing(1),
          },
        },
      }}
      getOptionLabel={(option) =>
        useCountryCode ? COUNTRY_CODE_MAP[option]?.label ?? "" : option
      }
      value={
        useCountryCode ? (COUNTRY_CODE_MAP[value] && value) || null : value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={error}
          label={label || "Country"}
          variant={variant || "outlined"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
            ...inputProps,
          }}
          {...props}
        />
      )}
      disabled={disabled}
    />
  );
};
