import React, { useEffect, useMemo, useState } from "react";

interface Props {
  title: string;
  signingUrl?: string;
  onLoad: React.ReactEventHandler<HTMLIFrameElement>;
}

export const SigningIframe = ({ signingUrl, title, onLoad }: Props) => {
  const frameOrigin = useMemo(() => {
    const hostName = window.location.hostname;
    const hasHttp =
      hostName.includes("https://") || hostName.includes("http://");
    return `${hasHttp ? "" : "https://"}${hostName}`;
  }, []);

  const [src, setSrc] = useState("");

  useEffect(() => {
    setSrc((current) => {
      if (current || !signingUrl) {
        return current;
      }
      return `${signingUrl}?frameorigin=${frameOrigin}`;
    });
  }, [src, frameOrigin, signingUrl]);

  if (!src) {
    return null;
  }

  return (
    <iframe
      src={src}
      height="100%"
      width="100%"
      style={{
        outline: 0,
        border: 0,
      }}
      title={title}
      onLoad={onLoad}
    />
  );
};
