import React, { FC } from "react";
import { Field, FieldAttributes } from "formik";

export const FieldWithNotifyLabel: FC<
  FieldAttributes<
    | {
        label?: string;
        notify?: boolean;
      }
    | any
  >
> = ({ children, name, notify, label, ...props }) => {
  return (
    <Field {...props} name={name} label={label}>
      {children}
    </Field>
  );
};
