import React from "react";
import { Box, IconButton } from "@mui/material";
import bufferLogo from "media/images/buffer-logo.png";
import { Text } from "Components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "hooks";

interface Props {
  state?: boolean;
  onClickOpen?: () => void;
  onClickClose?: () => void;
  showToggle?: boolean;
  showInfo?: boolean;
}

export const DashboardHeader = ({
  state,
  onClickOpen,
  onClickClose,
  showToggle = true,
  showInfo = true,
}: Props) => {
  const { fullName } = useAuth();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      component="header"
      px={{ xs: 2, lg: 3 }}
      sx={{
        backgroundColor: "#FCFCFC",
        width: "100%",
        borderBottom: "1px solid #E0E0E0",
        zIndex: 1000,
        paddingTop: 1,
        paddingBottom: 1,
      }}
    >
      <Box>
        {showToggle && (
          <IconButton
            sx={{
              marginRight: 2,
              display: { xs: "inline-block", sm: "none" },
            }}
            onClick={state ? onClickClose : onClickOpen}
          >
            {state ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
        <img
          src={bufferLogo}
          alt="buffer insurance"
          style={{ maxWidth: 100, objectFit: "contain" }}
        />
      </Box>
      {showInfo && (
        <Box>
          <Text
            textTransform="capitalize"
            fontWeight={400}
            fontSize={{ xs: 12, md: 14 }}
            content={fullName}
          />
        </Box>
      )}
    </Box>
  );
};
