import { axiosRequest, graphqlRequest } from "api";
import {
  AddEmployeeToCensusInput,
  AddEmployeeToCensusResponse,
  Company,
  UpdateCompanyJobClassificationsFromPortalResponse,
  UpdateEmployeeToCensusInput,
  UpdateEmployeeToCensusResponse,
} from "gql/graphql";
import { gql } from "graphql-request";
import { ValidateActivationTokenResponse } from "Views/Requests/types";

export const getPortalUserCompany = async () => {
  const res = await graphqlRequest.request<{
    getPortalUserCompany: Partial<Company>;
  }>(gql`
    query {
      getPortalUserCompany {
        id
        zohoID
        name
        legalName
        SICCode
        stateOfIncorporation
        industry
        companySize
        numberOfEmployees
        numberOfFullTimeEmployees
        numberOfPartTimeEmployees
        website
        payrollCycle
        numberOf1099Contractors
        linkedInCompanyPage
        facebookCompanyPage
        employeesTheCompanyWantsToOfferBenefitsTo
        isTheCompanyRequiredToHaveOSHATraining
        waitingPeriod
        doesTheCompanyHaveASection125PlanInPlace
        isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch
        logoS3Key
        jobClassifications {
          id
          name
        }
        physicalAddress {
          country
          state
          city
          street
          pobox
        }
        mailingAddress {
          country
          state
          city
          street
          pobox
        }
      }
    }
  `);

  return res.getPortalUserCompany;
};

export const updateCompanyRequest = async (
  companyId: string,
  values: Partial<Company>
) => {
  const res = await graphqlRequest.request<{
    updateCompanyJobClassificationsFromPortal: UpdateCompanyJobClassificationsFromPortalResponse;
  }>(
    gql`
      mutation updateCompanyFromPortal(
        $companyId: ID!
        $input: UpdateCompanyFromPortalInput!
      ) {
        updateCompanyFromPortal(companyId: $companyId, input: $input) {
          company {
            id
            zohoID
            name
            legalName
            SICCode
            SICCodeDescription
            EIN
            stateOfIncorporation
            industry
            companySize
            numberOfEmployees
            numberOfFullTimeEmployees
            numberOfPartTimeEmployees
            website
            payrollCycle
            numberOf1099Contractors
            linkedInCompanyPage
            facebookCompanyPage
            otherInformation
            employeesTheCompanyWantsToOfferBenefitsTo
            portalStatus
            jobClassifications {
              id
              name
            }
            primaryContactPerson {
              id
              zohoID
              firstName
              lastName
              email
              mobile
              phone
              primaryContactForCompany {
                id
                zohoID
                name
                legalName
                SICCode
                SICCodeDescription
                EIN
                stateOfIncorporation
                industry
                companySize
                numberOfEmployees
                numberOfFullTimeEmployees
                numberOfPartTimeEmployees
                website
                payrollCycle
                numberOf1099Contractors
                linkedInCompanyPage
                facebookCompanyPage
                otherInformation
                employeesTheCompanyWantsToOfferBenefitsTo
                portalStatus
                isTheCompanyRequiredToHaveOSHATraining
                waitingPeriod
                doesTheCompanyHaveASection125PlanInPlace
                isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch
                logoS3Key
              }
              contactForCompany {
                id
                zohoID
                name
                legalName
                SICCode
                SICCodeDescription
                EIN
                stateOfIncorporation
                industry
                companySize
                numberOfEmployees
                numberOfFullTimeEmployees
                numberOfPartTimeEmployees
                website
                payrollCycle
                numberOf1099Contractors
                linkedInCompanyPage
                facebookCompanyPage
                otherInformation
                employeesTheCompanyWantsToOfferBenefitsTo
                portalStatus
                isTheCompanyRequiredToHaveOSHATraining
                waitingPeriod
                doesTheCompanyHaveASection125PlanInPlace
                isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch
                logoS3Key
              }
            }
            businessOwner {
              id
              zohoID
              firstName
              lastName
              email
              mobile
              phone
            }
            companySigningAuthority {
              id
              zohoID
              firstName
              lastName
              email
              mobile
              phone
            }
            isTheCompanyRequiredToHaveOSHATraining
            waitingPeriod
            doesTheCompanyHaveASection125PlanInPlace
            isYourGroupAChurchOrWillThePolicyBeMaintainedByAChurch
            logoS3Key
            activePolicies {
              id
              number
              insuranceType
              termDate
              summaryOfMonthlycost
              otherDetails
              activePolicyDocuments {
                id
                fileName
                expiryDate
                s3Key
                type
              }
            }
            contactPersons {
              id
              zohoID
              firstName
              lastName
              email
              mobile
              phone
            }
            physicalAddress {
              country
              state
              city
              street
              pobox
            }
            mailingAddress {
              country
              state
              city
              street
              pobox
            }
          }
        }
      }
    `,
    { companyId, input: values }
  );

  return res.updateCompanyJobClassificationsFromPortal.company;
};

export const updateJobClassificationsRequest = async (
  jobClassifications: string[],
  companyId: string
) => {
  const res = await graphqlRequest.request<{
    updateCompanyJobClassificationsFromPortal: UpdateCompanyJobClassificationsFromPortalResponse;
  }>(
    gql`
      mutation updateCompanyJobClassificationsFromPortal(
        $companyId: ID!
        $input: UpdateCompanyJobClassificationsFromPortalInput!
      ) {
        updateCompanyJobClassificationsFromPortal(
          companyId: $companyId
          input: $input
        ) {
          company {
            id
            jobClassifications {
              id
              name
            }
          }
        }
      }
    `,
    { companyId, input: { jobClassifications } }
  );

  return (
    res.updateCompanyJobClassificationsFromPortal.company.jobClassifications ||
    []
  );
};

export const getContactDetails = async () => {
  const res = await axiosRequest.post<ValidateActivationTokenResponse>(
    "/auth/validate-activation-token"
  );
  return res.data?.data?.contact;
};

export const signUp = async (password: string) => {
  const res = await axiosRequest.post("/auth/sign-up", { password });
  return res.data?.data;
};

export const deleteEmployeeRequest = async (
  companyId: string,
  employeeID: string
) => {
  const res = await graphqlRequest.request<{
    deleteEmployeeToCensus: any;
  }>(
    gql`
      mutation DeleteEmployeeToCensus(
        $companyId: ID!
        $isZohoId: Boolean!
        $input: DeleteEmployeeToCensusInput!
      ) {
        deleteEmployeeToCensus(
          companyId: $companyId
          isZohoId: $isZohoId
          input: $input
        ) {
          message
        }
      }
    `,
    { isZohoId: false, input: { id: employeeID }, companyId }
  );

  return res.deleteEmployeeToCensus;
};

export const updateEmployeeRequest = async (
  companyId: string,
  values: UpdateEmployeeToCensusInput
) => {
  const res = await graphqlRequest.request<{
    updateEmployeeToCensus: UpdateEmployeeToCensusResponse;
  }>(
    gql`
      mutation UpdateEmployeeToCensus(
        $companyId: ID!
        $isZohoId: Boolean!
        $input: UpdateEmployeeToCensusInput!
      ) {
        updateEmployeeToCensus(
          companyId: $companyId
          isZohoId: $isZohoId
          input: $input
        ) {
          employment {
            id
          }
        }
      }
    `,
    { isZohoId: false, input: values, companyId }
  );

  return res.updateEmployeeToCensus;
};

export const addEmployeeRequest = async (
  companyId: string,
  values: AddEmployeeToCensusInput
) => {
  const res = await graphqlRequest.request<{
    addEmployeeToCensus: AddEmployeeToCensusResponse;
  }>(
    gql`
      mutation AddEmployeeToCensus(
        $companyId: ID!
        $isZohoId: Boolean!
        $input: AddEmployeeToCensusInput!
      ) {
        addEmployeeToCensus(
          companyId: $companyId
          isZohoId: $isZohoId
          input: $input
        ) {
          employment {
            id
          }
        }
      }
    `,
    { isZohoId: false, input: values, companyId }
  );

  return res.addEmployeeToCensus;
};
