import {
  removeAxiosAuthorizationHeader,
  setAxiosAuthorizationHeader,
} from "./axios-request";
import {
  removeN8NAxiosAuthorizationHeader,
  setN8NAxiosAuthorizationHeader,
} from "./n8n-request";
import {
  removeGraphqlAuthorizationHeader,
  setGraphqlAuthorizationHeader,
} from "./graphql-request";

export * from "./graphql-request";
export * from "./n8n-request";
export * from "./axios-request";

export const setAuthorizationHeader = (token: string) => {
  setGraphqlAuthorizationHeader(token);
  setAxiosAuthorizationHeader(token);
  setN8NAxiosAuthorizationHeader(token);
};

export const removeAuthorizationHeader = () => {
  removeGraphqlAuthorizationHeader();
  removeAxiosAuthorizationHeader();
  removeN8NAxiosAuthorizationHeader();
};
