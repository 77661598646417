import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { employeeTableColumns } from "./helpers";
import { EmployeeTableCell } from "./EmployeeTableCell";
import { MappedEmployee } from "./types";
import { DependentTableRow } from "./DependentTableRow";

interface Props {
  employee: MappedEmployee;
  onDelete: (employee: MappedEmployee) => void;
  onEdit: (employee: MappedEmployee) => void;
}

export const EmployeeTableRow = ({ employee, onDelete, onEdit }: Props) => {
  return (
    <>
      <TableRow>
        {employeeTableColumns.map((label) => (
          <TableCell key={label.key}>
            <EmployeeTableCell
              employee={employee}
              label={label}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          </TableCell>
        ))}
      </TableRow>
      {employee.dependents.map((dependent) => (
        <DependentTableRow key={dependent.id} dependent={dependent} />
      ))}
    </>
  );
};
