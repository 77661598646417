import { graphqlRequest } from "api";
import { Opportunity } from "gql/graphql";
import { gql } from "graphql-request";

export const fetchOpportunitiesRequests = async () => {
  const res = await graphqlRequest.request<{
    getPortalUserCompany: {
      opportunities: Opportunity[];
    };
  }>(
    gql`
      query {
        getPortalUserCompany {
          opportunities {
            id
            name
            stage
            type
            updatedAt
            createdAt
            opportunityProducts {
              id
              policyContributionType
              networkType
              approximateEmployerContribution
              maximumMonthlyContributionByEmployee
              numberOfTeamMembersThatMightWantToEnroll
              plannedEffectiveDate
              otherSpecificCoverageRelatedRequests
              product {
                name
                formattedName
              }
              opportunityProductInterestedEmployees {
                id
                employee {
                  id
                }
                interestedEmployeeDependentIds
                opportunityProduct {
                  id
                }
                coverageType
              }
            }
            numberOfTeamMembersThatMightWantToEnroll
            requiresHB2015Signing
            otherPolicyRequirements {
              currentBrokerTasksThatClientWantsContinued
              whatWouldClientLikeBufferToStartDoing
              clientEmployeesCoveragePriorities
            }
            opportunityActivePolicies {
              id
              hb2015LetterSigned
              activePolicy {
                activePolicyDocuments {
                  id
                  fileName
                  s3Key
                }
                carrier
                insuranceType
                number
                otherDetails
                id
                summaryOfMonthlycost
                termDate
                relatedCarrier {
                  id
                }
              }
            }
          }
        }
      }
    `
  );

  return res.getPortalUserCompany.opportunities;
};
