import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { RelationshipEnum } from "gql/graphql";
import { useResponsive } from "hooks";
import { FormValuesDependent } from "./types";

interface Props {
  onClose: () => void;
  onRemove: () => void;
  dependent: FormValuesDependent;
  open: boolean;
}

export const DeleteDependentDialog = ({
  onClose,
  dependent,
  open,
  onRemove,
}: Props) => {
  const { isDesktop } = useResponsive();

  const align = useMemo(() => (isDesktop ? "center" : "left"), [isDesktop]);

  const handleRemove = useCallback(() => {
    onRemove();
  }, [onRemove]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{
          background: "rgba(246, 246, 246, 1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          Remove Dependent
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(3),
          }}
        >
          <Typography fontWeight={500} textAlign={align}>
            Are you sure you want to{" "}
            {dependent.firstName || dependent.lastName ? (
              <>
                {" "}
                <Typography
                  color="error.main"
                  component="span"
                  fontWeight={700}
                >
                  Remove {dependent.firstName || ""} {dependent.lastName || ""}
                </Typography>{" "}
                as a
              </>
            ) : (
              <>
                <Typography
                  color="error.main"
                  component="span"
                  fontWeight={700}
                >
                  Remove
                </Typography>{" "}
                this
              </>
            )}{" "}
            {dependent.relationship === RelationshipEnum.Spouse
              ? "Spouse"
              : "Child"}
            ?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button onClick={handleRemove} color="error" variant="contained">
          REMOVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
