import axios, { AxiosError } from "axios";
import {
  pkAuthActions,
  ENDPOINT_AUTH,
  ENDPOINT_AUTH_LOGOUT,
} from "@deep-consulting-solutions/auth-web-muiv5";

import { TOKEN_EXPIRED_BE_ERROR } from "app-constants";
import { store, authActions, resetStore } from "store";
import { notifications } from "services";

export const createClient = (options?: { baseUrl?: string }) => {
  const client = axios.create({
    baseURL: options?.baseUrl ?? process.env.REACT_APP_BASE_URL,
  });
  const silentAxiosClient = axios.create({
    baseURL: options?.baseUrl ?? process.env.REACT_APP_BASE_URL,
  });

  const setAuthorizationHeader = (token: string) => {
    client.defaults.headers.Authorization = `Bearer ${token}`;
    silentAxiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const getAuthorizationToken = (): string | undefined => {
    return (client.defaults.headers.Authorization as string | null)?.replace(
      "Bearer ",
      ""
    );
  };

  const removeAuthorizationHeader = () => {
    delete client.defaults.headers.Authorization;
    delete silentAxiosClient.defaults.headers.Authorization;
  };

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let message: string =
        (error?.response?.data as any)?.message ||
        error?.message ||
        "Unknown error";

      if (typeof message !== "string") {
        try {
          message = JSON.stringify(message);
        } catch {
          message = "Unknown error";
        }
      }

      if (!options) {
        const originalRequest = error.config;
        if (
          error.response?.status === 401 &&
          originalRequest?.url !== ENDPOINT_AUTH
        ) {
          if (message === TOKEN_EXPIRED_BE_ERROR) {
            notifications.notifyInfo(
              "Your session has expired. Please login again.",
              {
                preventDuplicate: true,
              }
            );

            store.dispatch(authActions.resetAuth());
          } else {
            notifications.notifyError(message, { preventDuplicate: true });
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          resetStore();
          store.dispatch(authActions.tokenExpired());
          return Promise.reject(error);
        }
      }

      notifications.notifyError(message, {
        persist: [408, 500, 501, 502, 503, 504].includes(
          error.response?.status as number
        ),
      });

      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  silentAxiosClient.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        originalRequest?.url !== ENDPOINT_AUTH_LOGOUT
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await store.dispatch(pkAuthActions.logout());
      }

      return Promise.reject(error);
    }
  );

  return {
    client,
    silentClient: silentAxiosClient,
    setAuthorizationHeader,
    removeAuthorizationHeader,
    getAuthorizationToken,
  };
};

export const {
  client: axiosRequest,
  silentClient: silentAxiosRequest,
  removeAuthorizationHeader: removeAxiosAuthorizationHeader,
  setAuthorizationHeader: setAxiosAuthorizationHeader,
  getAuthorizationToken: getAxiosAuthorizationToken,
} = createClient();
