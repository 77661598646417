import { GraphQLClient } from "graphql-request";
import { Response } from "graphql-request/src/types";

function createClient() {
  function requestMiddleware(request: RequestInit) {
    return {
      ...request,
      headers: { ...request.headers },
    };
  }

  function responseMiddleware(response: Response<unknown>) {
    if (response.errors) {
      // do error stuff
      // const traceId = response.headers.get("x-b3-traceid") || "unknown";
      // console.error(
      //   `[${traceId}] Request error:
      //   status ${response.status}
      //   details: ${response.errors}`
      // );
    }
  }

  const appRequest = new GraphQLClient(
    `${process.env.REACT_APP_BASE_URL}/graphql`,
    {
      timeout: 3000,
      requestMiddleware: requestMiddleware as any,
      responseMiddleware: responseMiddleware as any,
    }
  );

  const setAuthorizationHeader = (token: string) => {
    appRequest.setHeader("authorization", `Bearer ${token}`);
  };

  const removeAuthorizationHeader = () => {
    appRequest.setHeader("authorization", "");
  };

  return {
    appRequest,
    setAuthorizationHeader,
    removeAuthorizationHeader,
  };
}

export const {
  appRequest: graphqlRequest,
  removeAuthorizationHeader: removeGraphqlAuthorizationHeader,
  setAuthorizationHeader: setGraphqlAuthorizationHeader,
} = createClient();
