import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  RequestOptions,
} from "use-places-autocomplete";
import {
  TextField,
  AutocompleteChangeReason,
  Autocomplete,
} from "@mui/material";
import { FormikProps } from "formik";
import { AutocompleteRenderInputParams } from "formik-mui";
import { useScript } from "hooks";
import { LabelNotify } from "./LabelNotify";
import { getValueFromObject } from "./helpers";

export function getShortAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

export function getLongAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.long_name;
    if (element.types.includes("sublocality")) {
      address.sublocality = element.long_name;
    }
  });
  return address;
}

function statusMessage(status: string) {
  switch (status) {
    case "ZERO_RESULTS":
      return "No Results Found";
    case "UNKNOWN_ERROR":
      return "Netwrok or Server Error";
    case "ERROR":
      return "Network Error";
    default:
      return "Server Error";
  }
}

export interface FormikStreeFieldProps<FormValues extends Record<string, any>> {
  formikProps: FormikProps<FormValues>;
  name?: string;
  cityName?: string;
  islandName?: string;
  countryName?: string;
  poBoxName?: string;
  label?: string;
  disabled?: boolean;
  onSelect?: (result: google.maps.GeocoderResult | undefined) => void;
  requestOptions?: Omit<RequestOptions, "location">;
  country?: string | string[];
  coordinations?: { lat: number; lng: number };
  use2Stars?: boolean;
  size?: "small";
}

(window as any).dummyPlacesLoaded = () => {};

export const FormikStreetField = <FormValues extends Record<string, any>>({
  name = "street",
  cityName = "city",
  islandName = "island",
  countryName = "country",
  poBoxName = "poBoxOrZipOrPostalCode",
  label = "Street",
  disabled = false,
  formikProps: {
    setFieldTouched,
    setFieldValue,
    values,
    isSubmitting,
    errors,
    touched,
  },
  onSelect,
  requestOptions,
  country,
  coordinations,
  use2Stars,
  notify,
  size = "small",
}: { notify?: boolean } & FormikStreeFieldProps<FormValues>) => {
  const googleStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=dummyPlacesLoaded`
  );
  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined
  );

  const componentRestrictions = country
    ? {
        country,
      }
    : undefined;

  const {
    ready,
    init,
    suggestions: { data, loading: placesLoading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      location,
      componentRestrictions,
      ...requestOptions,
    },
    callbackName: "dummyPlacesLoaded2", // different on purpose, so that it wont be called automatically
    cache: false,
  });

  useEffect(() => {
    if (googleStatus === "ready") {
      init();
    }
  }, [init, googleStatus]);

  useEffect(() => {
    if (ready && coordinations) {
      setLocation(new google.maps.LatLng(coordinations.lat, coordinations.lng));
    }
  }, [coordinations, ready]);

  const [loadingAddress, setLoadingAddress] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setError] = useState("");

  useEffect(() => {
    if (googleStatus === "error") {
      setError("Failed to load Google Maps Places library.");
    } else {
      setError("");
    }
  }, [googleStatus]);

  const getDataFromObj = useCallback(
    (obj: any) => {
      return getValueFromObject(name.split("."), obj);
    },
    [name]
  );

  const fieldError = useMemo(
    () => getDataFromObj(errors) as string | undefined,
    [errors, getDataFromObj]
  );

  const showFieldError = useMemo(
    () => getDataFromObj(touched) as boolean | undefined,
    [touched, getDataFromObj]
  );

  const fieldValue = useMemo(() => {
    return getDataFromObj(values) as string | undefined;
  }, [values, getDataFromObj]);

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
        setError(
          statusMessage(typeof err === "string" ? err : (err as Error).message)
        );
      }
      return undefined;
    },
    [setValue]
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setError("");
      setValue(value);
      setFieldValue(name, value);
    },
    [name, setFieldValue, setValue]
  );

  const onInputBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  const onAutocompleteChangeAsync = useCallback(
    async (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === "selectOption") {
        const result = await handleSelect(
          (value as google.maps.places.AutocompletePrediction).description
        );

        if (onSelect) {
          onSelect(result);
          return;
        }

        if (result) {
          const longAddress = getLongAddressObject(result);
          const shortAddress = getShortAddressObject(result);

          const street = `${longAddress.street_number || ""}${
            longAddress.route
              ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
              : ""
          }`;
          setFieldValue(name, street);
          setValue(street, false);

          setFieldValue(
            cityName,
            longAddress.locality ||
              longAddress.administrative_area_level_3 ||
              longAddress.postal_town ||
              ""
          );

          const islandValue =
            shortAddress.country === "GB"
              ? longAddress.administrative_area_level_2 ||
                longAddress.administrative_area_level_1
              : longAddress.administrative_area_level_1 ||
                longAddress.administrative_area_level_2;

          setFieldValue(islandName, islandValue || "");

          if (shortAddress.country !== "BS") {
            setFieldValue(poBoxName, longAddress.postal_code || "");
          }

          setFieldValue(countryName, shortAddress.country || "");
        }
      } else if (reason === "clear") {
        setFieldValue(name, "");
      }
    },
    [
      handleSelect,
      onSelect,
      setFieldValue,
      name,
      setValue,
      cityName,
      islandName,
      countryName,
      poBoxName,
    ]
  );

  const onAutocompleteChange = useCallback(
    (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      onAutocompleteChangeAsync(event, value, reason);
    },
    [onAutocompleteChangeAsync]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = ((value || "") as unknown as string).split(
        " "
      );
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );
  return (
    <Autocomplete<google.maps.places.AutocompletePrediction, false, false, true>
      freeSolo
      noOptionsText="No Results Found"
      value={fieldValue}
      isOptionEqualToValue={getOptionSelected}
      onChange={onAutocompleteChange}
      inputValue={fieldValue}
      onInputChange={onInputChange}
      loading={placesLoading}
      disabled={disabled || loadingAddress || isSubmitting}
      options={data}
      getOptionLabel={(
        option: google.maps.places.AutocompletePrediction | string
      ) => (typeof option === "string" ? option : option.description)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          autoComplete="off"
          onBlur={onInputBlur}
          error={showFieldError}
          helperText={showFieldError && fieldError}
          size={size}
          label={
            <LabelNotify
              show={notify && fieldError}
              label={`${label} ${use2Stars ? "**" : "*"}`}
            />
          }
        />
      )}
    />
  );
};
