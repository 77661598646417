import { Box } from "@mui/material";
import { ForgotPassword as ForgotPasswordComponent } from "@deep-consulting-solutions/auth-web-muiv5";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "app-constants";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const onToLoginClick = useCallback(() => {
    navigate(ROUTES.login.fullPath);
  }, [navigate]);

  return (
    <Box
      sx={{
        maxWidth: 400,
        width: "100%",
        borderRadius: 12,
        overflow: "hidden",
      }}
    >
      <ForgotPasswordComponent onToLoginClick={onToLoginClick} />
    </Box>
  );
};
