import { useCallback, useEffect, useMemo, useState } from "react";
import { SavePolicyRequirementsAction } from "store/reducers/quote-request/types";
import {
  useAppSelector,
  companySelectors,
  quoteRequestSelectors,
  useAppDispatch,
  quoteRequestActions,
} from "store";
import * as yup from "yup";
import { Box, Grid, Theme, Typography } from "@mui/material";
import { Field, Form, Formik, FormikConfig } from "formik";
import { Button, Loader } from "Components";
import { CheckboxWithLabel, TextField } from "formik-mui";
import { useResponsive } from "hooks";
import { PolicyRequirement } from "./PolicyRequirement";

const ALL_PRODUCTS_ARE_SAME = "ALL_PRODUCTS_ARE_SAME";

const getConditionForValidation = (
  val: string,
  policyRequirementsAreTheSame: boolean
) =>
  (policyRequirementsAreTheSame && val === ALL_PRODUCTS_ARE_SAME) ||
  (!policyRequirementsAreTheSame && val !== ALL_PRODUCTS_ARE_SAME);

const productsValidation = (policyRequirementsAreTheSame: boolean) => {
  return {
    plannedEffectiveDate: yup.date().when("id", {
      is: (val: string) =>
        getConditionForValidation(val, policyRequirementsAreTheSame),
      then: yup
        .date()
        .typeError("Planned effective date is invalid")
        .required("Planned effective date is required"),
      otherwise: yup.date().nullable(),
    }),
    numberOfTeamMembersThatMightWantToEnroll: yup.number().when("id", {
      is: (val: string) =>
        getConditionForValidation(val, policyRequirementsAreTheSame),
      then: yup
        .number()
        .integer(
          "Number of team members that might want to enroll must be a whole number"
        )
        .positive(
          "Number of team members that might want to enroll must be greater than 0"
        )
        .typeError(
          "Number of team members that might want to enroll must be a number"
        )
        .required(
          "Number of team members that might want to enroll is required"
        ),
      otherwise: yup.number().nullable(),
    }),
    approximateEmployerContribution: yup.string().nullable(),
    maximumMonthlyContributionByEmployee: yup.string().nullable(),
    otherSpecificCoverageRelatedRequests: yup.string().nullable(),
  };
};

const validationSchema = yup.object().shape({
  products: yup.array().when("policyRequirementsAreTheSame", {
    is: true,
    then: yup.array().of(yup.object().shape(productsValidation(true))),
    otherwise: yup.array().of(yup.object().shape(productsValidation(false))),
  }),
  otherDetails: yup.string().nullable(),
});

interface PolicyRequirementsFormProps {
  onGoToEmployeeForm: (lines: SavePolicyRequirementsAction) => void;
  onSaveAsDraft: (lines: SavePolicyRequirementsAction) => Promise<void>;
}

export const PolicyRequirementsForm = ({
  onGoToEmployeeForm,
  onSaveAsDraft,
}: PolicyRequirementsFormProps) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useResponsive();
  const company = useAppSelector(companySelectors.getCompany);
  const products = useAppSelector(quoteRequestSelectors.getProducts);
  const otherDetails = useAppSelector(quoteRequestSelectors.getOtherDetails);
  const policyRequirements = useAppSelector(
    quoteRequestSelectors.getPolicyRequirements
  );
  const policyRequirementsAreTheSame = useAppSelector(
    quoteRequestSelectors.getPolicyRequirementsAreTheSame
  );
  const productsOffers = useAppSelector(
    quoteRequestSelectors.getProductsOffers
  );

  const [loadingCount, setLoadingCount] = useState(0);

  const loading = useMemo(() => loadingCount > 0, [loadingCount]);

  const initialValues: SavePolicyRequirementsAction = useMemo(() => {
    const policyRequirementsAllValues =
      policyRequirementsAreTheSame && policyRequirements
        ? {
            id: ALL_PRODUCTS_ARE_SAME,
            approximateEmployerContribution:
              policyRequirements[0]?.approximateEmployerContribution || "",
            maximumMonthlyContributionByEmployee:
              policyRequirements[0]?.maximumMonthlyContributionByEmployee || "",
            numberOfTeamMembersThatMightWantToEnroll:
              policyRequirements[0]?.numberOfTeamMembersThatMightWantToEnroll ||
              ("" as unknown as number),
            otherDetails: policyRequirements[0]?.otherDetails || "",
            otherSpecificCoverageRelatedRequests:
              policyRequirements[0]?.otherSpecificCoverageRelatedRequests || "",
            plannedEffectiveDate:
              policyRequirements[0]?.plannedEffectiveDate || "",
          }
        : {
            id: ALL_PRODUCTS_ARE_SAME,
            approximateEmployerContribution: "",
            maximumMonthlyContributionByEmployee: "",
            numberOfTeamMembersThatMightWantToEnroll: "" as unknown as number,
            otherDetails: "",
            otherSpecificCoverageRelatedRequests: "",
            plannedEffectiveDate: "",
          };
    return {
      policyRequirementsAreTheSame: !!policyRequirementsAreTheSame,
      products: [
        policyRequirementsAllValues,
        ...(products?.map((product) => {
          const policyRequirement = policyRequirements?.find((p) => {
            return p.id === product.id;
          });
          return {
            id: product.id,
            approximateEmployerContribution:
              policyRequirement?.approximateEmployerContribution || "",
            maximumMonthlyContributionByEmployee:
              policyRequirement?.maximumMonthlyContributionByEmployee || "",
            numberOfTeamMembersThatMightWantToEnroll:
              policyRequirement?.numberOfTeamMembersThatMightWantToEnroll ||
              ("" as unknown as number),
            otherDetails: policyRequirement?.otherDetails || "",
            otherSpecificCoverageRelatedRequests:
              policyRequirement?.otherSpecificCoverageRelatedRequests || "",
            plannedEffectiveDate: policyRequirement?.plannedEffectiveDate || "",
          };
        }) || []),
      ],
      otherDetails: otherDetails?.clientEmployeesCoveragePriorities || "",
    };
  }, [
    policyRequirementsAreTheSame,
    policyRequirements,
    otherDetails,
    products,
  ]);

  const setLoading = useCallback((value: boolean) => {
    setLoadingCount((current) => current + (value === true ? 1 : -1));
  }, []);

  const mapValues = useCallback((values: SavePolicyRequirementsAction) => {
    const res: SavePolicyRequirementsAction = {
      ...values,
      products:
        values.products?.filter((v) => v.id !== ALL_PRODUCTS_ARE_SAME) || [],
    };
    if (values.policyRequirementsAreTheSame) {
      const allSameProduct = values.products!.find(
        (v) => v.id === ALL_PRODUCTS_ARE_SAME
      )!;

      return {
        ...res,
        products:
          res.products?.map(({ id }) => ({ ...allSameProduct, id })) || [],
      };
    }
    return res;
  }, []);

  const onSubmit: FormikConfig<SavePolicyRequirementsAction>["onSubmit"] =
    useCallback(
      (values) => {
        onGoToEmployeeForm(mapValues(values));
      },
      [onGoToEmployeeForm, mapValues]
    );

  const handleSaveAsDraft = useCallback(
    (values: SavePolicyRequirementsAction) => {
      onSaveAsDraft(mapValues(values));
    },
    [onSaveAsDraft, mapValues]
  );

  const fetchProductsOffers = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(quoteRequestActions.fetchProductsOffers());
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading]);

  useEffect(() => {
    if (!productsOffers.length) {
      fetchProductsOffers();
    }
  }, [productsOffers.length, fetchProductsOffers]);

  if (!company) return null;

  return (
    <Box>
      <Loader open={loading} />
      <Typography
        variant="h5"
        sx={{
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        Policy Requirements
      </Typography>
      <Formik<SavePolicyRequirementsAction>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isValid, values, isSubmitting }) => {
          return (
            <Form>
              <Box mt={2}>
                {products?.length && products?.length > 1 ? (
                  <Field
                    name="policyRequirementsAreTheSame"
                    component={CheckboxWithLabel}
                    type="checkbox"
                    sx={{
                      color: (theme: Theme) => theme.palette.primary,
                    }}
                    Label={{
                      label: (
                        <Typography variant="body2">
                          Policy Requirements are the same for all selected
                          Products
                        </Typography>
                      ),
                    }}
                    color="primary"
                  />
                ) : null}
                <Box mt={3}>
                  <Grid container spacing={3} flexDirection="column">
                    {values.products?.map((product, index) =>
                      (values.policyRequirementsAreTheSame &&
                        product.id === ALL_PRODUCTS_ARE_SAME) ||
                      (!values.policyRequirementsAreTheSame &&
                        product.id !== ALL_PRODUCTS_ARE_SAME) ? (
                        <Grid key={product.id} item>
                          <PolicyRequirement
                            product={product}
                            name={`products.${index}`}
                            defaultExpanded={
                              values.policyRequirementsAreTheSame || index === 1
                            }
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Typography variant="h6">Other Details</Typography>
                  <Box mt={1}>
                    <Typography variant="body2">
                      Is there anything that your current benefits&apos; broker
                      does not do now that you would really like Buffer to start
                      doing?
                    </Typography>
                    <Grid
                      container
                      justifyContent="flex-start"
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Grid item xs={isDesktop ? 6 : 12}>
                        <Box mt={1}>
                          <Field
                            component={TextField}
                            name="otherDetails"
                            fullWidth
                            size="small"
                            multiline
                            rows={3}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box mt={3}>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={!isValid || isSubmitting}
                      onClick={() => handleSaveAsDraft(values)}
                      color="success"
                    >
                      SAVE AS DRAFT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                    >
                      NEXT
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
