import { createTheme } from "@mui/material";

export default createTheme({
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 40,
    },
  },
  palette: {
    primary: {
      main: "#0669DE",
      dark: "#192954",
      light: "#E3EAF3",
      contrastText: "#FFFFFF",
    },
  },
  spacing: 8,
});
