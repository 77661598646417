import { ROUTES } from "app-constants";
import { DashboardHomeLayout } from "Components";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { QuoteRequest } from "Views";

const DefaultHomePage = () => <Navigate to={ROUTES.dashboardHome.fullPath} />;

export const DashboardHomeRoutes = () => {
  return (
    <DashboardHomeLayout>
      <Routes>
        <Route index element={<QuoteRequest />} />
        <Route path="/*" element={<DefaultHomePage />} />
      </Routes>
    </DashboardHomeLayout>
  );
};
