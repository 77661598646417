import React from "react";
import { StateEmployee } from "store";
import { EmployeeDetailsForm } from "./EmployeeDetailsForm";
import { FormValues } from "./types";

interface Props {
  onClose: () => void;
  onSave: (values: FormValues) => Promise<void>;
  open: boolean;
  onCreateNew: () => void;
  employee: StateEmployee | null;
}

export const EditEmployeeDialog = (props: Props) => {
  return <EmployeeDetailsForm {...props} title="Update Employee" />;
};
