import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Button,
  CompanyForm,
  CompanyFormProvider,
  EmployeeCensus,
  Loader,
} from "Components";
import { Company } from "gql/graphql";
import React, { useCallback, useState } from "react";
import { companySelectors, useAppSelector } from "store";
import { useResponsive } from "hooks";
import { CompanyDetailsDialogActions } from "./CompanyDetailsDialogActions";

interface Props {
  open: boolean;
  onClose: () => void;
  onSaveCompany: (payload: Partial<Company>) => Promise<void>;
}

export const CompanyDetailsDialog = ({
  open,
  onClose,
  onSaveCompany,
}: Props) => {
  const { isDesktop } = useResponsive();
  const company = useAppSelector(companySelectors.getCompany);

  const [openEmployeeCensusDialog, setOpenEmployeeCensusDialog] =
    useState(false);
  const [companyImageBase64, setCompanyImageBase64] = useState("");
  const [loading, setLoading] = useState(false);

  const onSaveBase64Image = useCallback((params: string) => {
    setCompanyImageBase64(params);
  }, []);

  const handleCloseEmployeeCensusDialog = useCallback(() => {
    setOpenEmployeeCensusDialog(false);
  }, []);

  const handleOpenEmployeeCensusDialog = useCallback(() => {
    setOpenEmployeeCensusDialog(true);
  }, []);

  const onSubmit = useCallback(
    async (params: Partial<Company>) => {
      try {
        setLoading(true);
        await onSaveCompany(params);
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [onSaveCompany]
  );

  return (
    <>
      <CompanyFormProvider
        onCancel={onClose}
        onSubmit={onSubmit}
        company={company}
        onSaveBase64Image={onSaveBase64Image}
        companyImageBase64={companyImageBase64}
      >
        <Dialog open={open} fullWidth maxWidth="lg">
          <DialogTitle
            sx={{
              background: "rgba(246, 246, 246, 1)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              Update Company Details
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Loader open={loading} />
            <Box
              sx={{
                paddingTop: (theme) => theme.spacing(3),
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems={isDesktop ? "center" : "stretch"}
                direction={isDesktop ? "row" : "column-reverse"}
              >
                <Grid
                  item
                  sx={{
                    width: !isDesktop ? "100%" : undefined,
                  }}
                >
                  <Alert severity="info">* Required for Quote Request</Alert>
                </Grid>
                <Grid
                  item
                  sx={
                    !isDesktop
                      ? {
                          display: "flex",
                          justifyContent: "flex-end",
                        }
                      : {}
                  }
                >
                  <Button onClick={handleOpenEmployeeCensusDialog}>
                    UPDATE CENSUS
                  </Button>
                </Grid>
              </Grid>
              <Box mt={3}>
                <CompanyForm />
              </Box>
            </Box>
          </DialogContent>
          <CompanyDetailsDialogActions onClose={onClose} />
        </Dialog>
      </CompanyFormProvider>

      <EmployeeCensus
        open={openEmployeeCensusDialog}
        onClose={handleCloseEmployeeCensusDialog}
      />
    </>
  );
};
