import React from "react";
import { EmployeeDetailsForm } from "./EmployeeDetailsForm";
import { FormValues } from "./types";

interface Props {
  onClose: () => void;
  onSave: (values: FormValues) => Promise<void>;
  open: boolean;
}

export const AddEmployeeDialog = (props: Props) => {
  return <EmployeeDetailsForm {...props} title="New Employee" />;
};
