import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const fontSize = 18;

export const LepCalculatorPartBResult3 = () => {
  return (
    <Box>
      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          <ArrowForward fontSize="small" /> Looks like you enrolled into
          Medicare Part B in time and{" "}
          <b>you have no Late Enrollment Penalties</b>.
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography
          sx={{
            fontSize,
          }}
        >
          But are you sure you&apos;re{" "}
          <b>getting the best bang for your buck</b> with your current Medicare
          Plan?
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize,
        }}
      >
        <u>
          <b>
            <i>Learn more about:</i>
          </b>
        </u>
      </Typography>
      <Typography
        sx={{
          fontSize,
          marginBottom: ({ spacing }) => spacing(2),
        }}
        component="ul"
      >
        <li>
          <i>The different parts and types of Medicare</i>
        </li>
        <li>
          <i>The best Medicare plans you can get</i>
        </li>
        <li>
          <i>How to maximize your Medicare benefits</i>
        </li>
      </Typography>
      <Typography
        sx={{
          fontSize,
        }}
      >
        in our{" "}
        <b>
          Free eBook: &quot;Medicare: The Guide to Protecting your Future&quot;
        </b>
      </Typography>
    </Box>
  );
};
