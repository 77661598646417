import { Box, Grid } from "@mui/material";
import React from "react";
import { Button } from "Components";
import { GetQuoteTBC } from "./GetQuoteTBC";

interface Props {
  onGetQuote: () => void;
}

export const GetQuoteActivityStep = ({ onGetQuote }: Props) => {
  return (
    <Box>
      <GetQuoteTBC />
      <Box mt={2}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button onClick={onGetQuote}>GET QUOTE</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
